import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  ScreenName: any;
  UserName: any;
  constructor() { }


}
