import { Component, OnInit, TemplateRef, ViewChild, Input, EventEmitter, Output, HostListener, Injectable, ElementRef } from '@angular/core';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { NgbModal, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PreloadService } from 'src/app/services/common/preload.service';
import { LocalStorageService } from 'angular-web-storage';
import { DataService } from 'src/app/services/common/data.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { FormatDate, IsLoggedIn, FileFilter } from 'src/app/common/common';
import { Router, CanDeactivate } from '@angular/router';
import { TitleServiceService } from 'src/app/services/common/title-service.service';
import { isNullOrUndefined, format } from 'util';
import { AttendanceValidationUI } from 'src/app/common/validationUI';
import { AttendancetimesheetService } from 'src/app/services/application/attendancetimesheet.service';
import { CalendarView, CalendarEventTimesChangedEvent, CalendarMonthViewBeforeRenderEvent, CalendarEventAction, CalendarMonthViewDay } from 'angular-calendar';
import { Subject, Observable } from 'rxjs';
import {
  startOfDay,
  endOfDay,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { CalendarEvent } from 'calendar-utils';
import { DatePipe } from '@angular/common';
import { ThemeService } from 'ng2-charts';
import { el, tr } from 'date-fns/locale';

const colors: any = {
  red: {
    primary: '#931209',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  },
  Green: {
    primary: '#1a863b',
    secondary: '#FAE3E3'
  },
  Orange: {
    primary: '#FF601e',
    secondary: '#FF601e'
  }

};

@Component({
  selector: 'app-attendance-adjust-request',
  templateUrl: './attendance-adjust-request.component.html',
  styleUrls: ['./attendance-adjust-request.component.scss'],
  styles: [
    `
      .bg-pink {
        background-color: hotpink !important;
      }
    `
  ]
})


export class AttendanceAdjustRequestComponent implements OnInit {
  @ViewChild('holidayAlert', { static: true }) holidayAlert: TemplateRef<any>;
  PageNavigateresult: boolean;
  monthly: boolean = true;  
  weekly: boolean;
  istrue :boolean=true;
  holidayalert:string;
  public selectedTime;
  public SelectedInTime: any;
  public SelectedOutTime: any;
  AttendanceAdjustmentRequestUI: any;
  SelectAttendanceAdjustmentRequest: number;
  AttendanceAdjustmentRequestData: any = [];
  EmployeeAttendancId: any;
  public GetTodayDate: any;
  public GetDay: any;
  UISelectAttendanceAdjustmentRequestDetail: any;
  AttendanceAdjustmentRequestValidationUI: AttendanceValidationUI;
  AttendanceAdjustmentRequestListUI: any = [];
  BoolDaily: boolean = true;
  BoolWeekly: boolean = false;
  BoolMonthLy: boolean = false;
  ShowDel : boolean = true;
  diff_Hours: any;
  diff_Minutes: any;
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'DD-MM-YYYY',
    defaultOpen: false,
  }
  UIDate: any;
  TotalDiff: any = "00:00";
  CurrentDates: string;
  BoolOpenMsgShow: boolean = false;
  ShowDeletebtn: boolean = true;
  IsSave: boolean = false;
  SelectedMissedHourRequestRow: number;
  SelectedMissedHourRequestDetailRow: number;
  modalReferrence: any;
  UISelectTotalAttendanceAdjustmentRequest: any;
  searchIndex: any;
  SelectAttendance: number;
  UISelectedTotalAttendanceDetail: any;
  UIRequestEndDate: NgbDateStruct;
  UIRequestBeginDate: NgbDateStruct;
  Mothlycheck: boolean;
  WeeklyCheck: boolean;
  DDLMonthly: any;
  DDLYear: any;
  DDLWeek: any;
  DDLRequestStatus: any;
  DDLEmployee: any;
  InitialDDL: any;
  monthValue: any;
  weekvalue: any;
  yearvalue: any;
  AttendanceRequestListUI: any;
  AttendanceListUI: any;

  AttendanceRequestApprovelListUI: any;
  SelectDateDetail: any;
  UISelectDateDetail: any;
  AdjustmentRequestDateDetailListUI: any;
  AttendanceAdjustmentRequestTimeDetailUI: any;
  AttendanceAdjustmentRequestTimeDetailList: any;
  SelectTimeDetail: any;
  UISelectTimeDetail: any;

  IsCheckAllRequest: boolean = false;
  TempRequestDateDetail: any;
  UITempObj: any;
  TodayDate: any;
  SelectedCalanderDate: any;
  is_edit: boolean;
  AttendanceTimeTypeUI: any;
  DDLBenefits: any;
  mwlCalendarBtnClick: boolean = false;
  moreEventStyles: any;
  moreEvents: any[];
  LeaveListUI: any = [];
  HolidayList: any = [];
  TempDateLists: any;
  RequestListUI: any;
  UITempRequestList: any;
  TempAdjustmentRequestDate: any;
  IsCheckValidOutTime: boolean = false;
  IsCheckValidInTime: boolean = false;
  selectedDate: Date;
  PreviousSelectedDate: any;
  selectedDay: any;
  UpdateBtn: boolean = false;
  EnableRequestApprvlbtn : boolean = true;
  disableExcessDutyDiv: boolean = false;
  FalseFlag: boolean = false;
  supervisorValue = false;
  // xviewDate: Date = new Date();
  selectedDays: any = [];
  selectedMonthViewDay: CalendarMonthViewDay;
  maxChars = 500;
  JsonComment: any;
  formdata: any;
  InvalidFile: boolean;
  InvalidFileError: string;
  uploadData: any[];
  @ViewChild('uploadFile', { static: false })
  UploadVariable: ElementRef;
  FileContent: string | ArrayBuffer;
  IsUploadIsVisible: boolean = false;
  MaxNonBreakWorkHrsAlertContent: string;
  max_working_hours_per_day: any;
  months: boolean = false;
  month: any;
  DisplayContentInAlert: string;
  IsUserLoggedIn: boolean;
  searchText: any;
  dateselect: string;

  show() {
    this.months = !this.month;
  }

  ngOnInit() {
    this.CreateNewAttendanceAdjustmentRequest();
    this.title.setsubTitle('Attendance');
    this.title.setTitle('My Timesheet Adjustment Request');
    this.title.setTitleforPdf('My Timesheet Adjustment Request');
    this.title.setModuleName("ApplicationModule");
    this.title.setshowpdf(true);
    this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI();
    this.SelectedInTime = "00:00";
    this.SelectedOutTime = "00:00";
    this.TotalDiff = "00:00";
  }

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
 

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [

    {
      start: addHours(startOfDay(new Date()), 2),

      title: '',
      color: colors.yellow,
      cssClass:'',
      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      draggable: false
    }
  ];

  activeDayIsOpen: boolean = false;



  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.Green,
        cssClass: '',
        draggable: false,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  constructor(
    private modal: NgbModal,
    private atp: AmazingTimePickerService,
    private title: TitleServiceService,
    private modalService: NgbModal,
    private MissedHourRequesttimesheetService: AttendancetimesheetService,
    public preLoader: PreloadService,
    public localStorage: LocalStorageService,
    public dataService: DataService,
    public alert: AlertService,
    public dateFormat: FormatDate,
    public ngbDateParserFormatter: NgbDateParserFormatter,
    public IsLoggedIn: IsLoggedIn,
    public datePipe: DatePipe,
    public router: Router,
    private dateParseFormatter: NgbDateParserFormatter,
  ) 
  {
    this.IsUserLoggedIn = this.IsLoggedIn.IsUserLoggedIn();
    if (!this.IsUserLoggedIn) {
      this.router.navigate(['/login'])
      return;
    }
    this.searchText;
    let today: any = new Date();  
    this.SelectedCalanderDate = today;
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    if (this.UITempRequestList.strattendance_adjustment_request_status_value == 'PNSUB') {
      this.PageNavigateresult = false;

      this.PageNavigateresult = window.confirm("Changes you made may not be saved");
      console.log(event);
      console.log(this.PageNavigateresult);
      if (this.PageNavigateresult == true) {
        this.DeleteAttendanceAdjustmentRequest();
      }
      return this.PageNavigateresult;
    }
  }

  /**
   * name
   */
  public setFormSubmitting() {
    if (this.PageNavigateresult == true) {
      this.DeleteAttendanceAdjustmentRequest();
    }
  }
  public preventInput(event) {
    console.log(event.keyCode);
    switch (event.keyCode) {
      case 101: //e
      case 18 && 115: //.
        event.preventDefault();
        break;
    }
  }


  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent,
    SelectedDate): void {
    console.log(SelectedDate, "SelectedDate");
    console.log(renderEvent, "10");
    renderEvent.body.forEach(day => {
      if (renderEvent.body.indexOf != null) {
        //  day.cssClass = 'cal-day-selected';
      }
    });
  }
  dayClicked(day: CalendarMonthViewDay, xdate: CalendarMonthViewDay,
    events: CalendarEvent[]): void {
    this.selectedMonthViewDay = day;
    const dateIndex = this.selectedDays.findIndex(
      (selectedDay) => {
        delete selectedDay.cssClass;
      }
    );
    this.selectedDays.push(this.selectedMonthViewDay);
    day.cssClass = 'cal-day-selected';
    this.selectedMonthViewDay = day;
    const date = xdate.date;

    this.activeDayIsOpen = false;
    this.formdata = null;
    this.disableExcessDutyDiv = false;
    this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.dtmadjustment_date =
     date.toLocaleDateString('zh-Hans-CN').replace(new RegExp('/', 'g'),'-');
    this.dateselect=this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.dtmadjustment_date;
    this.SelectedCalanderDate = date;
    this.IsCheckValidOutTime = false;
    this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI();
    
    this.GetAttendanceDetailFromSelectedDate(this.AttendanceAdjustmentRequestUI);
  //  this.preLoader.show(false);
  }

  countErrors(): number {
    let length = 0;
    this.events.filter(event => {
      if (event.meta.type === 0) {
        length++;
      }
    })
    return length;
  }

  closeOpenMonthViewDayForInitalLoad() {
    let today: any = new Date();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    this.BoolWeekly = false;
    this.BoolDaily = false;
    this.BoolMonthLy = true;
    this.IsCheckValidOutTime = false;
    this.IsCheckValidInTime = false;
    if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI)) {
      this.AttendanceAdjustmentRequestUI.BoolDaily = false;
      this.AttendanceAdjustmentRequestUI.BoolWeekly = false;
      this.AttendanceAdjustmentRequestUI.BoolMonthLy = true;
      this.AttendanceAdjustmentRequestUI.istrMonth = month;
      this.AttendanceAdjustmentRequestUI.istrWeek = "";
      this.AttendanceAdjustmentRequestUI.istrYear = year;
      this.InitialLoadOnAAR(this.AttendanceAdjustmentRequestUI);
      this.activeDayIsOpen = false;
      this.mwlCalendarBtnClick = false;
      this.formdata = null;
      this.IsHoliday();
      this.refresh.next();
    }
  }

  closeOpenMonthViewDay() {
    
    this.mwlCalendarBtnClick = true;
    this.IsCheckValidOutTime = false;
    this.IsCheckValidInTime = false;
    this.AttendanceAdjustmentRequestUI.dtmDate = this.viewDate;
    let today: any = new Date(this.AttendanceAdjustmentRequestUI.dtmDate);
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    this.BoolWeekly = false;
    this.BoolDaily = false;
    this.BoolMonthLy = true;
    if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI)) {
      this.AttendanceAdjustmentRequestUI.BoolDaily = false;
      this.AttendanceAdjustmentRequestUI.BoolWeekly = false;
      this.AttendanceAdjustmentRequestUI.BoolMonthLy = true;
      this.AttendanceAdjustmentRequestUI.istrMonth = month;
      this.AttendanceAdjustmentRequestUI.istrWeek = "";
      this.AttendanceAdjustmentRequestUI.istrYear = year;
      this.InitialLoadOnAAR(this.AttendanceAdjustmentRequestUI);
      this.formdata = null;
      this.IsHoliday();
    }
    this.activeDayIsOpen = false;
  }

  public getDateAndDay() {
    let todayDate: any = new Date();
    let date = todayDate.getDate();
    let month = todayDate.getMonth() + 1;
    let year = todayDate.getFullYear();
    this.TodayDate = <string>date + "-" + <string>month + "-" + <string>year;
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    this.GetDay = gsDayNames[todayDate.getDay()];
  }

  //////***********************************************load Atttendance *****************************************//


  GetAttendanceRequestInitialData() {
    this.preLoader.show(true);
    this.Mothlycheck = true;
    this.WeeklyCheck = false;

    this.MissedHourRequesttimesheetService.GetAttendanceRequestInitialData().subscribe(
      data => { this.GetUserInitialDataSuccess(data) }, error => { this.GetAttendanceInitialDataError(error) });
  }

  GetUserInitialDataSuccess(data: any) {
    this.InitialDDL = data;
    // this.BoolWeekly = false;
    // this.BoolDaily = false;
    // this.BoolMonthLy = true;
    this.SetDDLInLocalStorage();
    this.InitializeDDL();
    this.preLoader.show(false);
  }

  GetAttendanceInitialDataError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

  SetDDLInLocalStorage() {
    this.localStorage.set("DDLBenefits", this.InitialDDL.DDLBenefits);
    this.localStorage.set("DDLEmployee", this.InitialDDL.DDLEmployee);
  }

  InitializeDDL() {
    if (this.localStorage.get("DDLBenefits") != null) {
      this.DDLBenefits = this.localStorage.get("DDLBenefits");
    }
    if (this.localStorage.get("DDLEmployee") != null) {
      this.DDLEmployee = this.localStorage.get("DDLEmployee");
      
    }
  }

  CreateInitializeDDL() {
    this.localStorage.set("DDLBenefits", this.AttendanceAdjustmentRequestUI.ilistUIDDLExcessDuty);
    this.localStorage.set("DDLEmployee", this.AttendanceAdjustmentRequestUI.ilistUIDDLReportToEmployee);
    if (this.localStorage.get("DDLEmployee") != null) {
      this.DDLEmployee = this.localStorage.get("DDLEmployee");
      
    }
    if (this.localStorage.get("DDLBenefits") != null) {
      this.DDLBenefits = this.localStorage.get("DDLBenefits");
    }
  }

  public InitialLoadOnAAR(data: any) {
    this.preLoader.show(true);
    this.MissedHourRequesttimesheetService.LoadAttendanceAdjustmentRequest(data)
      .subscribe(
        data => { this.LoadAttendanceOnDeilyMonthlyWeeklySuccess(data) }, error => { this.LoadAttendanceRequestApprovelError(error) });
  }

  LoadAttendanceOnDeilyMonthlyWeeklySuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.RequestListUI = data;
      this.UISelectTimeDetail = null;
      this.SelectTimeDetail = null;

      this.TempDateLists = data.ilistbusUIAttendanceAdjustmentRequestDateDetail;

     
      var TempDateList = data.ilistbusUIAttendanceAdjustmentRequestDateDetail;
      // if (TempDateList != null && TempDateList.length > 0) {
      //   this.events = this.GetEventData();
      // } else {
      //   this.events = [];
      // }
 

    } else {
      this.events = [];
      this.AttendanceAdjustmentRequestListUI = data;
    }
    this.formdata = null;
    this.IsHoliday();
    this.disableExcessDutyDiv = false;
    this.EnableRequestApprvlbtn = true;
    this.refresh.next();    
    this.LoadLeaveshowonTimesheetAdjustment(this.AttendanceAdjustmentRequestUI);
    this.preLoader.show(false);
  }

  LoadAttendanceRequestApprovelError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

  // public GetEventData() {
  //   let TempDateList = this.TempDateLists;
  //   this.events = [];
  //   for (let i = 0; i < TempDateList.length; i++) {
  //     let std = this.TimeFormat(<string>TempDateList[i].istrstd_total_hours);
  //     let flx = this.TimeFormat(<string>TempDateList[i].istrflex_total_hours);
  //     let exc = this.TimeFormat(<string>TempDateList[i].istrexcess_duty_hours);
  //     this.events = this.GetEvent_std(TempDateList[i], std), this.GetEvent_Flex(TempDateList[i], flx),
  //       this.GetEvent_Exc(TempDateList[i], exc);
  //   }
  //   return this.events;
  // }

  public GetEventData() {   
    debugger;
    this.preLoader.show(true);
    let TempHolidayList=this.HolidayList;
    let TempDateList =this.TempDateLists;
    let TempLeaveList = this.LeaveListUI;

    this.events = [];

    if (TempHolidayList) {
      for (let i = 0; i < TempHolidayList.length; i++) {
        //  let std = TempDateList[i].strstd_total_hours;
        //  let flx = TempDateList[i].strflex_total_hours;
        //  let exc = TempDateList[i].strtotal_Over_Time_hours;
        this.events = this.GetEvent_Holiday(TempHolidayList[i]);
      }
    }

    if (TempDateList) {
      for (let i = 0; i < TempDateList.length; i++) {
        let std = this.TimeFormat(<string>TempDateList[i].istrstd_total_hours);
        let flx = this.TimeFormat(<string>TempDateList[i].istrflex_total_hours);
        let exc = this.TimeFormat(<string>TempDateList[i].istrexcess_duty_hours);
        this.events = this.GetEvent_std(TempDateList[i], std), this.GetEvent_Flex(TempDateList[i], flx),
          this.GetEvent_Exc(TempDateList[i], exc);
      }
    }
    if (TempLeaveList) {
      for (let i = 0; i < TempLeaveList.length; i++) {
        this.events = this.GetEvent_leave(TempLeaveList[i]);
      }
    }   
    this.preLoader.show(false);   
    return this.events;
  }

  public GetEvent_Holiday(data: any) {
    if (data != null) {
      [
        this.events =
        [
          ...this.events,
          {
            title:
            // 'Holiday',
            data.strholiday_name,
            start: startOfDay(new Date(data.dtmholiday_date)),
            end: endOfDay(new Date(data.dtmholiday_date)),
            cssClass: 'holiday',
            color: colors.Orange,
            draggable: true,

            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          }
        ]
      ];
    }
    return this.events;
  }

  public GetEvent_leave(data: any) {
    console.log(data);
    [
      this.events =
      [
        ...this.events,
        {
          id: "leave",
          title: 'LEAVE (' + data.leavepolicycode + ')',
          start: startOfDay(new Date(data.dtleave_request_from_date
          )),
          end: endOfDay(new Date(data.dtleave_request_to_date
          )),
          allDay: true,
          color: colors.red,
          cssClass: '',
          //color:'RED',
          resizable: {
            beforeStart: true,
            afterEnd: true
          },
          draggable: false,
          meta: {
            type: 0
          },
        }
      ]
    ];

    return this.events;
  }

  public GetEvent_std(data: any, std: any) {
    if (std != null 
     && std != "00:00"
     ) {
      [
        this.events =
        [
          ...this.events,
          {
            title: 'SH - ' + std,
            start: startOfDay(new Date(data.dtmadjustment_date)),
            end: endOfDay(new Date(data.dtmadjustment_date)),
            color: colors.Green,
            cssClass: '',
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          }
        ]
      ];
    }   
    return this.events;
  }
  public GetEvent_Flex(data: any, Flex: any) {
    if (Flex != null 
     && Flex != "00:00"
    ) {
      [
        this.events =
        [
          ...this.events,
          {
            title: 'FH - ' + Flex,
            start: startOfDay(new Date(data.dtmadjustment_date)),
            end: endOfDay(new Date(data.dtmadjustment_date)),
            cssClass: '',
            color: colors.blue,
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          }
        ]
      ];
    }
    return this.events;
  }
  public GetEvent_Exc(data: any, Exc: any) {
    if (Exc != null 
     && Exc != "00:00"
    ) {
      [
        this.events =
        [
          ...this.events,
          {
            title: 'EH - ' + Exc,
            start: startOfDay(new Date(data.dtmadjustment_date)),
            end: endOfDay(new Date(data.dtmadjustment_date)),
            cssClass: '',
            color: colors.yellow,
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          }
        ]
      ];
    }
    return this.events;
  }


  selectedEmployeeDetail(Id: number) {
    if (Id > 0) {
      this.closeOpenMonthViewDayForInitalLoad();
      this.AdjustmentRequestDateDetailListUI = null;
      this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.istrcomments = "";
      this.AttendanceAdjustmentRequestTimeDetailList = null;
      this.AttendanceListUI = null;
      this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.dtmadjustment_date = null;
    }
  }

  public GetAttendanceDetailFromSelectedDate(SelectedDate) {
    SelectedDate.lngattendance_adjustment_request_id = 0;
    this.SelectDateDetail = null;
    this.preLoader.show(true);
    this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI();
    this.MissedHourRequesttimesheetService.GetAttendanceDetailFromSelectedDate(SelectedDate).subscribe(
      data => { this.GetAttendanceDetailFromSelectedDateSuccess(data) }, error => { this.GetAttendanceDetailFromSelectedDateError(error) });
  }  

  GetAttendanceDetailFromSelectedDateSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        this.AttendanceAdjustmentRequestUI = data;
        this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.dtmadjustment_date
          = this.SelectedCalanderDate.toLocaleDateString('zh-Hans-CN').replace(new RegExp('/', 'g'),'-');
        this.DateDetailListBinding(data);
        //this.GetSupervisorddl();
        this.AttendanceAdjustmentRequestTimeDetailList = data.ibusUIAttendanceAdjustmentRequestDateDetail.ilistbusUIAttendanceAdjustmentRequestTimeDetail;
        this.AttendanceListUI = data.ibusUIAttendanceAdjustmentRequestDateDetail.ilistbusUIAttendanceDetail;
        this.SelectedInTime = "00:00";
        this.SelectedOutTime = "00:00";
        this.TotalDiff = "00:00";
        this.SelectTimeDetail = null;
        this.SelectAttendance = null;
        this.IsCheckValidOutTime = false;
        this.IsCheckValidInTime = false;
        this.UpdateBtn = false;
        if (this.localStorage.get("supervisor") != null) {
          this.AttendanceAdjustmentRequestUI.lngsupervisor_id = this.localStorage.get("supervisor");
        }
if(this.AttendanceAdjustmentRequestUI.strattendance_adjustment_request_status_value =='APRVD' ||
this.AttendanceAdjustmentRequestUI.strattendance_adjustment_request_status_value == 'RECAN'
)
{
  if(this.AttendanceAdjustmentRequestUI.strattendance_adjustment_request_status_value != 'RECAN')
  {
  this.AttendanceAdjustmentRequestUI.IsEligibleCancelRequestedLeaveAfterApproved = true;
  }
  this.ShowDel = false;
}
else
{
  this.ShowDel = true;
}
      this.JsonComment = JSON.parse(JSON.stringify(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.istrcomments));
      this.IsHoliday();
      this.Holidayalert(this.dateselect);
      }
      else {
        let i = data.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
      }
    }
    this.preLoader.show(false);
  }

  GetAttendanceDetailFromSelectedDateError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }
  supervisorChange() {
    this.localStorage.set('supervisor',this.AttendanceAdjustmentRequestUI.lngsupervisor_id);
  }


  public OpenAttendanceAdjustmentRequestDateDetail(value: any) {
    if (!isNullOrUndefined(value)) {
      this.preLoader.show(true);
      this.UISelectDateDetail = value;
      this.MissedHourRequesttimesheetService.
        OpenAttendanceAdjustRequestDateDetail(this.UISelectDateDetail.ilngattendance_adjustment_request_date_detail_id).subscribe(
          data => { this.OpenAttendanceAdjustmentRequestDateDetailUISuccess(data) },
          error => { this.OpenAttendanceAdjustmentRequestDateDetailUIError(error) }
        );
    }
  }

  private OpenAttendanceAdjustmentRequestDateDetailUISuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        this.AttendanceAdjustmentRequestUI = data;
        this.AttendanceAdjustmentRequestTimeDetailList = data.ibusUIAttendanceAdjustmentRequestDateDetail.ilistbusUIAttendanceAdjustmentRequestTimeDetail;
        this.AttendanceListUI = data.ibusUIAttendanceAdjustmentRequestDateDetail.ilistbusUIAttendanceDetail;
        this.SelectTimeDetail = null;
        this.SelectAttendance = null;
        this.SelectedInTime = "00:00";
        this.SelectedOutTime = "00:00";
        this.TotalDiff = "00:00";
        this.ShowSuccessAlert("Data selected and opened successfully.");
        this.JsonComment = JSON.parse(JSON.stringify(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.istrcomments));
        this.IsHoliday();
      }
      else {
        let i = data.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
      }
    }
    this.preLoader.show(false);
  }

  private OpenAttendanceAdjustmentRequestDateDetailUIError(error: any) {
    this.dataService.GetCommonErrorMessage(error);
  }


  public IconClickOpenAttendanceAdjustmentRequestTimeDetail() {
    if (!isNullOrUndefined(this.UISelectTimeDetail) &&
      this.UISelectTimeDetail.lngattendance_adjustment_request_time_detail_id > 0) {
      this.OpenAttendanceAdjustmentRequestTimeDetail(this.UISelectTimeDetail.lngattendance_adjustment_request_time_detail_id);
    }
    else {
      this.ShowWarinngAlert("Please select any one Time Detail.");
    }
  }


  public RefreshAttendanceAdjustmentReq() {

    this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI();
    if (!(isNullOrUndefined(this.AttendanceAdjustmentRequestUI))
      && !(isNullOrUndefined(this.AttendanceAdjustmentRequestUI)) &&
      !(isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail)) &&
      this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestTimeDetail.lngattendance_adjustment_request_time_detail_id > 0) {
      this.OpenAttendanceAdjustmentRequestTimeDetail(this.UISelectTimeDetail.lngattendance_adjustment_request_time_detail_id);
    }
  }


  ShowWarinngAlert(Msg: any) {
    this.alert.ShowWarningAlert(true);
    this.alert.SetWarningMsg(Msg);
  }



  public OpenAttendanceAdjustmentRequestTimeDetail(Id: any) {
    let value = Id;
    if (!isNullOrUndefined(Id) && Id > 0) {
      let AttendanceAdjustmentRequestUITimeID = Id;
      this.preLoader.show(true);
      this.MissedHourRequesttimesheetService.OpenAttendanceAdjustmentRequestTimeDetail(AttendanceAdjustmentRequestUITimeID).subscribe(
        data => { this.OpenAttendanceAdjustmentRequestTimeDetailUISuccess(data) },
        error => { this.OpenAttendanceAdjustmentRequestTimeDetailUIError(error) }
      );
    }
  }

  private OpenAttendanceAdjustmentRequestTimeDetailUISuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        this.UpdateBtn = true;
        this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI()
        this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail = data;
        this.ConvertDateToTime();
        if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.
          ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value) &&
          this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.
            ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value != "") {
          this.disableExcessDutyDiv = true;
          this.IsVisableUploadBtn();
        } else {
          this.IsUploadIsVisible = false;
          this.disableExcessDutyDiv = false;
        }
        this.formdata = null;
        this.selectedTimeTotalHrBinding();
        this.ShowSuccessAlert(data.iInfoMessage);
      }
      else {
        let i = data.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
      }
    }
    this.preLoader.show(false);
  }

  selectedTimeTotalHrBinding() {
    var addHrs;
    var addMins;
    if (!isNullOrUndefined(this.SelectedInTime) && this.SelectedInTime != ""
      && !isNullOrUndefined(this.SelectedOutTime) && this.SelectedOutTime != "") {
      var inTime = this.SelectedInTime;
      var outTime = this.SelectedOutTime;

      var Indate = new Date();
      var INdateTimes = new Date((Indate.getMonth() + 1) + "/" + Indate.getDate() + "/" + Indate.getFullYear() + " " + inTime);
      var INdateTime = INdateTimes.getTime();

      var Outdate = new Date();
      var OutdateTimes = new Date((Outdate.getMonth() + 1) + "/" + Outdate.getDate() + "/" + Outdate.getFullYear() + " " + outTime);
      var OutdateTime = OutdateTimes.getTime();

      if (INdateTime != OutdateTime) {
        if (INdateTime > OutdateTime) {

          var GRT12CurrentDate = new Date();
          var GRT12CurrentTime = new Date((GRT12CurrentDate.getMonth() + 1)
            + "/" + GRT12CurrentDate.getDate() + "/" + GRT12CurrentDate.getFullYear() + " " +
            "12:00");

          var G12CurrentTime = GRT12CurrentTime.getTime();

          if (INdateTime > G12CurrentTime && OutdateTime < G12CurrentTime) {

            var Add12CurrentoutTimes = new Date();
            Add12CurrentoutTimes = new Date((Add12CurrentoutTimes.getMonth() + 1)
              + "/" + (Add12CurrentoutTimes.getDate() + 1) + "/" + Add12CurrentoutTimes.getFullYear() + " " +
              outTime);

            var delta = Math.abs(Add12CurrentoutTimes.getTime() - INdateTimes.getTime()) / 1000;
            console.log(delta);

            addHrs = Math.floor(delta / 3600) % 24;

            addMins = Math.floor(delta / 60) % 60;

          }
          else {
            var delta = Math.abs(OutdateTimes.getTime() - INdateTimes.getTime()) / 1000;
            console.log(delta);

            addHrs = Math.floor(delta / 3600) % 24;

            addMins = Math.floor(delta / 60) % 60;
          }
        } else {
          var delta = Math.abs(OutdateTimes.getTime() - INdateTimes.getTime()) / 1000;
          console.log(delta);

          addHrs = Math.floor(delta / 3600) % 24;

          addMins = Math.floor(delta / 60) % 60;
        }
      }
      if (addHrs == null)
        addHrs = 0;

      if (addMins == null)
        addMins = 0;

      if (addHrs < 10) {

        addHrs = String(addHrs).padStart(2, '0');
      }
      if (addMins < 10) {

        addMins = String(addMins).padStart(2, '0');
      }
      this.TotalDiff = addHrs + ":" + addMins
    }
  }

  private OpenAttendanceAdjustmentRequestTimeDetailUIError(error: any) {
    this.dataService.GetCommonErrorMessage(error);
  }

  public CancelAAR() {
    this.preLoader.show(true);
    this.MissedHourRequesttimesheetService.CancelAttendanceAdjustmentRequest().subscribe(
      data => { this.CancelAttendanceAdjustmentRequestSuccess(data) }, error => { this.CreateNewAttendanceAdjustmentRequestError(error) });
  }
  CancelAttendanceAdjustmentRequestSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.AttendanceAdjustmentRequestUI = data;
      this.SelectedMissedHourRequestDetailRow = null;
      this.UIDate = null;
      this.UISelectTimeDetail = null;
      this.SelectTimeDetail = null;
      this.SelectedInTime = "00:00";
      this.SelectedOutTime = "00:00";
      this.TotalDiff = "00:00";
      this.disableExcessDutyDiv = false;
      this.formdata = null;
      this.formdata = null;
      this.max_working_hours_per_day = this.AttendanceAdjustmentRequestUI.dtmmax_non_break_work_hours;
      this.CreateInitializeDDL();
      this.UpdateBtn = false;
      this.AttendanceAdjustmentRequestTimeDetailList = data.
      ibusUIAttendanceAdjustmentRequestDateDetail.ilistbusUIAttendanceAdjustmentRequestTimeDetail;
      this.DateDetailListBinding(data);
      this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI();
      this.IsHoliday();
    }
    this.preLoader.show(false);
  }

  public CreateNewAttendanceAdjustmentRequest() {
    this.preLoader.show(true);
    this.MissedHourRequesttimesheetService.CreateNewAttendanceAdjustmentRequest().subscribe(
      data => { this.CreateNewAttendanceAdjustmentRequestSuccess(data) }, error => { this.CreateNewAttendanceAdjustmentRequestError(error) });
  }

  CreateNewAttendanceAdjustmentRequestSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.AttendanceAdjustmentRequestUI = data;
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
      this.SelectedMissedHourRequestDetailRow = null;
      this.UIDate = null;
      this.UISelectTimeDetail = null;
      this.SelectTimeDetail = null;
      this.SelectedInTime = "00:00";
      this.SelectedOutTime = "00:00";
      this.TotalDiff = "00:00";
      this.max_working_hours_per_day = this.AttendanceAdjustmentRequestUI.dtmmax_non_break_work_hours;
     
      this.GetSupervisorddl();
      this.CreateInitializeDDL();
      this.closeOpenMonthViewDayForInitalLoad();
      this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI();
      }
      else
      {
        let i = data.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
        this.preLoader.show(false);
      }
    }
  //  this.preLoader.show(false);
  }

  CreateNewAttendanceAdjustmentRequestError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }


 ////////////////////////////////////////////supervisor
 public GetSupervisorddl() {
 
  if(this.AttendanceAdjustmentRequestUI.lngemployee_Id>0)
  {
    this.preLoader.show(true);  //    
    let EmpId = this.AttendanceAdjustmentRequestUI.lngemployee_Id;
    let Value = {EmpId};
    this.MissedHourRequesttimesheetService.GetSupervisorddlattendanceadjust(Value)
      .subscribe(
         data => { this.GetSupervisorddlSuccess(data) },
         error => { this.GetSupervisorddlError(error) }
        );
  }
  else
  {
   
    this.AttendanceAdjustmentRequestUI.ibusAttendanceAdjustmentRequestDateDetail.lngSupervisor_id=0;
    this.DDLReportTo=[];
  }
  }
  
       
  DDLReportTo:any;
  private GetSupervisorddlSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      let response = data;
      if (response.length > 0) {
        this.DDLReportTo = response;
        console.log("this.DDLReportTo",this.DDLReportTo);
        this.AttendanceAdjustmentRequestUI.lngsupervisor_id=this.AttendanceAdjustmentRequestUI.ibusUIUser.user_serial_id
        console.log(this.AttendanceAdjustmentRequestUI.ibusUIUser.user_serial_id);
      }
      else {
        this.DDLReportTo=[];
        this.AttendanceAdjustmentRequestUI.lngSupervisor_id=0;
      }
    }
    this.preLoader.show(false);
  }
  private GetSupervisorddlError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }
   




  public ClearNewAttendanceAdjustmentRequest() {
  //this.GetAttendanceDetailFromSelectedDate(this.selectedDate);
  //this.CreateNewAttendanceAdjustmentRequest();
    this.SelectedInTime = "00:00";
    this.SelectedOutTime = "00:00";
    this.TotalDiff = "00:00";
    this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI();
    this.disableExcessDutyDiv = false;
    this.formdata = null;
    this.AttendanceAdjustmentRequestUI.
    ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.ibusUIAttendanceAdjustmentRequestDocument.strDocument_name=null;
    this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.istrcomments = this.JsonComment;
    this.IsHoliday();
    this.ShowInfoAlert("Values cleared Successfully.");
  }
  public CancelAttendanceAdjustmentRequest() {
    this.SelectTimeDetail = null;
    this.SelectedInTime = "00:00";
    this.SelectedOutTime = "00:00";
    this.TotalDiff = "00:00";
    this.UpdateBtn = false;
    this.CancelAAR();
    this.AttendanceAdjustmentRequestTimeDetailList = null;
    this.selectedDate = null;
    this.SelectDateDetail = null;
    this.ShowInfoAlert("Values Cleared Successfully.");
  }


  public UpdateAttendanceAdjustmentRequestForApproval() {
    this.supervisorValue = false; 
  if( this.AttendanceAdjustmentRequestUI.lngsupervisor_id == 0){
    this.supervisorValue = true;
    return
  }
    if (!(isNullOrUndefined(this.AttendanceAdjustmentRequestUI))) {
      this.preLoader.show(true);
      this.supervisorValue = false; 
      this.MissedHourRequesttimesheetService.UpdateAttendanceAdjustmentRequestForApproval(this.AttendanceAdjustmentRequestUI).subscribe(
        data => { this.UpdateAttendanceAdjustmentRequestSuccess(data) }, error => { this.SaveAttendanceAdjustmentRequestError(error) });
    }

  }


  UpdateAttendanceAdjustmentRequestSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      console.log(this.AttendanceAdjustmentRequestUI);
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        this.AttendanceAdjustmentRequestUI = data;
        this.UITempRequestList = data;
        this.ShowSuccessAlert(data.iInfoMessage);
      }
    }
    this.disableExcessDutyDiv = false;
    this.IsHoliday();
    this.formdata = null;
    console.log(this.AttendanceAdjustmentRequestUI);
    console.log(this.AdjustmentRequestDateDetailListUI);
    this.preLoader.show(false);
  }

  public SaveStdTimeAttendanceAdjustReq(STDDeleteContent) {
    if (!(isNullOrUndefined(this.AttendanceAdjustmentRequestUI)) &&
      !(isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail))) {
      if (this.IsValidAttendanceForSTDBtn()) {
        if (this.IsCheckShowSTDDeleteAlert(STDDeleteContent)) {
          this.preLoader.show(true);
          this.MissedHourRequesttimesheetService.SaveStdTimeAttendanceAdjustReq(this.AttendanceAdjustmentRequestUI).subscribe(
            data => { this.SaveAttendanceAdjustmentRequestSuccess(data) }, error => { this.SaveAttendanceAdjustmentRequestError(error) });
        }
      }
    }
  }
  public AllowToSaveSTDAttendanceAdjustReq() {
    this.modalReferrence.close();
    if (!(isNullOrUndefined(this.AttendanceAdjustmentRequestUI))) {
      this.preLoader.show(true);
      this.MissedHourRequesttimesheetService.SaveStdTimeAttendanceAdjustReq(this.AttendanceAdjustmentRequestUI).subscribe(
        data => { this.SaveAttendanceAdjustmentRequestSuccess(data) }, error => { this.SaveAttendanceAdjustmentRequestError(error) });
    }
  }

  public AllowToSaveAttendanceAdjustmentRequest() {
    this.modalReferrence.close();
    if (!(isNullOrUndefined(this.AttendanceAdjustmentRequestUI))) {
      this.preLoader.show(true);
      this.MissedHourRequesttimesheetService.SaveAttendanceAdjustmentRequest(this.AttendanceAdjustmentRequestUI).subscribe(
        data => { this.SaveAttendanceAdjustmentRequestSuccess(data) }, error => { this.SaveAttendanceAdjustmentRequestError(error) });
    }
  }

  public SaveAttendanceAdjustmentRequest(MaxNonBreakWorkHrsAlert) {
    if (!(isNullOrUndefined(this.AttendanceAdjustmentRequestUI)) &&
      !(isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail))) {
      if (this.IsValidAttendanceAdjustmentRequest(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail)) {
        if (this.IsCheckValidOutTime == false && this.IsCheckValidInTime == false) {
          if (this.IsCheckMaxNonBreakWorkHrs(MaxNonBreakWorkHrsAlert)) {
            if(this.istrue==false)
            {
              return;
            }
            this.preLoader.show(true);
            this.MissedHourRequesttimesheetService.SaveAttendanceAdjustmentRequest(this.AttendanceAdjustmentRequestUI).subscribe(
              data => { this.SaveAttendanceAdjustmentRequestSuccess(data) }, error => { this.SaveAttendanceAdjustmentRequestError(error) });
          }
        }
      }
    }
  }


  SaveAttendanceAdjustmentRequestSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        this.disableExcessDutyDiv = false;
        this.AttendanceAdjustmentRequestUI = data;
        this.formdata = null;
        this.DateDetailListBinding(data);
        this.GetSupervisorddl();
        this.AttendanceAdjustmentRequestTimeDetailList = data.ibusUIAttendanceAdjustmentRequestDateDetail.ilistbusUIAttendanceAdjustmentRequestTimeDetail;
        this.UITempRequestList = data;
        this.IsHoliday();

       //this.LoadAttendaceAfterSaveAndDelete(data);
         if (this.mwlCalendarBtnClick == true) {
         this.closeOpenMonthViewDay();
         } 
        else {
          this.closeOpenMonthViewDayForInitalLoad();
        }

        this.UISelectTimeDetail = null;
        this.SelectTimeDetail = null;
        this.SelectedInTime = "00:00";
        this.SelectedOutTime = "00:00";
        this.TotalDiff = "00:00";
        this.UISelectTotalAttendanceAdjustmentRequest = data;
        this.IsSave = true;
        this.JsonComment = JSON.parse(JSON.stringify(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.istrcomments));

        if (this.UITempRequestList.iInfoMessage != null) {
          this.ShowSuccessAlert(this.UITempRequestList.iInfoMessage);
        } else {
          this.ShowSuccessAlert('Data Updated Successfully.');
        }

      }
      else {
        let i = data.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
      }
    }

    this.preLoader.show(false);
  }



  SaveAttendanceAdjustmentRequestError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }


  public LoadAttendaceAfterSaveAndDelete(data) {
    this.LoadAttendanceOnDeilyMonthlyWeeklySuccess(data);
  }


// AllowToSaveSTDAttendanceAdjustReqBothStdTimeandExistingTime

public AllowToSaveSTDAttendanceAdjustReqBothStdTimeandExistingTime() {
  this.modalReferrence.close();
  if (!(isNullOrUndefined(this.AttendanceAdjustmentRequestUI))) {
    this.preLoader.show(true);
    this.MissedHourRequesttimesheetService.AllowToSaveSTDAttendanceAdjustReqBothStdTimeandExistingTime(this.AttendanceAdjustmentRequestUI).subscribe(
      data => { this.AllowToSaveSTDAttendanceAdjustReqBothStdTimeandExistingTimeSuccess(data) }, 
      error => { this.AllowToSaveSTDAttendanceAdjustReqBothStdTimeandExistingTimeError(error) });
  }
}

AllowToSaveSTDAttendanceAdjustReqBothStdTimeandExistingTimeSuccess(data: any) {
  if (!isNullOrUndefined(data)) {
    if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
      this.disableExcessDutyDiv = false;
      this.AttendanceAdjustmentRequestUI = data;
      this.formdata = null;
      this.DateDetailListBinding(data);
      this.AttendanceAdjustmentRequestTimeDetailList = data.ibusUIAttendanceAdjustmentRequestDateDetail.ilistbusUIAttendanceAdjustmentRequestTimeDetail;
      this.UITempRequestList = data;
      this.IsHoliday();
      if (this.mwlCalendarBtnClick == true) {
        this.closeOpenMonthViewDay()
      }
       else {
        this.closeOpenMonthViewDayForInitalLoad();
      }
      this.UISelectTimeDetail = null;
      this.SelectTimeDetail = null;
      this.SelectedInTime = "00:00";
      this.SelectedOutTime = "00:00";
      this.TotalDiff = "00:00";
      this.UISelectTotalAttendanceAdjustmentRequest = data;
      this.IsSave = true;
      this.JsonComment = JSON.parse(JSON.stringify(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.istrcomments));
      if (this.UITempRequestList.iInfoMessage != null) {
        this.ShowSuccessAlert(this.UITempRequestList.iInfoMessage);
      } else {
        this.ShowSuccessAlert('Data Updated Successfully.');
      }
    }
    else {
      let i = data.ilstErrorMessages.length
      this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
    }
  }
  this.preLoader.show(false);
}

AllowToSaveSTDAttendanceAdjustReqBothStdTimeandExistingTimeError(error: any | string) {
  this.dataService.GetCommonErrorMessage(error);
}

///

  IsCheckShowSTDDeleteAlert(STDDeleteContent): boolean {
    if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI)) {
      if (this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ilistbusUIAttendanceAdjustmentRequestTimeDetail != null &&
        this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ilistbusUIAttendanceAdjustmentRequestTimeDetail.length > 0) {
        this.DisplayContentInAlert =
          'To set the standard time, existing In-Time and Out-Time details will be removed.  Do you want to continue?';
        this.modalReferrence = this.modalService.open(STDDeleteContent, { backdrop: 'static' });
        return false;
      }
    }
    return true;
  }

  IsValidAttendanceForSTDBtn() {
    let IsValidAttendance = false;
    this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI();
    let errorCount = 0;
    let istrcomments = this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.istrcomments;
    if (this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.dtmadjustment_date == null) {
      this.ShowErrorAlert(1, "Please Select Adjustment Request Date.", 'ER001');
      errorCount++;
    } 
    else {
      if (istrcomments == null || istrcomments == "") {
        this.AttendanceAdjustmentRequestValidationUI.Comments = true;
        errorCount++;
      }
    }
    if (this.IsUploadIsVisible == true &&
      isNullOrUndefined(this.AttendanceAdjustmentRequestUI
        .ibusUIAttendanceAdjustmentRequestDateDetail.
        ibusUIAttendanceAdjustmentRequestTimeDetail.ibusUIAttendanceAdjustmentRequestDocument.strDocument_name)) {
      errorCount++;
      this.ShowErrorAlert(1, "Selected day is Holiday. Please Upload an approval document.", 'ER001');
    }
    this.alert.ClearMsgPanels();
    if (errorCount > 0) {
      return false;
    }
    else {
      IsValidAttendance = true;
      return IsValidAttendance;
    }
  }

  IsHoliday() {
    if (this.AttendanceAdjustmentRequestUI
      .ibusUIAttendanceAdjustmentRequestDateDetail.IsPublicHoliday == true) {
      this.IsUploadIsVisible = true;
    }
     else {
      this.IsUploadIsVisible = false;
    }
  }

  IsCheckMaxNonBreakWorkHrs(MaxNonBreakWorkHrsAlert): boolean {

    if (!isNullOrUndefined(this.TotalDiff)) {
      var Indate = new Date();
      let TotalEnteredTime = this.TotalDiff;

      let EINdateTimes = new Date((Indate.getMonth() + 1) + "/" +
        Indate.getDate() + "/" + Indate.getFullYear() + " " + TotalEnteredTime);

      let max_working_hours_per_day = this.TimeFormat(<string>this.max_working_hours_per_day);

      let IsCheckMaxWorkHrsDate = new Date((Indate.getMonth() + 1) + "/" +
        Indate.getDate() + "/" + Indate.getFullYear() + " " + max_working_hours_per_day);

      if (EINdateTimes.getTime() > IsCheckMaxWorkHrsDate.getTime()) {

        this.MaxNonBreakWorkHrsAlertContent =
          'Duration between the Entered In-Time and Out-Time is greater than configured non-break work hours (' + max_working_hours_per_day + ').  Do you want to continue?';


        this.modalReferrence = this.modalService.open(MaxNonBreakWorkHrsAlert, { backdrop: 'static' });
        return false;
      }
    }
    return true;
  }


  IsValidAttendanceAdjustmentRequest(data) {
    let IsValidAttendanceAdjustmentRequest = false;
    this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI();
    let istrcomments = this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.istrcomments;
    let errorCount = 0;
    let InHours;

    if(this.SelectedInTime == "00:00" &&  this.SelectedOutTime == "00:00" && this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.
                                    ibusUIAttendanceAdjustmentRequestTimeDetail.boolIsReduce==false)
    {
     this.ShowErrorAlert(1, "Enter Valid In and Out Time.", 'ER001');
     return;
    }

    if (this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.dtmadjustment_date == null) {
      this.ShowErrorAlert(1, "Please Select Adjustment Request Date.", 'ER001');
      errorCount++;
    } else {
      if (istrcomments == null || istrcomments == "") {
        this.AttendanceAdjustmentRequestValidationUI.Comments = true;
        errorCount++;
      }


      if (isNullOrUndefined(this.SelectedInTime) || this.SelectedInTime == "") {
        this.AttendanceAdjustmentRequestValidationUI.Intime = true;
        this.TotalDiff = "00:00";
        errorCount++;
      }

      if (isNullOrUndefined(this.SelectedOutTime) || this.SelectedOutTime == ""
        //|| this.SelectedOutTime == "00:00" || this.SelectedOutTime == "00:00:00"
      ) {
        this.AttendanceAdjustmentRequestValidationUI.OutTime = true;
        this.TotalDiff = "00:00";
        errorCount++;
      }
      if(this.IsCheckValidInTime == true){
        this.AttendanceAdjustmentRequestValidationUI.IsCheckValidInTime=true;
       }
 
       if(this.IsCheckValidOutTime == true){
         this.AttendanceAdjustmentRequestValidationUI.IsCheckValidOutTime=true;
        }
  
      // let IsClockEvent = false;

      // let ISValidout = this.CheckValidOutTime(IsClockEvent);
      // let ISValidintime = this.CheckValidInTime(IsClockEvent);

      if (this.IsCheckValidInTime == true || this.IsCheckValidOutTime == true) {
        errorCount++;
      }

      if (this.IsCheckValidInTime == false && this.IsCheckValidOutTime == false) {
        if (this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value == 'OT' ||
          this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value == 'TOIL') {
          this.IsUploadIsVisible = true;
        } else {
          this.IsUploadIsVisible = false;
        }

        if (this.IsUploadIsVisible == true &&
          isNullOrUndefined(this.AttendanceAdjustmentRequestUI
            .ibusUIAttendanceAdjustmentRequestDateDetail.
            ibusUIAttendanceAdjustmentRequestTimeDetail.ibusUIAttendanceAdjustmentRequestDocument.strDocument_name)) {
          errorCount++;
          this.ShowErrorAlert(1, "Please Upload an approval document.", 'ER001');
        }
      }

    }




    this.alert.ClearMsgPanels();
    if (errorCount > 0) {
      return false;
    }
    else {
      IsValidAttendanceAdjustmentRequest = true;
      return IsValidAttendanceAdjustmentRequest;
    }

  }



  ConfirmDeleteAttendanceAdjustmentRequestTimeDetail(AttendanceAdjustmentRequestTimeDetailUIDeleteContent: any) {
    if (!isNullOrUndefined(this.UISelectTimeDetail)) {
      this.modalReferrence = this.modalService.open(AttendanceAdjustmentRequestTimeDetailUIDeleteContent, { backdrop: 'static' });
    }
  }


  DeleteAttendanceAdjustmentRequestTimeDetail() {
    if (!isNullOrUndefined(this.UISelectTimeDetail) &&
      this.UISelectTimeDetail.lngattendance_adjustment_request_time_detail_id > 0) {
      this.preLoader.show(true);
      this.modalReferrence.close();
      this.MissedHourRequesttimesheetService.DeleteAttendanceAdjustmentRequestTimeDetail(this.UISelectTimeDetail)
        .subscribe(
          data => { this.DeleteAttendanceAdjustmentRequestTimeDetailUISuccess(data) },
          error => { this.DeleteAttendanceAdjustmentRequestTimeDetailUIError(error) });
    }
  }

  DeleteAttendanceAdjustmentRequestTimeDetailUISuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        this.AttendanceAdjustmentRequestUI = data;
       
      this.UISelectTimeDetail =null;
        if (this.AdjustmentRequestDateDetailListUI != null) {
          this.SelectDateDetail = null;

          let SelectedObject = this.AdjustmentRequestDateDetailListUI.find(x =>
            (x.ilngattendance_adjustment_request_date_detail_id
              == data.ibusUIAttendanceAdjustmentRequestDateDetail.ilngattendance_adjustment_request_date_detail_id));

          if (SelectedObject != null && SelectedObject != "") {
            let index = this.AdjustmentRequestDateDetailListUI.indexOf(SelectedObject);
            this.AdjustmentRequestDateDetailListUI[index] = data.ibusUIAttendanceAdjustmentRequestDateDetail;
            this.SelectDateDetail = index;
            this.AttendanceAdjustmentRequestTimeDetailList = data.ibusUIAttendanceAdjustmentRequestDateDetail.ilistbusUIAttendanceAdjustmentRequestTimeDetail;
            this.TempAdjustmentRequestDate = data;
          }
        }
        this.DateDetailListBinding(data);
        this.UITempRequestList = data;
        if (this.mwlCalendarBtnClick == true) {
          this.closeOpenMonthViewDay()
        } else {
          this.closeOpenMonthViewDayForInitalLoad();
        }

        this.SelectedInTime = "00:00";
        this.SelectedOutTime = "00:00";
        this.TotalDiff = "00:00";
        this.UISelectTotalAttendanceAdjustmentRequest = data;
        this.ConvertDateToTime();
        this.IsSave = true;
        this.disableExcessDutyDiv = false;
        this.IsHoliday();
        this.formdata = null;
        this.UpdateBtn = false;
        this.ShowSuccessAlert("Selected Adjustment Request Time Detail has been deleted successfully.");
      }
      else {
        let i = data.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
      }
    }
    this.preLoader.show(false);
  }

  DeleteAttendanceAdjustmentRequestTimeDetailUIError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }


  ConfirmDeleteAttendanceAdjustmentRequestDateDetail(data: any, AttendanceAdjustmentRequestDetailDetailUIDeleteContent: any) {
    if (!isNullOrUndefined(data)) {
      this.UISelectDateDetail = data;
      this.modalReferrence = this.modalService.open(AttendanceAdjustmentRequestDetailDetailUIDeleteContent, { backdrop: 'static' });
    }
  }

  DeleteAttendanceAdjustmentRequestDateDetail() {
    if (!isNullOrUndefined(this.UISelectDateDetail) &&
      this.UISelectDateDetail.ilngattendance_adjustment_request_date_detail_id > 0) {
      this.preLoader.show(true);
      this.MissedHourRequesttimesheetService.DeleteAttendanceAdjustmentRequestDateDetail(this.UISelectDateDetail)
        .subscribe(
          data => { this.DeleteAttendanceAdjustmentRequestDateDetailUISuccess(data) }, error => { this.DeleteAttendanceAdjustmentRequestDateDetailUIError(error) });
    }
  }

  DeleteAttendanceAdjustmentRequestDateDetailUISuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.modalReferrence.close();

      this.AttendanceAdjustmentRequestUI = data;
      this.UITempRequestList = data;
    //  this.UITempRequestList.strattendance_adjustment_request_status_value=null;
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        if (this.AdjustmentRequestDateDetailListUI != null) {
          this.UITempObj = JSON.parse(JSON.stringify(this.AdjustmentRequestDateDetailListUI));

          var SelectedObject = this.UITempObj.find(x =>
            (x.ilngattendance_adjustment_request_date_detail_id == this.UISelectDateDetail.ilngattendance_adjustment_request_date_detail_id));

          if (SelectedObject != null && SelectedObject != "") {
            var index = this.UITempObj.indexOf(SelectedObject);
            this.UITempObj.splice(index, 1);

            this.AdjustmentRequestDateDetailListUI = this.UITempObj;

            this.AttendanceAdjustmentRequestTimeDetailList = [];
            this.AttendanceListUI = [];
          }
          
        }
        this.TempAdjustmentRequestDate = [];
        if (this.mwlCalendarBtnClick == true) {
          this.closeOpenMonthViewDay()
        } else {
          this.closeOpenMonthViewDayForInitalLoad();
        }
        this.selectedDate = null;
        this.SelectedInTime = "00:00";
        this.SelectedOutTime = "00:00";
        this.TotalDiff = "00:00";
        this.UISelectTimeDetail = null;
        this.SelectTimeDetail = null;
        this.SelectDateDetail = null;
        this.UISelectTotalAttendanceAdjustmentRequest = data;
        this.ConvertDateToTime();
        this.IsSave = true;
        this.UpdateBtn = false;
        if(this.AdjustmentRequestDateDetailListUI.length == 0)
      {
           this.EnableRequestApprvlbtn = false;  
      }
      else
      {
        this.EnableRequestApprvlbtn = true;  
      }
        this.ShowSuccessAlert("Selected Adjustment Request Date Detail has been deleted successfully.");
      }
      else {
        let i = data.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
      }
    }
    this.preLoader.show(false);
  }

  DeleteAttendanceAdjustmentRequestDateDetailUIError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }





  DeleteAttendanceAdjustmentRequest() {

    if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI) &&
      this.AttendanceAdjustmentRequestUI.lngattendance_adjustment_request_id > 0) {
      this.preLoader.show(true);
      this.MissedHourRequesttimesheetService.DeleteAttendanceAdjustmentRequest(this.AttendanceAdjustmentRequestUI.lngattendance_adjustment_request_id)
        .subscribe(
          data => { this.DeleteAttendanceAdjustmentRequestUISuccess(data) }, error => { this.DeleteAttendanceAdjustmentRequestUIError(error) });
    }

  }

  DeleteAttendanceAdjustmentRequestUISuccess(data: any) {
    this.TempAdjustmentRequestDate = null;
    this.UITempRequestList = data;
    this.preLoader.show(false);
  }

  DeleteAttendanceAdjustmentRequestUIError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

  //*********** Active Index *///

  public SelecteAttendanceAdjustmentRequestDateDetail(row: any, DateDetail: any) {
    this.SelectDateDetail = row;
    this.UISelectDateDetail = DateDetail;

  }

  public SelecteAttendanceAdjustmentRequestTimeDetail(row: any, TimeDetail: any) {
    this.SelectTimeDetail = row;
    this.UISelectTimeDetail = TimeDetail;

  }
  //this.OpenAttendanceAdjustmentRequestTimeDetail(this.UISelectTimeDetail.lngattendance_adjustment_request_time_detail_id);

  public SelectedAttendanceAdjustmentRequest(row: number, data: any) {
    this.SelectAttendanceAdjustmentRequest = row;
    this.UISelectAttendanceAdjustmentRequestDetail = data;
  }

  //***********Active Index End *********///

  //***************************************** Time Convertion *********************************************** */

  ConvertDateToTime() {
    if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI) && !isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail) &&
      this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.dtmdate_in_time != null) {
      let time = this.TimeFormat(<string>this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.dtmdate_in_time);
      this.SelectedInTime = time;
    } else {
      this.SelectedInTime = "00:00";
    }
    if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI) && !isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail) &&
      this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.dtmdate_out_time != null) {
      let time = this.TimeFormat(<string>this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.dtmdate_out_time);
      this.SelectedOutTime = time;
    } else {
      this.SelectedOutTime = "00:00";
    }
  }




  SetBeginDateFormat(date: NgbDateStruct) {
    if (!isNullOrUndefined(date)) {
      this.UIRequestBeginDate = date;
      this.AttendanceAdjustmentRequestUI.idtmPeriodStartTime = this.dateFormat.SetUIDateToDate(this.UIRequestBeginDate, this.ngbDateParserFormatter);

    }

  }

  SetEndDateFormat(date: NgbDateStruct) {
    if (!isNullOrUndefined(date)) {
      this.UIRequestEndDate = date;
      this.AttendanceAdjustmentRequestUI.idtmPeriodEndTime = this.dateFormat.SetUIDateToDate(this.UIRequestEndDate, this.ngbDateParserFormatter);
    }
  }


  GetInTime() {
    const amazingTimePicker = this.atp.open({
      theme: 'material-blue',
    });
    amazingTimePicker.afterClose().subscribe(time => {
      this.SelectedInTime = time;
      let IsClockEvent = true;
      this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI();

      this.CheckValidInTime(IsClockEvent);
    });
  }

  GetDateInTmeFromTextBox(SelectedInTime) {
    if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI) &&
     !isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail)) {
      this.selectedTime = SelectedInTime;
      let IsClockEvent = true;
      this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI();

      this.CheckValidInTime(IsClockEvent);
    }
  }

  GetDateOutTmeFromTextBox(SelectedOutTime) {
    this.preLoader.show(true);
    if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI) && !isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail)) {
      this.SelectedOutTime = SelectedOutTime;
      let IsClockEvent = true;
      this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI();
      this.CheckValidOutTime(IsClockEvent);
     this.DDlEXDutyBind();
    }
  }


  GetOutTime() {   
    const amazingTimePicker = this.atp.open({
      theme: 'material-blue',
    });
    amazingTimePicker.afterClose().subscribe(time => {
      this.preLoader.show(true);
      this.SelectedOutTime = time;
      let IsClockEvent = true;
      this.AttendanceAdjustmentRequestValidationUI = new AttendanceValidationUI();
      this.CheckValidOutTime(IsClockEvent);
    });
  }



  CheckValidOutTime(IsClockEvent): boolean {
    this.preLoader.show(true);
    let IsValid = false;
    let errorCount = 0;
    this.IsCheckValidOutTime = false;
    this.IsCheckValidInTime = false;
    var addHrs;
    var addMins;
    if (isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.dtmdate_in_time)) {
      this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.dtmdate_in_time = this.ConvertTimeToDateTime(this.SelectedInTime);
    }

    if (!isNullOrUndefined(this.SelectedOutTime) && this.SelectedOutTime != "") {
      if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI) &&
        !isNullOrUndefined(this.AttendanceAdjustmentRequestUI.
          ibusUIAttendanceAdjustmentRequestDateDetail.
          ibusUIAttendanceAdjustmentRequestTimeDetail)) {
        this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.
          ibusUIAttendanceAdjustmentRequestTimeDetail.dtmdate_out_time =
          this.ConvertTimeToDateTime(this.SelectedOutTime);
      }
    }

    if (!isNullOrUndefined(this.SelectedInTime) && this.SelectedInTime != ""
      && !isNullOrUndefined(this.SelectedOutTime) && this.SelectedOutTime != ""
      //&& this.SelectedOutTime != "00:00" && this.SelectedOutTime != "00:00:00"
    ) {
      var time ;
      var inTime = this.SelectedInTime;
      var outTime = this.SelectedOutTime;
      var outtime12:number;
      if(outTime=="00:00")
      {
        time="00:00";
        outTime="23:59";
      }
      
      var Indate = new Date();
      var INdateTimes = new Date((Indate.getMonth() + 1) + "/" + Indate.getDate() + "/" + Indate.getFullYear() + " " + inTime);
      var INdateTime = INdateTimes.getTime();

      var Outdate = new Date();
      var OutdateTimes = new Date((Outdate.getMonth() + 1) + "/" + Outdate.getDate() + "/" + Outdate.getFullYear() + " " + outTime);
      // if(this.SelectedInTime=="23:00")
      // {
      //   var OutdateTimes = new Date((Outdate.getMonth() + 1) + "/" + Outdate.getDate() + "/" + Outdate.getFullYear() + " " + outTime+"1:00");
      // }
      var OutdateTime = OutdateTimes.getTime();

      let NextDayIndate = new Date();
      NextDayIndate = new Date((Indate.getMonth() + 1) + "/" + Indate.getDate() + "/" + Indate.getFullYear() + " " + inTime);
      let CurrentDate = new Date(NextDayIndate).setHours(0, 0, 0, 0);

      let NextDayOutdate = new Date();
      var TotalDiff = Math.abs(OutdateTimes.getTime() - INdateTimes.getTime()) / 1000;
      let hrs = Math.floor(TotalDiff / 3600) % 24;
      let minits = Math.floor(TotalDiff / 60) % 60;
      let total = hrs + ":" + minits
      var NextDayOutdateTimes = new Date((NextDayOutdate.getMonth() + 1) + "/" + NextDayOutdate.getDate() + "/" + NextDayOutdate.getFullYear() + " " + total);
      NextDayOutdate.setHours(NextDayIndate.getHours() + NextDayOutdateTimes.getHours());
      let NextDay = new Date(NextDayOutdate).setHours(0, 0, 0, 0);


      if ((INdateTime != OutdateTime) && (CurrentDate == NextDay) && (INdateTime < OutdateTime)) {
        if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI) &&
          !isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail
            .ibusUIAttendanceAdjustmentRequestTimeDetail) &&
          IsClockEvent == true) {
          this.OpenAttendanceOTType();
        }
        // if(this.SelectedInTime=="23:00")
        // {
        //   var OutdateTimes = new Date((Outdate.getMonth() + 1) + "/" + Outdate.getDate() + "/" + Outdate.getFullYear() + " " + outTime);
        // }
        var delta = Math.abs(OutdateTimes.getTime() - INdateTimes.getTime()) / 1000;
        console.log(delta);

        addHrs = Math.floor(delta / 3600) % 24;

        addMins = Math.floor(delta / 60) % 60;
        if (addHrs == null)
          addHrs = 0;

        if (addMins == null)
          addMins = 0;

        if (addHrs < 10) {

          addHrs = String(addHrs).padStart(2, '0');
        }
        if (addMins < 10) {
          addMins = String(addMins).padStart(2, '0');
        }

        if (outTime == "23:59") {
          if (!isNullOrUndefined(time) && time == "00:00") {
            outtime12 = Number(addMins) + 1;
            if (outtime12 == 60) {
              outtime12 = Number(addHrs) + 1;
              addHrs = String(outtime12).padStart(2, '0');
              addMins = String("00").padStart(2, '0');
            } 
            else {
              addMins = String(outtime12).padStart(2, '0');
            }
          }
        }

        this.TotalDiff = addHrs + ":" + addMins

      } else {
        this.IsCheckValidOutTime = true;
        errorCount++;
        this.disableExcessDutyDiv = false;
        this.IsUploadIsVisible = false;
        this.TotalDiff = "00" + ":" + "00"
      }
    }
  this.preLoader.show(false);
    if (errorCount > 0) {
      IsValid = false;
      return IsValid;
    }
    else {
      IsValid = true;
      return IsValid;
    }
    
  }


  CheckValidInTime(IsClockEvent): boolean {
    let IsValid = false;
    let errorCount = 0;
    this.IsCheckValidOutTime = true;
    this.IsCheckValidInTime = false;
    var addHrs;
    var addMins;
    if (!isNullOrUndefined(this.SelectedInTime) && this.SelectedInTime != "") {
      this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.dtmdate_in_time = this.ConvertTimeToDateTime(this.SelectedInTime);
    }
    if (!isNullOrUndefined(this.SelectedOutTime) && this.SelectedOutTime != "") {
      this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.dtmdate_out_time = this.ConvertTimeToDateTime(this.SelectedOutTime);
    }


    if (!isNullOrUndefined(this.SelectedInTime) && this.SelectedInTime != ""
      && !isNullOrUndefined(this.SelectedOutTime) && this.SelectedOutTime != ""
      && this.SelectedOutTime != "00:00" && this.SelectedOutTime != "00:00:00"
    ) {
      var inTime = this.SelectedInTime;
      var outTime = this.SelectedOutTime;


      var Indate = new Date();
      var INdateTimes = new Date((Indate.getMonth() + 1) + "/" + Indate.getDate() + "/" + Indate.getFullYear() + " " + inTime);
      var INdateTime = INdateTimes.getTime();

      var Outdate = new Date();
      var OutdateTimes = new Date((Outdate.getMonth() + 1) + "/" + Outdate.getDate() + "/" + Outdate.getFullYear() + " " + outTime);
      var OutdateTime = OutdateTimes.getTime();


      let NextDayIndate = new Date();
      NextDayIndate = new Date((Indate.getMonth() + 1) + "/" + Indate.getDate() + "/" + Indate.getFullYear() + " " + inTime);
      let CurrentDate = new Date(NextDayIndate).setHours(0, 0, 0, 0);

      let NextDayOutdate = new Date();
      var TotalDiff = Math.abs(OutdateTimes.getTime() - INdateTimes.getTime()) / 1000;
      let hrs = Math.floor(TotalDiff / 3600) % 24;
      let minits = Math.floor(TotalDiff / 60) % 60;
      let total = hrs + ":" + minits
      var NextDayOutdateTimes = new Date((NextDayOutdate.getMonth() + 1) + "/" + NextDayOutdate.getDate() + "/" + NextDayOutdate.getFullYear() + " " + total);
      NextDayOutdate.setHours(NextDayIndate.getHours() + NextDayOutdateTimes.getHours());
      let NextDay = new Date(NextDayOutdate).setHours(0, 0, 0, 0);


      if ((INdateTime != OutdateTime) && (CurrentDate == NextDay) && (INdateTime < OutdateTime)) {

        if (IsClockEvent == true) {
          this.OpenAttendanceOTType();
        }
      // if ((INdateTime != OutdateTime) 
      // // && (CurrentDate == NextDay)         
      //   // && (INdateTime < OutdateTime)
      // ) {
      //     if(this.SelectedOutTime=="00:00")
      //     {
      //       this.OpenAttendanceOTType();
      //     }
      //     if((CurrentDate == NextDay)         
      //      && (INdateTime < OutdateTime))
      //      {
      //   if (IsClockEvent == true) {
      //     this.OpenAttendanceOTType();
      //   }
      // }

        var delta = Math.abs(OutdateTimes.getTime() - INdateTimes.getTime()) / 1000;
        console.log(delta);

        addHrs = Math.floor(delta / 3600) % 24;

        addMins = Math.floor(delta / 60) % 60;
        if (addHrs == null)
          addHrs = 0;

        if (addMins == null)
          addMins = 0;

        if (addHrs < 10) {

          addHrs = String(addHrs).padStart(2, '0');
        }
        if (addMins < 10) {

          addMins = String(addMins).padStart(2, '0');
        }

        this.TotalDiff = addHrs + ":" + addMins

      } else {
        
        this.IsCheckValidOutTime = true;
        errorCount++;
        this.IsUploadIsVisible = false;
        this.disableExcessDutyDiv = false;
        this.TotalDiff = "00" + ":" + "00"
      }
    }
    if (errorCount > 0) {
      IsValid = false;
      return IsValid;
    }
    else {
      IsValid = true;
      return IsValid;
    }
  }




  ConvertTimeToDateTime(dtmTime: any): any {
    if (!isNullOrUndefined(dtmTime) && dtmTime != "") {
      {
        console.log(dtmTime);
        let StartTime = dtmTime;
        if (StartTime != null) {
          let times = StartTime;
          let today: any;

          if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI
            .ibusUIAttendanceAdjustmentRequestDateDetail.dtmadjustment_date)) {
            today = new Date(
              this.AttendanceAdjustmentRequestUI
                .ibusUIAttendanceAdjustmentRequestDateDetail.dtmadjustment_date);
          } else {
            today = new Date();
          }

          let date = today.getDate();
          let month = today.getMonth();
          let year = today.getFullYear();

          if (date < 10) {
            var dd = "0" + format(date);
          }
          else {
            var dd = format(date);
          }

          if (month <= 8) {
            let Month = month + 1;
            var mm = "0" + format(Month);
          }
          else {
            let Month = month + 1;
            var mm = format(Month);
          }
          let yyyy = format(year);
          today = <string>yyyy + "-" + <string>mm + "-" + <string>dd;
          let Convertedtime: any = <string>today + "T" + <string>times;
          return Convertedtime;
        }
      }
    }
    else {
      let Convertedtime = null;
      return Convertedtime;
    }
  }


  //Time Extraction Function
  private TimeFormat(data: string): any {
    if (!isNullOrUndefined(data)) {
      let TimeStruct = this.datePipe.transform(data, 'HH:mm')
      return TimeStruct;
    }
  }

  public getTimeDiffInHours(SelectedInTime: any, SelectedOutTime: any) {
    let InTime = SelectedInTime;
    let OutTime = SelectedOutTime;
    SelectedInTime = InTime.split("T", 2);
    SelectedOutTime = OutTime.split("T", 2);
    var startTime = SelectedInTime[1].split(":", 2);
    let starthour = startTime[0];
    let startMinutes = startTime[1];
    var EndTime = SelectedOutTime[1].split(":", 2);
    let Endhour = EndTime[0];
    let EndMinutes = EndTime[1];
    this.diff_Hours = Endhour - starthour;
    this.diff_Minutes = EndMinutes - startMinutes;
    this.TotalDiff = this.diff_Hours + ":" + this.diff_Minutes;
    console.log(this.diff_Hours, this.diff_Minutes);

  }


  SelectUIDate(event) {
    if (!isNullOrUndefined(event)) {
      console.log(event);
      this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.dtmadjustment_date = this.dateFormat.SetUIDateToDate(this.UIDate, this.ngbDateParserFormatter);
      this.DefaultSelectedateBinding();
      this.GetAttendanceDetailFromSelectedDate(this.AttendanceAdjustmentRequestUI);
    }
  }



  DefaultSelectedateBinding() {
    let today: any = new Date(this.AttendanceAdjustmentRequestUI.dtmadjustment_date);
    let date = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    this.GetTodayDate = <string>date + "-" + <string>month + "-" + <string>year;
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    this.GetDay = gsDayNames[today.getDay()];
    if (isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.dtmadjustment_date)) {
      this.AttendanceAdjustmentRequestUI.dtmDate = null;
    }
  }

  SetDateForUIBinding() {
    if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.dtmadjustment_date)) {
      let Date = this.dateFormat.FormatDate(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.dtmadjustment_date as string);
      this.UIDate = this.ngbDateParserFormatter.parse(Date);
      this.DefaultSelectedateBinding();

    }

  }

  GetHoursBinding() {
    if (
      !isNullOrUndefined(this.AttendanceAdjustmentRequestUI) &&
      !isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail) &&
      !isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.dtmdate_out_time) &&

      !isNullOrUndefined(this.AttendanceAdjustmentRequestUI) &&
      !isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail) &&
      !isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.dtmdate_in_time)
    ) {
      this.getTimeDiffInHours(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.dtmdate_in_time,
        this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.dtmdate_out_time);
    }
  }


  CheckfutureDate(): boolean {
    let errorCount = 0;
    let SelectedDate: any = new Date(this.SelectedCalanderDate);
    let CurrentDates: any = new Date();
    let mm: any = CurrentDates.getMonth() + 1;
    let CurrentMonth = mm;
    let SelectedMonth = SelectedDate.getMonth() + 1;
    if (CurrentMonth <= SelectedMonth) {
      errorCount++;
    }

    this.alert.ClearMsgPanels();
    if (errorCount > 0) {
      return false;
    }
    else {
      return true;
    }

  }


  public SelectedAttendance(row: number, data: any) {
    this.SelectAttendance = row;
    this.UISelectedTotalAttendanceDetail = data;
  }

  public ClosePopup() {
    this.modalReferrence.close();
  }

  //***************************** Error info Warinng *************************************************************** */

  GetCommonErrorMessage(error: any | string) {
    console.log(error);
    let i;
    this.ShowErrorAlert(i, error, 1087);
    // let Errmsg = { "Status": "true", "Message": ErrorMsg.ErrorMessage, "Type": "danger" }
    // this.alert.alert(Errmsg);
    this.preLoader.show(false);
  }

  ShowErrorPanelAlert(Msg: any) {
    this.alert.ShowErrorPanelAlert(true);
    this.alert.SetErrorPanelMsg(Msg);
  }

  ShowSuccessAlert(Msg: any) {
    this.alert.ShowSuccessAlert(true);
    this.alert.SetSuccessMsg(Msg);
  }

  ShowInfoAlert(Msg: any) {
    this.alert.ShowInfoAlert(true);
    this.alert.SetInfoMsg(Msg);
  }

  ShowWaringAlert(Msg: any) {
    this.alert.ShowWarningAlert(true);
    this.alert.SetWarningMsg(Msg);
  }

  ShowErrorAlert(ErrorCount: any, ErrorMsg: any, ErrorCode: any) {
    let ErrMsg = '';
    if (ErrorCount > 2) {
      ErrMsg = 'Error has been occured.'
    }
    else {
      ErrMsg = ErrorMsg;
    }
    this.alert.SetErrorCode(ErrorCode);
    this.alert.SetErrorDescription(ErrMsg);
    this.alert.ShowErrorAlert(true);
  }


  public OpenAttendanceOTType() {
    this.istrue=false;
    if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI)) {
      this.preLoader.show(true);
      this.MissedHourRequesttimesheetService.OpenAttendanceOTType(this.AttendanceAdjustmentRequestUI).subscribe(
        data => { this.OpenAttendanceTypesUISuccess(data) },
        error => { this.OpenAttendanceTypesUIerror(error) }
      );
    }
  }

  OpenAttendanceTypesUIerror(error: any) {
    this.dataService.GetCommonErrorMessage(error);
  }



  OpenAttendanceTypesUISuccess(data: any) {
    this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.
      ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value = null;
    this.AttendanceTimeTypeUI = data;

    if (this.AttendanceTimeTypeUI != null &&
      this.AttendanceTimeTypeUI.ilngm_attendance_time_type_id > 0) {

      if (this.AttendanceTimeTypeUI.iblois_payable == "Y" && this.AttendanceTimeTypeUI.is_toil_allowed == "N"
      && this.AttendanceTimeTypeUI.IsshowExcessDutyType == true) {
        this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value = "OT";
        this.disableExcessDutyDiv = true;
        this.is_edit = true;
        this.IsUploadIsVisible = true;
      }

      else if (this.AttendanceTimeTypeUI.iblois_payable == "N" && this.AttendanceTimeTypeUI.is_toil_allowed == "Y" 
      && this.AttendanceTimeTypeUI.IsshowExcessDutyType == true) {
        this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value = "TOIL";
        this.disableExcessDutyDiv = true;
        this.is_edit = true;
        this.IsUploadIsVisible = true;
      }

      else if (this.AttendanceTimeTypeUI.iblois_payable == "N" && this.AttendanceTimeTypeUI.is_toil_allowed == "N"
      && this.AttendanceTimeTypeUI.IsshowExcessDutyType == true) {
        this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value = "TOIL";
        this.disableExcessDutyDiv = true;
        this.is_edit = false;
        this.IsUploadIsVisible = true;
      }

      else if (this.AttendanceTimeTypeUI.iblois_payable == "Y" && this.AttendanceTimeTypeUI.is_toil_allowed == "Y"
      && this.AttendanceTimeTypeUI.IsshowExcessDutyType == true) {
        this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value = "TOIL";
        this.disableExcessDutyDiv = true;
        this.is_edit = false;
        this.IsUploadIsVisible = true;
      }

      else if (this.AttendanceTimeTypeUI.iblois_payable == "N" && this.AttendanceTimeTypeUI.is_toil_allowed == "Y" 
      && this.AttendanceTimeTypeUI.IsshowExcessDutyType == true) {
        this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value = "TOIL";
        this.disableExcessDutyDiv = true;
       this.is_edit = true;
        this.IsUploadIsVisible = true;
      }

      else if (this.AttendanceTimeTypeUI.iblois_payable == "Y" && this.AttendanceTimeTypeUI.is_toil_allowed == null) {
        this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value = "OT";
        this.disableExcessDutyDiv = true;
        this.is_edit = true;
        this.IsUploadIsVisible = true;
      }

      else if (this.AttendanceTimeTypeUI.iblois_payable == "N" && this.AttendanceTimeTypeUI.is_toil_allowed == null) {
        this.disableExcessDutyDiv = false;
        this.is_edit = true;
        this.IsUploadIsVisible = true;
      }

      else if (this.AttendanceTimeTypeUI.iblois_payable == null && this.AttendanceTimeTypeUI.is_toil_allowed == null) {
        this.disableExcessDutyDiv = false;
        this.is_edit = true;
        this.IsUploadIsVisible = true;
      }
      if(this.AttendanceTimeTypeUI.IsshowExcessDutyType == true)
      {
        //this.disableExcessDutyDiv = true;
        this.IsUploadIsVisible = true;
       // this.is_edit = true;
      }
      else
      {
       this.IsUploadIsVisible = false;
       this.is_edit = false;
      }
    }
    else {
      this.IsUploadIsVisible = false;
      this.disableExcessDutyDiv = false;
      this.is_edit = false;
    }
    this.istrue=true;
    this.preLoader.show(false);
  }

  public OpenAttendanceOTTypeForTimeOpen() {
    if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI)) {

      this.MissedHourRequesttimesheetService.OpenAttendanceOTType(this.AttendanceAdjustmentRequestUI).subscribe(
        data => { this.OpenAttendanceTypesUIForTimeOpenSuccess(data) },
        error => { this.OpenAttendanceTypesUIerror(error) }
      );
    }
  }

  OpenAttendanceTypesUIForTimeOpenSuccess(data: any) {
    if (!isNullOrUndefined(data) && data) {
      this.AttendanceTimeTypeUI = data;

      if (this.AttendanceTimeTypeUI != null) {
        if (this.AttendanceTimeTypeUI.iblois_payable == "Y" && this.AttendanceTimeTypeUI.is_toil_allowed == "N") {
          this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value = "OT";
          this.disableExcessDutyDiv = true;
          this.is_edit = true;
        }

      else if (this.AttendanceTimeTypeUI.iblois_payable == "N" && this.AttendanceTimeTypeUI.is_toil_allowed == "Y") {
        this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value = "TOIL";
        this.disableExcessDutyDiv = true;
        this.is_edit = true;
      }

        else if (this.AttendanceTimeTypeUI.iblois_payable == "N" && this.AttendanceTimeTypeUI.is_toil_allowed == "N") {
          this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value = "TOIL";
          this.disableExcessDutyDiv = true;
          this.is_edit = false;
        }

        else if (this.AttendanceTimeTypeUI.iblois_payable == "Y" && this.AttendanceTimeTypeUI.is_toil_allowed == "Y") {
          this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value = "TOIL";
          this.disableExcessDutyDiv = true;
          this.is_edit = false;

        }

        else if (this.AttendanceTimeTypeUI.iblois_payable == "N" && this.AttendanceTimeTypeUI.is_toil_allowed == "Y") {
          this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value = "TOIL";
          this.disableExcessDutyDiv = true;
          this.is_edit = true;

        }


        else if (this.AttendanceTimeTypeUI.iblois_payable == "Y" && this.AttendanceTimeTypeUI.is_toil_allowed == null) {
          this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value = "OT";
          this.disableExcessDutyDiv = true;
          this.is_edit = true;
        }
        else if (this.AttendanceTimeTypeUI.iblois_payable == "N" && this.AttendanceTimeTypeUI.is_toil_allowed == null) {
          this.disableExcessDutyDiv = false;
          this.is_edit = true;
        }


        else if (this.AttendanceTimeTypeUI.iblois_payable == null && this.AttendanceTimeTypeUI.is_toil_allowed == null) {
          this.disableExcessDutyDiv = false;
          this.is_edit = true;
        }
        this.IsUploadIsVisible = true;

      }
    }
    else {
      this.IsUploadIsVisible = false;
      this.disableExcessDutyDiv = false;
      this.is_edit = false;
    }



  }


  DDlEXDutyBind() {
    
    if (!isNullOrUndefined(this.SelectedInTime) && this.SelectedInTime != ""
      && !isNullOrUndefined(this.SelectedOutTime) && this.SelectedOutTime != "" &&
      this.IsCheckValidOutTime == false && this.IsCheckValidInTime == false
      && this.SelectedOutTime != "00:00" && this.SelectedOutTime != "00:00:00") {
      if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI) &&
        !isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail)) {
          this.preLoader.show(true);
        //this.OpenAttendanceOTType();
      }
    }
  }


  DateDetailListBinding(data: any) {
    if (data.ilistbusUIAttendanceAdjustmentRequestDateDetail != null &&
      data.ilistbusUIAttendanceAdjustmentRequestDateDetail.length > 0) {
      this.AdjustmentRequestDateDetailListUI = [];
      this.AdjustmentRequestDateDetailListUI =
        data.ilistbusUIAttendanceAdjustmentRequestDateDetail;
        this.EnableRequestApprvlbtn = true;
    }
     else {
      this.AdjustmentRequestDateDetailListUI = [];
      this.SelectDateDetail = null;
      this.AttendanceAdjustmentRequestTimeDetailList = [];
      this.EnableRequestApprvlbtn = false;
    }

    if (this.AdjustmentRequestDateDetailListUI != null &&
      this.AdjustmentRequestDateDetailListUI.length > 0) {
      this.SelectDateDetail = null;

      var SelectedObject = this.AdjustmentRequestDateDetailListUI.find(x =>
        (x.ilngattendance_adjustment_request_date_detail_id
          == data.ibusUIAttendanceAdjustmentRequestDateDetail.ilngattendance_adjustment_request_date_detail_id));

      if (SelectedObject != null && SelectedObject != "") {
        var index = this.AdjustmentRequestDateDetailListUI.indexOf(SelectedObject);
        this.SelectDateDetail = index;
        this.TempAdjustmentRequestDate = data;
        this.UITempRequestList = data;
      }
    }

    if (data.ibusUIAttendanceAdjustmentRequestDateDetail.ilistbusUIAttendanceAdjustmentRequestTimeDetail == null
      || data.ibusUIAttendanceAdjustmentRequestDateDetail.ilistbusUIAttendanceAdjustmentRequestTimeDetail.length == 0) {
      this.AttendanceAdjustmentRequestTimeDetailList = [];
      this.AttendanceListUI = [];
    }
  }




  public FileUploadInOT_TOIL($event) {
    this.formdata = null;
    this.InvalidFile = false;
    this.InvalidFileError = '';
    this.uploadData = [];
    let files = $event.target.files;
    for (var uploadFile of files) {
      let filter = new FileFilter();
      if (filter.FileFormatFilter(uploadFile)) {
        if (filter.FileSizeFilter(uploadFile)) {
          this.formdata = new FormData();
          this.uploadData.push(uploadFile)
          if (this.uploadData.length > 0) {
            for (let i = 0; i < this.uploadData.length; i++) {
              this.formdata.append('files', this.uploadData[i]);
              this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.ibusUIAttendanceAdjustmentRequestDocument.strDocument_name = this.uploadData[i].name;
              this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.ibusUIAttendanceAdjustmentRequestDocument.strDocument_extension = this.uploadData[i].type;
              this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.ibusUIAttendanceAdjustmentRequestDocument.decDocument_size = this.uploadData[i].size;
              this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.ibusUIAttendanceAdjustmentRequestDocument.strRelative_path = this.uploadData[i].webkitRelativePath;
              var file = this.uploadData[i];
              var reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onloadend = () => {
                this.FileContent = reader.result;

                this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.ibusUIAttendanceAdjustmentRequestDocument.istrFileContent = this.bisect64string(this.FileContent);
              }
              //  console.log(22 ,this.EmployeeUI.ibusUIEmployeeDocument.istrFileContent );
              this.alert.clearAlert();
              //  this.UploadVariable.nativeElement.value = "";
            }
          }
        }

        else
        {
          if(uploadFile.size < 1)
{
         this.InvalidFileError = "Selected File size is 0 Kb.The Minimum allowed size is 1 kb.";
          this.InvalidFile = true;
          this.ShowWaringAlert(this.InvalidFileError);
          return;
}
          else {
            this.formdata = null;
  
            if (!isNullOrUndefined(this.UploadVariable)) {
              this.UploadVariable.nativeElement.value = "";
            }
  
            this.InvalidFileError = "Selected File Size is Exceeded. The Maximum Allowed Size is " + this.localStorage.get("attachmentSize") + " MB.";
            this.InvalidFile = true;
            this.ShowWaringAlert(this.InvalidFileError);
          }
        }
      }
      else {
        this.formdata = null;
        if (!isNullOrUndefined(this.UploadVariable)) {
          this.UploadVariable.nativeElement.value = "";
        }

        this.InvalidFileError = "The Selected File Format is not Supported. Please Select again.";
        this.InvalidFile = true;
        this.ShowWaringAlert("The Selected File Format is not Supported. Please Select again.");
      }

    }

  }

  bisect64string(base64StringFromURL) {
    var parts = base64StringFromURL.split(";base64,");
    var contentType = parts[0].replace("data:", "");
    var base64 = parts[1];
    return base64;


  }

  //Download Document Code Starts
  private Base64ToArrayBuffer(base64: any) {
    let Binary_String = window.atob(base64);
    let len = Binary_String.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = Binary_String.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private SaveData(blobData: any, FileName: any) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";

    let blob = blobData;
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = FileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }


  public DownloadDocument(Id: any) {
    if (!isNullOrUndefined(Id)) {
      this.MissedHourRequesttimesheetService.DownloadAttendanceAdjustmentRequestDocument(Id).subscribe(
        data => { this.DownloadDocumentSuccess(data) },
        error => { this.DownloadDocumentError(error) }
      );
    }
  }

  private DownloadDocumentSuccess(data: any) {
    let ApplicationDocument = data;
    let FileName = ApplicationDocument.idoBase.document_name;
    if(FileName!=null)
    {
    let arrayBuffer = this.Base64ToArrayBuffer(ApplicationDocument.istrFileContent);
    let file = new Blob([arrayBuffer], {
      type: 'application/octet-stream'
    });

    if (file != null && navigator.msSaveBlob) {
      return navigator.msSaveBlob(file, FileName);
    }
    this.SaveData(file, FileName);
  }
  }

  private DownloadDocumentError(error: any) {
    console.log("Something Went Wrong Try Again...");
  }


  IsVisableUploadBtn() {
    if (this.IsCheckValidInTime == false && this.IsCheckValidOutTime == false) {
      if (this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value == 'OT' ||
        this.AttendanceAdjustmentRequestUI.ibusUIAttendanceAdjustmentRequestDateDetail.ibusUIAttendanceAdjustmentRequestTimeDetail.istrpayable_or_toil_value == 'TOIL') {
        this.IsUploadIsVisible = true;
      } else {
        this.IsUploadIsVisible = false;
      }
    }
  }

  public DownloadAttendaceDocument(Id: any) {
    if (!isNullOrUndefined(Id)) {
      this.MissedHourRequesttimesheetService.DownloadAttendanceDocument(Id).subscribe(
        data => { this.DownloadDocumentSuccess(data) },
        error => { this.DownloadDocumentError(error) }
      );
    }
  }

  /// Request for Cancel
  
  public RequestToCancelAttnAdjustReqToApprover(): any {
    if (!isNullOrUndefined(this.AttendanceAdjustmentRequestUI)) {
      this.AttendanceAdjustmentRequestUI = this.AttendanceAdjustmentRequestUI;
      this.preLoader.show(true);
      this.MissedHourRequesttimesheetService.RequestToCancelAttnAdjustReqToApprover(this.AttendanceAdjustmentRequestUI).subscribe(
        data => { this.SaveAttendanceAdjustmentRequestApprovalSuccess(data, this.AttendanceAdjustmentRequestUI) }, error => { this.SaveAttendanceAdjustmentRequestApprovalError(error) });
    }
  }

  SaveAttendanceAdjustmentRequestApprovalSuccess(data: any, Attendance: any) {
    if (!isNullOrUndefined(data)) {
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        //this.AttendanceAdjtRequestEmpTeamListListUI[Attendance] = data;
        this.AttendanceAdjustmentRequestUI = data;
        this.AdjustmentRequestDateDetailListUI =  data.ilistbusUIAttendanceAdjustmentRequestDateDetail;
        // this.AttendanceAdjustmentRequestUI.ilistbusUIAttendanceAdjustmentRequestDateDetail =
        //   data.ilistbusUIAttendanceAdjustmentRequestDateDetail;
        this.AttendanceAdjustmentRequestUI.strattendance_adjustment_request_status_description = data.strattendance_adjustment_request_status_description;
        this.AttendanceAdjustmentRequestUI.IsEligibleCancelRequestedLeaveAfterApproved = false;
       // this.AfterSearch();
        this.ShowSuccessAlert("Data updated successfully.");
        // if (this.BoolMonthLy == true) {
        //   this.MonthWeekWise();
        // } else {
        //   this.PeriodWise();
        // }
        this.ShowDeletebtn = false;
      }
      else {
        let i = data.ilstErrorMessages
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
      }
    }
    this.preLoader.show(false);
  }

  SaveAttendanceAdjustmentRequestApprovalError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

  public Holidayalert(Selecteddate) {
    if ((!isNullOrUndefined(Selecteddate))) {
      this.AttendanceAdjustmentRequestUI.ibusUIMPublicHolidays.selecteddate = Selecteddate;
    }
    this.MissedHourRequesttimesheetService.Holidayalertadjustment(this.AttendanceAdjustmentRequestUI.ibusUIMPublicHolidays).subscribe(
      data => { this.HolidayalertSuccess(data) }, error => { this.HolidayalertError(error) });
  }
   
  private HolidayalertSuccess(data) {
    if (!isNullOrUndefined(data)) {
      console.log(this.AttendanceAdjustmentRequestUI);
      
        this.AttendanceAdjustmentRequestUI.ibusUIMPublicHolidays = data; 
       if(this.AttendanceAdjustmentRequestUI.ibusUIMPublicHolidays.isholiday==true)
      {       

        this.openHolidayAlert(this.AttendanceAdjustmentRequestUI.ibusUIMPublicHolidays);
      }      
          
      
    }
    this.preLoader.show(false);
  }
  openHolidayAlert(data){
    this.AttendanceAdjustmentRequestUI.ibusUIMPublicHolidays=data;
    if(!isNullOrUndefined(this.AttendanceAdjustmentRequestUI.ibusUIMPublicHolidays.strholiday_name))
    {
      let latest_date =this.datePipe.transform(this.AttendanceAdjustmentRequestUI.ibusUIMPublicHolidays.dtmholiday_date, 'dd/MM/yyyy');
      this.holidayalert="Selected Date ( "+latest_date + " ) is a " +this.AttendanceAdjustmentRequestUI.ibusUIMPublicHolidays.strholiday_name+".";
      this.modalReferrence = this.modalService.open(this.holidayAlert, { backdrop: 'static' });
    }
   
  }

  private HolidayalertError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }


  //*** INITIAL LOAD Leave Show CALENDER  ***///
  public LoadLeaveshowonTimesheetAdjustment(data: any) {
    this.preLoader.show(true);
    this.AttendanceAdjustmentRequestUI.lngemployee_Id = parseFloat(this.AttendanceAdjustmentRequestUI.lngemployee_Id);
    this.MissedHourRequesttimesheetService.LoadLeaveshowonTimesheetAdjustment(this.AttendanceAdjustmentRequestUI)
      .subscribe(
        data => {
          this.LoadLeaveshowonTimesheetAdjustmentSuccess(data)
        }, error => { this.LoadLeaveshowonTimesheetAdjustmentError(error) });
  }

  LoadLeaveshowonTimesheetAdjustmentSuccess(data: any) {   
    debugger;
    console.log(this.TempDateLists)
    this.LeaveListUI = data.ilistbusUILeaveRequest;
    this.HolidayList=data.ilistbusUIMPublicHolidays;
    if ((this.TempDateLists && this.TempDateLists.length > 0) ||
      (this.LeaveListUI && this.LeaveListUI.length > 0)
    ||(this.HolidayList && this.HolidayList.length > 0)
    ) {
      this.events = this.GetEventData();
      this.refresh.next();
    } else {
      this.events = [];
    }

    this.preLoader.show(false);
  }

  LoadLeaveshowonTimesheetAdjustmentError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }




}



