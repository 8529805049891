import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class AttendancePolicyService {

  constructor(public http: HttpClient) { }


  public CreateNewShiftConfig() {
    return this.http.get(AppConstants.BasePath + 'CreateNewShiftConfig').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveShiftConfig(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveShiftConfig', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }



  public LoadShiftConfigAndMapping(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadShiftConfigAndMapping', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }



  public LoadBreakType(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadBreakType', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetShiftWeekInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetShiftWeekInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }

  public OpenShiftConfig() {
    return this.http.get(AppConstants.BasePath + 'OpenShiftConfig').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }

  public DeleteShiftConfig(ConfigId: any) {
    return this.http.post(AppConstants.BasePath + 'DeleteShiftConfig', ConfigId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  SaveShiftWeeklyMappingList(ilistbusUIShiftWeeklyMapping: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveShiftWeeklyMappingList', ilistbusUIShiftWeeklyMapping).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  SaveBreakMapping(ilistbusUIBreakMapping: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveBreakMapping', ilistbusUIBreakMapping).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }


  public DeleteBreakMapping(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteBreakMapping', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }
  private ErrorHandler(error: Response | any) {
    let errorMSg: string;
    if (error instanceof Response) {
      const err = error || '';
      errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errorMSg = error.message ? error.message : error.toString();
    }
    return _throw(error);
  }
}
