import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbModal, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { isNullOrUndefined } from 'util';
import { TitleServiceService } from 'src/app/services/common/title-service.service';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
import { AlertService } from 'src/app/services/common/alert.service';

import { FormatDate, IsLoggedIn, ErrorMsg, ErrorMsgTab } from 'src/app/common/common';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/admin/user.service';
import { DataService } from 'src/app/services/common/data.service';

import { PreloadService } from 'src/app/services/common/preload.service';
import { MasterService } from 'src/app/services/master/master.service';
import { EmployeeShiftConfigValidationUI } from 'src/app/common/validationUI';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-employee-shift-config',
  templateUrl: './employee-shift-config.component.html',
  styleUrls: ['./employee-shift-config.component.scss']
})
export class EmployeeShiftConfigComponent implements OnInit {


  UIFromDate: any;
  UIToDate: any;
  EmployeeShiftConfig:any; 
  IntialDDL: any;
  errorCount: number;
  infoCount: number;
  warningCount: number;
  MsgTabList: any;
  response: any;
  EmployeeShiftConfigList: any;
  Flag: boolean;
  Employeebutton:boolean=true;
  ErrorMsgTab: ErrorMsgTab;
  DuplicateMsgTabList: any;
  UISelectedEmployeeShiftConfig: any;
  Message: string;
  modalref: any;
 
  DDLCompanyDepartment:any;
  DDLCompanyTeam:any;
  DDLShiftConfig:any;
  UIUserBeginDate: NgbDateStruct;
  DeleteModalReferrence: any;

  TotalCount:any;

  constructor(
    private titleService: TitleServiceService,
    private modalService: NgbModal,
    public localStorage: LocalStorageService,
    public preLoader: PreloadService,
    public userService: UserService,
    public employeeShiftConfigValidationUI:EmployeeShiftConfigValidationUI, 
    public alert: AlertService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private masterService: MasterService,
    public datepipe: DatePipe,
    public dataService: DataService,
    public dateFormat: FormatDate,
    public isLoggedIn: IsLoggedIn,
    private router: Router,
    private title: TitleServiceService,
    public SessionStorage: SessionStorageService,
    // private isLoggedIn: IsLoggedIn,
  
  ) { }

  ngOnInit() {

    this.title.setTitle('Configuration');
    this.title.setTitleforPdf('EmployeeShiftConfig');
    this.title.setshowpdf(true);
    this.employeeShiftConfigValidationUI = new EmployeeShiftConfigValidationUI();
    this.CreateEmployeeShiftConfig();
    this.GetEmployeeShiftConfigInitialData();

  }



  private SetUIUserDates() {

    if (!isNullOrUndefined(this.EmployeeShiftConfig)) {

      if (!isNullOrUndefined(this.EmployeeShiftConfig.dtstart_date)) {
        let EmployeeShiftConfigBeginDate = this.dateFormat.FormatDate(this.EmployeeShiftConfig.dtstart_date as string);
        this.UIFromDate = this.ngbDateParserFormatter.parse(EmployeeShiftConfigBeginDate);
      }
      else {
        this.UIFromDate = null;
      }


      if (!isNullOrUndefined(this.EmployeeShiftConfig.dtend_date)) {
        let EmployeeShiftConfigEndDate = this.dateFormat.FormatDate(this.EmployeeShiftConfig.dtend_date as string);
        this.UIToDate = this.ngbDateParserFormatter.parse(EmployeeShiftConfigEndDate);
      }
      else {
        this.UIToDate = null;
      }

    }
  }



SearchUI:any;

UIEffectiveStartDateFrom:any;
UIEffectiveStartDateTo:any;
UIEffectiveEndDateFrom:any;
UIEffectiveEndDateTo:any;


ShiftId:any;
StartFrom:any;
StartTo:any;
EndFrom:any;
EndTo:any;


StartFromSearch:any;
StartToSearch:any;
EndFromSearch:any;
EndToSearch:any;


dateUIFrom:any;
dateUIEnd:any;

// SetDateForUIFromBinding() {
//   let todays: any = new Date();
//   let dateFrom = todays.getDate();
//   let monthfrom = todays.getMonth() + 1;
//   let yearfrom = todays.getFullYear();

//   this.StartFrom= <string>yearfrom + "-" + <string> monthfrom+ "-" + <string>dateFrom;

//   if (!isNullOrUndefined(this.StartFrom)) {
//      let Date = this.dateFormat.FormatDate(this.StartFrom as string);
//     this.StartFrom = this.ngbDateParserFormatter.parse(Date);

//     this.StartFromSearch=this.dateFormat.SetUIDateToDate(this.StartFrom,this.ngbDateParserFormatter);
//   }
//   else {
//     this.StartFromSearch = null;
//   }

// }

// SetDateForUIEndFromBinding() {
//   let todays: any = new Date();
//   let dateFrom = todays.getDate();
//   let monthfrom = todays.getMonth() + 1;
//   let yearfrom = todays.getFullYear();

//   this.EndFrom= <string>yearfrom + "-" + <string> monthfrom+ "-" + <string>dateFrom;

//   if (!isNullOrUndefined(this.EndFrom)) {
//      let Date = this.dateFormat.FormatDate(this.EndFrom as string);
//     this.EndFrom =this.ngbDateParserFormatter.parse(Date);

//     this.EndFromSearch=this.dateFormat.SetUIDateToDate(this.EndFrom, this.ngbDateParserFormatter);

//   }
//   else {
//     this.EndFromSearch = null;
//   }

// }

  SearchStartdateFromFormate(date: NgbDateStruct) {
    if (!isNullOrUndefined(date)) {
    this.StartFrom = date;
    this.StartFromSearch = this.dateFormat.SetUIDateToDate(this.StartFrom, this.ngbDateParserFormatter);
    
    }
    // else
    // {
    //   this.SetDateForUIFromBinding();
    // }
  }

  
  SearchStartdateToFormate(date: NgbDateStruct) {
    if (!isNullOrUndefined(date)) {
     this.StartTo = date;
     this.StartToSearch = this.dateFormat.SetUIDateToDate(this.StartTo, this.ngbDateParserFormatter);
     
    }
  
  }

  SearchEndDateFromFormat(date: NgbDateStruct) {
    if (!isNullOrUndefined(date)) {
      this.EndFrom = date;
      this.EndFromSearch = this.dateFormat.SetUIDateToDate(this.EndFrom, this.ngbDateParserFormatter);
     
    }
    // else{
    //   this.SetDateForUIEndFromBinding();
    // }
  
  }


  SearchEndDateToFormat(date: NgbDateStruct) {
    if (!isNullOrUndefined(date)) {
      this.EndTo = date;
      this.EndToSearch = this.dateFormat.SetUIDateToDate(this.EndTo, this.ngbDateParserFormatter);
     
    }
  
  }


public Clear()
{
  this.ShiftId=0,
  this.StartFrom="",
  this.EndFrom="",
  // this.SetDateForUIEndFromBinding();
  // this.SetDateForUIFromBinding();
  this.StartTo="",
  this.EndTo=""
this.StartFromSearch=null;
this.StartToSearch=null;
this.EndFromSearch=null;
this.EndToSearch=null;
this.Employeebutton=true;
this.EmployeeNamebind=null;
this.Filterbtn();

}


SearchList:any;

public Filterbtn()
{

let data={
  "ShiftConfigId":this.ShiftId,
  "StartFrom":this.StartFromSearch,
  "StartTo":this.StartToSearch,
  "EndFrom":this.EndFromSearch,
  "EndTo":this.EndToSearch
 
}
  console.log("DATSDDD",data);
  this.masterService.LoadshiftconfigChilds(data).subscribe(
  data => { this.LoadSearchListSuccess(data) }, error => { this.LoadSearchListError(error) });
}


EmployeeShiftConfigSearchList:any;

LoadSearchListSuccess(data: any) {
  if (!isNullOrUndefined(data)) {
    this.EmployeeShiftConfigSearchList = data;
   console.log("this.EmployeeShiftConfigSearchList",data);
    this.preLoader.show(false);
   
    }
    else
    {
      this.EmployeeShiftConfigSearchList.ilistbusUIEmployeeshiftConfigList=[];
      this.ShowSuccessAlert("No Record found");
    }

  }

 

LoadSearchListError(error: any | string) {
  this.dataService.GetCommonErrorMessage(error);
}


  SetFromDateFormat(date: NgbDateStruct) {
    if (!isNullOrUndefined(date)) {
      this.UIFromDate = date;
      this.EmployeeShiftConfig.dtstart_date = this.dateFormat.SetUIDateToDate(this.UIFromDate, this.ngbDateParserFormatter);
     
    }
  
  }


  SetToDateFormat(date: NgbDateStruct) {
    if (!isNullOrUndefined(date)) {
      this.UIToDate = date;
      this.EmployeeShiftConfig.dttodate = this.dateFormat.SetUIDateToDate(this.UIToDate, this.ngbDateParserFormatter);
    }
  }
  public GetEmployeeShiftConfigInitialData() {
    this.masterService.GetEmployeeShiftConfigInitialData().subscribe(
      data => { this.GetEmployeeShiftConfigInitialDataSucess(data) },
      error => { this.GetEmployeeShiftConfigInitialDataError(error) }
    );
  }
  GetEmployeeShiftConfigInitialDataSucess(data: any): any {
    this.IntialDDL = data;
    this.SetInLocalStorage();
    this.InitializeData();

  }
  InitializeData(): any {
    
    if (this.localStorage.get("DDLCompanyDepartment") != null) {
      this.DDLCompanyDepartment = this.localStorage.get("DDLCompanyDepartment");

      console.log("DDLCompanyDepartment",this.DDLCompanyDepartment);
  
    }
    if (this.localStorage.get("DDLCompanyTeam") != null) {
      this.DDLCompanyTeam = this.localStorage.get("DDLCompanyTeam");
      console.log("DDLCompanyTeam",this.DDLCompanyTeam);
    }

    if (this.localStorage.get("DDLShiftConfig") != null) {
      this.DDLShiftConfig = this.localStorage.get("DDLShiftConfig");
    }
  }

  SetInLocalStorage(): any {
  
    this.localStorage.set("DDLCompanyDepartment", this.IntialDDL.DDLCompanyDepartment);
    this.localStorage.set("DDLCompanyTeam", this.IntialDDL.DDLCompanyTeam);
    this.localStorage.set("DDLShiftConfig", this.IntialDDL.DDLShiftConfig);

  }

  GetEmployeeShiftConfigInitialDataError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }
  GetCommonErrorMessage(error: any | string) {
    console.log(error);
    this.ShowErrorMsg(ErrorMsg.ErrorMessage);
    this.preLoader.show(false);
  }
  public CreateEmployeeShiftConfig() {
    this.preLoader.show(true);
     this.employeeShiftConfigValidationUI = new EmployeeShiftConfigValidationUI();
    this.masterService.CreateEmployeeShiftConfig().subscribe(
      data => { this.CreateEmployeeShiftConfigSuccess(data) }, error => { this.CreateEmployeeShiftConfigError(error) });
  }

  CreateEmployeeShiftConfigSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.EmployeeShiftConfig = data;
  console.log("EmployeeShiftConfig",this.EmployeeShiftConfig);
     this.UISelectedEmployeeShiftConfig=null;
       this.SetDefaultValues();
       this.SetUIUserDates();
       this.Filterbtn();
       this.Clear();
       this.checkList=[];


    }
    this.preLoader.show(false);
  }

  CreateEmployeeShiftConfigError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

  private SetDefaultValues() {
    if (!isNullOrUndefined(this.EmployeeShiftConfig)) {
      if (isNullOrUndefined(this.EmployeeShiftConfig.lngDepartment))
      this.EmployeeShiftConfig.lngDepartment = "0";
      if (isNullOrUndefined(this.EmployeeShiftConfig.lngTeam))
      this.EmployeeShiftConfig.lngTeam = "0";
      if (isNullOrUndefined(this.EmployeeShiftConfig.lngemployee_id))
      this.EmployeeShiftConfig.lngemployee_id = "0";
      if (isNullOrUndefined(this.EmployeeShiftConfig.lngshift_config_id))
      this.EmployeeShiftConfig.lngshift_config_id = "0";
      if (isNullOrUndefined(this.EmployeeShiftConfig.lngcompany_id))
      this.EmployeeShiftConfig.lngcompany_id = "0";
      
    }
  }

SaveEmplist:any;
  public SaveEmployeeShiftConfig() {
    if (!(isNullOrUndefined(this.EmployeeShiftConfig))) {
      if (this.IsValidReqApprovalConfig()) {
        this.preLoader.show(true);
     
        this.EmployeeShiftConfig.ilistbusUIEmployeeShiftConfig= this.checkList;

        console.log("savelist",this.EmployeeShiftConfig);
        this.masterService.SaveEmployeeShiftConfig(this.EmployeeShiftConfig).subscribe(
          data => { this.SaveApprovalConfigurationSuccess(data) }, error => { this.SaveApprovalConfigurationError(error) });
      }
    }
  }
  SaveApprovalConfigurationSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      var result = data;
      this.EmployeeShiftConfig = data;
     
      if (this.EmployeeShiftConfig.ilstErrorMessages == null || this.EmployeeShiftConfig.ilstErrorMessages.length == 0) {
         this.CreateEmployeeShiftConfig();
        this.ShowSuccessAlert("Record Save Successfully");

        this.Filterbtn();
    
      }
      else {


        let i = this.EmployeeShiftConfig.ilstErrorMessages.length
        this.ShowErrorAlert(i, this.EmployeeShiftConfig.ilstErrorMessages, 'ER001')
        this.ShowErrorMessageInTab(this.EmployeeShiftConfig.ilstErrorMessages);
      }
    }
    this.preLoader.show(false);
   
  }
  SaveApprovalConfigurationError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }


  IsValidReqApprovalConfig() {
    let IsValidUser = false;
    if (!isNullOrUndefined(this.EmployeeShiftConfig)) {
      this.employeeShiftConfigValidationUI = new EmployeeShiftConfigValidationUI();

      let errors = [];
      let errMsg = ""
      let errorCount = 0;
      let lStartDate = "";
      let lEndDate = "";


      let lEmployee= this.checkList;

      let lShift= this.EmployeeShiftConfig.lngshift_config_id;
      let lFromDate = this.EmployeeShiftConfig.dtstart_date;

     
      if (lShift == "" || isNullOrUndefined(lShift)) {
        errMsg = "Shift Period Status is required"
        this.employeeShiftConfigValidationUI.Shift = true;
        errorCount++;
      }
      if (!isNullOrUndefined(this.UIFromDate)) {
        if (!isNullOrUndefined(this.UIToDate)) {
          lStartDate = this.dateFormat.SetUIDateToDate(this.UIFromDate, this.ngbDateParserFormatter);
          let UTLSD = new Date(lStartDate).setHours(0, 0, 0, 0);
          lEndDate = this.dateFormat.SetUIDateToDate(this.UIToDate, this.ngbDateParserFormatter);
          let UTLED = new Date(lEndDate).setHours(0, 0, 0, 0);
          if (UTLED < UTLSD) {
            errMsg = "From Date must be less than End Date.";
            this.employeeShiftConfigValidationUI.FromDateG = true;
            errorCount++;
          }
        }
      }
      else {
        errMsg = "From Date is required"
        this.employeeShiftConfigValidationUI.FromDate = true;
        errorCount++;
      }
      if (errorCount > 0) {
        return false;
      }
      else {
        IsValidUser = true;
        return IsValidUser;
      }
    }
  }

  public ClearEmployeeShiftConfig() {

    this.CreateEmployeeShiftConfig();
    this.ShowSuccessAlert("Values cleared Successfully.");
    }
  public LoadEmployeeShiftConfigList()
  {
    this.employeeShiftConfigValidationUI = new EmployeeShiftConfigValidationUI();
    this.preLoader.show(true);
    this.masterService.LoadEmployeeShiftConfigList().subscribe(
      data=>{this.LoadEmployeeShiftConfigSuccess(data)},
     error=>{this.LoadEmployeeShiftConfigListError(error)}
    )
  }

  public CancelEmployeeShiftConfig() {

    this.CreateEmployeeShiftConfig();
  
   this.ShowSuccessAlert("Values cancelled Successfully");
    }
  
  private LoadEmployeeShiftConfigSuccess(data: any): any {
    if (!isNullOrUndefined(data)) {
      let response = data;
         this.EmployeeShiftConfig = response;
  
   this.SetUIUserDates();
   this.preLoader.show(false);


  }
  }

  private LoadEmployeeShiftConfigListError(error: any): any {
    this.dataService.GetCommonErrorMessage(error);
  }

  public DeleteEmployeeShiftConfig() {
    if (!isNullOrUndefined(this.UISelectedEmployeeShiftConfig)){
    this.preLoader.show(true);
    this.masterService.DeleteEmployeeShiftConfig(this.UISelectedEmployeeShiftConfig.lngemployee_shift_config_id).subscribe(
      data => { this.DeleteEmployeeShiftConfigSuccess(data) },
      error => { this.DeleteEmployeeShiftConfigError(error) }
    );
  }
  else{
    this.Message="Please select any one Employee Shift Config Record";
      this.ShowWarinngAlert(this.Message); 
  }
  }
  
  private DeleteEmployeeShiftConfigSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.EmployeeShiftConfig = data;
      this.DeleteModalReferrence.close();
      if (this.EmployeeShiftConfig.ilstErrorMessages == null || this.EmployeeShiftConfig.ilstErrorMessages.length == 0) {
        
        this.Message="Deleted Successfully";
         this.ShowWarinngAlert(this.Message); 
         this.CreateEmployeeShiftConfig();
        this.ShowInfoAlert(this.EmployeeShiftConfig.iInfoMessage);
        
      }
      else {
     
       let i = this.EmployeeShiftConfig.ilstErrorMessages.length
       this.ShowErrorAlert(i, this.EmployeeShiftConfig.ilstErrorMessages, 'ER001')
       this.ShowErrorMessageInTab(this.EmployeeShiftConfig.ilstErrorMessages);
        //this.alert.alert(Errmsg);
      }
     
    }
    this.preLoader.show(false);
   // this.modalref.close();
  }
  
  private DeleteEmployeeShiftConfigError(error: any) {
    this.dataService.GetCommonErrorMessage(error);
    //this.preload.show(false);
  }

  CloseUserGroupPopUp(){
    this.modalref.close();
  }

  CloseDeleteModalReferrence(){
    this.DeleteModalReferrence.close();
  }
  public SelectedEmployeeShiftConfig(ReqApproval: any) {
    if (!isNullOrUndefined(ReqApproval)) {
      this.UISelectedEmployeeShiftConfig = ReqApproval;
      console.log("this.UISelectedEmployeeShiftConfig",this.UISelectedEmployeeShiftConfig)
      this.EmployeeShiftConfig=ReqApproval;
    }

  }

  public LoadEmployeeShiftConfig(element:any)
  { this.UISelectedEmployeeShiftConfig=element;
    
    if(this.UISelectedEmployeeShiftConfig!=null && this.UISelectedEmployeeShiftConfig.lngemployee_shift_config_id)
    {
    this.LoadEmployeeShiftConfigdetails(this.UISelectedEmployeeShiftConfig.lngemployee_shift_config_id);
    }
    else{
      let listerrormessage=[];
      this.Message="Please select any one Employee Shift Config Record";
      this.ShowWarinngAlert(this.Message); 
    }
  }
  public RefreshEmployeeShiftConfig() {
    if (!(isNullOrUndefined(this.UISelectedEmployeeShiftConfig)) && this.UISelectedEmployeeShiftConfig.lngemployee_shift_config_id > 0) {
    
      this.LoadEmployeeShiftConfigdetails(this.UISelectedEmployeeShiftConfig.lngemployee_shift_config_id);
    }
    this.Message="Please select any one Employee Shift Config Record";
      this.ShowWarinngAlert(this.Message); 
  }
  
  public ConfirmDeleteEmployeeShiftConfig() {
    if (!isNullOrUndefined(this.UISelectedEmployeeShiftConfig))
     {
     
      //this.DeleteModalReferrence = this.modalService.open(RequestDelete, { backdrop: 'static' });
    }
    else{
      this.Message="Please select any one Employee Shift Config Record";
      this.ShowWarinngAlert(this.Message); 
    }
  }
  fullname:any;
public LoadEmployeeShiftConfigdetails(ReqApproval)
{
  this.employeeShiftConfigValidationUI=new EmployeeShiftConfigValidationUI();
  if(this.UISelectedEmployeeShiftConfig!=null && this.UISelectedEmployeeShiftConfig.lngemployee_shift_config_id)
  {

  this.preLoader.show(true);
  this.masterService.LoadEmployeeShiftConfig(ReqApproval).subscribe(
    data=>{this.LoadEmployeeShiftConfigdetailsSuccess(data)},
   error=>{this.LoadEmployeeShiftConfigdetailsError(error)}
  )
}
else{
  this.Message="Please select any one Employee Shift Config Record";
    this.ShowWarinngAlert(this.Message); 
}
}
OpenEmployeeShiftconfigrow
private LoadEmployeeShiftConfigdetailsSuccess(data: any): any {
  if (!isNullOrUndefined(data)) {
    let response = data;
  this.OpenEmployeeShiftconfigrow = response;


  this.EmployeeShiftConfig.lngshift_config_id=this.OpenEmployeeShiftconfigrow.lngshift_config_id;
  this.EmployeeShiftConfig.dtend_date=this.OpenEmployeeShiftconfigrow.dtend_date;
  this.EmployeeShiftConfig.dtstart_date=this.OpenEmployeeShiftconfigrow.dtstart_date;
  this.EmployeeShiftConfig.lngDepartment=this.OpenEmployeeShiftconfigrow.ibusUICompanydepartment.ilngcompany_department_id;
  this.DDLCompanyTeam[0].strId=this.OpenEmployeeShiftconfigrow.ibusUICompanyteam.ilngcompany_team_id;

  this.EmployeeShiftConfig.lngTeam=this.OpenEmployeeShiftconfigrow.ibusUICompanyteam.ilngcompany_team_id;
  this.EmployeeShiftConfig.lngEmployeeId=this.OpenEmployeeShiftconfigrow.lngemployee_id;
  this.fullname=this.EmployeeShiftConfig.ibusUIUser.full_name;
  this.EmployeeShiftConfig.lngEmpShiftConfigId=this.OpenEmployeeShiftconfigrow.lngemployee_shift_config_id;
  this.EmployeeNamebind=this.fullname;

  console.log("this.EmployeeNamebind",this.EmployeeNamebind);
  this.Employeebutton=false;
  this.SetUIUserDates();
  this.preLoader.show(false);
  this.ShowSuccessAlert("Data selected and opened successfully");
  
}

}

private LoadEmployeeShiftConfigdetailsError(data: any): any {
  this.GetCommonErrorMessage(data);
}
  

  ShowWarinngAlert(Msg: any) {
    this.alert.ShowWarningAlert(true);
    this.alert.SetWarningMsg(Msg);
  }
  
  GetMessageCount() {
    this.errorCount = 0;
    this.infoCount = 0;
    this.warningCount = 0;

    if (!isNullOrUndefined(this.MsgTabList) && this.MsgTabList.length > 0) {
      for (let i = 0; i < this.MsgTabList.length; i++) {

        if (this.MsgTabList[i].Type == 'Error') {
          this.errorCount = this.errorCount + 1;
        }
        else if (this.MsgTabList[i].Type == 'Warn') {
          this.warningCount = this.warningCount + 1;
        }
        else if (this.MsgTabList[i].Type == 'Info') {
          this.infoCount = this.infoCount + 1;
        }
      }

    }
  }
  ShowErrorAlert(ErrorCount: any, ErrorMsg: any, ErrorCode: any) {
    let ErrMsg = '';
    if (ErrorCount > 2) {
      ErrMsg = 'Error has been occured.'
    }
    else {
      ErrMsg = ErrorMsg;
    }

    this.alert.SetErrorCode(ErrorCode);
    this.alert.SetErrorDescription(ErrMsg);
    this.alert.ShowErrorAlert(true);
  }
  ShowErrorMsg(Message: any) {
    this.alert.clearAlert();
    let Msg = { "Status": "true", "Message": Message, "Type": "danger" }
    this.alert.alert(Msg);
  }

  ShowSuccessAlert(Msg: any) {
    this.alert.ShowSuccessAlert(true);
    this.alert.SetSuccessMsg(Msg);
  }

  ShowInfoAlert(Msg: any) {
    this.alert.ShowInfoAlert(true);
    this.alert.SetInfoMsg(Msg);
  }

  ShowWarningAlert(Msg: any) {
    this.alert.ShowWarningAlert(true);
    this.alert.SetWarningMsg(Msg);
  } 

  ShowErrorMessageInTab(ErrorMessage: any) {

    // if (isNullOrUndefined(this.MsgTabList)) {
    this.MsgTabList = [];
    // }
    if (isNullOrUndefined(this.ErrorMsgTab)) {
      this.ErrorMsgTab = new ErrorMsgTab();
    }

    if (!isNullOrUndefined(ErrorMessage) && ErrorMessage.length > 0) {
      for (let i = 0; i < ErrorMessage.length; i++) {
        this.ErrorMsgTab.Date = new Date();
        this.ErrorMsgTab.MessageDescription = ErrorMessage[0];
        this.ErrorMsgTab.Type = 'Error';
        this.MsgTabList.push(this.ErrorMsgTab);
      }

      this.GetMessageCount();
    }

    this.DuplicateMsgTabList = this.MsgTabList;
  }

public LoadEmployeelistpopUp(TablePopUp:any)
{
 
  this.preLoader.show(true);
  let value={"DeptId":this.EmployeeShiftConfig.lngDepartment,"TeamId":this.EmployeeShiftConfig.lngTeam}
   this.masterService.LoadEmployeeList(value).subscribe(
    data=>{this.LoadEmployeeListSuccess(data)

      console.log("Loaddata",data);
    
      if(data!=null)
      {
        this.modalref = this.modalService.open(TablePopUp,{ backdrop: 'static' });
      }
      
    
    },
   error=>{this.LoadEmployeeListError(error)}
  )

 

}
EmployeeShiftConfigEmployee:any;
private LoadEmployeeListSuccess(data: any): any {
  if (!isNullOrUndefined(data)) {

    this.saveEmpList=data;
    if(data.ilistbusUIEmployeeShiftConfig.length > 0)
    {

       this.EmployeeShiftConfigEmployee = data;

      console.log("empekep",this.EmployeeShiftConfig);
      
    }
    else
    {
      this.ShowSuccessAlert("No Employee Record");

    }


 this.preLoader.show(false);
}
}

private LoadEmployeeListError(error: any): any {
  this.dataService.GetCommonErrorMessage(error);
}


Employeelist:any;
UISelectedEmployee:any;

saveEmpList:any;

public SelectedEmployee(Employee: any) {
  if (!isNullOrUndefined(Employee)) {
    this.UISelectedEmployee = Employee;
  }

}


isReadOnly:boolean=true;
TeamID:any;
deptID:any;
public GetDepartBasedOnTeam() {

    if (this.EmployeeShiftConfig.lngDepartment != null && this.EmployeeShiftConfig.lngDepartment != "" && this.EmployeeShiftConfig.lngDepartment !=0 ) {
      this.preLoader.show(true);
     
      let Value = { id: this.EmployeeShiftConfig.lngDepartment};
      this.masterService.GetDepartBasedOnTeam(Value)
        .subscribe(
          data => { this.GetDepartBasedOnTeamSuccess(data) },
          error => { this.GetDepartBasedOnTeamError(error) });
    }
  }
  private GetDepartBasedOnTeamSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      let response = data;
      this.DDLCompanyTeam = response;
      console.log("this.DDLSector ",this.DDLCompanyTeam);

      if(this.DDLCompanyTeam.length > 0)
      {
        this.EmployeeShiftConfig.lngTeam = this.DDLCompanyTeam[0].strId;

        this.TeamID= this.DDLCompanyTeam[0].strId;
        this.deptID=this.EmployeeShiftConfig.lngDepartment;
      }
     else{
      this.EmployeeShiftConfig.lngTeam=0;

       }
     
    
     
    }
    this.preLoader.show(false);
  }
  private GetDepartBasedOnTeamError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }


public CheckBoxALLSelect(event)
{

  console.log(event); 

  if(true==event)
  {
    for (let i = 0; i < this.Employeelist.length; i++) {
      
      this.Employeelist[i].strlbIsCheckedBox = true;
  
    }
  }
else{
  for (let i = 0; i < this.Employeelist.length; i++) {
      
    this.Employeelist[i].strlbIsCheckedBox = false;

  }

}
 
}



checkList:any;
ChecklistObjList:any;
count:number;
EmployeeName:any;
EmployeeCount:number;
EmployeeNamebind:string;


public Submitcheckbox()
{
  
  this.checkList=[];
  for (let i = 0; i < this.EmployeeShiftConfigEmployee.ilistbusUIEmployeeShiftConfig.length; i++) {
      
    if(this.EmployeeShiftConfigEmployee.ilistbusUIEmployeeShiftConfig[i].strlbIsCheckedBox==true){
    this.checkList.push(this.EmployeeShiftConfigEmployee.ilistbusUIEmployeeShiftConfig[i]);
     this.count=i;

    }

  }


if(this.checkList.length > 0)
{
  this.TotalCount=this.checkList.length-1;
  
  console.log("this.TotalCount",this.TotalCount);

  this.EmployeeName=this.checkList[0].strEmployeeName;

  console.log("this.EmployeeName",this.EmployeeName);



  this.CloseUserGroupPopUp();
}
else{
  this.ShowSuccessAlert("Select any one Employee");

}






}





}





