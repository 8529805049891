import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  constructor(public http: HttpClient) { }




  public GetResourceDetailInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetResourceDetailsInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetResourceSearch() {
    return this.http.get(AppConstants.BasePath + 'GetResourceSearchObject').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public searchResources(ResourceSearch: any): any {
    return this.http.post(AppConstants.BasePath + 'searchResources', ResourceSearch).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public CreateNewResource(): any {
    return this.http.get(AppConstants.BasePath + 'createNewResource').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public openSelectedResource(resourceid: number): any {
    return this.http.post(AppConstants.BasePath + 'openSelectedResource', resourceid).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteResource(resourceid: number): any {
    return this.http.post(AppConstants.BasePath + 'DeleteResource', resourceid).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }




  public saveResource(ResourceUI: any): any {
    return this.http.post(AppConstants.BasePath + 'saveResource', ResourceUI).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }
  private ErrorHandler(error: Response | any) {
    let errorMSg: string;
    if (error instanceof Response) {
      const err = error || '';
      errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errorMSg = error.message ? error.message : error.toString();
    }
    return _throw(error);
  }

}
