
import { isNullOrUndefined } from 'util';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

export class DateCheck {
    isFutureDate: boolean = false;
    isValid: boolean = false;
    UIDate: any;

    constructor() {

    }
    SetUIDateToDate(date: any, Formatter: NgbDateParserFormatter) {
        let FormatDate = null;

        if (!isNullOrUndefined(date) && date != '') {
            if (date.day != null && date.day != '' && date.month != null &&
                date.month != '' && date.year != null && date.year != '') {
                this.UIDate = date;
                let Dates = Formatter.format(this.UIDate);
                let splitddmmyy = Dates.split('-', 3)
                let Year = splitddmmyy[2];
                let Month = splitddmmyy[1];
                let Day = splitddmmyy[0];
                FormatDate = Year + '-' + Month + '-' + Day;
                return FormatDate;
            }
            else {
                return FormatDate;
            }
        }
        else {
            return FormatDate;
        }
    }


}
