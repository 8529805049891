import { Injectable, EventEmitter, Output } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export class Alert {
  Status: string;
  Message: string;
  Type: string;
}

@Injectable()
export class AlertService {

  private ErrorAlert = new BehaviorSubject<boolean>(false);
  private ErrorAlert$ = this.ErrorAlert.asObservable();

  private ErrorCode = new BehaviorSubject<string>('');
  private ErrorCode$ = this.ErrorCode.asObservable();

  private ErrorDescription = new BehaviorSubject<string>('');
  private ErrorDescription$ = this.ErrorDescription.asObservable();



  private Success = new BehaviorSubject<boolean>(false);
  private Success$ = this.Success.asObservable();

  private Info = new BehaviorSubject<boolean>(false);
  private Info$ = this.Info.asObservable();

  private ErrorPanel = new BehaviorSubject<boolean>(false);
  private ErrorPanel$ = this.ErrorPanel.asObservable();


  private Warning = new BehaviorSubject<boolean>(false);
  private Warning$ = this.Warning.asObservable();


  private InfoMsg = new BehaviorSubject<string>('');
  private InfoMsg$ = this.InfoMsg.asObservable();

  private SuccessMsg = new BehaviorSubject<string>('');
  private SuccessMsg$ = this.SuccessMsg.asObservable();


  private WarningMsg = new BehaviorSubject<string>('');
  private WarningMsg$ = this.WarningMsg.asObservable();


  private ErrorPanelMsg = new BehaviorSubject<string>('');
  private ErrorPanelMsg$ = this.ErrorPanelMsg.asObservable();

  constructor(private http: HttpClient) {

  }

  clearAlert() {

  }

  alert(Msg: any) {
  }

  ShowSuccess(): Observable<boolean> {
    return this.Success$;
  }

  ShowInfo(): Observable<boolean> {
    return this.Info$;
  }

  ShowWarning(): Observable<boolean> {
    return this.Warning$;
  }

  ShowErrorMsgPanel(): Observable<boolean> {
    return this.ErrorPanel$;
  }

  SetSuccessMsg(Value: string) {
    this.SuccessMsg.next(Value);
  }

  GetSuccessMsg(): Observable<string> {
    return this.SuccessMsg$;
  }

  SetInfoMsg(Value: string) {
    this.InfoMsg.next(Value);
  }

  GetInfoMsg(): Observable<string> {
    return this.InfoMsg$;
  }

  SetWarningMsg(Value: string) {
    this.WarningMsg.next(Value);
  }

  GetWarningMsg(): Observable<string> {
    return this.WarningMsg$;
  }

  //
  SetErrorPanelMsg(Value: string) {
    this.ErrorPanelMsg.next(Value);
  }

  GetErrorPanelMsg(): Observable<string> {
    return this.ErrorPanelMsg$;
  }
  //
  ShowInfoAlert(Value: boolean) {
    this.Info.next(Value);
    this.Warning.next(false);
    this.Success.next(false);
  }

  ShowWarningAlert(Value: boolean) {
    this.Warning.next(Value);
    this.Info.next(false);
    this.Success.next(false);
  }

  ShowSuccessAlert(Value: boolean) {
    this.Success.next(Value);
    this.Info.next(false);
    this.Warning.next(false);
  }

  ShowErrorAlert(Value: boolean) {
    this.ErrorAlert.next(Value);
  }


  ShowErrorPanelAlert(Value: boolean) {
    this.ErrorPanel.next(Value);
    this.Info.next(false);
    this.Warning.next(false);
    this.Success.next(false);
  }

  ShowAlert(): Observable<boolean> {
    return this.ErrorAlert$;
  }





  SetErrorCode(Value: string) {
    this.ErrorCode.next(Value);
  }

  GetErrorCode(): Observable<string> {
    return this.ErrorCode$;
  }



  SetErrorDescription(Value: string) {
    this.ErrorDescription.next(Value);
  }

  GetErrorDescription(): Observable<string> {
    return this.ErrorDescription$;
  }

  ClearMsgPanels() {
    this.ShowSuccessAlert(false);
    this.ShowWarningAlert(false);
    this.ShowInfoAlert(false);
    this.ShowErrorPanelAlert(false);
  }


}










