import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class LeaveAdjustmentRequestservice {

  constructor(public http: HttpClient) { }

  public GetLeaveAdjustmentRequestInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetLeaveAdjustmentRequestInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveLeaveAdjustmentRequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveLeaveAdjustmentRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public   RequestToCancelLeaveAdjustmentToApprovalUser(data: any): any {
    return this.http.post(AppConstants.BasePath + 'RequestToCancelLeaveToApproverForLAR', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  public   CreateNewLeaveAdjustmentRequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'CreateNewLeaveAdjustmentRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

 ////////////////////////////supervisor 
 public GetSupervisorddlleaveadjust(deptId: any) {
  return this.http.post(AppConstants.BasePath + 'GetSupervisorddl', deptId).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
//////////////////////////////////////////GetSupervisormailleaveadjust
public GetSupervisormailleaveadjust(deptId: any) {
  return this.http.post(AppConstants.BasePath + 'GetSupervisormail', deptId).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

  public   GetRequestedEmployeeShiftTimeForLAR(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetRequestedEmployeeShiftTimeForLAR', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public   CreateNewLeaveAdjustmentRequestApproval(data: any): any {
    return this.http.post(AppConstants.BasePath + 'CreateNewLeaveAdjustmentRequestApproval', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenLeaveAdjustmentRequest(ilngLeaveAdjustmentRequest: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenLeaveAdjustmentRequest', ilngLeaveAdjustmentRequest).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  public DeleteLeaveAdjustmentRequest(ilngLeaveAdjustmentRequest: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteLeaveAdjustmentRequest', ilngLeaveAdjustmentRequest).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenListOFLARDocumentForPopUP(ilngLeaveAdjustmentRequest: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenListOFLARDocumentForPopUP', ilngLeaveAdjustmentRequest).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public UploadLARDocument(formdata: FormData): any {
    return this.http.post(AppConstants.BasePath + 'UploadLARDocument',formdata).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DownloadLARDocument(DocumentID: any): any {
    return this.http.post(AppConstants.BasePath + 'DownloadLARDocument', DocumentID).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadDocumentBasedonLeaveType(DocumentObject: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadDocumentBasedonLeaveTypeForLAR', DocumentObject).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetValueFromLeavePolicy(Data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetValueFromLeavePolicy', Data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenLARDocument(DocumentID: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenLARDocument', DocumentID).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetLeaveRequestDateByEmployeeId(EmployeeID: any): any {
    return this.http.post(AppConstants.BasePath + 'GetLeaveRequestDateByEmployeeId', EmployeeID).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetLeaveRequestReferenceNo(Data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetLeaveRequestReferenceNo', Data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetAllLeaveAdjustmentRequest(Data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetAllLeaveAdjustmentRequest', Data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveLeaveAdjustmentRequestApproval(Data: any): any {
    return this.http.post(AppConstants.BasePath +
      'SaveLeaveAdjustmentRequestApproval', Data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
 
  public SendLeaveAdjustmentRequestpprovalRemainderMail() {
    return this.http.get(AppConstants.BasePath + 'SendLeaveAdjustmentRequestpprovalRemainderMail').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  public GetAllLeaveAdjustmentRequestApproval(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetAllLeaveAdjustmentRequestApproval', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetLeaveDaysCountbyWeekShiftConfigLAR(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetLeaveDaysCountbyWeekShiftConfigLAR', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }
  private ErrorHandler(error: Response | any) {
    let errorMSg: string;
    if (error instanceof Response) {
      const err = error || '';
      errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errorMSg = error.message ? error.message : error.toString();
    }
    return _throw(error);
  }

  public GetDepartBasedOnTeam(deptId: any) {
    return this.http.post(AppConstants.BasePath + 'GetDepartBasedOnTeam', deptId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public Getemployeebasedonteamanddept(deptId: any) {
    return this.http.post(AppConstants.BasePath + 'Getemployeebasedonteamanddept', deptId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

 public SearchLeaveAdjustmentRequest(data: any) {
    return this.http.post(AppConstants.BasePath + 'SearchLeaveAdjustmentRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

public SearchLeaveAdjustmentRequestApproval(data: any) {
    return this.http.post(AppConstants.BasePath + 'SearchLeaveAdjustmentRequestApproval', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

 public GetleavebalancefieldAdjustment(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetleavebalancefieldAdjustment', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

}
