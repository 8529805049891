import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class LeavePolicyService {


  constructor(public http: HttpClient) { }

  public GetLeavepolicyInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetLeavepolicyInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }


  public LoadLeavePolicy() {
    return this.http.get(AppConstants.BasePath + 'LoadLeavePolicy').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }

  public CreateLeavepolicyDetails() {
    return this.http.get(AppConstants.BasePath + 'CreateLeavepolicyDetails').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }

  public CreateLeaveApprovalConfig() {
    return this.http.get(AppConstants.BasePath + 'CreateLeaveApprovalConfig').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }

  public SaveLeavePolicyConfig(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveLeavePolicyConfig', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public DeleteLeavePolicy(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteLeavePolicy', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public DeleteLeavePolicyApproval(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteLeavePolicyApproval', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveLeaveApprovalHierarchy(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveLeaveApprovalHierarchy', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }



  OpenLeavePolicy(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenLeavePolicy', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  OpenLeaveApprovalconfig(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenLeaveApprovalconfig', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadLeaveEmployeePolicy(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadLeaveEmployeePolicy', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public ApprovalmasterList(Value: any) {
    return this.http.post(AppConstants.BasePath + 'ApprovalmasterList', Value).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }
  
  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }
  private ErrorHandler(error: Response | any) {
    let errorMSg: string;
    if (error instanceof Response) {
      const err = error || '';
      errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
    } 
    else {
      errorMSg = error.message ? error.message : error.toString();
    }
    return _throw(error);
  }
}
