import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})

export class AdjustmentApprovalAssignedOfficerService {

  constructor(public http: HttpClient) { }

  public GetMissedHoursApprovalSearch() {
    return this.http.get(AppConstants.BasePath + 'GetAttendanceAdjustmentRequestAssignedOfficer').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public searchMissedHoursApproval(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SearchAttendanceAdjustmentRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetMissedHoursApprovalInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetMissedHoursApprovalDetailInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetAttendanceAdjustMentRequest() {
    return this.http.get(AppConstants.BasePath + 'GetbusAttendanceAdjustmentRequestSearch').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenEmployeeMissedHourRequestDetail(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenEmployeeMissedHourRequestDetail', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  public OpenAttendanceAdjustmentRequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenAttendanceAdjustmentRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadAdjustRequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadAdjustRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  /////NOtes

  public CreateNewAdjustRequest(): any {
    return this.http.get(AppConstants.BasePath + 'CreateNewAdjustRequestNotes').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveAdjustRequestNotes(EmployerNotes: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveAdjustRequestNotes', EmployerNotes).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  ///////////
  public OpenNotes(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenNotes', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public CreateNotes(): any {
    return this.http.get(AppConstants.BasePath + 'CreateNewNotes').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveNotes(EmployerNotes: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveNotes', EmployerNotes).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveAssignedOfficer(obj: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveAssignedOfficer', obj).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }

  private ErrorHandler(error: Response | any) {
    let errorMSg: string;
    if (error instanceof Response) {
      const err = error || '';
      errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errorMSg = error.message ? error.message : error.toString();
    }
   return _throw(error);
  }
}
