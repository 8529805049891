import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(public http: HttpClient) { }


  public GetConfigSearch() {
    return this.http.get(AppConstants.BasePath + 'getConfigSearchObject').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }

  public searchConfigs(searchConfigs: any): any {
    return this.http.post(AppConstants.BasePath + 'searchConfigs', searchConfigs).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public SaveConfig(ConfigUI: any): any {
    return this.http.post(AppConstants.BasePath + 'saveConfig', ConfigUI).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public CreateNewConfig(): any {
    return this.http.get(AppConstants.BasePath + 'createNewConfig').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }



  public OpenConfig(ConfigId: number): any {
    return this.http.post(AppConstants.BasePath + 'openSelectedConfig', ConfigId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public OpenSelectedConfigValue(ConfigId: number): any {
    return this.http.post(AppConstants.BasePath + 'openSelectedConfigValue', ConfigId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public CreateNewConfigValue(): any {
    return this.http.get(AppConstants.BasePath + 'createNewConfigValue').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public SaveConfigValue(ConfigUI: any): any {
    return this.http.post(AppConstants.BasePath + 'saveConfigValue', ConfigUI).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public CreateNewConfigMetaData(): any {
    return this.http.get(AppConstants.BasePath + 'createNewConfigMetaData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public SaveConfigMetaData(ConfigMetadataUI: any): any {
    return this.http.post(AppConstants.BasePath + 'saveConfigMetaData', ConfigMetadataUI).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }



  public OpenConfigMetaData(configmatadataid: number): any {
    return this.http.post(AppConstants.BasePath + 'openSelectedConfigMetaData', configmatadataid).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteConfigValue(Selectedconfigvalue: number): any {
    return this.http.post(AppConstants.BasePath + 'deleteSelectedConfigValue', Selectedconfigvalue).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteSelectedConfigMetaData(Selectedconfigvaluemetadata: number): any {
    return this.http.post(AppConstants.BasePath + 'deleteSelectedConfigMetaData', Selectedconfigvaluemetadata).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public SyncConfigValueFromPATIS(Config: any): any {
    return this.http.post(AppConstants.BasePath + 'SyncConfigValueByConfigId', Config).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }
  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }
  private ErrorHandler(error: Response | any) {
    let errorMSg: string;
    if (error instanceof Response) {
      const err = error || '';
      errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errorMSg = error.message ? error.message : error.toString();
    }
  return _throw(error);
  }

}
