import { Component, OnInit } from '@angular/core';
import { PreloadService } from 'src/app/services/common/preload.service';


@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {


  msg:boolean = true;
  constructor(public preload:PreloadService) { }

  ngOnInit() {
    this.preload.enablePreload().subscribe((msg) => {
      this.msg = msg;
    });
  }



}
