
import { Component, OnInit, ElementRef } from '@angular/core';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { NgbModal, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PreloadService } from 'src/app/services/common/preload.service';
import { LocalStorageService } from 'angular-web-storage';
import { DataService } from 'src/app/services/common/data.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { FormatDate, IsLoggedIn, FileFilter } from 'src/app/common/common';
import { Router } from '@angular/router';
import { TitleServiceService } from 'src/app/services/common/title-service.service';
import { isNullOrUndefined, format } from 'util';
import { AttendancetimesheetService } from 'src/app/services/application/attendancetimesheet.service';
import { AttendanceValidationUI, AttendancemontylwiseValidationUI } from 'src/app/common/validationUI';
import {
  ViewChild,
  TemplateRef
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { Subject } from 'rxjs';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
  CalendarMonthViewDay
} from 'angular-calendar';
import { DatePipe } from '@angular/common';

const colors: any = {
  red: {
    primary: '#931209',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  },
  Green: {
    primary: '#1a863b',
    secondary: '#FAE3E3'
  },
  Orange: {
    primary: '#FF601e',
    secondary: '#FF601e'
  }
};

@Component({
  selector: 'app-attendance-team',
  templateUrl: './attendance-team.component.html',
  styleUrls: ['./attendance-team.component.scss']
})

export class AttendanceTeamComponent implements OnInit {
  @ViewChild('holidayAlert', { static: true }) holidayAlert: TemplateRef<any>;
  @ViewChild('submitforApprovalAlert', { static: true }) submitforApprovalAlert: TemplateRef<any>;
  public selectedTime;
  public SelectedInTime: any;
  public SelectedOutTime: any;
  istrue: boolean = true;
  Stdhourbtn: boolean = true;
  AttendanceUI: any;
  SelectAttendance: number;
  AttendanceData: any = [];
  EmployeeAttendancId: any;
  HolidayList: any = [];
  public GetTodayDate: any;
  public GetDay: any;
  UISelectedTotalAttendanceDetail: any;
  AttendanceValidationUI: AttendanceValidationUI;
  AttendancemontylwiseValidationUI: AttendancemontylwiseValidationUI;
  AttendanceListUI: any = [];
  BoolDaily: boolean = true;
  BoolWeekly: boolean = false;
  BoolMonthLy: boolean = false;
  holidayalert: string;
  diff_Hours: any;
  diff_Minutes: any;
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'DD-MM-YYYY',
    defaultOpen: false,
  }
  from_date: any;
  to_date: any;
  UIDate: any;
  TotalDiff: any = "00:00";
  CurrentDates: string;
  BoolOpenMsgShow: boolean = false;
  supervisorValue = false;
  IsSave: boolean = false;
  SelectedAttendanceRow: number;
  SelectedAttendanceDetailRow: number;
  modalReferrence: any;
  SelectTotalAttendance: any;
  searchIndex: any;
  day: any;
  month: any;
  year: any;
  dtmax: any;
  minDate: any;
  curr: Date;
  first: number;
  lastday: string;
  firstday: string;
  InitialDDL: any;
  DDLMonthly: any;
  WeeklyCheck: boolean;
  Mothlycheck: boolean;
  months: boolean = false;
  date: boolean = false;
  DDLWeek: any;
  DDLYear: any;
  UIUserBeginDate: any;
  UIUserEndDate: any;
  yearvalue: any;
  monthValue: any;
  weekvalue: any;
  BoolWeeklys: boolean;
  BoolMonthLys: boolean;
  totalHour: any;
  GetToday: string;
  GetDays: string;
  DDLBenefits: any;
  AttendanceTimeTypeUI: any;
  is_edit: boolean;
  AttendanceCalendarListUI: any;
  LeaveListUI: any[];
  UISelectedTo: boolean;
  disable: boolean;
  SelectedDate: string;
  DDLStatus: any;
  DDLAttendanceStatus: any;
  DDLISUserTeam: any;
  IsTeamUser: any;
  EmplyeeId: number;
  DDLEmployee: any;
  AttendanceStatus: any;
  Filtercontent: boolean = false;
  IsCheckValidOutTime: boolean = false;
  IsCheckValidInTime: boolean = false;
  SelectedCalanderDate: any;
  istrYear: any;
  istrMonth: any;
  istrWeek: any;
  istStatusValue: any;
  idtmPeriodStartTime: any;
  idtmPeriodEndTime: any;
  formdata: any;
  InvalidFile: boolean;
  InvalidFileError: string;
  uploadData: any[];
  FileContent: string | ArrayBuffer;
  @ViewChild('uploadFile', { static: false })
  UploadVariable: ElementRef;
  max_working_hours_per_day: any;
  MaxNonBreakWorkHrsAlertContent: string;
  LoginedEmployeeId: any;
  DisplayContentInAlert: string;
  IsUserLoggedIn: boolean;
  publicholidaystdtime: boolean = true;
  searchText: any;
  dateselect: string;
  AttendanceteamrequestUI: any;
  Submitalert: any;

  validdate = true;
  Fromdatemandatory = false;
  todatemandatory = false;
  Datecheck = false;
  show() {
    this.months = !this.month;
  }
  show1() {
    this.date = !this.date;
  }
  xviewDate: Date = new Date();
  IsUploadIsVisible: boolean = false;
  selectedDays: any = [];
  selectedMonthViewDay: CalendarMonthViewDay;
  refresh: Subject<any> = new Subject;

  constructor(
    private atp: AmazingTimePickerService,
    private title: TitleServiceService,
    private modalService: NgbModal,
    private modal: NgbModal,
    private attendancetimesheetService: AttendancetimesheetService,
    public preLoader: PreloadService,
    public localStorage: LocalStorageService,
    public dataService: DataService,
    public alert: AlertService,
    public dateFormat: FormatDate,
    public ngbDateParserFormatter: NgbDateParserFormatter,
    public IsLoggedIn: IsLoggedIn,
    public datePipe: DatePipe,
    public router: Router) {
    this.IsUserLoggedIn = this.IsLoggedIn.IsUserLoggedIn();
    if (!this.IsUserLoggedIn) {
      this.router.navigate(['/login'])
      return;
    }
    this.searchText;
  }

  ngOnInit() {
    this.events = [];
    this.title.setsubTitle('Team');
    this.title.setTitle('Team Timesheet');
    this.title.setTitleforPdf('Team Timesheet');
    this.title.setModuleName("ApplicationModule");
    this.title.setshowpdf(true);
    this.CreateAttendanceForInitial();
    let today: any = new Date();
    this.SelectedCalanderDate = today;
    this.AttendanceValidationUI = new AttendanceValidationUI();
    this.AttendancemontylwiseValidationUI = new AttendancemontylwiseValidationUI();
    this.SelectedInTime = "00:00";
    this.SelectedOutTime = "00:00";
  }

  GetAttendanceInitialData() {
    this.preLoader.show(true);
    this.attendancetimesheetService.GetAttendanceInitialData().subscribe(
      data => { this.GetUserInitialDataSuccess(data) }, error => { this.GetAttendanceInitialDataError(error) });
  }

  GetUserInitialDataSuccess(data: any) {
    this.InitialDDL = data;

    this.SetDDLInLocalStorage();
    this.InitializeDDL();
    this.preLoader.show(false);
  }

  GetAttendanceInitialDataError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }


  SetDDLInLocalStorage() {
    this.localStorage.set("DDLMonthly", this.InitialDDL.DDLMonthly);
    this.localStorage.set("DDLYear", this.InitialDDL.DDLYear);
    this.localStorage.set("DDLWeek", this.InitialDDL.DDLWeek);
    this.localStorage.set("DDLBenefits", this.InitialDDL.DDLBenefits);
    this.localStorage.set("DDLEmployee", this.InitialDDL.DDLEmployee);
    this.localStorage.set("DDLAttendanceStatus", this.InitialDDL.DDLAttendanceStatus);
    this.localStorage.set("DDLISUserTeam", this.InitialDDL.DDLISUserTeam);
  }

  InitializeDDL() {
    if (this.localStorage.get("DDLMonthly") != null) {
      this.DDLMonthly = this.localStorage.get("DDLMonthly");
    }
    if (this.localStorage.get("DDLYear") != null) {
      this.DDLYear = this.localStorage.get("DDLYear");
    }
    if (this.localStorage.get("DDLWeek") != null) {
      this.DDLWeek = this.localStorage.get("DDLWeek");
    }

    if (this.localStorage.get("DDLBenefits") != null) {
      this.DDLBenefits = this.localStorage.get("DDLBenefits");
    }

    if (this.localStorage.get("DDLAttendanceStatus") != null) {
      this.DDLAttendanceStatus = this.localStorage.get("DDLAttendanceStatus");
    }

    if (this.localStorage.get("DDLEmployee") != null) {
      this.DDLEmployee = this.localStorage.get("DDLEmployee");
    }
    if (this.localStorage.get("DDLISUserTeam") != null) {
      this.DDLISUserTeam = this.localStorage.get("DDLISUserTeam");

      this.IsTeamUser = this.DDLISUserTeam[0].strConstant;
    }
  }


  CreateInitializeDDL() {
    this.localStorage.set("DDLBenefits", this.AttendanceUI.ilistUIDDLExcessDuty);
    this.localStorage.set("DDLEmployee", this.AttendanceUI.ilistUIDDLReportToEmployee);
    if (this.localStorage.get("DDLEmployee") != null) {
      this.DDLEmployee = this.localStorage.get("DDLEmployee");
    }
    if (this.localStorage.get("DDLBenefits") != null) {
      this.DDLBenefits = this.localStorage.get("DDLBenefits");
    }
  }

  //////***********************************************load Atttendance *****************************************//

  public LoadAttendanceBasedOnDeilyMonthlyWeekly(data: any) {
    this.preLoader.show(true);
    if (data.BoolMonthLy == true) {
      this.Mothlycheck = true;
      this.WeeklyCheck = false;
    }
    else if (data.BoolWeekly == true) {
      this.WeeklyCheck = true;
      this.Mothlycheck = false;
    }
    else {
      this.WeeklyCheck = false;
      this.Mothlycheck = false;
    }
    this.attendancetimesheetService.LoadAttendanceInitially(this.AttendanceUI)
      .subscribe(
        data => { this.LoadAttendanceOnDeilyMonthlyWeeklySuccess(data) }, error => { this.LoadAttendanceBasedOnDeilyMonthlyWeeklyError(error) });
  }

  LoadAttendanceOnDeilyMonthlyWeeklySuccess(data: any) {
    this.GetNextpayrollstartdateenddate();

    if (data.length > 0) {
      this.AttendanceListUI = data;
      this.AttendanceCalendarListUI = data;
      let isvalidcurrentdate = true;
      let SelectedObject = this.AttendanceListUI.find(x => x.IsboolCurrentDate
        == isvalidcurrentdate);
      if (SelectedObject != null) {
        this.AttendanceUI = SelectedObject;
        var index = this.AttendanceListUI.indexOf(SelectedObject);
        this.SelectedAttendanceDetailRow = index;
        this.AttendanceListUI[index] = SelectedObject;
        this.SelectedCalanderDate = SelectedObject.dtmDate;
        // if (!isNullOrUndefined(this.AttendanceUI)) {
        //   this.OpenAttendance(this.AttendanceUI.lngAttendance_id);
        // }
      } else {
        let today: any = new Date();
        // this.SelectedCalanderDate = today;
        this.SelectedAttendanceDetailRow = null;
      }
      this.AttendanceValidationUI = new AttendanceValidationUI();
      this.SelectedInTime = "00:00";
      this.SelectedOutTime = "00:00";
      console.log(this.AttendanceListUI);
    }
    else {
      this.AttendanceCalendarListUI = [];
      this.AttendanceListUI = [];
      this.AttendanceUI.ilistbusUIAttendanceDetail = [];
      this.events = [];
      this.preLoader.show(false);
      console.log('call4')
    }
    console.log("before")
    this.BeforeSearch();
    console.log("after")
    this.IsHoliday();
    this.refresh.next();
    this.preLoader.show(false);
    this.LoadLeaveShowonTimesheet(this.AttendanceUI);
    this.GetSelectedDateDetails(this.AttendanceUI);
  }





  public LoadAttendaceAfterSaveAndDelete(data) {

    this.LoadAttendanceCalendarMonthlySuccess(data);

  }
  //   this.attendancetimesheetService.LoadAttendanceBasedOnDeilyMonthlyWeekly(this.AttendanceUI)
  //     .subscribe(
  //       data => { this.LoadAttendanceCalendarMonthlySuccess(data) }, error => { this.LoadAttendanceBasedOnDeilyMonthlyWeeklyError(error) });
  // }


  LoadAttendanceCalendarMonthlySuccess(data: any) {

    if (data.length > 0) {
      this.AttendanceListUI = data;
      this.AttendanceCalendarListUI = data;
      if (this.AttendanceCalendarListUI != null && this.AttendanceCalendarListUI.length > 0) {
        this.events = this.GetEventData();
      }
      else {
        this.events = [];
      }
      if (this.AttendanceUI && this.AttendanceUI.lngAttendance_id > 0) {
        let SelectedObject = this.AttendanceListUI.find(x => x.lngAttendance_id
          == this.AttendanceUI.lngAttendance_id);
        if (SelectedObject != null) {
          var index = this.AttendanceListUI.indexOf(SelectedObject);
          this.SelectedAttendanceDetailRow = index;
          this.AttendanceListUI[index] = SelectedObject;
          this.AttendanceUI = SelectedObject;
          // if (!isNullOrUndefined(this.AttendanceUI)) {
          //   this.OpenAttendance(this.AttendanceUI.lngAttendance_id);
          // }
        }
      }
      this.AttendanceValidationUI = new AttendanceValidationUI();
      this.SelectedInTime = "00:00";
      this.SelectedOutTime = "00:00";
      this.SelectAttendance = null;
    }
    else {
      this.AttendanceListUI = [];
      this.AttendanceUI.ilistbusUIAttendanceDetail = [];
      this.events = [];
    }
    this.IsHoliday();
    this.refresh.next();
    this.preLoader.show(false);
  }

  IsHoliday() {
    if (this.AttendanceUI.IsPublicHoliday == true) {
      this.IsUploadIsVisible = true;
    } else {
      this.IsUploadIsVisible = false;
    }
  }

  LoadAttendanceBasedOnDeilyMonthlyWeeklyError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }


  dayClicked(day: CalendarMonthViewDay, xdate: CalendarMonthViewDay,
    events: CalendarEvent[]): void {
    this.selectedMonthViewDay = day;
    const dateIndex = this.selectedDays.findIndex(
      (selectedDay) => {
        delete selectedDay.cssClass;
      }
    );
    this.selectedDays.push(this.selectedMonthViewDay);
    day.cssClass = 'cal-day-selected';
    this.selectedMonthViewDay = day;
    const date = xdate.date;

    this.activeDayIsOpen = false;

    this.preLoader.show(true);
    this.UISelectedTo = false;
    this.Filtercontent = false;
    this.SelectedCalanderDate = date;
    this.BoolMonthLy = false;
    this.BoolWeekly = false;
    //this.AttendanceUI.dtmDate = this.SelectedCalanderDate;
    this.AttendanceUI.dtmDate = date.toLocaleDateString('zh-Hans-CN').replace(new RegExp('/', 'g'), '-');
    this.dateselect = this.AttendanceUI.dtmDate;
    this.UISelectedTotalAttendanceDetail = null;
    this.disable = false;
    this.IsCheckValidOutTime = false;
    this.IsCheckValidInTime = false;
    if (isNullOrUndefined(this.AttendanceUI.dtmDate)) {
      this.AttendanceUI.dtmDate = null;
    }

    this.GetSelectedDateDetails(this.AttendanceUI);
    this.SelectedInTime = "00:00";
    this.SelectedOutTime = "00:00";
    //this.preLoader.show(false);
  }

  public GetSelectedDateDetails(data: any) {

    if (!isNullOrUndefined(data)) {
      this.preLoader.show(true);
      data.BoolWeekly = true;
      data.BoolMonthLy = false;

      this.attendancetimesheetService.GetSelectedDayAttendance(this.AttendanceUI)
        .subscribe(
          data => { this.GetSelectedDateDetailsSuccess(data) }, error => { this.LoadAttendanceBasedOnDeilyMonthlyWeeklyError(error) });
    }
  }

  GetSelectedDateDetailsSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      if (this.AttendanceListUI != null && this.AttendanceListUI.length > 0) {
        let SelectedObject = this.AttendanceListUI.find(x => x.lngAttendance_id
          == data.lngAttendance_id);
        if (SelectedObject != null) {
          let index = this.AttendanceListUI.indexOf(SelectedObject);
          this.AttendanceListUI[index] = SelectedObject;
          this.SelectedAttendanceDetailRow = index;
        }
      }
      this.AttendanceUI = data;
      this.GetSupervisorddl();
      this.GetNextpayrollstartdateenddate();
      this.AttendanceValidationUI = new AttendanceValidationUI();
      this.SelectedInTime = "00:00";
      this.SelectedOutTime = "00:00";
      this.TotalDiff = "00:00";
      this.SelectAttendance = null;
      this.IsCheckValidOutTime = false;
      this.IsCheckValidInTime = false;
      this.disable = false;
      this.IsHoliday();
      this.Holidayalert(this.dateselect);
    }
    // this.refresh.next();
    //this.refresh.next();

    this.preLoader.show(false);
  }


  // public GetSelectedDateDetails(data: any) {
  //   if (!isNullOrUndefined(data)) {
  //     this.preLoader.show(true);
  //     data.BoolWeekly = true;
  //     data.BoolMonthLy = false;
  //     this.AttendanceUI.idtmPeriodStartTime = this.SelectedCalanderDate;
  //     this.AttendanceUI.idtmPeriodEndTime = this.SelectedCalanderDate;
  //     if(this.AttendanceUI.idtmPeriodStartTime=="Invalid date")
  //     {
  //       this.AttendanceUI.idtmPeriodStartTime=null;
  //     }
  //     if(this.AttendanceUI.idtmPeriodEndTime=="Invalid date")
  //     {
  //       this.AttendanceUI.idtmPeriodEndTime=null;
  //     }
  //     this.attendancetimesheetService.LoadAttendanceBasedOnDeilyMonthlyWeekly(this.AttendanceUI)
  //       .subscribe(
  //         data => { this.GetSelectedDateDetailsSuccess(data) }, error => { this.LoadAttendanceBasedOnDeilyMonthlyWeeklyError(error) });
  //   }
  // }

  // GetSelectedDateDetailsSuccess(data: any) {
  //   if (data.length > 0) {
  //     if (this.AttendanceListUI != null && this.AttendanceListUI.length > 0) {
  //       let SelectedObject = this.AttendanceListUI.find(x => x.lngAttendance_id
  //         == data[0].lngAttendance_id);
  //       if (SelectedObject != null) {
  //         this.AttendanceUI = SelectedObject;
  //         let index = this.AttendanceListUI.indexOf(SelectedObject);
  //         this.AttendanceListUI[index] = SelectedObject;
  //         this.SelectedAttendanceDetailRow = index;
  //         if (!isNullOrUndefined(this.AttendanceUI)) {
  //           this.OpenAttendance(this.AttendanceUI.lngAttendance_id);
  //         }
  //         this.AttendanceValidationUI = new AttendanceValidationUI();
  //         this.SelectedInTime = "00:00";
  //         this.SelectedOutTime = "00:00";
  //         this.SelectAttendance = null;
  //         this.IsCheckValidOutTime = false;
  //         this.IsCheckValidInTime = false;
  //       }
  //     }
  //   } else {
  //     this.CreateNewAttendance();
  //   }
  //   this.preLoader.show(false);
  // }

  MonthWeekWise() {
    this.TotalDiff = "00:00";
    this.BoolMonthLy = true;
    this.BoolWeekly = false;
    this.BoolDaily = false;
    var year = new Date().getFullYear().toString();
    var TodayMonth = new Date().getMonth() + 1;
    var month = TodayMonth.toString();
    if (!isNullOrUndefined(this.AttendanceUI)) {
      this.AttendanceUI.istrMonth = month;
      this.AttendanceUI.istrWeek = "";
      this.AttendanceUI.istrYear = year;
      this.AttendanceUI.BoolDaily = false;
      this.AttendanceUI.BoolWeekly = false;
      this.AttendanceUI.BoolMonthLy = true;
      this.AttendanceUI.istStatusValue = null;
      this.BeforeSearch();
    }
  }


  PeriodWise() {
    this.TotalDiff = "00:00";
    this.BoolWeekly = true;
    this.BoolDaily = false;
    this.BoolMonthLy = false;
    this.SetDateForUITOBinding();
    this.SetDateForUIFromBinding();
    if (!isNullOrUndefined(this.AttendanceUI)) {
      this.AttendanceUI.BoolDaily = false;
      this.AttendanceUI.BoolWeekly = true;
      this.AttendanceUI.BoolMonthLy = false;
      this.AttendanceUI.istStatusValue = null;
      this.BeforeSearch();
    }
  }


  SetDateForUIFromBinding() {
    let todays: any = new Date();
    // this.AttendanceUI.idtmPeriodStartTime= this.dateFormat.FormatDate(todays as string);
    this.AttendanceUI.idtmPeriodStartTime = todays;

  }


  SetDateForUITOBinding() {
    let todays: any = new Date();
    this.AttendanceUI.idtmPeriodEndTime = todays;
  }



  // SetDateForUIFromBinding() {
  //   let todays: any = new Date();
  //   this.AttendanceUI.idtmPeriodStartTime = todays;
  // }


  // SetDateForUITOBinding() {
  //   let todays: any = new Date();
  //   this.AttendanceUI.idtmPeriodEndTime = todays;
  // }
  WeeklyClear() {
    this.PeriodWise();
    let today: any = new Date();
    this.SelectedCalanderDate = today;
    this.AttendanceListUI = [];
    this.AttendanceUI.ilistbusUIAttendanceDetail = [];
    this.events = [];
    this.UISelectedTo = false;
    this.AttencenceClear();
    this.AttendanceUI.lngemployee_Id = this.LoginedEmployeeId;
  }

  MonthLyClear() {
    this.MonthWeekWise();
    let today: any = new Date();
    this.SelectedCalanderDate = today;
    this.AttendanceListUI = [];
    this.AttendanceUI.ilistbusUIAttendanceDetail = [];
    this.events = [];
    this.UISelectedTo = false;
    this.AttencenceClear();
    this.AttendanceUI.lngemployee_Id = this.LoginedEmployeeId;
    this.ChangeMonthLy();
  }

  ChangeWeekly() {
    this.AttendancemontylwiseValidationUI = new AttendancemontylwiseValidationUI();
    this.TotalDiff = "00:00";
    this.BoolWeekly = true;
    this.BoolDaily = false;
    this.BoolMonthLy = false;
    if (!isNullOrUndefined(this.AttendanceUI)) {
      this.AttendanceUI.BoolDaily = false;
      this.AttendanceUI.BoolWeekly = true;
      this.AttendanceUI.BoolMonthLy = false;
      if (this.AttendanceUI.istStatusValue == "null")
        this.AttendanceUI.istStatusValue = null;
      this.BeforeSearch();
      if (this.IsValidWeekWisedata()) {
        this.LoadAttendanceGridBinding(this.AttendanceUI);
      }
    }
  }

  ChangeMonthLy() {
    this.AttendancemontylwiseValidationUI = new AttendancemontylwiseValidationUI();
    this.TotalDiff = "00:00"; ``
    this.BoolWeekly = false;
    this.BoolDaily = false;
    this.BoolMonthLy = true;
    if (!isNullOrUndefined(this.AttendanceUI)) {
      this.AttendanceUI.BoolDaily = false;
      this.AttendanceUI.BoolWeekly = false;
      this.AttendanceUI.BoolMonthLy = true;
      this.monthValue = this.AttendanceUI.istrMonth;
      this.weekvalue = this.AttendanceUI.istrWeek;
      this.yearvalue = this.AttendanceUI.istrYear;
      if (this.AttendanceUI.istStatusValue == "null")
        this.AttendanceUI.istStatusValue = null;
      this.BeforeSearch();
      if (this.IsValidmonthlywisedata()) {
        this.LoadAttendanceGridBinding(this.AttendanceUI);
      }
    }
  }


  BeforeSearch() {
    if (!isNullOrUndefined(this.AttendanceUI.istrYear) &&
      this.AttendanceUI.istrYear != null &&
      this.AttendanceUI.istrYear != "") {
      this.istrYear = JSON.parse(JSON.stringify(this.AttendanceUI.istrYear));
    } else {
      this.istrYear = '';
    }

    if (!isNullOrUndefined(this.AttendanceUI.istrMonth) &&
      this.AttendanceUI.istrMonth != null &&
      this.AttendanceUI.istrMonth != "") {
      this.istrMonth = JSON.parse(JSON.stringify(this.AttendanceUI.istrMonth));
    } else {
      this.istrMonth = '';
    }

    if (!isNullOrUndefined(this.AttendanceUI.istrWeek) &&
      this.AttendanceUI.istrWeek != null && this.AttendanceUI.istrWeek != "") {
      this.istrWeek = JSON.parse(JSON.stringify(this.AttendanceUI.istrWeek));
    } else {
      this.istrWeek = '';
    }

    if (!isNullOrUndefined(this.AttendanceUI.istStatusValue)
      && this.AttendanceUI.istStatusValue != null &&
      this.AttendanceUI.istStatusValue != "null") {
      this.istStatusValue = JSON.parse(JSON.stringify(this.AttendanceUI.istStatusValue));
    } else {
      this.istStatusValue = null;
    }

    this.idtmPeriodStartTime = JSON.parse(JSON.stringify(this.AttendanceUI.idtmPeriodStartTime));
    this.idtmPeriodEndTime = JSON.parse(JSON.stringify(this.AttendanceUI.idtmPeriodEndTime));
  }
  AfterSearch() {
    console.log("aftersearch" + "-" + this.AttendanceUI.istrMonth)
    if (!isNullOrUndefined(this.istrWeek) && this.istrWeek != null && this.istrWeek != "") {
      this.AttendanceUI.istrWeek = this.istrWeek;
    } else {
      this.AttendanceUI.istrWeek = '';
    }


    if (!isNullOrUndefined(this.istrMonth) && this.istrMonth != null && this.istrMonth != "") {
      this.AttendanceUI.istrMonth = this.istrMonth;
    } else {
      this.AttendanceUI.istrMonth = ''
    }

    if (!isNullOrUndefined(this.istrYear) && this.istrYear != null && this.istrYear != "") {
      this.AttendanceUI.istrYear = this.istrYear;
    } else {
      this.AttendanceUI.istrYear = ''
    }

    if (!isNullOrUndefined(this.istStatusValue) && this.istStatusValue != null && this.istStatusValue != "null") {
      this.AttendanceUI.istStatusValue = this.istStatusValue;
    } else {
      this.AttendanceUI.istStatusValue = null;
    }


    if (!isNullOrUndefined(this.idtmPeriodStartTime) && this.idtmPeriodStartTime != null)
      this.AttendanceUI.idtmPeriodStartTime = this.idtmPeriodStartTime;

    if (!isNullOrUndefined(this.idtmPeriodEndTime) && this.idtmPeriodEndTime != null)
      this.AttendanceUI.idtmPeriodEndTime = this.idtmPeriodEndTime;

  }


  ChangeMonthLyForDelete() {
    this.TotalDiff = "00:00";
    this.BoolMonthLy = true;
    this.BoolWeekly = false;
    this.BoolDaily = false;
    var year = new Date().getFullYear().toString();
    var TodayMonth = new Date().getMonth() + 1;
    var month = TodayMonth.toString();
    if (!isNullOrUndefined(this.AttendanceUI)) {
      this.AttendanceUI.istrMonth = month;
      this.AttendanceUI.istrWeek = "";
      this.AttendanceUI.istrYear = year;
      this.AttendanceUI.BoolDaily = false;
      this.AttendanceUI.BoolWeekly = false;
      this.AttendanceUI.BoolMonthLy = true;
      this.AttendanceUI.istStatusValue = null;
      this.BeforeSearch();
      if (this.IsValidmonthlywisedata()) {
        this.LoadAttendanceGridBinding(this.AttendanceUI);
      }
    }
  }

  public LoadAttendanceGridBinding(data: any) {
    this.preLoader.show(true);
    if (data.BoolMonthLy == true) {
      this.Mothlycheck = true;
      this.WeeklyCheck = false;
    }
    else if (data.BoolWeekly == true) {
      this.WeeklyCheck = true;
      this.Mothlycheck = false;
    }
    else {
      this.WeeklyCheck = false;
      this.Mothlycheck = false;
    }
    this.attendancetimesheetService.LoadAttendanceBasedOnDeilyMonthlyWeekly(this.AttendanceUI)
      .subscribe(
        data => { this.LoadAttendanceGridBindingSuccess(data) }, error => { this.LoadAttendanceBasedOnDeilyMonthlyWeeklyError(error) });
  }

  LoadAttendanceGridBindingSuccess(data: any) {

    if (data.length > 0) {
      this.AttendanceListUI = data;
      this.AttendanceCalendarListUI = data;
      if (this.AttendanceCalendarListUI != null && this.AttendanceCalendarListUI.length > 0) {
        this.events = this.GetEventData();
      }
      else {
        this.events = [];
      }
      this.SelectedAttendanceDetailRow = null;
      this.AttendanceUI.ilistbusUIAttendanceDetail = [];
      this.AttendanceValidationUI = new AttendanceValidationUI();
      this.SelectedInTime = "00:00";
      this.SelectedOutTime = "00:00";
      this.ShowSuccessAlert("Search executed Successfully.");
    }
    else {
      this.AttendanceListUI = [];
      this.AttendanceUI.ilistbusUIAttendanceDetail = [];
      this.events = [];
      this.ShowInfoAlert("No records found.");
    }
    this.IsHoliday();
    this.preLoader.show(false);
  }

  //*****************************Attendance info *************************************************************** */

  public CreateAttendanceForInitial() {
    this.preLoader.show(true);
    this.attendancetimesheetService.CreateNewAttendance().subscribe(
      data => { this.CreateAttendanceSuccess(data) }, error => { this.CreateNewAttendanceError(error) });
  }

  CreateAttendanceSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.AttendanceUI = data;
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        this.LoginedEmployeeId = this.AttendanceUI.lngemployee_Id;
        //this.AttendanceUI.EmplyeeId=this.AttendanceUI.lngemployee_Id;
        this.AttendanceUI.dtmDate = this.SelectedCalanderDate;
        this.MonthWeekWise();
        this.GetSupervisorddl();
        this.GetNextpayrollstartdateenddate();
        this.UISelectedTo = false;
        this.SelectAttendance = null;
        this.UIDate = null;
        this.WeeklyCheck = false;
        this.BoolWeekly = false;
        this.Mothlycheck = true;
        this.disable = false;
        this.TotalDiff = "00:00";
        this.max_working_hours_per_day = this.AttendanceUI.dtmmax_non_break_work_hours;
        this.CreateInitializeDDL();
        this.BeforeSearch();
        this.AttendanceValidationUI = new AttendanceValidationUI();
        //this.OpenMonthViewDay();
        this.EmployeNameBinding();
        this.OpenMonthViewDay();
        this.IsHoliday();
        if (this.AttendanceUI.ibusUIEmployee.ibusUIEmployeeJobInformation.strEmployee_type_value == "PARTT") {
          this.Stdhourbtn = false;
        }
      }
      else {
        let i = data.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
        this.preLoader.show(false);
      }
    }
    //this.preLoader.show(false);
  }

  public CancelAttendance() {
    this.preLoader.show(true);

    this.attendancetimesheetService.CancelAttendance().subscribe(
      data => { this.CancelAttendanceSuccess(data) }, error => { this.CreateNewAttendanceError(error) });
  }

  CancelAttendanceSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.AttendanceUI = data;
      if (this.EmplyeeId > 0) {
        this.AttendanceUI.lngemployee_Id = this.EmplyeeId;
      }
      this.AttendanceUI.dtmDate = this.SelectedCalanderDate;
      this.MonthWeekWise();
      this.UISelectedTo = false;

      this.SelectedAttendanceDetailRow = null;
      this.SelectedInTime = "00:00";
      this.SelectedOutTime = "00:00";
      this.UIDate = null;
      this.WeeklyCheck = false;
      this.BoolWeekly = false;
      this.Mothlycheck = true;
      this.disable = false;
      this.TotalDiff = "00:00";
      this.AttendanceValidationUI = new AttendanceValidationUI();
      this.formdata = null;
      this.SelectAttendance = null;
      this.IsHoliday();
    }
    this.preLoader.show(false);
  }

  public CreateNewAttendance() {
    this.preLoader.show(true);

    this.attendancetimesheetService.CreateNewAttendance().subscribe(
      data => { this.CreateNewAttendanceSuccess(data) }, error => { this.CreateNewAttendanceError(error) });
  }

  CreateNewAttendanceSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.AttendanceUI = data;
      if (this.EmplyeeId > 0) {
        this.AttendanceUI.lngemployee_Id = this.EmplyeeId;
      }
      this.AttendanceUI.dtmDate = this.SelectedCalanderDate;
      this.max_working_hours_per_day = this.AttendanceUI.dtmmax_non_break_work_hours;
      this.MonthWeekWise();
      this.GetSupervisorddl();
      this.GetNextpayrollstartdateenddate();
      this.UISelectedTo = false;
      this.SelectAttendance = null;
      this.SelectedAttendanceDetailRow = null;
      this.SelectedInTime = "00:00";
      this.SelectedOutTime = "00:00";
      this.UIDate = null;
      this.WeeklyCheck = false;
      this.BoolWeekly = false;
      this.Mothlycheck = true;
      this.disable = false;
      this.TotalDiff = "00:00";
      this.IsCheckValidOutTime = false;
      this.IsCheckValidInTime = false;
      this.BeforeSearch();
      this.AttendanceValidationUI = new AttendanceValidationUI();
      this.EmployeNameBinding();
      this.formdata = null;
      this.SelectAttendance = null;
      this.IsHoliday();
      this.GetSelectedDateDetails(this.AttendanceUI);
    }
    //this.preLoader.show(false);
  }

  CreateNewAttendanceError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }
  public EmployeNameBinding() {
    if (this.DDLEmployee != null && this.DDLEmployee.length > 0) {
      let SelectedObject = this.DDLEmployee.find(x => x.strId
        == this.AttendanceUI.lngemployee_Id);

      if (SelectedObject == null) {
        this.AttendanceUI.lngemployee_Id = 0;
      }
    } else {
      this.AttendanceUI.lngemployee_Id =0;
    }
  }

  public AttencenceClear() {
    this.SelectedOutTime = "00:00";
    this.SelectedInTime = "00:00";
    this.disable = false;
    this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value = "";
    this.TotalDiff = "00:00";
    this.IsCheckValidOutTime = false;
    this.IsCheckValidInTime = false;
    this.AttendanceValidationUI = new AttendanceValidationUI();
    this.AttendanceUI.
      ibusUIAttendanceDetail.ibusUIAttendanceDocument.strDocument_name = "";
    this.formdata = null;
    this.IsHoliday();
    this.ShowSuccessAlert("Values cleared Successfully.");
  }






  public RefreshAttendance() {

    this.AttendanceValidationUI = new AttendanceValidationUI();
    if (!(isNullOrUndefined(this.AttendanceUI)) && this.AttendanceUI.ibusUIAttendanceDetail.lngAttendance_detail_id > 0) {
      this.OpenAttendanceDetail(this.AttendanceUI.ibusUIAttendanceDetail.lngAttendance_detail_id);
    }
  }



  public ClearAttendance() {
    this.CreateNewAttendance();
    this.formdata = null;
    this.SelectAttendance = null;
    this.IsHoliday();
    this.ShowInfoAlert("Values cleared Successfully.");
  }

  public SaveStdTimeAttendance(STDDeleteContent) {

    if ((isNullOrUndefined(this.AttendanceUI.dtmDate))) {
      this.AttendanceUI.dtmDate = this.SelectedCalanderDate;
    }
    if (this.EmplyeeId > 0) {
      this.AttendanceUI.lngemployee_Id = this.EmplyeeId;
    }
    if (!(isNullOrUndefined(this.AttendanceUI))) {
      if (!(isNullOrUndefined(this.AttendanceUI.dtmDate))) {
        if (this.IsValidAttendanceForSTDBtn()) {
          if (this.IsCheckShowSTDDeleteAlert(STDDeleteContent)) {
            this.preLoader.show(true);
            this.AttendanceUI.publicholidaystdtime = true;
            this.attendancetimesheetService.SaveStdTimeAttendance(this.AttendanceUI).subscribe(
              data => { this.SaveAttendanceSuccess(data) }, error => { this.SaveAttendanceError(error) });
          }
        }
      } else {
        this.ShowErrorAlert(1, "Please select a date.", 'ER001')
      }
    }
  }
  public AllowToSaveSTDAttendance() {
    this.modalReferrence.close();
    if (!(isNullOrUndefined(this.AttendanceUI))) {
      this.preLoader.show(true);
      this.attendancetimesheetService.SaveStdTimeAttendance(this.AttendanceUI).subscribe(
        data => { this.SaveAttendanceSuccess(data) }, error => { this.SaveAttendanceError(error) });
    }
  }



  public SaveAttendance(MaxNonBreakWorkHrsAlert) {

    if ((isNullOrUndefined(this.AttendanceUI.dtmDate))) {
      if (this.EmplyeeId > 0) {
        this.AttendanceUI.lngemployee_Id = this.EmplyeeId;
      }
      this.AttendanceUI.dtmDate = this.SelectedCalanderDate;
    }

    if (!(isNullOrUndefined(this.AttendanceUI))) {
      if (!(isNullOrUndefined(this.AttendanceUI.dtmDate))) {
        if (this.IsValidAttendance()) {
          if (this.IsCheckValidOutTime == false && this.IsCheckValidInTime == false) {
            //  if (this.CheckfutureDate()) {
            if (this.IsCheckMaxNonBreakWorkHrs(MaxNonBreakWorkHrsAlert)) {
              if (this.istrue == false) {
                return;
              }
              this.preLoader.show(true);
              this.attendancetimesheetService.SaveAttendance(this.AttendanceUI).subscribe(
                data => { this.SaveAttendanceSuccess(data) }, error => { this.SaveAttendanceError(error) });
            }
            // } else {
            //   this.ShowErrorAlert(1, "Present and future date only allowed.Please Select Valid date.", 'ER001')
            // }
          }
        }
      } else {
        this.ShowErrorAlert(1, "Please select a date.", 'ER001')
      }
    }
  }

  public AllowToSaveAttendance() {
    this.modalReferrence.close();
    if (!(isNullOrUndefined(this.AttendanceUI))) {
      this.preLoader.show(true);
      this.attendancetimesheetService.SaveAttendance(this.AttendanceUI).subscribe(
        data => { this.SaveAttendanceSuccess(data) }, error => { this.SaveAttendanceError(error) });
    }
  }


  SaveAttendanceSuccess(data: any) {
    this.GetNextpayrollstartdateenddate();
    if (!isNullOrUndefined(data)) {
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        this.AttendanceUI = data;
        this.SelectTotalAttendance = data;
        this.TotalDiff = "00:00";
        this.UISelectedTo = false;
        this.IsSave = true;
        this.Filtercontent = false;
        this.LoadAttendaceAfterSaveAndDelete(data.ilistbusUIAttendance);
        this.BoolMonthLy = true;
        this.ShowSuccessAlert(this.AttendanceUI.iInfoMessage);
        this.disable = false;
        this.formdata = null;
        this.SelectAttendance = null;
        this.IsHoliday();
        //  this.CreateNewAttendance();
      }
      else {
        let i = data.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
      }
    }
    this.preLoader.show(false);
  }

  SaveAttendanceError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

  //
  public AllowToSaveSTDAttendanceExistingInandOutTime() {
    this.modalReferrence.close();
    if (!(isNullOrUndefined(this.AttendanceUI))) {
      this.preLoader.show(true);

      this.attendancetimesheetService.SaveSTDAttendanceBothStdTimeandExistingTime(this.AttendanceUI).subscribe(
        data => { this.SaveSTDAttendanceBothStdTimeandExistingTimeSucces(data) }, error => { this.SaveSTDAttendanceBothStdTimeandExistingTimeError(error) });
    }
  }

  SaveSTDAttendanceBothStdTimeandExistingTimeSucces(data: any) {
    if (!isNullOrUndefined(data)) {
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        this.AttendanceUI = data;
        this.SelectTotalAttendance = data;
        this.TotalDiff = "00:00";
        this.UISelectedTo = false;
        this.IsSave = true;
        this.Filtercontent = false;
        this.LoadAttendaceAfterSaveAndDelete(data.ilistbusUIAttendance);
        this.BoolMonthLy = true;
        this.ShowSuccessAlert(this.AttendanceUI.iInfoMessage);
        this.disable = false;
        this.formdata = null;
        this.SelectAttendance = null;
        this.IsHoliday();
        //  this.CreateNewAttendance();
      }
      else {
        let i = data.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
      }
    }
    this.preLoader.show(false);
  }

  SaveSTDAttendanceBothStdTimeandExistingTimeError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

  //

  IsValidAttendanceForSTDBtn() {
    let IsValidAttendance = false;
    this.AttendanceValidationUI = new AttendanceValidationUI();
    let errorCount = 0;

    if (this.AttendanceUI.IsPublicHoliday == true) {
      this.IsUploadIsVisible = true;
    }

    if (this.IsUploadIsVisible == true &&
      isNullOrUndefined(this.AttendanceUI.
        ibusUIAttendanceDetail.ibusUIAttendanceDocument.strDocument_name)) {
      errorCount++;
      this.ShowErrorAlert(1, "Selected day is Holiday. Please Upload an approval document.", 'ER001');
    }


    this.alert.ClearMsgPanels();
    if (errorCount > 0) {
      return false;
    }
    else {
      IsValidAttendance = true;
      return IsValidAttendance;
    }

  }


  IsCheckMaxNonBreakWorkHrs(MaxNonBreakWorkHrsAlert): boolean {

    if (!isNullOrUndefined(this.TotalDiff)) {
      var Indate = new Date();
      let TotalEnteredTime = this.TotalDiff;

      let EINdateTimes = new Date((Indate.getMonth() + 1) + "/" +
        Indate.getDate() + "/" + Indate.getFullYear() + " " + TotalEnteredTime);

      let max_working_hours_per_day = this.TimeFormat(<string>this.max_working_hours_per_day);

      let IsCheckMaxWorkHrsDate = new Date((Indate.getMonth() + 1) + "/" +
        Indate.getDate() + "/" + Indate.getFullYear() + " " + max_working_hours_per_day);

      if (EINdateTimes.getTime() > IsCheckMaxWorkHrsDate.getTime()) {
        this.DisplayContentInAlert =
          'Duration between the Entered In-Time and Out-Time is greater than configured non-break work hours (' + max_working_hours_per_day + ').  Do you want to continue?';
        this.modalReferrence = this.modalService.open(MaxNonBreakWorkHrsAlert, { backdrop: 'static' });
        return false;
      }
    }
    return true;
  }

  public SelectedAttendance(row: number, data: any) {
    this.SelectAttendance = row;
    this.UISelectedTotalAttendanceDetail = data;

  }

  IsValidAttendance() {
    let IsValidAttendance = false;
    this.AttendanceValidationUI = new AttendanceValidationUI();
    let errorCount = 0;


    if (isNullOrUndefined(this.SelectedInTime) || this.SelectedInTime == "") {
      this.AttendanceValidationUI.Intime = true;
      this.TotalDiff = "00:00";
      errorCount++;
    }
    if (this.SelectedInTime == "00:00" && this.SelectedOutTime == "00:00") {
      this.ShowErrorAlert(1, "Enter Valid In and Out Time.", 'ER001');
      return;
    }

    if (isNullOrUndefined(this.SelectedOutTime) || this.SelectedOutTime == ""
      // || this.SelectedOutTime == "00:00" || this.SelectedOutTime == "00:00:00"
    ) {
      this.AttendanceValidationUI.OutTime = true;
      this.TotalDiff = "00:00";
      errorCount++;
    }

    if (this.IsCheckValidInTime == true) {
      this.AttendanceValidationUI.IsCheckValidInTime = true;
    }

    if (this.IsCheckValidOutTime == true) {
      this.AttendanceValidationUI.IsCheckValidOutTime = true;
    }

    if (this.IsCheckValidInTime == true || this.IsCheckValidOutTime == true) {
      errorCount++;
    }
    // if (ISValidout == false || ISValidintime == false) {
    //   errorCount++;
    //   this.ShowErrorAlert(1, "Out-Time should be greater than In-Time.", 'ER001')
    // }
    if (this.IsCheckValidInTime == false && this.IsCheckValidOutTime == false) {

      if (this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value == 'OT' ||
        this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value == 'TOIL') {
        this.IsUploadIsVisible = true;
      }
      else {
        this.IsUploadIsVisible = false;
      }

      //   if (this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value == '' ||
      //   this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value == null) {
      //   this.IsUploadIsVisible = true;
      // }

      if (this.IsUploadIsVisible == true &&
        isNullOrUndefined(this.AttendanceUI.
          ibusUIAttendanceDetail.ibusUIAttendanceDocument.strDocument_name)) {
        errorCount++;
        this.ShowErrorAlert(1, "Please Upload an approval document.", 'ER001');
      }

    }



    this.alert.ClearMsgPanels();
    if (errorCount > 0) {
      return false;
    }
    else {
      IsValidAttendance = true;
      return IsValidAttendance;
    }

  }
  CheckValidInTime(IsClockEvent): boolean {
    if (!(isNullOrUndefined(this.AttendanceUI.dtmDate))) {
      this.preLoader.show(true);
      let IsValid = false;
      let errorCount = 0;
      this.IsCheckValidOutTime = false;
      this.IsCheckValidInTime = false;
      var addHrs;
      var addMins;
      if (!isNullOrUndefined(this.SelectedInTime) && this.SelectedInTime != "") {
        this.AttendanceUI.ibusUIAttendanceDetail.dtmdate_in_time = this.ConvertTimeToDateTime(this.SelectedInTime);
      }
      if (!isNullOrUndefined(this.SelectedOutTime) && this.SelectedOutTime != "") {
        this.AttendanceUI.ibusUIAttendanceDetail.dtmdate_out_time = this.ConvertTimeToDateTime(this.SelectedOutTime);
      }

      if (!isNullOrUndefined(this.SelectedInTime) && this.SelectedInTime != ""
        && !isNullOrUndefined(this.SelectedOutTime) && this.SelectedOutTime != ""
        && this.SelectedOutTime != "00:00" && this.SelectedOutTime != "00:00:00"
      ) {
        var inTime = this.SelectedInTime;
        var outTime = this.SelectedOutTime;

        var Indate = new Date();
        var INdateTimes = new Date((Indate.getMonth() + 1) + "/" + Indate.getDate() + "/" + Indate.getFullYear() + " " + inTime);
        var INdateTime = INdateTimes.getTime();

        var Outdate = new Date();
        var OutdateTimes = new Date((Outdate.getMonth() + 1) + "/" + Outdate.getDate() + "/" + Outdate.getFullYear() + " " + outTime);
        var OutdateTime = OutdateTimes.getTime();

        let NextDayIndate = new Date();
        NextDayIndate = new Date((Indate.getMonth() + 1) + "/" + Indate.getDate() + "/" + Indate.getFullYear() + " " + inTime);
        let CurrentDate = new Date(NextDayIndate).setHours(0, 0, 0, 0);

        let NextDayOutdate = new Date();
        var TotalDiff = Math.abs(OutdateTimes.getTime() - INdateTimes.getTime()) / 1000;
        let hrs = Math.floor(TotalDiff / 3600) % 24;
        let minits = Math.floor(TotalDiff / 60) % 60;
        let total = hrs + ":" + minits
        var NextDayOutdateTimes = new Date((NextDayOutdate.getMonth() + 1) + "/" + NextDayOutdate.getDate() + "/" + NextDayOutdate.getFullYear() + " " + total);
        NextDayOutdate.setHours(NextDayIndate.getHours() + NextDayOutdateTimes.getHours());
        let NextDay = new Date(NextDayOutdate).setHours(0, 0, 0, 0);

        if ((INdateTime != OutdateTime) && (CurrentDate == NextDay) &&
          (INdateTime < OutdateTime)) {
          if (IsClockEvent == true) {
            this.OpenAttendanceType();
          }

          // if ((INdateTime != OutdateTime)
          // // && (CurrentDate == NextDay)
          //   // && (INdateTime < OutdateTime)
          // ) {
          //     if(this.SelectedOutTime=="00:00")
          //     {
          //       this.OpenAttendanceType();
          //     }
          //     if((CurrentDate == NextDay)
          //      && (INdateTime < OutdateTime))
          //      {
          //   if (IsClockEvent == true) {
          //     this.OpenAttendanceType();
          //   }
          // }
          var delta = Math.abs(OutdateTimes.getTime() - INdateTimes.getTime()) / 1000;
          console.log(delta);

          addHrs = Math.floor(delta / 3600) % 24;

          addMins = Math.floor(delta / 60) % 60;

          if (addHrs == null)
            addHrs = 0;

          if (addMins == null)
            addMins = 0;

          if (addHrs < 10) {
            addHrs = String(addHrs).padStart(2, '0');
          }
          if (addMins < 10) {

            addMins = String(addMins).padStart(2, '0');
          }

          this.TotalDiff = addHrs + ":" + addMins;
        }
        else {
          this.IsCheckValidInTime = true;
          errorCount++;
          this.IsUploadIsVisible = false;
          this.disable = false;
          this.TotalDiff = "00" + ":" + "00"
        }
      }

      this.preLoader.show(false);
      if (errorCount > 0) {
        IsValid = false;
        return IsValid;
      }
      else {
        IsValid = true;
        return IsValid;
      }
    }

  }

  CheckValidOutTime(IsClockEvent): boolean {
    this.preLoader.show(true);

    if (!(isNullOrUndefined(this.AttendanceUI.dtmDate))) {
      this.preLoader.show(true);
      let IsValid = false;

      let errorCount = 0;
      this.IsCheckValidOutTime = false;
      this.IsCheckValidInTime = false;
      var addHrs;
      var addMins;

      if (isNullOrUndefined(this.AttendanceUI.ibusUIAttendanceDetail.dtmdate_in_time)) {
        this.AttendanceUI.ibusUIAttendanceDetail.dtmdate_in_time = this.ConvertTimeToDateTime(this.SelectedInTime);
      }

      if (!isNullOrUndefined(this.SelectedOutTime) && this.SelectedOutTime != "") {
        if (!isNullOrUndefined(this.AttendanceUI) &&
          !isNullOrUndefined(this.AttendanceUI.
            ibusUIAttendanceDetail)) {
          this.AttendanceUI.ibusUIAttendanceDetail.dtmdate_out_time =
            this.ConvertTimeToDateTime(this.SelectedOutTime);
        }
      }

      if (!isNullOrUndefined(this.SelectedInTime) && this.SelectedInTime != ""
        && !isNullOrUndefined(this.SelectedOutTime) && this.SelectedOutTime != ""
        //  && this.SelectedOutTime != "00:00" && this.SelectedOutTime != "00:00:00"
      ) {
        var time;
        var inTime = this.SelectedInTime;
        var outTime = this.SelectedOutTime;
        var outtime12: number;
        if (outTime == "00:00") {
          time = "00:00";
          outTime = "23:59";
        }
        var Indate = new Date();
        var INdateTimes = new Date((Indate.getMonth() + 1) + "/" + Indate.getDate() + "/" + Indate.getFullYear() + " " + inTime);
        var INdateTime = INdateTimes.getTime();

        var Outdate = new Date();
        var OutdateTimes = new Date((Outdate.getMonth() + 1) + "/" + Outdate.getDate() + "/" + Outdate.getFullYear() + " " + outTime);
        // if(this.SelectedInTime=="23:00")
        // {
        //   var OutdateTimes = new Date((Outdate.getMonth() + 1) + "/" + Outdate.getDate() + "/" + Outdate.getFullYear() + " " + outTime+"1:00");
        // }
        var OutdateTime = OutdateTimes.getTime();


        let NextDayIndate = new Date();
        NextDayIndate = new Date((Indate.getMonth() + 1) + "/" + Indate.getDate() + "/" + Indate.getFullYear() + " " + inTime);
        let CurrentDate = new Date(NextDayIndate).setHours(0, 0, 0, 0);

        let NextDayOutdate = new Date();
        var TotalDiff = Math.abs(OutdateTimes.getTime() - INdateTimes.getTime()) / 1000;
        let hrs = Math.floor(TotalDiff / 3600) % 24;
        let minits = Math.floor(TotalDiff / 60) % 60;
        let total = hrs + ":" + minits
        var NextDayOutdateTimes = new Date((NextDayOutdate.getMonth() + 1) + "/" + NextDayOutdate.getDate() + "/" + NextDayOutdate.getFullYear() + " " + total);
        NextDayOutdate.setHours(NextDayIndate.getHours() + NextDayOutdateTimes.getHours());
        let NextDay = new Date(NextDayOutdate).setHours(0, 0, 0, 0);

        if ((INdateTime != OutdateTime) && (CurrentDate == NextDay) && (INdateTime < OutdateTime)) {

          if (!isNullOrUndefined(this.AttendanceUI) &&
            !isNullOrUndefined(this.AttendanceUI.ibusUIAttendanceDetail) &&
            IsClockEvent == true) {
            this.OpenAttendanceType();
          }
          // if(this.SelectedInTime=="23:00")
          // {
          //   var OutdateTimes = new Date((Outdate.getMonth() + 1) + "/" + Outdate.getDate() + "/" + Outdate.getFullYear() + " " + outTime);
          // }
          var delta = Math.abs(OutdateTimes.getTime() - INdateTimes.getTime()) / 1000;
          console.log(delta);

          addHrs = Math.floor(delta / 3600) % 24;

          addMins = Math.floor(delta / 60) % 60;

          if (addHrs == null)
            addHrs = 0;

          if (addMins == null)
            addMins = 0;

          if (addHrs < 10) {
            addHrs = String(addHrs).padStart(2, '0');
          }

          if (addMins < 10) {
            addMins = String(addMins).padStart(2, '0');
          }

          if (outTime == "23:59") {
            if (!isNullOrUndefined(time) && time == "00:00") {
              outtime12 = Number(addMins) + 1;
              if (outtime12 == 60) {
                outtime12 = Number(addHrs) + 1;
                addHrs = String(outtime12).padStart(2, '0');
                addMins = String("00").padStart(2, '0');
              }
              else {
                addMins = String(outtime12).padStart(2, '0');
              }
            }
          }
          this.TotalDiff = addHrs + ":" + addMins
        }
        else {
          this.IsCheckValidOutTime = true;
          errorCount++;
          this.IsUploadIsVisible = false;
          this.disable = false;
          this.TotalDiff = "00" + ":" + "00"
        }
      }
      this.preLoader.show(false);
      if (errorCount > 0) {
        IsValid = false;
        return IsValid;
      }
      else {
        IsValid = true;
        return IsValid;
      }
    }
  }

  DDlBind() {
    if (!isNullOrUndefined(this.SelectedInTime) && this.SelectedInTime != ""
      && !isNullOrUndefined(this.SelectedOutTime) && this.SelectedOutTime != "") {

      if (!isNullOrUndefined(this.AttendanceUI) &&
        !isNullOrUndefined(this.AttendanceUI.ibusUIAttendanceDetail)) {
        this.OpenAttendanceType();
      }
    }
  }

  selectedTimeTotalHrBinding() {
    let addHrs;
    let addMins;
    if (!isNullOrUndefined(this.SelectedInTime) && this.SelectedInTime != ""
      && !isNullOrUndefined(this.SelectedOutTime) && this.SelectedOutTime != "") {
      var inTime = this.SelectedInTime;
      var outTime = this.SelectedOutTime;

      var Indate = new Date();
      var INdateTimes = new Date((Indate.getMonth() + 1) + "/" + Indate.getDate() + "/" + Indate.getFullYear() + " " + inTime);
      var INdateTime = INdateTimes.getTime();

      var Outdate = new Date();
      var OutdateTimes = new Date((Outdate.getMonth() + 1) + "/" + Outdate.getDate() + "/" + Outdate.getFullYear() + " " + outTime);
      var OutdateTime = OutdateTimes.getTime();

      if (INdateTime != OutdateTime) {
        if (INdateTime > OutdateTime) {

          var GRT12CurrentDate = new Date();
          var GRT12CurrentTime = new Date((GRT12CurrentDate.getMonth() + 1)
            + "/" + GRT12CurrentDate.getDate() + "/" + GRT12CurrentDate.getFullYear() + " " +
            "12:00");

          var G12CurrentTime = GRT12CurrentTime.getTime();

          if (INdateTime > G12CurrentTime && OutdateTime < G12CurrentTime) {

            var Add12CurrentoutTimes = new Date();
            Add12CurrentoutTimes = new Date((Add12CurrentoutTimes.getMonth() + 1)
              + "/" + (Add12CurrentoutTimes.getDate() + 1) + "/" + Add12CurrentoutTimes.getFullYear() + " " +
              outTime);

            var delta = Math.abs(Add12CurrentoutTimes.getTime() - INdateTimes.getTime()) / 1000;
            console.log(delta);

            addHrs = Math.floor(delta / 3600) % 24;

            addMins = Math.floor(delta / 60) % 60;

          }
          else {
            var delta = Math.abs(OutdateTimes.getTime() - INdateTimes.getTime()) / 1000;
            console.log(delta);

            addHrs = Math.floor(delta / 3600) % 24;

            addMins = Math.floor(delta / 60) % 60;
          }
        } else {
          var delta = Math.abs(OutdateTimes.getTime() - INdateTimes.getTime()) / 1000;
          console.log(delta);

          addHrs = Math.floor(delta / 3600) % 24;

          addMins = Math.floor(delta / 60) % 60;
        }
      }
      if (addHrs == null)
        addHrs = 0;

      if (addMins == null)
        addMins = 0;

      if (addHrs < 10) {
        addHrs = String(addHrs).padStart(2, '0');
      }
      if (addMins < 10) {
        addMins = String(addMins).padStart(2, '0');
      }

      this.TotalDiff = addHrs + ":" + addMins

    }
  }


  CheckfutureDate(): boolean {
    let errorCount = 0;
    let CurrentDates: any = new Date();
    let mm: any = CurrentDates.getMonth() + 1;
    let CurrentMonth = mm;
    let month = new Date(this.AttendanceUI.dtmDate);
    let months = month.getMonth() + 1;
    if (CurrentMonth > months) {
      errorCount++;
    }
    this.alert.ClearMsgPanels();
    if (errorCount > 0) {
      return false;
    }
    else {
      return true;
    }

  }

  public EditbtnOpenAttendanceTimeDetail() {
    if (!isNullOrUndefined(this.UISelectedTotalAttendanceDetail) &&
      this.UISelectedTotalAttendanceDetail.lngAttendance_detail_id > 0) {
      this.OpenAttendanceDetail(this.UISelectedTotalAttendanceDetail.lngAttendance_detail_id);
    }
    else {
      this.ShowWarinngAlert("Please select any one Time Detail.");
    }
  }

  ShowWarinngAlert(Msg: any) {
    this.alert.ShowWarningAlert(true);
    this.alert.SetWarningMsg(Msg);
  }

  public OpenAttendanceDetail(Id: any) {
    if (!isNullOrUndefined(Id) && Id > 0) {
      let AttendanceUIID = Id;
      this.preLoader.show(true);
      this.attendancetimesheetService.OpenAttendanceDetail(AttendanceUIID).subscribe(
        data => { this.OpenAttendanceDetailUISuccess(data) },
        error => { this.OpenAttendanceDetailUIError(error) }
      );
    }
  }

  private OpenAttendanceDetailUISuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        this.AttendanceUI.ibusUIAttendanceDetail = data;
        let today: any = new Date(this.AttendanceUI.dtmDate);
        this.SelectedCalanderDate = today;
        if (isNullOrUndefined(this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value)) {
          this.disable = false;
          this.IsHoliday();
        } else {
          this.disable = true;
          this.IsVisableUploadBtn();
        }
        this.TotalDiff = "00:00";
        this.ConvertDateToTime();
        this.selectedTimeTotalHrBinding();
        this.UISelectedTo = true;
        this.AttendanceValidationUI = new AttendanceValidationUI();
        this.ShowInfoAlert(data.iInfoMessage);
      }
      else {
        let i = data.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
      }
    }
    this.preLoader.show(false);
  }

  private OpenAttendanceDetailUIError(error: any) {
    this.dataService.GetCommonErrorMessage(error);
  }


  public TotalAttendanceDetail(row: any, Attendance: any) {
    this.SelectedAttendanceDetailRow = row;
    this.SelectTotalAttendance = Attendance;

  }



  public OpenAttendance(Id: any) {
    if (!isNullOrUndefined(Id) && Id > 0) {
      let AttendanceUIID = Id;
      this.preLoader.show(true);
      this.attendancetimesheetService.OpenAttendance(AttendanceUIID).subscribe(
        data => { this.OpenAttendanceUISuccess(data) },
        error => { this.OpenAttendanceUIError(error) }
      );
    }
  }

  private OpenAttendanceUISuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        this.AttendanceUI = data;
        this.AttendanceUI.istrMonth = this.monthValue;
        this.AttendanceUI.istrWeek = this.weekvalue;
        this.AttendanceUI.istrYear = this.yearvalue;
        let today: any = new Date(this.AttendanceUI.dtmDate);
        this.SelectedCalanderDate = today;
        this.SelectedInTime = "00:00";
        this.SelectedOutTime = "00:00";
        this.AttendanceValidationUI = new AttendanceValidationUI();

        this.formdata = null;
        this.IsHoliday();
        this.disable = false;
        this.SelectAttendance = null;

        this.BeforeSearch();
        this.GetNextpayrollstartdateenddate();
        if (!this.IsSave) {
          this.ShowInfoAlert(data.iInfoMessage);
        }
        else {
          this.IsSave = false;
        }
      }
      else {
        let i = data.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
      }
    }
    this.preLoader.show(false);
  }

  private OpenAttendanceUIError(error: any) {
    this.dataService.GetCommonErrorMessage(error);
  }

  public OpenAttendanceType() {
    if (!isNullOrUndefined(this.AttendanceUI)) {
      this.preLoader.show(true);
      this.istrue = false;
      this.attendancetimesheetService.OpenAttendanceType(this.AttendanceUI).subscribe(
        data => { this.OpenAttendanceTypesUISuccess(data) },
        error => { this.OpenAttendanceTypesUIerror(error) }
      );
    }
  }
  OpenAttendanceTypesUISuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.AttendanceTimeTypeUI = data
      if (this.AttendanceTimeTypeUI != null &&
        this.AttendanceTimeTypeUI.ilngm_attendance_time_type_id > 0) {
        if (this.AttendanceTimeTypeUI.iblois_payable == "Y" && this.AttendanceTimeTypeUI.is_toil_allowed == "N") {
          this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value = "OT";

          this.disable = true;
          this.is_edit = true;
        }

        else if (this.AttendanceTimeTypeUI.iblois_payable == "N" && this.AttendanceTimeTypeUI.is_toil_allowed == "Y") {
          this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value = "TOIL";
          this.disable = true;
          this.is_edit = true;
        }

        else if (this.AttendanceTimeTypeUI.iblois_payable == "N" && this.AttendanceTimeTypeUI.is_toil_allowed == "N") {
          this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value = "TOIL";
          this.disable = true;
          this.is_edit = false;

        }

        else if (this.AttendanceTimeTypeUI.iblois_payable == "Y" && this.AttendanceTimeTypeUI.is_toil_allowed == "Y") {
          this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value = "TOIL";
          this.disable = true;
          this.is_edit = false;

        }

        else if (this.AttendanceTimeTypeUI.iblois_payable == "N" && this.AttendanceTimeTypeUI.is_toil_allowed == "Y") {
          this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value = "TOIL";
          this.disable = true;
          this.is_edit = true;

        }


        else if (this.AttendanceTimeTypeUI.iblois_payable == "Y" && this.AttendanceTimeTypeUI.is_toil_allowed == null) {
          this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value = "OT";
          this.disable = true;
          this.is_edit = true;
        }


        else if (this.AttendanceTimeTypeUI.iblois_payable == "N" && this.AttendanceTimeTypeUI.is_toil_allowed == null) {
          this.disable = false;
          this.is_edit = true;
        }


        else if (this.AttendanceTimeTypeUI.iblois_payable == null && this.AttendanceTimeTypeUI.is_toil_allowed == null) {
          this.disable = false;
          this.is_edit = true;
        }
        this.IsUploadIsVisible = true;
      }
      else {
        this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value = null;
        this.disable = false;
        this.is_edit = false;
        this.IsUploadIsVisible = false;
      }
      this.istrue = true;
    }
    this.preLoader.show(false);
  }

  OpenAttendanceTypesUIerror(error: any) {
    this.dataService.GetCommonErrorMessage(error);
  }

  ConfirmDeleteAttendanceDetail(data: any, AttendanceDetailUIDeleteContent: any) {
    if (!isNullOrUndefined(data)) {
      this.UISelectedTotalAttendanceDetail = data;
      this.modalReferrence = this.modalService.open(AttendanceDetailUIDeleteContent, { backdrop: 'static' });
    }
  }

  DeleteAttendanceDetail() {
    if (!isNullOrUndefined(this.UISelectedTotalAttendanceDetail) &&
      this.UISelectedTotalAttendanceDetail.lngAttendance_detail_id > 0) {
      if (this.AttendanceUI.ilistbusUIAttendanceDetail != null &&
        this.AttendanceUI.ilistbusUIAttendanceDetail.length > 0) {
        let ListCount = this.AttendanceUI.ilistbusUIAttendanceDetail.length;
        if (ListCount == 1) {
          this.SelectedAttendanceDetailRow = null;
        }
      }
      this.preLoader.show(true);
      this.attendancetimesheetService.DeleteAttendanceDetail(this.UISelectedTotalAttendanceDetail.lngAttendance_detail_id)
        .subscribe(
          data => { this.DeleteAttendanceDetailUISuccess(data) }, error => { this.DeleteAttendanceDetailUIError(error) });
    }
  }

  DeleteAttendanceDetailUISuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.modalReferrence.close();
      if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
        this.AttendanceUI = data;
        this.SelectTotalAttendance = data;
        this.UISelectedTo = false;
        this.IsSave = true;
        this.SelectedInTime = "00:00";
        this.SelectedOutTime = "00:00";
        this.TotalDiff = "00:00";
        this.UISelectedTotalAttendanceDetail = null;
        this.ShowInfoAlert(data.iInfoMessage);
        this.AttendanceValidationUI = new AttendanceValidationUI();
        this.Filtercontent = false;
        this.disable = false;
        this.formdata = null;
        this.SelectAttendance = null;
        this.IsHoliday();
        this.LoadAttendaceAfterSaveAndDelete(data.ilistbusUIAttendance);
        let ListCount = this.AttendanceUI.ilistbusUIAttendanceDetail.length;
        if (ListCount == 0) {
          this.SelectedCalanderDate = null;
        }
      }
      else {
        let i = data.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
        this.preLoader.show(false);
      }
    }
    this.preLoader.show(false);
  }

  DeleteAttendanceDetailUIError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }




  ChangeDaily() {
    this.TotalDiff = "00:00";
    this.BoolDaily = true;
    this.BoolWeekly = false;
    this.BoolMonthLy = false;
    if (!isNullOrUndefined(this.AttendanceUI)) {
      this.AttendanceUI.BoolDaily = true;
      this.AttendanceUI.BoolWeekly = false;
      this.AttendanceUI.BoolMonthLy = false;
      this.LoadAttendanceBasedOnDeilyMonthlyWeekly(this.AttendanceUI);
    }
  }


  IsValidmonthlywisedata() {
    this.AttendancemontylwiseValidationUI = new AttendancemontylwiseValidationUI();
    let errors = [];
    let errorCount = 0;
    let errMsg = ""
    let monthy = this.AttendanceUI.istrMonth;
    let year = this.AttendanceUI.istrYear;

    if (monthy == "" || isNullOrUndefined(monthy)) {
      this.AttendancemontylwiseValidationUI.monthly = true;
      errorCount++;
    }

    if (year == "" || isNullOrUndefined(year)) {
      this.AttendancemontylwiseValidationUI.year = true;
      errorCount++;
    }

    if (errorCount > 0) {
      return false;
    }
    else {
      return true;
    }
  }


  //********************************** time validation  ******************************** */

  ConvertDateToTime() {
    if (!isNullOrUndefined(this.AttendanceUI) && !isNullOrUndefined(this.AttendanceUI.ibusUIAttendanceDetail)
      && this.AttendanceUI.ibusUIAttendanceDetail.dtmdate_in_time != null) {
      let time = this.TimeFormat(<string>this.AttendanceUI.ibusUIAttendanceDetail.dtmdate_in_time);
      this.SelectedInTime = time;
    } else {
      this.SelectedInTime = "00:00";
    }
    if (!isNullOrUndefined(this.AttendanceUI) && !isNullOrUndefined(this.AttendanceUI.ibusUIAttendanceDetail) &&
      this.AttendanceUI.ibusUIAttendanceDetail.dtmdate_out_time != null) {
      let time = this.TimeFormat(<string>this.AttendanceUI.ibusUIAttendanceDetail.dtmdate_out_time);
      this.SelectedOutTime = time;
    } else {
      this.SelectedOutTime = "00:00";
    }
  }


  GetInTime() {
    const amazingTimePicker = this.atp.open({
      theme: 'material-blue',
    });
    amazingTimePicker.afterClose().subscribe(time => {
      this.SelectedInTime = time;
      let IsClockEvent = true;
      this.IsCheckValidOutTime = true;
      this.AttendanceValidationUI = new AttendanceValidationUI();

      this.CheckValidInTime(IsClockEvent);
    });
  }


  GetDateInTmeFromTextBox(SelectedInTime) {
    if (!isNullOrUndefined(this.AttendanceUI) &&
      !isNullOrUndefined(this.AttendanceUI.ibusUIAttendanceDetail)) {
      this.SelectedInTime = SelectedInTime;
      let IsClockEvent = true;
      this.AttendanceValidationUI = new AttendanceValidationUI();
      this.IsCheckValidOutTime = true;
      this.CheckValidInTime(IsClockEvent);
    }
  }

  GetDateOutTmeFromTextBox(SelectedOutTime) {

    this.preLoader.show(true);
    if (!isNullOrUndefined(this.AttendanceUI) &&
      !isNullOrUndefined(this.AttendanceUI.ibusUIAttendanceDetail)) {
      this.SelectedOutTime = SelectedOutTime;
      let IsClockEvent = true;
      this.IsCheckValidInTime = false;
      this.AttendanceValidationUI = new AttendanceValidationUI();
      this.CheckValidOutTime(IsClockEvent);
      this.DDlEXDutyBind();
    }
  }

  GetOutTime() {
    const amazingTimePicker = this.atp.open({
      theme: 'material-blue',
    });
    amazingTimePicker.afterClose().subscribe(time => {
      this.preLoader.show(true);
      this.SelectedOutTime = time;
      let IsClockEvent = true;
      this.IsCheckValidInTime = false;
      this.AttendanceValidationUI = new AttendanceValidationUI();
      this.CheckValidOutTime(IsClockEvent);
    });
  }



  ConvertTimeToDateTime(dtmTime: any): any {
    if (!isNullOrUndefined(dtmTime) && dtmTime != "") {
      {
        console.log(dtmTime);
        let StartTime = dtmTime;
        if (StartTime != null) {
          let times = StartTime;
          let today: any;
          if (!isNullOrUndefined(this.AttendanceUI.dtmDate)) {
            today = new Date(this.AttendanceUI.dtmDate);
          } else {
            today = new Date();
          }
          let date = today.getDate();
          let month = today.getMonth();
          let year = today.getFullYear();

          if (date < 10) {
            var dd = "0" + format(date);
          }
          else {
            var dd = format(date);
          }

          if (month <= 8) {
            let Month = month + 1;
            var mm = "0" + format(Month);
          }
          else {
            let Month = month + 1;
            var mm = format(Month);
          }
          let yyyy = format(year);
          today = <string>yyyy + "-" + <string>mm + "-" + <string>dd;
          let Convertedtime: any = <string>today + "T" + <string>times;
          return Convertedtime;
        }
      }
    }
    else {
      let Convertedtime = null;
      return Convertedtime;
    }
  }

  //Time Extraction Function
  private TimeFormat(data: string): any {
    if (!isNullOrUndefined(data)) {
      let TimeStruct = this.datePipe.transform(data, 'HH:mm')
      return TimeStruct;
    }
  }
  SetDateForUIBinding() {
    if (!isNullOrUndefined(this.AttendanceUI) && !isNullOrUndefined(this.AttendanceUI.dtmDate)) {
      let Date = this.dateFormat.FormatDate(this.AttendanceUI.dtmDate as string);
      this.UIDate = this.ngbDateParserFormatter.parse(Date);
    }
    else {
      this.UIDate = null;
    }
  }


  public ClosePopup() {
    this.modalReferrence.close();
  }
  //*****************************Attendance Basic info *************************************************************** */



  //***************************** Error info Warinng *************************************************************** */


  GetCommonErrorMessage(error: any | string) {
    console.log(error);
    let i;
    this.ShowErrorAlert(i, error, 1087);
    // let Errmsg = { "Status": "true", "Message": ErrorMsg.ErrorMessage, "Type": "danger" }
    // this.alert.alert(Errmsg);
    this.preLoader.show(false);
  }

  ShowErrorPanelAlert(Msg: any) {
    this.alert.ShowErrorPanelAlert(true);
    this.alert.SetErrorPanelMsg(Msg);
  }

  ShowSuccessAlert(Msg: any) {
    this.alert.ShowSuccessAlert(true);
    this.alert.SetSuccessMsg(Msg);
  }

  ShowInfoAlert(Msg: any) {
    this.alert.ShowInfoAlert(true);
    this.alert.SetInfoMsg(Msg);
  }



  ShowWaringAlert(Msg: any) {
    this.alert.ShowWarningAlert(true);
    this.alert.SetWarningMsg(Msg);
  }


  ShowErrorAlert(ErrorCount: any, ErrorMsg: any, ErrorCode: any) {
    let ErrMsg = '';
    if (ErrorCount > 2) {
      ErrMsg = 'Error has been occured.'
    }
    else {
      ErrMsg = ErrorMsg;
    }
    this.alert.SetErrorCode(ErrorCode);
    this.alert.SetErrorDescription(ErrMsg);
    this.alert.ShowErrorAlert(true);
  }


  //***************************** Error info Warinng *************************************************************** */



  //*****************calender */

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      a11yLabel: 'Delete',
      cssClass: '',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  //refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [

    {
      start: addHours(startOfDay(new Date()), 2),

      title: '',
      color: colors.yellow,

      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      draggable: false
    }
  ];

  activeDayIsOpen: boolean = true;



  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        cssClass: '',
        color: colors.Green,
        draggable: false,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }





  OpenMonthViewDay() {
    let today: any = new Date();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    this.BoolWeekly = false;
    this.BoolDaily = false;
    this.BoolMonthLy = false;
    if (!isNullOrUndefined(this.AttendanceUI)) {
      this.AttendanceUI.BoolDaily = false;
      this.AttendanceUI.BoolWeekly = false;
      this.AttendanceUI.BoolMonthLy = true;
      this.AttendanceUI.istrMonth = month;
      this.AttendanceUI.istrWeek = "";
      this.AttendanceUI.istrYear = year;
      this.LoadAttendanceBasedOnDeilyMonthlyWeekly(this.AttendanceUI);
    }
    this.activeDayIsOpen = false;
  }




  openclock() {
    const amazingTimePicker = this.atp.open({
      theme: 'material-blue',
    });
    amazingTimePicker.afterClose().subscribe(time => {
      this.selectedTime = time;
    });
  }




  public GetEventData() {
    let TempHolidayList=this.HolidayList;
    let TempDateList = this.AttendanceCalendarListUI;
    let TempLeaveList = this.LeaveListUI;
    this.events = [];


    if (TempHolidayList) {
      for (let i = 0; i < TempHolidayList.length; i++) {
        //  let std = TempDateList[i].strstd_total_hours;
        //  let flx = TempDateList[i].strflex_total_hours;
        //  let exc = TempDateList[i].strtotal_Over_Time_hours;
        this.events = this.GetEvent_Holiday(TempHolidayList[i]);
      }
    }

    if (TempDateList) {
      for (let i = 0; i < TempDateList.length; i++) {
        let std = TempDateList[i].strstd_total_hours;
        let flx = TempDateList[i].strflex_total_hours;
        let exc = TempDateList[i].strtotal_Over_Time_hours;
        this.events = this.GetEvent_std(TempDateList[i], std), this.GetEvent_Flex(TempDateList[i], flx),
          this.GetEvent_Exc(TempDateList[i], exc);
      }
    }



    if (TempLeaveList) {
      for (let i = 0; i < TempLeaveList.length; i++) {
        //  let std = TempDateList[i].strstd_total_hours;
        //  let flx = TempDateList[i].strflex_total_hours;
        //  let exc = TempDateList[i].strtotal_Over_Time_hours;
        this.events = this.GetEvent_leave(TempLeaveList[i]);
      }
    }

    return this.events;
  }

  public GetEvent_Holiday(data: any) {
    if (data != null) {
      [
        this.events =
        [
          ...this.events,
          {
            title:
            // 'Holiday',
            data.strholiday_name,
            start: startOfDay(new Date(data.dtmholiday_date)),
            end: endOfDay(new Date(data.dtmholiday_date)),
            cssClass: 'holiday',
            color: colors.Orange,
            draggable: true,

            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          }
        ]
      ];
    }
    return this.events;
  }


  public GetEvent_leave(data: any) {
    console.log(data);
    [
      this.events =
      [
        ...this.events,
        {
          id: "leave",
          title: 'LEAVE (' + data.leavepolicycode + ')',
          start: startOfDay(new Date(data.dtleave_request_from_date
          )),
          end: endOfDay(new Date(data.dtleave_request_to_date
          )),
          allDay: true,
          color: colors.red,
          //color:'RED',
          cssClass: '',
          resizable: {
            beforeStart: true,
            afterEnd: true
          },
          draggable: false,
          meta: {
            type: 0
          },
        }
      ]
    ];

    return this.events;
  }

  public GetEvent_std(data: any, std: any) {
    if (std != null && std != "00:00") {
      [
        this.events =
        [
          ...this.events,
          {
            title: 'SH - ' + std,
            start: startOfDay(new Date(data.dtmDate)),
            end: endOfDay(new Date(data.dtmDate)),
            allDay: true,
            color: colors.Green,
            cssClass: '',
            resizable: {
              beforeStart: true,
              afterEnd: true
            },
            draggable: false,
            meta: {
              type: 0
            },
          }
        ]
      ];
    }
    return this.events;
  }
  public GetEvent_Flex(data: any, Flex: any) {
    if (Flex != null && Flex != "00:00") {
      [
        this.events =
        [
          ...this.events,
          {
            title: 'FH - ' + Flex,
            start: startOfDay(new Date(data.dtmDate)),
            end: endOfDay(new Date(data.dtmDate)),
            cssClass: '',
            color: colors.blue,
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          }
        ]
      ];
    }
    return this.events;
  }
  public GetEvent_Exc(data: any, Exc: any) {
    if (Exc != null && Exc != "00:00") {
      [
        this.events =
        [
          ...this.events,
          {
            title: 'EH - ' + Exc,
            start: startOfDay(new Date(data.dtmDate)),
            end: endOfDay(new Date(data.dtmDate)),
            cssClass: '',
            color: colors.yellow,
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          }
        ]
      ];
    }
    return this.events;
  }


  LoadAttendanceCalendarMonthlyError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }
  closeOpenMonthViewDay() {

    this.AttendanceUI.dtmDate = this.viewDate;
    let today: any = new Date(this.AttendanceUI.dtmDate);

    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    this.BoolWeekly = false;
    this.BoolDaily = false;
    this.BoolMonthLy = false;
    if (!isNullOrUndefined(this.AttendanceUI)) {
      this.AttendanceUI.BoolDaily = false;
      this.AttendanceUI.BoolWeekly = false;
      this.AttendanceUI.BoolMonthLy = true;
      this.AttendanceUI.istrMonth = month;
      this.AttendanceUI.istrWeek = "";
      this.AttendanceUI.istrYear = year;
      if (this.IsValidmonthlywisedata()) {
        this.BeforeSearch();
        this.LoadAttendanceNextMonthClick(this.AttendanceUI);
      }

      //  this.SelectedCalanderDate = this.viewDate;
    }

    this.activeDayIsOpen = false;
  }



  public LoadAttendanceNextMonthClick(data: any) {
    this.preLoader.show(true);
    if (data.BoolMonthLy == true) {
      this.Mothlycheck = true;
      this.WeeklyCheck = false;
    }
    else if (data.BoolWeekly == true) {

      this.WeeklyCheck = true;
      this.Mothlycheck = false;
    }
    else {
      this.WeeklyCheck = false;
      this.Mothlycheck = false;
    }
    this.AttendanceUI.istrYear = (this.AttendanceUI.istrYear).toString();
    this.AttendanceUI.istrMonth = (this.AttendanceUI.istrMonth).toString();
    this.AttendanceUI.lngemployee_Id = parseFloat(this.AttendanceUI.lngemployee_Id);
    this.attendancetimesheetService.LoadAttendanceInitially(this.AttendanceUI)
      .subscribe(
        data => { this.LoadAttendanceNextMonthClickSuccess(data) }, error => { this.LoadAttendanceBasedOnDeilyMonthlyWeeklyError(error) });
  }

  LoadAttendanceNextMonthClickSuccess(data: any) {
    this.GetNextpayrollstartdateenddate();
    let today: any = new Date();
    if (data.length > 0) {
      this.AttendanceListUI = data;
      this.AttendanceCalendarListUI = data;

      let isvalidcurrentdate = true;

      let SelectedObject = this.AttendanceListUI.find(x => x.IsboolCurrentDate
        == isvalidcurrentdate);
      if (SelectedObject != null) {
        this.AttendanceUI = SelectedObject;
        var index = this.AttendanceListUI.indexOf(SelectedObject);
        this.SelectedAttendanceDetailRow = index;
        this.AttendanceListUI[index] = SelectedObject;
        // if (!isNullOrUndefined(this.AttendanceUI)) {
        //   this.OpenAttendance(this.AttendanceUI.lngAttendance_id);
        // }
      } else {
        this.AttendanceUI.dtmDate = null;
        let month = today.getMonth() + 1;
        let month1 = this.viewDate.getMonth() + 1;
        if (month1 == month) {
          this.SelectedCalanderDate = today;
        } else {
          this.SelectedCalanderDate = null;
        }
        this.SelectedAttendanceDetailRow = null;
        // this.AttendanceUI =  this.AttendanceListUI[0] ;
        // this.SelectedAttendanceDetailRow = 0;
        // if (!isNullOrUndefined(this.AttendanceUI)) {
        //   this.OpenAttendance(this.AttendanceUI.lngAttendance_id);
        // }
      }

      this.AttendanceValidationUI = new AttendanceValidationUI();
      this.SelectedInTime = "00:00";
      this.SelectedOutTime = "00:00";
      console.log(this.AttendanceListUI);
    } else {
      this.AttendanceUI.dtmDate = null;

      let month = today.getMonth() + 1;
      let month1 = this.viewDate.getMonth() + 1;
      if (month1 == month) {
        this.SelectedCalanderDate = today;
      } else {
        this.SelectedCalanderDate = null;
      }

      this.SelectedAttendanceDetailRow = null;
      this.AttendanceListUI = [];
      this.AttendanceCalendarListUI = [];
      this.AttendanceUI.ilistbusUIAttendanceDetail = [];
      this.events = [];
      this.SelectedAttendanceDetailRow = null;
    }
    this.BeforeSearch();
    this.BoolMonthLy = true;
    this.LoadLeaveShowonTimesheet(this.AttendanceUI);
    //this.preLoader.show(false);
   // this.GetSelectedDateDetails(this.AttendanceUI);
  }


  selectedEmployeeDetail(Id: number) {
    if (Id > 0) {
      this.preLoader.show(true);
      this.CreateNewAttendance();
      this.EmplyeeId = Id;
      this.AttendanceUI.lngemployee_Id = Id;
  setTimeout(() => {
    this.closeOpenMonthViewDay();
     }, 5000);


      //  let today: any = new Date();
      // this.SelectedCalanderDate = today;
      // this.preLoader.show(false);
    }
  }
  Openfiltercontent() {
    this.Filtercontent = !this.Filtercontent;
    this.MonthWeekWise();
    this.GetAttendanceInitialData();
  }


  IsValidWeekWisedata() {
    this.AttendancemontylwiseValidationUI = new AttendancemontylwiseValidationUI();
    let errorCount = 0;
    if (this.AttendanceUI.idtmPeriodStartTime == "Invalid date") {
      this.AttendanceUI.idtmPeriodStartTime = null;
    }
    if (this.AttendanceUI.idtmPeriodEndTime == "Invalid date") {
      this.AttendanceUI.idtmPeriodEndTime = null;
    }
    let UIFilterBeginDate = this.AttendanceUI.idtmPeriodStartTime;
    let UIFilterEndDate = this.AttendanceUI.idtmPeriodEndTime;


    if (!isNullOrUndefined(UIFilterEndDate) && UIFilterEndDate != "") {

      let beginDatefrom = UIFilterBeginDate;
      let BDF = new Date(beginDatefrom).setHours(0, 0, 0, 0);
      let beginDateto = UIFilterEndDate;
      let BDT = new Date(beginDateto).setHours(0, 0, 0, 0);

      if (BDT < BDF) {
        this.ShowErrorAlert(1, "From Date must be less than To Date. ", 876);

        errorCount++;
      }
    }


    if (errorCount > 0) {
      return false;
    }
    else {

      return true;
    }
  }






  public FileUploadInOT_TOIL($event) {
    this.formdata = null;
    this.InvalidFile = false;
    this.InvalidFileError = '';
    this.uploadData = [];
    let files = $event.target.files;
    for (var uploadFile of files) {
      let filter = new FileFilter();
      if (filter.FileFormatFilter(uploadFile)) {
        if (filter.FileSizeFilter(uploadFile)) {
          this.formdata = new FormData();
          this.uploadData.push(uploadFile)
          if (this.uploadData.length > 0) {
            for (let i = 0; i < this.uploadData.length; i++) {
              this.formdata.append('files', this.uploadData[i]);
              this.AttendanceUI.ibusUIAttendanceDetail.ibusUIAttendanceDocument.strDocument_name = this.uploadData[i].name;
              this.AttendanceUI.ibusUIAttendanceDetail.ibusUIAttendanceDocument.strDocument_extension = this.uploadData[i].type;
              this.AttendanceUI.ibusUIAttendanceDetail.ibusUIAttendanceDocument.decDocument_size = this.uploadData[i].size;
              this.AttendanceUI.ibusUIAttendanceDetail.ibusUIAttendanceDocument.strRelative_path = this.uploadData[i].webkitRelativePath;
              var file = this.uploadData[i];
              var reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onloadend = () => {
                this.FileContent = reader.result;

                this.AttendanceUI.ibusUIAttendanceDetail.ibusUIAttendanceDocument.istrFileContent = this.bisect64string(this.FileContent);
              }
              //  console.log(22 ,this.EmployeeUI.ibusUIEmployeeDocument.istrFileContent );
              this.alert.clearAlert();
              //  this.UploadVariable.nativeElement.value = "";
            }
          }
        }
        else {
          if (uploadFile.size < 1) {
            this.InvalidFileError = "Selected File size is 0 Kb.The Minimum allowed size is 1 kb.";
            this.InvalidFile = true;
            this.ShowWaringAlert(this.InvalidFileError);
            return;
          }
          else {
            this.formdata = null;
            if (!isNullOrUndefined(this.UploadVariable)) {
              this.UploadVariable.nativeElement.value = "";
            }
            this.InvalidFileError = "Selected File Size is Exceeded. The Maximum Allowed Size is " + this.localStorage.get("attachmentSize") + " MB.";
            this.InvalidFile = true;
            this.ShowWaringAlert(this.InvalidFileError);
          }
        }
      }
      else {
        this.formdata = null;
        if (!isNullOrUndefined(this.UploadVariable)) {
          this.UploadVariable.nativeElement.value = "";
        }
        this.InvalidFileError = "The Selected File Format is not Supported. Please Select again.";
        this.InvalidFile = true;
        this.ShowWaringAlert("The Selected File Format is not Supported. Please Select again.");
      }
    }
  }

  bisect64string(base64StringFromURL) {
    var parts = base64StringFromURL.split(";base64,");
    var contentType = parts[0].replace("data:", "");
    var base64 = parts[1];
    return base64;


  }

  //Download Document Code Starts
  private Base64ToArrayBuffer(base64: any) {
    let Binary_String = window.atob(base64);
    let len = Binary_String.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = Binary_String.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private SaveData(blobData: any, FileName: any) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";

    let blob = blobData;
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = FileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }


  public DownloadDocument(Id: any) {
    if (!isNullOrUndefined(Id)) {
      this.attendancetimesheetService.DownloadAttendanceDocument(Id).subscribe(
        data => { this.DownloadDocumentSuccess(data) },
        error => { this.DownloadDocumentError(error) }
      );
    }
  }

  private DownloadDocumentSuccess(data: any) {
    let ApplicationDocument = data;
    let FileName = ApplicationDocument.idoBase.document_name;
    let arrayBuffer = this.Base64ToArrayBuffer(ApplicationDocument.istrFileContent);
    let file = new Blob([arrayBuffer], {
      type: 'application/octet-stream'
    });

    if (file != null && navigator.msSaveBlob) {
      return navigator.msSaveBlob(file, FileName);
    }
    this.SaveData(file, FileName);
  }

  private DownloadDocumentError(error: any) {
    console.log("Something Went Wrong Try Again...");
  }


  IsVisableUploadBtn() {
    if (this.IsCheckValidInTime == false &&
      this.IsCheckValidOutTime == false) {
      if (this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value == 'OT' ||
        this.AttendanceUI.ibusUIAttendanceDetail.istrpayable_or_toil_value == 'TOIL') {
        this.IsUploadIsVisible = true;
      } else {
        this.IsUploadIsVisible = false;
      }
    }
  }

  IsCheckShowSTDDeleteAlert(STDDeleteContent): boolean {
    if (!isNullOrUndefined(this.AttendanceUI)) {
      if (this.AttendanceUI.ilistbusUIAttendanceDetail != null && this.AttendanceUI.ilistbusUIAttendanceDetail.length > 0) {
        let ListCount = this.AttendanceUI.ilistbusUIAttendanceDetail.length;
        if (this.AttendanceUI.ilistbusUIAttendanceDetail != null &&
          this.AttendanceUI.ilistbusUIAttendanceDetail.length > 0) {

          this.DisplayContentInAlert =
            'To set the standard time, existing In-Time and Out-Time details will be removed.  Do you want to continue?';

          this.modalReferrence = this.modalService.open(STDDeleteContent, { backdrop: 'static' });
          return false;
        }
      }
    }
    return true;
  }


  public AttencenceCanceled() {
    this.CancelAttendance();
    this.IsCheckValidOutTime = false;
    this.IsCheckValidInTime = false;
    this.UISelectedTotalAttendanceDetail = null;
    this.AttendanceValidationUI = new AttendanceValidationUI();

    this.ShowSuccessAlert("Values Cleared Successfully.");
  }



  DDlEXDutyBind() {
    if (!isNullOrUndefined(this.SelectedInTime) && this.SelectedInTime != ""
      && !isNullOrUndefined(this.SelectedOutTime) && this.SelectedOutTime != "" &&
      this.IsCheckValidOutTime == false && this.IsCheckValidInTime == false
      && this.SelectedOutTime != "00:00" && this.SelectedOutTime != "00:00:00") {
      if (!isNullOrUndefined(this.AttendanceUI) &&
        !isNullOrUndefined(this.AttendanceUI.ibusUIAttendanceDetail)) {
        this.OpenAttendanceType();
      }
    }
  }
  if() {

  }
  public Submitteamattendanceapproval() {
    if (!isNullOrUndefined(this.AttendanceUI.lngemployee_Id)) {
      this.supervisorValue = false;
      var json = {
        empid: this.AttendanceUI.lngemployee_Id, supid: this.AttendanceUI.lngsupervisor_id,
        payrollfromdate: this.AttendanceUI.ibusUIAttendanceRequest.payroll_from_date,
        payrolltodate: this.AttendanceUI.ibusUIAttendanceRequest.payroll_to_date
      };
      this.preLoader.show(true);
      this.attendancetimesheetService.Submitteamattendanceapproval(this.AttendanceUI).subscribe(
        data => { this.SubmitteamattendanceapprovalSuccess(data) },
        error => { this.Submitteamattendanceapprovalerror(error) }
      );
    }
  }

  SubmitteamattendanceapprovalSuccess(data) {
    if (!isNullOrUndefined(data)) {
      console.log(this.AttendanceUI);
      if (data.ibusUIAttendanceRequest.ilstErrorMessages == null || data.ibusUIAttendanceRequest.ilstErrorMessages.length == 0) {
        this.AttendanceteamrequestUI = data.ibusUIAttendanceRequest;
        this.AttendanceListUI = data.ilistbusUIAttendance;
        if (data.ibusUIAttendanceRequest.iInfoMessage != null) {
          this.ShowSuccessAlert(data.ibusUIAttendanceRequest.iInfoMessage);
        }
      }
      else {
        let i = data.ibusUIAttendanceRequest.ilstErrorMessages.length
        this.ShowErrorAlert(i, data.ibusUIAttendanceRequest.ilstErrorMessages, 'ER001')
      }
    }
    this.ClosePopup();
    this.preLoader.show(false);
  }

  Submitteamattendanceapprovalerror(error) {
    this.dataService.GetCommonErrorMessage(error);
  }
  ////////////////////////////////////////////supervisor
  public GetSupervisorddl() {

    if (this.AttendanceUI.lngemployee_Id > 0) {
      this.preLoader.show(true);
      let EmpId = this.AttendanceUI.lngemployee_Id;
      let Value = { EmpId };
      this.attendancetimesheetService.GetSupervisorddlattendance(Value)
        .subscribe(
          data => { this.GetSupervisorddlSuccess(data) },
          error => { this.GetSupervisorddlError(error) }
        );
    }
    else {

      this.AttendanceUI.lngsupervisor_id = 0;
      this.DDLReportTo = [];
    }
  }


  DDLReportTo: any;
  private GetSupervisorddlSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      let response = data;
      if (response.length > 0) {
        this.DDLReportTo = response;
        console.log("this.DDLReportTo", this.DDLReportTo);
        this.AttendanceUI.lngsupervisor_id = this.AttendanceUI.ibusUIUser.user_serial_id;
      }
      else {
        this.DDLReportTo = [];
        this.AttendanceUI.lngsupervisor_id = 0;
      }
    }
    //this.preLoader.show(false);
  }
  private GetSupervisorddlError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

  public Holidayalert(Selecteddate) {
    if ((!isNullOrUndefined(Selecteddate))) {
      this.AttendanceUI.ibusUIMPublicHolidays.selecteddate = Selecteddate;
    }
    this.attendancetimesheetService.Holidayalertteamtimesheet(this.AttendanceUI.ibusUIMPublicHolidays).subscribe(
      data => { this.HolidayalertSuccess(data) }, error => { this.HolidayalertError(error) });
  }

  private HolidayalertSuccess(data) {
    if (!isNullOrUndefined(data)) {
      console.log(this.AttendanceUI);

      this.AttendanceUI.ibusUIMPublicHolidays = data;
      if (this.AttendanceUI.ibusUIMPublicHolidays.isholiday == true) {

        this.openHolidayAlert(this.AttendanceUI.ibusUIMPublicHolidays);
      }


    }
    this.preLoader.show(false);
  }
  openHolidayAlert(data) {
    this.AttendanceUI.ibusUIMPublicHolidays = data;
    if (!isNullOrUndefined(this.AttendanceUI.ibusUIMPublicHolidays.strholiday_name)) {
      let latest_date = this.datePipe.transform(this.AttendanceUI.ibusUIMPublicHolidays.dtmholiday_date, 'dd/MM/yyyy');
      this.holidayalert = "Selected Date ( " + latest_date + " ) is a " + this.AttendanceUI.ibusUIMPublicHolidays.strholiday_name + ".";
      this.modalReferrence = this.modalService.open(this.holidayAlert, { backdrop: 'static' });
    }
  }

  private HolidayalertError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

  public Getpayrollstartdateandenddate() {
    this.Isvalidfromdatetodate();
    if (this.validdate === true) {

      if (!isNullOrUndefined(this.AttendanceUI.lngemployee_Id)) {
        var json = {
          empid: this.AttendanceUI.lngemployee_Id, supid: this.AttendanceUI.lngsupervisor_id,
          payrollfromdate: this.AttendanceUI.ibusUIAttendanceRequest.payroll_from_date,
          payrolltodate: this.AttendanceUI.ibusUIAttendanceRequest.payroll_to_date
        };
        this.preLoader.show(true);
        this.attendancetimesheetService.Getpayrollstartdateandenddate(this.AttendanceUI).subscribe(
          data => {
            this.GetpayrollstartdateandenddateSuccess(data)
          },
          error => { this.GetpayrollstartdateandenddateError(error) }
        );
      }
    }

  }

  private GetpayrollstartdateandenddateSuccess(data) {
    if (!isNullOrUndefined(data)) {
      if (this.AttendanceUI.lngsupervisor_id != 0) {
        this.supervisorValue = false;

        if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
          if (data.iInfoMessage != null) {
            this.Submitalert = data.iInfoMessage;
            this.openSubmitforApprovalalert(data.iInfoMessage);
          }

        }
        else {
          let i = data.ilstErrorMessages.length
          this.ShowErrorAlert(i, data.ilstErrorMessages, 'ER001')
        }

        //  this.Submitalert=data.pop_up_message;
        //   this.openSubmitforApprovalalert(data);
      }

    }
    this.preLoader.show(false);
  }
  private GetpayrollstartdateandenddateError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }
  openSubmitforApprovalalert(data: any) {
    this.modalReferrence = this.modalService.open(this.submitforApprovalAlert, { backdrop: 'static' });
  }

  GetNextpayrollstartdateenddate() {
    //this.preLoader.show(true);
    this.attendancetimesheetService.GetNextpayrollstartdateenddate().subscribe(
      data => { this.GetNextpayrollstartdateenddateSuccess(data) }, error => { this.GetNextpayrollstartdateenddateError(error) });
  }

  GetNextpayrollstartdateenddateSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.from_date = this.datePipe.transform(data.nextpayrollstartdate, 'yyyy-MM-dd');
      this.to_date = this.datePipe.transform(data.nextpayrollenddate, 'yyyy-MM-dd');
      this.AttendanceUI.ibusUIAttendanceRequest.payroll_from_date = this.from_date;
      this.AttendanceUI.ibusUIAttendanceRequest.payroll_to_date = this.to_date;
    }

  }

  GetNextpayrollstartdateenddateError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

  Isvalidfromdatetodate() {
    this.supervisorValue = false;
    this.Fromdatemandatory = false;
    this.todatemandatory = false;
    this.Datecheck = false;
    var fromdate = this.AttendanceUI.ibusUIAttendanceRequest.payroll_from_date;
    var todate = this.AttendanceUI.ibusUIAttendanceRequest.payroll_to_date;
    if (this.AttendanceUI.lngsupervisor_id == 0) {
      console.log(this.AttendanceUI.lngsupervisor_id)
      this.supervisorValue = true;
    }
    if (fromdate == "Invalid date" || fromdate == null || fromdate == '') {
      this.Fromdatemandatory = true;
    }
    if (todate == "Invalid date" || todate == null || todate == '') {
      this.todatemandatory = true;
    }
    if ((fromdate != "Invalid date" && fromdate != null && fromdate != '') && (todate != "Invalid date" && todate != null && todate != '')) {
      if (fromdate > todate) {
        this.ShowWaringAlert("To Date Should be greater than From Date.");
        this.Datecheck = true;
      }
    }
    if ((this.supervisorValue == true || this.Fromdatemandatory == true || this.todatemandatory == true || this.Datecheck == true)) {
      this.validdate = false;
    }
    else {
      this.validdate = true;
    }
  }

  //*** INITIAL LOAD Leave Show CALENDER  ***///
  public LoadLeaveShowonTimesheet(data: any) {
    this.preLoader.show(true);
    //this.AttendanceUI.lngemployee_Id=this.AttendanceUI.EmplyeeId;
    this.AttendanceUI.lngemployee_Id = parseFloat(this.AttendanceUI.lngemployee_Id);
    this.attendancetimesheetService.LoadLeaveshowonTimesheet(this.AttendanceUI)
      .subscribe(
        data => {
          this.LoadLeaveShowonTimesheetSuccess(data)
        }, error => { this.LoadLeaveShowonTimesheetError(error) });
  }

  LoadLeaveShowonTimesheetSuccess(data: any) {

    this.LeaveListUI = data.ilistbusUILeaveRequest;
    this.HolidayList=data.ilistbusUIMPublicHolidays;
    if ((this.AttendanceCalendarListUI && this.AttendanceCalendarListUI.length > 0) ||
      (this.LeaveListUI && this.LeaveListUI.length > 0)
      ||(this.HolidayList && this.HolidayList.length > 0)
    ) {
      this.events = this.GetEventData();
      this.refresh.next();
    } else {
      this.events = [];
    }

    this.preLoader.show(false);
  }

  LoadLeaveShowonTimesheetError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

}
