import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'hrPayroll';

  ngOnInit() {
    // let cc = window as any;
    // cc.cookieconsent.initialise({
    //   palette: {
    //     popup: {
    //       background: "#164969"
    //     },
    //     button: {
    //       background: "#ffe000",
    //       text: "#164969"
    //     }
    //   },
    //   theme: "classic",
    //   content: {
    //     message: "Accept Cookie to proceed.",
        
      
    //     href: "/#"
    //   }
    // });

  }
}

