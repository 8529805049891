import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
    providedIn: 'root'
})

export class AttendanceService {

    constructor(public http: HttpClient) { }

    public GetAttendanceSearch() {
        return this.http.get(AppConstants.BasePath + 'GetAttendanceSearch').pipe(
            map(this.SuccessHandler), catchError(this.ErrorHandler));
    }

    public SearchAttendance(data: any): any {
        return this.http.post(AppConstants.BasePath + 'SearchAttendance', data).pipe(
            map(this.SuccessHandler), catchError(this.ErrorHandler));
    }

    public GetAttendanceInitialData() {
        return this.http.get(AppConstants.BasePath + 'GetAttendanceInitialData').pipe(
            map(this.SuccessHandler), catchError(this.ErrorHandler));
    }

    public AttendanceWeeklyView(Params: any): any {
        return this.http.post(AppConstants.BasePath + 'AttendanceWeeklyView', Params).pipe(
            map(this.SuccessHandler), catchError(this.ErrorHandler));
    }

    public GetAttendanceSummeryList(Params: any): any {
        return this.http.post(AppConstants.BasePath + 'GetAttendanceSummeryList', Params).pipe(
            map(this.SuccessHandler), catchError(this.ErrorHandler));
    }

    public GetAttendanceSummeryMonthList(Params: any): any {
        return this.http.post(AppConstants.BasePath + 'GetAttendanceSummeryMonthList', Params).pipe(
            map(this.SuccessHandler), catchError(this.ErrorHandler));
    }

    //******************************** Attendance Approvel  ********************************************* */
    public LoadAttendanceApprovelOnDeilyMonthlyWeekly(Params: any): any {
        return this.http.post(AppConstants.BasePath + 'LoadAttendanceApprovelOnDeilyMonthlyWeekly', Params).pipe(
            map(this.SuccessHandler), catchError(this.ErrorHandler));
    }

    public CreateAttendanceApprovel() {
        return this.http.get(AppConstants.BasePath + 'CreateAttendanceApprovel').pipe(
            map(this.SuccessHandler), catchError(this.ErrorHandler));
    }

    public SaveAttendanceApprovel(Params: any): any {
        return this.http.post(AppConstants.BasePath + 'SaveAttendanceApprovel', Params).pipe(
            map(this.SuccessHandler), catchError(this.ErrorHandler));
    }

    public OpenAttendanceApprovel(Params: any): any {
        return this.http.post(AppConstants.BasePath + 'OpenAttendanceApprovel', Params).pipe(
            map(this.SuccessHandler), catchError(this.ErrorHandler));
    }

    public SendApprovalRemainderMail() {
        return this.http.get(AppConstants.BasePath + 'SendApprovalRemainderMail').pipe(
            map(this.SuccessHandler), catchError(this.ErrorHandler));
    }

    public DownloadAttendanceDocument(data: any): any {
        return this.http.post(AppConstants.BasePath + 'DownloadAttendanceDocument', data).pipe(
          map(this.SuccessHandler), catchError(this.ErrorHandler));
      }

    private SuccessHandler(responce: Response) {
        let data = responce;
        return data || {};
    }
    private ErrorHandler(error: Response | any) {
        let errorMSg: string;
        if (error instanceof Response) {
            const err = error || '';
            errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errorMSg = error.message ? error.message : error.toString();
        }
        return _throw(error);
    }

    
}