import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../../services/common/alert.service';
import { SecurityServiceService } from 'src/app/services/common/security-service.service';
import { Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
import { CompanyService } from 'src/app/services/application/company.service';
import { AuthenticationService } from '../../../services/login/authentication.service';
import { AesService } from '../../../services/login/aes.service';
import { LoginService } from '../../../services/login/login.service';
import { isNullOrUndefined } from 'util';
import { ErrorMsg } from '../../../common/common';
import { CompanyRegistrationUI, ResetPasswordUI, UserValidationUI } from '../../../common/validationUI';
import { PreloadService } from 'src/app/services/common/preload.service';
import { THIS_EXPR, ThrowStmt } from '@angular/compiler/src/output/output_ast';
import { DataService } from 'src/app/services/common/data.service';
//import { interval } from 'rxjs';
import { Location } from '@angular/common';
import { Observable, interval, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CommonAlertComponent } from '../common-alert/common-alert.component';
import { FormatDate, IsLoggedIn, FileFilter } from 'src/app/common/common';
import { formatDate } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  RememberMe: {};
  ResetPasswordUI: ResetPasswordUI;
  usrnme: boolean;
  password: boolean;
  Regbutton: boolean;
  boolRegistrationInfo: boolean = false;
  UserNameEmpty: boolean = false;
  EmployeeIDEmpty: boolean = false;
  CompanyId: any;
  ValidEmailIdCheck: boolean;
  UserValidationUI: UserValidationUI;
  IntervalSeconds: any;
  timerIntervalSubscription: any;
  private route: ActivatedRoute;
  private updateSubscription: Subscription;
  DateTime: Date;
  urldate: any;
  date: any;
  IsBoolFrgtPassword: boolean = false;
  constructor(
    public Router: Router,
    private activatedRoute: ActivatedRoute,
    public alert: AlertService,
    public preLoader: PreloadService,
    private location: Location,
    public companyRegistrationUI: CompanyRegistrationUI,
    public resetPasswordUI: ResetPasswordUI,
    public authenticationService: AuthenticationService,
    public aesService: AesService,
    public localStorage: LocalStorageService,
    public SessionStorage: SessionStorageService,
    public dataService: DataService,
    public companyService: CompanyService,
    private SecurityService: SecurityServiceService,
    public dialog: MatDialog,
    public loginService: LoginService) { }
  login: boolean = true;
  register: boolean = false;
  ForgotsetPass: boolean = false;
  // forgotUsername:boolean=false;
  // forgotPassword:boolean=false;

  forgotuser: boolean = false;
  forgotpass: boolean = false;
  resetPass: boolean = false;
  ADValidation: boolean = true;
  BoolResetPasswordLink = false;
  isActive: boolean = true;
  isActiveCon: boolean = true;
  public LoggedInUser: any = {
    newpassword: '',
    confirmpassword: ''
  };
  public User: any;
  public Token: any;
  public IsUserGroupActive: boolean;
  public errorCount: any;
  Registration: any;
  response: any;
  validate_SubmitRegistration: any;
  Message: string;
  Messages: any[];

  ngOnInit() {
    // this.showMsgDialog('this is sample');    
    this.UserValidationUI = new UserValidationUI();
    this.loginOpen();
    this.GetToken();
    this.GetResetpasswordFromURl();
    this.BoolResetPasswordLink = false;
    this.preLoader.show(false);
   
  //   this.updateSubscription = interval(320000).subscribe(
  //     (val) => { 
  //       var splitted = window.location.pathname.split("/")
  //       this.url =splitted[2];
  //       if(this.url=='login'||this.url=="")
  //       {
  //       this.UserValidationUI = new UserValidationUI();
  //       this.loginOpen();
  //       this.GetToken();
  //       this.GetResetpasswordFromURl();
  //       this.BoolResetPasswordLink = false;
  //       this.preLoader.show(false);
  //       }
  //     });   
   }

   pathId = {
    id: '',
    datetime: '',
    value: '',
    isFromLink: true,
  };

  forgotpwd() {
    this.login = false;
    this.forgotuser = false;
    this.forgotpass = false;
    this.resetPass = false;
    this.register = false;
    this.ForgotsetPass = true;
    // this.alert.clearAlert();
    this.LoggedInUser = {};
    this.usrnme = false;
    this.password = false;
    this.UserNameEmpty = false;
    this.EmployeeIDEmpty = false;
    this.UserValidationUI = new UserValidationUI();
  }

  eye() {
    this.isActive = !this.isActive
  }

  eyeCon() {
    this.isActiveCon = !this.isActiveCon;
  }

  loginOpen() {
    this.login = true;
    this.forgotuser = false;
    this.forgotpass = false;
    this.ForgotsetPass = false;
    this.resetPass = false;
    this.register = false;
    // this.alert.clearAlert();
    this.LoggedInUser = {};
    this.usrnme = false;
    this.password = false;
    this.UserNameEmpty = false;
    this.EmployeeIDEmpty = false;
    this.UserValidationUI = new UserValidationUI();
  }

  regClick() {
    this.login = false;
    this.Regbutton = true;
    this.register = true;
    this.forgotuser = false;
    this.forgotpass = false;
    this.ForgotsetPass = false;
    // this.alert.clearAlert();
    this.LoggedInUser = {};
    this.CreateNewRegistration();
    this.companyRegistrationUI = new CompanyRegistrationUI();
    this.usrnme = false;
    this.UserNameEmpty = false;
    this.EmployeeIDEmpty = false;
    this.boolRegistrationInfo = false;
  }

  usernameOpen() {
    this.forgotuser = true;
    this.login = false;
    this.forgotpass = false;
    this.resetPass = false;
    this.ForgotsetPass = false;
    // this.alert.clearAlert();
    this.LoggedInUser = {};
    this.usrnme = false;
    this.password = false;
    this.UserNameEmpty = false;
    this.EmployeeIDEmpty = false;
    this.UserValidationUI = new UserValidationUI();
  }

  resetpass() {
    this.forgotuser = false;
    this.login = false;
    this.forgotpass = true;
    this.resetPass = false;
    this.ForgotsetPass = false;
    // this.alert.clearAlert();
    this.LoggedInUser = {};
    this.usrnme = false;
    this.password = false;
    this.UserNameEmpty = false;
    this.EmployeeIDEmpty = false;
    this.UserValidationUI = new UserValidationUI();
  }

  resetPassword() {
    this.login = false;
    this.forgotuser = false;
    this.forgotpass = false;
    this.resetPass = true;
    this.ForgotsetPass = false;
    // this.alert.clearAlert();
    this.LoggedInUser = {};
    this.usrnme = false;
    this.password = false;
    this.UserNameEmpty = false;
    this.EmployeeIDEmpty = false;
    this.UserValidationUI = new UserValidationUI();
  }

  public ngAfterViewChecked() {
    this.SecurityService.ApplySecurity();
  }

  passwordOpen() {
    this.forgotuser = false;
    this.login = false;
    this.forgotpass = true;
    this.resetPass = false;
    this.ForgotsetPass = false;
    // this.alert.clearAlert();
    this.LoggedInUser = {};
    this.usrnme = false;
    this.password = false;
    this.UserNameEmpty = false;
    this.EmployeeIDEmpty = false;
    this.UserValidationUI = new UserValidationUI();
  }

  data: any;
  url: any;
  id: any;
  value: any;
  key: any;

  public GetResetpasswordFromURl() {
    var search = [];
    this.url = window.location.origin + window.location.pathname;
    search[0] = window.location.search
      .split(/[&||?]/)
      .filter(function (x) { return x.indexOf("=") > -1; })
      .map(function (x) { return x.split(/=/); })
      .map(function (x) {
        x[1] = x[1].replace(/\+/g, " ");
        return x;
      })
      .reduce(function (acc, current) {
        acc[current[0]] = current[1];
        return acc;
      }, {});
    // var flag = search[].Value;
    if (search[0].value == "Y") {
      this.resetPassword();
      var login_id = search[0].key;
      this.key = search[0].key;
      this.value = search[0].value;
      this.id = search[0].id;
      this.urldate=search[0].CRDT;
      this.LoggedInUser.user_login_id = login_id;
      this.LoggedInUser.Comapny_id = search[0].id;
      this.LoggedInUser.DateTime = new Date();
      this.LoggedInUser.Password = this.LoggedInUser.newpassword;
      this.localStorage.set("LoggedInUser", this.LoggedInUser);
      this.localStorage.set("LoggedInUserDateTime", this.LoggedInUser);
    }
    else {
      this.loginOpen();
    }
  }

  // getData() {
  //   this.portal.loadingTrue = true;
  //   this.fullSpinner.spin.next(true);
  //   this.data
  //     .postData(AppGlobal.apiPaths.portalLogin.userinfo, this.pathId)
  //     .then(
  //       (success: any) => {
  //         this.portal.loadingTrue = false;
  //         this.fullSpinner.spin.next(false);
  //         this.portal.user = success;

  //         this.general.login.organizationRefNo = this.portal.user.organizationRefNo;
  //         this.general.login.portalUserId = this.portal.user.employerPortalUserId;
  //       },
  //       (error) => {
  //         this.portal.loadingTrue = false;
  //         this.fullSpinner.spin.next(false);
  //         this.data.errorMethod(error);
  //         this.router.navigateByUrl('/');
  //       }
  //     );
  // }

  GetToken() {
    this.preLoader.show(true);
    return this.authenticationService.GetToken().
      subscribe(data => { this.GetTokensuccessMethod(data) },
        error => { this.GetTokenerrorMethod(error) });
  }

  GetTokensuccessMethod(data) {
    this.Token = data;
    console.log(this.Token);
    this.dataService.SessionId = this.Token[2];
    this.SessionStorage.set("SessionKey", this.dataService.SessionId);
    this.preLoader.show(false);
  }

  GetTokenerrorMethod(error) {
    console.log(error);
    this.GetCommonErrorMessage();
  }

  AuthenticateUser() {
    if (!isNullOrUndefined(this.LoggedInUser.istrCheckbox) &&
      !this.LoggedInUser.istrCheckbox) {
      this.RememberMe = {};
      this.localStorage.set("IsRemember", this.RememberMe);
    }
    this.UserValidationUI = new UserValidationUI();
    if(this.IsBoolFrgtPassword  == true){
      var Password = this.localStorage.get("Password");
      this.LoggedInUser.userloginid = this.LoggedInUser.user_login_id;
      this.LoggedInUser.password = Password;
    }
    if (!isNullOrUndefined(this.LoggedInUser.userloginid)
      && this.LoggedInUser.userloginid != "" &&
      this.LoggedInUser.password != "" &&
      !isNullOrUndefined(this.LoggedInUser.password)) {
      let UserName = this.aesService.EncryptText(this.LoggedInUser.userloginid, this.Token) as string;
      let Password = this.aesService.EncryptText(this.LoggedInUser.password, this.Token) as string;
      var Authentication = { LoggedInUserName: UserName, LoggedInPassword: Password, Guid: this.dataService.SessionId };
      this.preLoader.show(true);
      this.authenticationService.AuthenticateUser(Authentication)
        .subscribe(
          data => { this.AuthenticateUserSuccess(data) }, error => { this.AuthenticateUserError(error) });
    }
    else {
      // this.alert.clearAlert();
      if ((this.LoggedInUser.userloginid == "" || this.LoggedInUser.userloginid == null || this.LoggedInUser.userloginid == undefined) &&
        (this.LoggedInUser.password == "" || this.LoggedInUser.password == null || this.LoggedInUser.password == undefined)) {
        //     this.ShowErrorPanelAlert("User name/ Password should not be empty");
        this.UserValidationUI.usrnme = true;
        this.UserValidationUI.Password = true;
      }
      else if (this.LoggedInUser.userloginid == "" || this.LoggedInUser.userloginid == null || this.LoggedInUser.userloginid == undefined) {
        //   this.ShowErrorPanelAlert("User name should not be empty");
        this.UserValidationUI.usrnme = true;
      }
      else if (this.LoggedInUser.password == "" || this.LoggedInUser.password == null || this.LoggedInUser.password == undefined) {
        //   this.ShowErrorPanelAlert("Password should not be empty");
        this.UserValidationUI.Password = true;
      }
    }
  }

  AuthenticateUserSuccess(response: any) {
    if (response.ilstErrorMessages.length == 0) {
      if (!isNullOrUndefined(response)) {
        this.User = response;
        this.alert.clearAlert();
        this.localStorage.set("LoggedInUser", this.User);
        this.ValidateUser(this.User)
        if (this.errorCount == 0) {
          if (this.User.iListbusUIUserGroup != null
            && this.User.iListbusUIUserGroup.length > 0) {
            this.IsUserGroupActive = this.IsUserHasActiveGroups();
            if (!this.IsUserGroupActive) {
              this.ShowErrorAlert(1, "No Group(s) are Active Status to this user. Please contact Administrator.", 1087);
              this.preLoader.show(false);
              return;
            }
          }
          // if( this.User.ibusUIUserAddtionalDetail.strIsTempPassword =='Y' && 
          // this.User.ibusUIUserAddtionalDetail.IsFormValidationTrue ==true){
          //   this.Router.navigate(["/PayrollResetPassword"]);
          //   return;
          // }
          if (this.localStorage.get("LoggedInUser") != null &&
            this.localStorage.get("LoggedInUser") != undefined) {
            let UserDetails = this.localStorage.get("LoggedInUser");
            this.localStorage.set("attachmentSize", this.User.AttachmentSizeLimit);
            this.localStorage.set("attachmentTypes", this.User.FileTypes);

            if (!isNullOrUndefined(UserDetails)) {
              let UserRightList = UserDetails.ilistUserRights;
              this.localStorage.set("UserRightsList", UserRightList);
            }
            if (!isNullOrUndefined(UserDetails.full_name)) {
              this.SessionStorage.set("UserName", UserDetails.full_name)
              this.SessionStorage.set("LastLogin", UserDetails.ibusUIUserAddtionalDetail.dtmLastLogin)
              // this.authenticationService.UserName = UserDetails.full_name;
              // this.Layout.setLastLogin(UserDetails.ibusUIUserAddtionalDetail.dtmLastLogin);
              // this.Layout.setUserName(UserDetails.full_name);
            }
            if (!isNullOrUndefined(this.User.ibusUIEmployee)) {
              this.localStorage.set("EmployeeValues", this.User.ibusUIEmployee);
            }
            let bool = this.DefaultLandingPage(UserDetails);
            if (!bool) {
              this.ShowErrorAlert(1, "Default Landing Page not set. Please contact Administrator.", 1087);
              this.preLoader.show(false);
              return;
            }
            let Path = UserDetails.istrUserDefaultLP;
            if (this.LoggedInUser.istrCheckbox) {

              if (isNullOrUndefined(this.RememberMe)) {
                this.RememberMe = {};
              }
            }
            if (UserDetails.ibusUICompany != null) {
              this.dataService.CompanyData = UserDetails.ibusUICompany;
              this.localStorage.set("CompanyDetail", UserDetails.ibusUICompany);
            }
            this.Router.navigate([Path]);
            this.SessionStorage.set("IsLoggedIn", true);
          }
          else {
            this.ShowErrorAlert(1, "Login Failed.", 1087);
          }
        }
      }
    } 
    else {
      if (!isNullOrUndefined(response)) {
        this.ShowErrorAlert(1, response.ilstErrorMessages, 1087);
      }
    }
    this.preLoader.show(false);
  }

  AuthenticateUserError(error: string | any) {
    console.log(error);
    this.GetCommonErrorMessage();
  }

  IsUserHasActiveGroups() {
    for (let i = 0; i < this.User.iListbusUIUserGroup.length; i++) {
      if (this.User.iListbusUIUserGroup[i].strStatusValue == 'ACTIV') {
        return true;
      }
    }
    return false;
  }

  ValidateUser(User: any) {
    this.errorCount = 0;
    if (isNullOrUndefined(User)) {
      this.errorCount = 1;
      this.ShowErrorAlert(1, "Login Failed.", 1087);
    }
    else if (this.User.ilstErrorMessages != null &&
      this.User.ilstErrorMessages.length > 0) {
      this.errorCount = 1;
      this.ShowErrorAlert(1, this.User.ilstErrorMessages, 1087);
    }
    else if (this.User.iListbusUIUserGroup == null || this.User.iListbusUIUserGroup.length <= 0) {
      this.errorCount = 1;
      this.ShowErrorAlert(1, "No Group(s) assigned. Please contact Administrator.", 1087);
    }
  }

  public DefaultLandingPage(User: any) {
    if (!isNullOrUndefined(User) && User != null) {
      if (!isNullOrUndefined(User.istrUserDefaultLP) && User.istrUserDefaultLP != "") {
        let SideBar;
        if (User.istrUserDefaultLP == "/userSearch"
          || User.istrUserDefaultLP == "/userDetail"
          || User.istrUserDefaultLP == "/roleSearch"
          || User.istrUserDefaultLP == "/roleDetail"
          || User.istrUserDefaultLP == "/groupSearch"
          || User.istrUserDefaultLP == "/groupDetail"
          || User.istrUserDefaultLP == "/resource"
          || User.istrUserDefaultLP == "/configSearch"
          || User.istrUserDefaultLP == "/configDetail"
          || User.istrUserDefaultLP == "/auditlog"
          || User.istrUserDefaultLP == "/processlog"
          || User.istrUserDefaultLP == "/emailTracking") {
          SideBar = "Admin";
          // this.Layout.setSideBar("Admin");
        }
        else {
          SideBar = "Application";
          // this.Layout.setSideBar("Application");
        }
        this.SessionStorage.set("SideBarDiv", SideBar);
        this.SessionStorage.set("DefaultLandingPage", User.istrUserDefaultLP);
        return true;
      }
      else {
        return false;
      }
    }
  }

  GetCommonErrorMessage() {
    this.ShowErrorAlert(1, ErrorMsg.ErrorMessage, 1087);
    this.preLoader.show(false);
  }

  ShowSuccessAlert(Msg: any) {
    this.alert.ShowSuccessAlert(true);
    this.alert.SetSuccessMsg(Msg);
  }

  ShowInfoAlert(Msg: any) {
    this.alert.ShowInfoAlert(true);
    this.alert.SetInfoMsg(Msg);
   // this.alert.ShowSuccess(true);
  }

  ShowErrorPanelAlert(Msg: any) {
    this.alert.ShowErrorPanelAlert(true);
    this.alert.SetErrorPanelMsg(Msg);
  }

  ShowWarinngAlert(Msg: any) {
    this.alert.ShowWarningAlert(true);
    this.alert.SetWarningMsg(Msg);
  }

  ShowErrorAlert(ErrorCount: any, ErrorMsg: any, ErrorCode: any) {
    let ErrMsg = '';
    if (ErrorCount > 2) {
      ErrMsg = 'Error has been occured.'
    }
    else {
      ErrMsg = ErrorMsg;
    }
    this.alert.SetErrorCode(ErrorCode);
    this.alert.SetErrorDescription(ErrMsg);
    this.alert.ShowErrorAlert(true);
   // this.alert.ShowSuccessAlert(true);
  }

  GetUserNameByEmployeeID() {
    if (!isNullOrUndefined(this.LoggedInUser.EmployeeID) && this.LoggedInUser.EmployeeID != "") {
      this.EmployeeIDEmpty = false;
      let forgotusername = { employeeID: this.LoggedInUser.EmployeeID };
      this.preLoader.show(true);
      this.authenticationService.GetUserNameByEmployeeID(forgotusername)
        .subscribe(
          data => { this.GetForgetUserIdSuccess(data) }, error => { this.GetForgetUserIdError(error) });
    }
    else {
      this.EmployeeIDEmpty = true;
    }
  }

  GetForgetUserIdSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      let response = data;
      if (response.ilstErrorMessages == null || response.ilstErrorMessages.length == 0) {
        //   this.loginOpen();
        this.LoggedInUser = {};
        this.ShowSuccessAlert(response.iInfoMessage);
      }
      else {
        this.ShowErrorAlert(1, response.ilstErrorMessages, 1087);
      }
    }
    this.preLoader.show(false);
  }

  GetForgetUserIdError(error: any) {
    console.log(error);
    this.GetCommonErrorMessage();
  }






  //////REGISTER//////
  ClearRegister() {
    this.Registration = {};
    this.CreateNewRegistration();
    this.ShowInfoAlert("Values cleared Successfully.");
  }

  CreateNewRegistration() {
    this.preLoader.show(true);
    this.authenticationService.CreateNewRegistration().subscribe(
      data => { this.CreateNewRegistrationsuccessMethod(data) },
      error => { this.CreateNewRegistrationerrorMethod(error) }
    )
  }

  CreateNewRegistrationsuccessMethod(data: any) {
    if (!isNullOrUndefined(data)) {
      this.Registration = data;
      console.log("user", this.Registration);
      this.preLoader.show(false);
    }
  }

  CreateNewRegistrationerrorMethod(error: any | string) {
    console.log(error);
    this.GetCommonErrorMessage();
  }


  SubmitRegistration() {
    if (!isNullOrUndefined(this.Registration)) {
      if (this.ValidateSubmitRegistration()) {
        this.boolRegistrationInfo = false;
        this.Message = "";
        this.preLoader.show(true);
        this.authenticationService.SubmitRegistration(this.Registration).subscribe(
          data => { this.SubmitRegistrationsuccessMethod(data) }, error => { this.SubmitRegistrationFailureMethod(error) });
      }
    }
  }
  SubmitRegistrationsuccessMethod(response) {
    this.Messages = [];
    if (response != null && response != "") {
      var result = response;
      if (result.ilstErrorMessages == null || result.ilstErrorMessages.length == 0) {
        this.Registration = response;
        this.boolRegistrationInfo = true;
        this.Registration = {};
        this.CreateNewRegistration();
      }
      else {
        this.errorCount = 1;
        this.ShowErrorAlert(1, result.ilstErrorMessages, 1087);
      }

      this.GetToken();
    }
    this.preLoader.show(false);
  }

  SubmitRegistrationFailureMethod(error: any | string) {
    console.log(error);
    this.GetCommonErrorMessage();
  }

  ValidateSubmitRegistration() {
    if (!isNullOrUndefined(this.Registration)) {
      let errorCount = 0;
      this.companyRegistrationUI = new CompanyRegistrationUI();
      let IsValidUser = false;
      let strCompanyName = this.Registration.strCompany_name;
      let strPhoneNo = this.Registration.strPhone_no;
      let user_login_id = this.Registration.user_login_id;
      let strEmailId = this.Registration.strEmail_id;
      if (strCompanyName == "" || isNullOrUndefined(strCompanyName)) {
        this.companyRegistrationUI.ComapnyName = true;
        errorCount++;
      }
      if (user_login_id == "" || isNullOrUndefined(user_login_id)) {
        this.companyRegistrationUI.user_login_id = true;
        errorCount++;
      }

      if (strPhoneNo == "" || isNullOrUndefined(strPhoneNo)) {
        this.companyRegistrationUI.PhoneNo = true;
        errorCount++;
      }
      else {

        var NumericalExpression = new RegExp(/^[0-9.]*$/);
        if (strPhoneNo != "" && strPhoneNo != null && strPhoneNo != 0) {
          if (!NumericalExpression.test(strPhoneNo)) {
            this.companyRegistrationUI.PhoneNoFormat = true;
            errorCount++;
          }
        }
      }

      if (strEmailId == "" || isNullOrUndefined(strEmailId)) {
        this.companyRegistrationUI.EmailID = true;
        errorCount++;
      }
      else {
        if (strEmailId != "" && !isNullOrUndefined(strEmailId)) {
          
          let EmailValidation =   /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-zA-Z0-9-]+)*(\.[a-z A-Z]{2,4})$/;
      

          if (!strEmailId.match(EmailValidation)) {
            this.companyRegistrationUI.EmailIDFormat = true;
            errorCount++;
          }
        }
      }
      if (errorCount > 0) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  SetPasswordLinkGetByEmailID() {
    if (!isNullOrUndefined(this.LoggedInUser)) {
      this.UserValidationUI = new UserValidationUI();
      if (this.ValidateEmailId(this.LoggedInUser)) {
        let todays: any = Date();
         this.date = todays;
        // let CrtDateTime =formatDate(this.date, "yyyy-MM-dd hh:mm:ss",  'en-US', '+0530'); // Format: dd/MM/yyyy OR dd-MM-yyyy OR yyyy-MM-dd
        let CrtDateTime = moment().format('YYYY-MM-DD hh:mm:ss a');
        this.preLoader.show(true);
        let Values = { EmailId: this.LoggedInUser.UserEmailId, Username: this.LoggedInUser.user_name,CurrentDateTime : CrtDateTime };
        this.authenticationService.SetPasswordLinkGetByEmailID(Values).subscribe(
          data => { this.ActiveSuccess(data) },
          error => { this.ActiveFail(error) });
      }
    }
    else {
      this.UserValidationUI.BoolResetPasswordLink = true;
    }
  }

  ActiveSuccess(data) {
    if (data.ilstErrorMessages == null || data.ilstErrorMessages.length == 0) {
      // this.loginOpen();
      this.LoggedInUser = {};
       this.showMsgDialog("Password reset link has been sent to your Email id. Please check your Email and create your New Password.");
     // this.ShowSuccessAlert("Password reset link has been sent to your Email id. Please check your Email and create your New Password.");
      //this.Router.navigate(["/login"]);
    }
    else {
      this.ShowErrorAlert(1, data.ilstErrorMessages, 1087);
    }
    this.preLoader.show(false);
  }

  public ActiveFail(error): any {
    console.log(error);
    this.GetCommonErrorMessage();
  }

  ValidateEmailId(data) {
    this.UserValidationUI = new UserValidationUI();
    let ValidEmailIdCheck = false;
    var errorCount = 0;
    var Email_Id = data.UserEmailId;
    var Username = data.user_name;
    this.ValidEmailIdCheck = false;
    if (isNullOrUndefined(Username)) {
      errorCount++;
      this.UserValidationUI.user_name = true;
    }
    if (isNullOrUndefined(Email_Id)) {
      errorCount++;
      this.UserValidationUI.BoolResetPasswordLink = true;
    }
    if (Email_Id != null && Email_Id != "") {
    
      let EmailValidation =   /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-zA-Z0-9-]+)*(\.[a-z A-Z]{2,4})$/;
      
      if (!Email_Id.match(EmailValidation)) {
        errorCount++;
        this.UserValidationUI.validEmailID = true;
      }
    }
    if (errorCount > 0) {
      return false;
    }
    else {
      ValidEmailIdCheck = true;
      return ValidEmailIdCheck;
    }
  }

  //***************************Check user info************************************** */


  public CheckUserInfoInRegularInterval(): any {
    const timer = interval(this.IntervalSeconds);
    this.timerIntervalSubscription = timer.subscribe(val => this.CheckUserInfo());
  }

  CheckUserInfo() {
    let IsLoggedIn = this.SessionStorage.get("IsLoggedIn");
    if (IsLoggedIn) {
      console.log('CheckUserInfo Service Call Begins')
      return this.authenticationService.CheckUserInfo().
        subscribe(data => { this.CheckUserInfoSuccessMethod(data) },
          error => { this.CheckUserInfoErrorMethod(error) });
    }
    // else {
    // this.timerIntervalSubscription.unsubscribe();
    // }
  }

  CheckUserInfoSuccessMethod(data) {
    console.log('CheckUserInfo Service Call End')
    if (data != true) {
      console.log('Redirecting to Login Page')
      this.ShowErrorAlert(1, "Your Session has been expired. Please Login again.", 22);
      this.Router.navigate(["/login"]);
    }
  }

  CheckUserInfoErrorMethod(error) {
    console.log('CheckUserInfo Service Call End')

    // this.GetCommonErrorMessage();
  }


  GetIntervalSessionTime() {
    return this.authenticationService.GetIntervalSessionTime().
      subscribe(data => { this.GetIntervalSessionTimeSuccessMethod(data) },
        error => { this.GetIntervalSessionTimeErrorMethod(error) });
  }

  GetIntervalSessionTimeSuccessMethod(data) {
    this.preLoader.show(false);
    console.log("GetIntervalSessionTimeSuccessMethod called");
    this.IntervalSeconds = data;
  }

  GetIntervalSessionTimeErrorMethod(error) {
    this.preLoader.show(false);
    console.log("GetIntervalSessionTimeErrorMethod called");
  }

  setNewUserPassword() {
    this.alert.clearAlert();
    this.ResetPasswordUI = new ResetPasswordUI();
    if (this.IsValidPassword()) {
      if(this.IsValidConfirmPassword()){
      if (this.LoggedInUser.newpassword == this.LoggedInUser.confirmpassword) {
        this.localStorage.set("Password", this.LoggedInUser.confirmpassword);
        var user = this.localStorage.get("LoggedInUser");
        if (user != null && !isNullOrUndefined(user)) {
          this.preLoader.show(true);
          let todays: any = Date();
          this.date = todays;
         //  let CurrentDateTime =formatDate(this.date, "yyyy-MM-dd hh:mm:ss",  'en-US', '+0530'); // Format: dd/MM/yyyy OR dd-MM-yyyy OR yyyy-MM-dd
         let CurrentDateTime = moment().format('YYYY-MM-DD hh:mm:ss a'); 
         let RequestDateTime = 
           CurrentDateTime;
          var NewPasswordDetail = {
            Password: this.LoggedInUser.newpassword, user_login_id: user.user_login_id, URLDate : this.urldate , RequestDateTime : CurrentDateTime
          };
          console.log("NewPassword", NewPasswordDetail);
          this.authenticationService.SaveResetUserPassword(NewPasswordDetail).subscribe(
            data => { this.ResetUserPasswordSuccess(data) }, error => { this.ResetUserPasswordError(error) });
        }
      }
      else {
        this.ResetPasswordUI.NewPasswordConfirPassworddoesntmatch = true;
      }
    }
  }
}
  
  ResetUserPasswordSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      console.log("sucs", data);
      this.LoggedInUser = data;
      // this.LoggedInUser = {};
      if (this.LoggedInUser.ilstErrorMessages == null || this.LoggedInUser.ilstErrorMessages.length == 0) {
       this.ShowSuccessAlert(this.LoggedInUser.iInfoMessage);
       this.IsBoolFrgtPassword = true;
       this.AuthenticateUser();
       // this.ShowInfoAlert(this.LoggedInUser.iInfoMessage);
        //this.Router.navigate(['/login']);
       // this.loginOpen();
      }
      else {
        let i = this.LoggedInUser.ilstErrorMessages.length
        this.ShowErrorAlert(i, this.LoggedInUser.ilstErrorMessages, 1087);
      }
    }
    this.preLoader.show(false);
  }

  ResetUserPasswordError(error: any) {
    console.log(error);
    this.GetCommonErrorMessage();
  }

  IsValidPassword() {
    this.ResetPasswordUI = new ResetPasswordUI();
    let errorCount = 0;
    let errMsg = ""
    var pwd = this.LoggedInUser.newpassword;
    var cmpwd = this.LoggedInUser.confirmpassword;
    var minLength = 6;
    var maxLength = 15;
    this.alert.clearAlert();

    if (pwd == "" || isNullOrUndefined(pwd)) {
      errMsg = "Password is required"
      this.ResetPasswordUI.Password = true;
      errorCount++;
    }
    // if (cmpwd == "" || isNullOrUndefined(cmpwd)) {
    //   errMsg = "Confirm Password is required"
    //   this.ResetPasswordUI.ConfirmPassword = true;
    //   errorCount++;
    // }
    if (pwd.length < minLength || pwd.length > maxLength) {
      errorCount++;
      this.ResetPasswordUI.Passwordlengthbe6to15characters = true;
      // errMsg = "Password length should be 6 to 15 characters."
      // errors.push(errMsg);
    }
    var regEx1 = new RegExp("^(?=.*[A-Z])");
    if (!regEx1.test(pwd)) {
      errorCount++;
      this.ResetPasswordUI.PleasePasswordoneUppercase = true;
      // errMsg = "Please ensure your password is having at least one Uppercase character(A to Z).";
      // errors.push(errMsg);
    }
    var regEx2 = new RegExp("^(?=.*[0-9])");
    if (!regEx2.test(pwd)) {
      errorCount++;
      this.ResetPasswordUI.Pleaseeastonenumeric = true;
      // errMsg = "Please ensure your password is having at least one numeric character(0 to 9).";
      // errors.push(errMsg);
    }
    var regEx3 = new RegExp("^(?=.*[ @#$_!])");
    if (!regEx3.test(pwd)) {
      errorCount++;
      this.ResetPasswordUI.Pleasepasswordleastonespecial = true;
      // errMsg = "Please ensure your password is having at least one special character Like(@,#,$,_,!)";
      // errors.push(errMsg);
    }
    if (errorCount > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  IsValidConfirmPassword() {
    this.ResetPasswordUI = new ResetPasswordUI();
    let errorCount = 0;
    let errMsg = ""
    var pwd = this.LoggedInUser.newpassword;
    var cmpwd = this.LoggedInUser.confirmpassword;
    var minLength = 6;
    var maxLength = 15;
    this.alert.clearAlert();

    // if (pwd == "" || isNullOrUndefined(pwd)) {
    //   errMsg = "Password is required"
    //   this.ResetPasswordUI.Password = true;
    //   errorCount++;
    // }

    if (cmpwd == "" || isNullOrUndefined(cmpwd)) {
      errMsg = "Confirm Password is required"
      this.ResetPasswordUI.ConfirmPassword = true;
      errorCount++;
    }

    // if (pwd.length < minLength || pwd.length > maxLength) {
    //   errorCount++;
    //   this.ResetPasswordUI.Passwordlengthbe6to15characters = true;
    //   // errMsg = "Password length should be 6 to 15 characters."
    //   // errors.push(errMsg);
    // }
    
    // var regEx1 = new RegExp("^(?=.*[A-Z])");
    // if (!regEx1.test(pwd)) {
    //   errorCount++;
    //   this.ResetPasswordUI.PleasePasswordoneUppercase = true;
    //   // errMsg = "Please ensure your password is having at least one Uppercase character(A to Z).";
    //   // errors.push(errMsg);
    // }
    // var regEx2 = new RegExp("^(?=.*[0-9])");
    // if (!regEx2.test(pwd)) {
    //   errorCount++;
    //   this.ResetPasswordUI.Pleaseeastonenumeric = true;
    //   // errMsg = "Please ensure your password is having at least one numeric character(0 to 9).";
    //   // errors.push(errMsg);
    // }
    // var regEx3 = new RegExp("^(?=.*[ @#$_!])");
    // if (!regEx3.test(pwd)) {
    //   errorCount++;
    //   this.ResetPasswordUI.Pleasepasswordleastonespecial = true;
    //   // errMsg = "Please ensure your password is having at least one special character Like(@,#,$,_,!)";
    //   // errors.push(errMsg);
    // }
    if (errorCount > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  showMsgDialog(msg){
    const dialogRef = this.dialog.open(CommonAlertComponent, {
      width: '450px',
      disableClose: true,
      data: {
        message: msg,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.Router.navigate(['/login']);
        this.loginOpen();
      }
    });
  }

}
