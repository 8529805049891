import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { HttpModule } from '@angular/http';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class SalaryService {

  constructor(public http: HttpClient) { }

////GetSalaryConfigInitialData
  public GetSalaryConfigInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetSalaryConfigInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)); 
  }

///////
public CreateNewSalary() {
  return this.http.get(AppConstants.BasePath + 'CreateNewSalary').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}


public CreateSalaryConfigurationApproval() {
  return this.http.get(AppConstants.BasePath + 'CreateSalaryConfigurationApproval').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
/////////
public GetDesignationandDesignationLevel() {
  return this.http.get(AppConstants.BasePath + 'GetDesignationandDesignationLevel').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}


public LoadSalaryConfigurationList(data: any): any {
  return this.http.post(AppConstants.BasePath + 'LoadSalaryConfigurationList', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
////DeleteSalaryConfigList///
public DeleteSalaryConfigList(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteSalaryConfigList', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public GetStatusBasedOnEffetiveDate(EffDate: any):any{
  return this.http.post(AppConstants.BasePath + 'GetStatusBasedOnEffetiveDate', EffDate).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public GetEffectivedateBasedonStatus(EffDate: any):any{
  return this.http.post(AppConstants.BasePath + 'GetEffectivedateBasedonStatus', EffDate).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}  
public GetSalaryConfigDetailcsSearch() {
  return this.http.get(AppConstants.BasePath + 'GetSalaryConfigDetailcsSearch').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}


public SearchSalaryConfigList(UserSearch: any): any {
  return this.http.post(AppConstants.BasePath + 'SearchSalaryConfigDetailcsSearch', UserSearch).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

////Salary Detail

public CreateSalaryConfigDetailcs() {
  return this.http.get(AppConstants.BasePath + 'CreateSalaryConfigDetailcs').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public SaveSalaryConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SaveSalaryConfig', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public SubmitSalaryConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SubmitSalaryConfig', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

///////// GetTotalHours
public GetTotalWorkHours() {
  return this.http.get(AppConstants.BasePath + 'GetTotalWorkHours').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

/// DeleteSalaryConfig
public DeleteSalaryConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteSalaryConfigDetails', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

////// OpenSalaryConfig
public OpenSalaryConfig(data): any {
  return this.http.get(AppConstants.BasePath + 'OpenSalaryConfig').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

///// CopyLastSalaryConfigData
public CopyLastSalaryConfigData() {
  return this.http.get(AppConstants.BasePath + 'CopyLastSalaryConfigData').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}


public SubmitSalaryConfigApproval(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SubmitSalaryConfigApproval', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

////// OpenSalaryApproverConfig
public OpenSalaryApproverConfig(data): any {
  return this.http.post(AppConstants.BasePath + 'OpenSalaryApproverConfig',data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

    //  ********************  Attachment Tab Ends     ********************  //
    private SuccessHandler(responce: Response) {
      let data = responce;
      return data || {};
    }
    private ErrorHandler(error: Response | any) {
      let errorMSg: string;
      if (error instanceof Response) {
        const err = error || '';
        errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
      } else {
        errorMSg = error.message ? error.message : error.toString();
      }
      return _throw(error);
    }
}
