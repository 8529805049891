import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TitleServiceService } from 'src/app/services/common/title-service.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
import { Router } from '@angular/router';
import { Pagination, SearchResultCount, FormatDate, ErrorMsg } from 'src/app/common/common';
import {DataService} from '../../../services/common/data.service';
import { IsLoggedIn } from 'src/app/common/common';
import { isNullOrUndefined, format } from 'util';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PreloadService } from 'src/app/services/common/preload.service';
import { MasterService } from 'src/app/services/master/master.service';
import { MasterValidationUI, ReqApproverConfigValidationUI} from 'src/app/common/validationUI';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { ErrorMsgTab } from 'src/app/common/common';

@Component({
  selector: 'app-request-Approver-configuration',
  templateUrl: './request-Approver-configuration.component.html',
  styleUrls: ['./request-Approver-configuration.component.scss']
})
export class RequestApproverConfigurationComponent implements OnInit {
  IntialDDL: any;
  errorCount: number;
  infoCount: number;
  warningCount: number;
  MsgTabList: any;
  ReqApproverConfiguration: any;
  response: any;
  ReqApproverConfigurationList: any;
  Flag: boolean;
  ErrorMsgTab: ErrorMsgTab;
  DuplicateMsgTabList: any;
  UISelectedReqApproverConfiguration: any;
  Message: string;
  modalref: any;
  DDLReqApproverConfigurationDesignation:any;
  DDLApproverLevel:any;
  DDLRequestType:any;
  constructor(
    private title: TitleServiceService,
    private alert: AlertService,
    private preLoader: PreloadService,
    private dataService: DataService,
    public localStorage: LocalStorageService,
    private router: Router,
    private pagination: Pagination,
    private updateResultCount: SearchResultCount,
    public dateFormat: FormatDate,
    private dateParseFormatter: NgbDateParserFormatter,
    public SessionStorage: SessionStorageService,
    public ReqApproverConfigValidationUI: ReqApproverConfigValidationUI,
    private isLoggedIn: IsLoggedIn,
    public masterService: MasterService,
    private modalService: NgbModal,
    private atp: AmazingTimePickerService
  ) { }


  ngOnInit() {
    this.title.setsubTitle('Configuration');
    this.title.setTitle('Request Approver Configuration');
    this.title.setTitleforPdf('RequestApproverConfiguration');
    this.title.setshowpdf(true);
    this.ReqApproverConfigValidationUI = new ReqApproverConfigValidationUI();
    this.CreateReqApproverConfiguration();
    this.GetReqApproverConfigurationInitialData();
     this.LoadReqApproverConfigurationList();
  }

  public GetReqApproverConfigurationInitialData() {
    this.masterService.GetReqApproverConfigurationInitialData().subscribe(
      data => { this.GetReqApproverConfigurationInitialDataSucess(data) },
      error => { this.GetReqApproverConfigurationInitialDataError(error) }
    );
  }
  GetReqApproverConfigurationInitialDataSucess(data: any): any {
    this.IntialDDL = data;
    this.SetInLocalStorage();
    this.InitializeData();

  }
  InitializeData(): any {
    if (this.localStorage.get("DDLReqApproverConfigurationDesignation") != null) {
      this.DDLReqApproverConfigurationDesignation = this.localStorage.get("DDLReqApproverConfigurationDesignation");
    
    }
    if (this.localStorage.get("DDLApproverLevel") != null) {
      this.DDLApproverLevel = this.localStorage.get("DDLApproverLevel");
     console.log("DDLApproverLevel",this.DDLApproverLevel);
    }
    if (this.localStorage.get("DDLRequestType") != null) {
      this.DDLRequestType = this.localStorage.get("DDLRequestType");
    }
  }
  SetInLocalStorage(): any {
    this.localStorage.set("DDLReqApproverConfigurationDesignation", this.IntialDDL.DDLReqApproverConfigurationDesignation);
    this.localStorage.set("DDLApproverLevel", this.IntialDDL.DDLApproverLevel);
    this.localStorage.set("DDLRequestType", this.IntialDDL.DDLRequestType);
   
  }

  GetReqApproverConfigurationInitialDataError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }
  GetCommonErrorMessage(error: any | string) {
    console.log(error);
    this.ShowErrorMsg(ErrorMsg.ErrorMessage);
    this.preLoader.show(false);
  }
  public CreateReqApproverConfiguration() {
    this.preLoader.show(true);
     this.ReqApproverConfigValidationUI = new ReqApproverConfigValidationUI();
    this.masterService.CreateReqApproverConfiguration().subscribe(
      data => { this.CreateReqApproverConfigurationSuccess(data) }, error => { this.CreateReqApproverConfigurationError(error) });
  }

  CreateReqApproverConfigurationSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.ReqApproverConfiguration = data;
  console.log("ReqApproverConfiguration",this.ReqApproverConfiguration);
     this.UISelectedReqApproverConfiguration=null;
       this.SetDefaultValues();


    }
    this.preLoader.show(false);
  }

  CreateReqApproverConfigurationError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

  private SetDefaultValues() {
    if (!isNullOrUndefined(this.ReqApproverConfiguration)) {
     
      if (isNullOrUndefined(this.ReqApproverConfiguration.strrequest_type_value))
      this.ReqApproverConfiguration.strrequest_type_value =null;
      if (isNullOrUndefined(this.ReqApproverConfiguration.strApprover_level_value))
      this.ReqApproverConfiguration.strApprover_level_value =null;
      if (isNullOrUndefined(this.ReqApproverConfiguration.lngm_designation_id))
      this.ReqApproverConfiguration.lngm_designation_id = 0;
    }
  }

  public SaveReqApproverConfiguration() {
    if (!(isNullOrUndefined(this.ReqApproverConfiguration))) {
      if (this.IsValidReqApproverConfig()) {
        this.preLoader.show(true);
        this.masterService.SaveRequestApproverConfiguration(this.ReqApproverConfiguration).subscribe(
          data => { this.SaveApproverConfigurationSuccess(data) }, error => { this.SaveApproverConfigurationError(error) });
      }
    }
  }
  SaveApproverConfigurationSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      var result = data;
      this.ReqApproverConfiguration = data;
    

      if (this.ReqApproverConfiguration.ilstErrorMessages == null || this.ReqApproverConfiguration.ilstErrorMessages.length == 0) {
        
          this.ShowSuccessAlert("Save Successfully.");
          this.LoadReqApproverConfigurationList();
          this.CreateReqApproverConfiguration();
    
      }
      else {


        let i = this.ReqApproverConfiguration.ilstErrorMessages.length
        this.ShowErrorAlert(i, this.ReqApproverConfiguration.ilstErrorMessages, 'ER001')
        this.ShowErrorMessageInTab(this.ReqApproverConfiguration.ilstErrorMessages);
      }
    }
    this.preLoader.show(false);
   
  }
  SaveApproverConfigurationError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }


  IsValidReqApproverConfig() {
    let IsValidUser = false;
    if (!isNullOrUndefined(this.ReqApproverConfiguration)) {
      this.ReqApproverConfigValidationUI = new ReqApproverConfigValidationUI();

      let errors = [];
      let errMsg = ""
      let errorCount = 0;
   
      let lrequest_type = this.ReqApproverConfiguration.strrequest_type_value;
      let lApproverLevel = this.ReqApproverConfiguration.strApprover_level_value;
      let Designation = this.ReqApproverConfiguration.lngm_designation_id;
      let WaitingPeriod = this.ReqApproverConfiguration.intwaiting_period;
      let description = this.ReqApproverConfiguration.strdescription;

      if (lrequest_type == "" || isNullOrUndefined(lrequest_type)) {
        errMsg = "Request Type is required"
        this.ReqApproverConfigValidationUI.request_type = true;
        errorCount++;
      }

      if (lApproverLevel == "" || isNullOrUndefined(lApproverLevel)) {
        errMsg = "Approver Level is required"
        this.ReqApproverConfigValidationUI.approval_level = true;
        errorCount++;
      }

      if (Designation == "" || isNullOrUndefined(Designation)) {
        errMsg = "Designation is required"
        this.ReqApproverConfigValidationUI.designation = true;
        errorCount++;
      }
      if (WaitingPeriod == "" || isNullOrUndefined(WaitingPeriod)) {
        errMsg = "Waiting Period Status is required"
        this.ReqApproverConfigValidationUI.waiting_period = true;
        errorCount++;
      }
      if (description == "" || isNullOrUndefined(description)) {
        errMsg = "Description is required"
        this.ReqApproverConfigValidationUI.description = true;
        errorCount++;
      }
      if (errorCount > 0) {
        return false;
      }
      else {
        IsValidUser = true;
        return IsValidUser;
      }
    }
  }

  public ClearReqApproverConfiguration() {

    this.CreateReqApproverConfiguration();
    this.ShowSuccessAlert("Values cleared Successfully.");
    }
  public LoadReqApproverConfigurationList()
  {
    this.ReqApproverConfigValidationUI = new ReqApproverConfigValidationUI();
    this.preLoader.show(true);
    this.masterService.LoadReqApproverConfigurationList().subscribe(
      data=>{this.LoadReqApproverConfigurationSuccess(data)},
     error=>{this.LoadReqApproverConfigurationListError(error)}
    )
  }

  public CancelReqApproverConfiguration() {

    this.CreateReqApproverConfiguration();
  
   this.ShowSuccessAlert("values Cancelled successfully");
    }
  
  private LoadReqApproverConfigurationSuccess(data: any): any {
    if (!isNullOrUndefined(data)) {
      let response = data;
   this.ReqApproverConfigurationList = response;
   console.log("ReqApproverConfigurationList",this.ReqApproverConfigurationList); 
   this.preLoader.show(false);
  }
  }

  private LoadReqApproverConfigurationListError(error: any): any {
    this.dataService.GetCommonErrorMessage(error);
  }

  public DeleteReqApproverConfiguration() {
    if (!isNullOrUndefined(this.UISelectedReqApproverConfiguration)){
    this.preLoader.show(true);
    this.masterService.DeleteReqApproverConfiguration(this.UISelectedReqApproverConfiguration.lngrequest_approver_configuration_id).subscribe(
      data => { this.DeleteReqApproverConfigurationSuccess(data) },
      error => { this.DeleteReqApproverConfigurationError(error) }
    );
  }
  else{
    this.Message="Please select any one Request Approver Configuration Record.";
      this.ShowWarinngAlert(this.Message); 
  }
  }
  
  private DeleteReqApproverConfigurationSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.ReqApproverConfiguration = data;
      this.modalref.close();
      if (this.ReqApproverConfiguration.ilstErrorMessages == null || this.ReqApproverConfiguration.ilstErrorMessages.length == 0) {
        
        var lstdepartDetail = this.ReqApproverConfigurationList;
        var Deptl = this.ReqApproverConfiguration;
        for (var i = 0; i < lstdepartDetail.length; i++) {
            if (lstdepartDetail[i].lngrequest_approver_configuration_id == Deptl.lngrequest_approver_configuration_id) {
              lstdepartDetail.splice(i, 1);
            }
        }
  
        this.ReqApproverConfigurationList = lstdepartDetail;
         //this.CreateDepartmentDetails();
         this.CreateReqApproverConfiguration();
        this.ShowInfoAlert(this.ReqApproverConfiguration.iInfoMessage);
        
      }
      else {
     
       let i = this.ReqApproverConfiguration.ilstErrorMessages.length
       this.ShowErrorAlert(i, this.ReqApproverConfiguration.ilstErrorMessages, 'ER001')
       this.ShowErrorMessageInTab(this.ReqApproverConfiguration.ilstErrorMessages);
        //this.alert.alert(Errmsg);
      }
     
    }
    this.preLoader.show(false);
   // this.modalref.close();
  }
  
  private DeleteReqApproverConfigurationError(error: any) {
    this.dataService.GetCommonErrorMessage(error);
    //this.preload.show(false);
  }

  CloseUserGroupPopUp(){
    this.modalref.close();
  }

  public SelectedReqApproverConfiguration(ReqApprover: any) {
    if (!isNullOrUndefined(ReqApprover)) {
      this.UISelectedReqApproverConfiguration = ReqApprover;
    }

  }

  public LoadReqApproverConfiguration(element:any)
  { this.UISelectedReqApproverConfiguration=element;
    
    if(this.UISelectedReqApproverConfiguration!=null && this.UISelectedReqApproverConfiguration.lngrequest_approver_configuration_id)
    {
    this.LoadReqApproverConfigurationdetails(this.UISelectedReqApproverConfiguration.lngrequest_approver_configuration_id);
    }
    else{
      let listerrormessage=[];
      this.Message="Please select any one Request Approver Configuration Record.";
      this.ShowWarinngAlert(this.Message); 
    }
  }
  public RefreshReqApproverConfiguration() {
    if (!(isNullOrUndefined(this.UISelectedReqApproverConfiguration)) && this.UISelectedReqApproverConfiguration.lngrequest_approver_configuration_id > 0) {
    
      this.LoadReqApproverConfigurationdetails(this.UISelectedReqApproverConfiguration.lngrequest_approver_configuration_id);
    }
    this.Message="Please select any one Request Approver Configuration Record.";
      this.ShowWarinngAlert(this.Message); 
  }
  
  public ConfirmDeleteReqApproverConfiguration(RequestDelete: any) {
    if (!isNullOrUndefined(this.UISelectedReqApproverConfiguration))
     {
     
      this.modalref = this.modalService.open(RequestDelete,{ backdrop: 'static' });
    }
    else{
      this.Message="Please select any one Request Approver Configuration Record.";
      this.ShowWarinngAlert(this.Message); 
    }
  }

public LoadReqApproverConfigurationdetails(ReqApprover)
{
  this.ReqApproverConfigValidationUI=new ReqApproverConfigValidationUI();
  if(this.UISelectedReqApproverConfiguration!=null && this.UISelectedReqApproverConfiguration.lngrequest_approver_configuration_id)
  {

  this.preLoader.show(true);
  this.masterService.LoadReqApproverConfiguration(ReqApprover).subscribe(
    data=>{this.LoadReqApproverConfigurationdetailsSuccess(data)},
   error=>{this.LoadReqApproverConfigurationdetailsError(error)}
  )
}
else{
  this.Message="Please select any one Request Approver Configuration Record.";
    this.ShowWarinngAlert(this.Message); 
}
}
private LoadReqApproverConfigurationdetailsSuccess(data: any): any {
  if (!isNullOrUndefined(data)) {
    let response = data;
  this.ReqApproverConfiguration = response;
  this.UISelectedReqApproverConfiguration=this.ReqApproverConfiguration;
  this.preLoader.show(false);
  this.ShowSuccessAlert("Data selected and opened successfully.");
  
}

}

private LoadReqApproverConfigurationdetailsError(data: any): any {
  this.GetCommonErrorMessage(data);
}
  

  ShowWarinngAlert(Msg: any) {
    this.alert.ShowWarningAlert(true);
    this.alert.SetWarningMsg(Msg);
  }
  
  GetMessageCount() {
    this.errorCount = 0;
    this.infoCount = 0;
    this.warningCount = 0;

    if (!isNullOrUndefined(this.MsgTabList) && this.MsgTabList.length > 0) {
      for (let i = 0; i < this.MsgTabList.length; i++) {

        if (this.MsgTabList[i].Type == 'Error') {
          this.errorCount = this.errorCount + 1;
        }
        else if (this.MsgTabList[i].Type == 'Warn') {
          this.warningCount = this.warningCount + 1;
        }
        else if (this.MsgTabList[i].Type == 'Info') {
          this.infoCount = this.infoCount + 1;
        }
      }

    }
  }
  ShowErrorAlert(ErrorCount: any, ErrorMsg: any, ErrorCode: any) {
    let ErrMsg = '';
    if (ErrorCount > 2) {
      ErrMsg = 'Error has been occured.'
    }
    else {
      ErrMsg = ErrorMsg;
    }

    this.alert.SetErrorCode(ErrorCode);
    this.alert.SetErrorDescription(ErrMsg);
    this.alert.ShowErrorAlert(true);
  }
  ShowErrorMsg(Message: any) {
    this.alert.clearAlert();
    let Msg = { "Status": "true", "Message": Message, "Type": "danger" }
    this.alert.alert(Msg);
  }

  ShowSuccessAlert(Msg: any) {
    this.alert.ShowSuccessAlert(true);
    this.alert.SetSuccessMsg(Msg);
  }

  ShowInfoAlert(Msg: any) {
    this.alert.ShowInfoAlert(true);
    this.alert.SetInfoMsg(Msg);
  }

  ShowWarningAlert(Msg: any) {
    this.alert.ShowWarningAlert(true);
    this.alert.SetWarningMsg(Msg);
  } 

  ShowErrorMessageInTab(ErrorMessage: any) {

    // if (isNullOrUndefined(this.MsgTabList)) {
    this.MsgTabList = [];
    // }
    if (isNullOrUndefined(this.ErrorMsgTab)) {
      this.ErrorMsgTab = new ErrorMsgTab();
    }

    if (!isNullOrUndefined(ErrorMessage) && ErrorMessage.length > 0) {
      for (let i = 0; i < ErrorMessage.length; i++) {
        this.ErrorMsgTab.Date = new Date();
        this.ErrorMsgTab.MessageDescription = ErrorMessage[0];
        this.ErrorMsgTab.Type = 'Error';
        this.MsgTabList.push(this.ErrorMsgTab);
      }

      this.GetMessageCount();
    }

    this.DuplicateMsgTabList = this.MsgTabList;
  }






}


