import { SecurityServiceService } from '../services/common/security-service.service';




export class SecurityRole {

    public static ApplySecurity(SecurityService: SecurityServiceService) {

        setTimeout(() => {
            SecurityService.ApplySecurity();
        }, 0);
    }

    public static TabChange(SecurityService: SecurityServiceService) {
        setTimeout(() => {
            SecurityService.ApplySecurity();
        }, 0);
    }
}