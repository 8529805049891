import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AesService {

  constructor() { }

  public EncryptText(text, token: any): string {
    //text = text.trim();
    if (token != null && token.length >= 2) {
      var Key = CryptoJS.enc.Utf8.parse(token[0]);
      var IV = CryptoJS.enc.Utf8.parse(token[1]);
      var encryptedText = CryptoJS.AES.encrypt(text, Key, { iv: IV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
      return encryptedText.toString();
    }
    return text;
  }
}
