import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(public http: HttpClient) { }
  //**************************************************Job Title************************************ */
  public GetJobTitleSearch() {
    return this.http.get(AppConstants.BasePath + 'GetJobTitleSearch').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SearchJobTitle(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SearchJobTitle', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetMasterInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetMasterInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public OpenJobTitle(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenJobTitle', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public CreateNewJobTitle() {
    return this.http.get(AppConstants.BasePath + 'CreateNewJobTitle').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveJobTitle(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveJobTitle', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteJobTitle(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteJobTitle', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetMasterDataForJobTitleAndDesignationAndLevel(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetMasterDataForJobTitleAndDesignationAndLevel', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  


  //************************************************** Document ************************************ */
public GetDocumentSearch() {
  return this.http.get(AppConstants.BasePath + 'GetDocumentSearch').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public SearchDocument(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SearchDocument', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public GetDocumentDetailInitialData() {
  return this.http.get(AppConstants.BasePath + 'GetDocumentDetailInitialData').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public OpenDocument(data: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenDocument', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public CreateNewDocument() {
  return this.http.get(AppConstants.BasePath + 'CreateNewDocument').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public SaveDocument(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SaveDocument', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public DeleteDocument(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteDocument', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

  //************************************************** Training Type ************************************ */


public GetTrainingTypeSearch() {
  return this.http.get(AppConstants.BasePath + 'GetTrainingTypeSearch').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public SearchTrainingType(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SearchTrainingType', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public GetTrainingTypeDetailInitialData() {
  return this.http.get(AppConstants.BasePath + 'GetTrainingTypeDetailInitialData').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public OpenTrainingType(data: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenTrainingType', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public CreateNewTrainingType() {
  return this.http.get(AppConstants.BasePath + 'CreateNewTrainingType').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public SaveTrainingType(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SaveTrainingType', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
LoadTrainingList(): any {
  return this.http.get(AppConstants.BasePath+'LoadTrainingList').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}

public DeleteTrainingType(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteTrainingType', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

DeleteTraining(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteTraining',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}
OpenTraining(id: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenTraining',id).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}
SaveTraining(data: any): any {
  return this.http.post(AppConstants.BasePath+'SaveTraining',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
CreateTrainingDetails(): any {
  return this.http.get(AppConstants.BasePath+'CreateTrainingDetails').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}


  //************************************************** Break Type ************************************ */

public GetBreakTypeSearch() {
  return this.http.get(AppConstants.BasePath + 'GetBreakTypeSearch').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public SearchBreakType(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SearchBreakType', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public GetBreakTypeDetailInitialData() {
  return this.http.get(AppConstants.BasePath + 'GetBreakTypeDetailInitialData').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public OpenBreakType(data: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenBreakType', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public CreateNewBreakType() {
  return this.http.get(AppConstants.BasePath + 'CreateNewBreakType').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public SaveBreakType(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SaveBreakType', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public DeleteBreakType(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteBreakType', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}


LoadBreakTypeTypeList(): any {
  return this.http.get(AppConstants.BasePath+'LoadBreakTypeTypeList').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
//////////////////////////////////Approval Level ////////////////////////////////////////




public GetApprovalLevelInitialdata() {
  return this.http.get(AppConstants.BasePath + 'GetApprovalLevelInitialdata').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
LoadNewApprovalConfiguration() {
  return this.http.get(AppConstants.BasePath + 'LoadNewApprovalConfiguration').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler)
  );
}
SaveApprovalConfiguration(WorkFlowConfiguration) {
  return this.http.post(AppConstants.BasePath + 'SaveApprovalConfiguration', WorkFlowConfiguration).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler)
  );
}






public DeleteWorkFlowConfigurationList(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteWorkFlowConfigurationList', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}





   //**************************************************MDesignation************************************ */
   public GetMDesignationSearch() {
    return this.http.get(AppConstants.BasePath + 'GetMDesignationSearch').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SearchMDesignation(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SearchMDesignation', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetMDesignationDetailInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetMDesignationDetailInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public OpenMDesignation(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenMDesignation', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public CreateNewMDesignation() {
    return this.http.get(AppConstants.BasePath + 'CreateNewMDesignation').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveMDesignation(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveMDesignation', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteMDesignation(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteMDesignation', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }






   //**************************************************MDesignationLevel************************************ */
   public GetMDesignationLevelSearch() {
    return this.http.get(AppConstants.BasePath + 'GetMDesignationLevelSearch').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SearchMDesignationLevel(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SearchMDesignationLevel', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetMDesignationLevelDetailInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetMDesignationLevelDetailInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public OpenMDesignationLevel(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenMDesignationLevel', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public CreateNewMDesignationLevel() {
    return this.http.get(AppConstants.BasePath + 'CreateNewMDesignationLevel').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveMDesignationLevel(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveMDesignationLevel', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteMDesignationLevel(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteMDesignationLevel', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


//////////////////////////////////////////////Department\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

DeleteDepartmentTeamDetails(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteDepartmentTeamDetails',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}
OpenDepartmentTeamDetails(id: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenDepartmentTeamDetails',id).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}
SaveDepartmentTeamDetails(data: any): any {
  return this.http.post(AppConstants.BasePath+'SaveDepartmentTeamDetails',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
CreateDepartmentTeamDetails(): any {
  return this.http.get(AppConstants.BasePath+'CreateDepartmentTeamDetails').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
DeleteDepartmentDetails(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteDepartmentDetails',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}
LoadDepartmentList(): any {
  return this.http.get(AppConstants.BasePath+'LoadDepartmentList').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
LoadDepartmenteDetails(id: any): any {
  return this.http.post(AppConstants.BasePath + 'LoadDepartmenteDetails',id).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}
SearchDepartment(data: any): any {
  return this.http.post(AppConstants.BasePath+'SearchDepartment',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}
SaveDepartmentDetails(data: any): any {
  return this.http.post(AppConstants.BasePath+'SaveDepartmentDetails',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
CreateDepartmentDetails(): any {
  return this.http.get(AppConstants.BasePath+'CreateDepartmentDetails').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
GetDepartmentInitialData(): any {
  return this.http.get(AppConstants.BasePath+'GetDepartmentInitialData').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
GetDepartmentSearch(): any {
  return this.http.get(AppConstants.BasePath+'GetDepartmentSearch').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
  }


//////////////////////////////////////////////Benefit\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\


  public GetMBenefitSearch() {
    return this.http.get(AppConstants.BasePath + 'GetMBenefitSearch').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SearchMBenefit(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SearchMBenefit', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetMBenefitDetailInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetMBenefitDetailInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public OpenMBenefit(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenMBenefit', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public CreateNewMBenefit() {
    return this.http.get(AppConstants.BasePath + 'CreateNewMBenefit').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveMBenefit(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveMBenefit', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteMBenefit(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteMBenefit', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetMasterDataBenefits(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetMasterDataBenefitsAndDeductions', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  

//******************************************* MDeduction ****************************************************************** */
  

  public OpenMDeduction(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenMDeduction', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public CreateNewMDeduction() {
    return this.http.get(AppConstants.BasePath + 'CreateNewMDeduction').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveMDeduction(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveMDeduction', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteMDeduction(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteMDeduction', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

////////////////////////////////////////////////////////////////////////////////////////////////////////



//////////////////////////////////////////////MEducationLevel\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\



public OpenMEducationLevel(data: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenMEducationLevel', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public CreateNewMEducationLevel() {
  return this.http.get(AppConstants.BasePath + 'CreateNewMEducationLevel').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public SaveMEducationLevel(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SaveMEducationLevel', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public DeleteMEducationLevel(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteMEducationLevel', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public GetMasterDataForEducationLevelAndlDocument(data: any): any {
  return this.http.post(AppConstants.BasePath + 'GetMasterDataForEducationLevelAndlDocument', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

// public GetMasterOthersInitialData() {
//   return this.http.get(AppConstants.BasePath + 'GetMasterOthersInitialData').pipe(
//     map(this.SuccessHandler), catchError(this.ErrorHandler));
// }


//******************************************* MDocument ****************************************************************** */


public OpenMDocument(data: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenMDocument', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public CreateNewMDocument() {
  return this.http.get(AppConstants.BasePath + 'CreateNewMDocument').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public SaveMDocument(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SaveMDocument', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public DeleteMDocument(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteMDocument', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}



//******************************************* MLeaveType ****************************************************************** */


public OpenMLeaveType(data: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenMLeaveType', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public CreateNewMLeaveType() {
  return this.http.get(AppConstants.BasePath + 'CreateNewMLeaveType').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public SaveMLeaveType(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SaveMLeaveType', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public DeleteMLeaveType(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteMLeaveType', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
  
public GetMasterDataLeaveDetail(data: any): any {
  return this.http.post(AppConstants.BasePath + 'GetMasterDataLeaveDetail', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}


//******************************************* PublicHolidays ****************************************************************** */

public OpenMPublicHolidays(data: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenMPublicHolidays', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public CreateNewMPublicHolidays() {
  return this.http.get(AppConstants.BasePath + 'CreateNewMPublicHolidays').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public SaveMPublicHolidays(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SaveMPublicHolidays', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public DeleteMPublicHolidays(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteMPublicHolidays', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public GeMPublicHolidaysInitialData() {
  return this.http.get(AppConstants.BasePath + 'GeMPublicHolidaysInitialData').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public LoadbusMPublicHolidays(data:any) {
  return this.http.post(AppConstants.BasePath + 'LoadbusMPublicHolidays',data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

  





  ////////////////////////////////////////////////////Attendancetype///////////////////////


public AttendanceTimeTypeInitialData() {
  return this.http.get(AppConstants.BasePath + 'AttendanceTimeTypeInitialData').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}

  public CreateNewAttendanceTimeType() {
    return this.http.get(AppConstants.BasePath + 'createNewAttendanceTimeType').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  
  }
  public saveAttendanceTimeType(data: any): any {
    return this.http.post(AppConstants.BasePath + 'saveAttendanceTimeType', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  LoadAttendanceTimeTypeList(): any {
    return this.http.get(AppConstants.BasePath+'LoadAttendanceTimeTypeList').pipe(
      map(this.SuccessHandler),catchError(this.ErrorHandler)
    );
  }
  
  public DeleteAttendanceTimeType(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteAttendanceTimeType', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  OpenAttendanceTimeType(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenAttendanceTimeType',data).pipe(
      map(this.SuccessHandler),catchError(this.ErrorHandler));
  }


///////////////////////////////////////// ShiftConfig ///////////////////////////////////////////////////////////////


public GetShiftConfigDetailInitialData() {
  return this.http.get(AppConstants.BasePath + 'GetShiftConifgInitialData').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public OpenShiftConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenShiftConfig', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public CreateNewShiftConfig() {
  return this.http.get(AppConstants.BasePath + 'CreateNewShiftConfig').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}
public SaveShiftConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SaveShiftConfig', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public DeleteShiftConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteShiftConfig', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public LoadShiftConfig() {
  return this.http.get(AppConstants.BasePath + 'LoadShiftConfig').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));

}

public SaveShiftWeekDayConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SaveShiftWeekDayConfig', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}



public OpenBreakTimeConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenBreakTimeConfig', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public SaveBreakTimeConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SaveBreakTimeConfig', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public OpenShiftWeekDayConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenShiftWeekDayConfig', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}


public CreateNewBreakTimeConfig() {
  return this.http.get(AppConstants.BasePath + 'CreateNewBreakTimeConfig').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}


public SaveFlexTimeConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SaveShiftFlexTimeConfig', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public OpenShiftWeekDayConfigForFlexTime(data: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenShiftWeekDayConfigForFlexTime', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}


public CreateNewShiftDayTimeConfig() {
  return this.http.get(AppConstants.BasePath + 'CreateNewShiftDayTimeConfig').pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public DeleteFlexTimeAndOverTime(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteFlexTimeAndOverTime', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public SaveOverTimeConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'SaveShiftOverTimeConfig', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

public OpenShiftWeekDayConfigForOverTime(data: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenShiftWeekDayConfigForOverTime', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
public DeleteBreakTimeConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteBreakTimeConfig', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

//////////////////////////////////////////Request Approver Configuration//////////////////////////////////////////////////////////////

DeleteReqApproverConfiguration(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteReqApproverConfiguration',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}
OpenReqApprovalConfiguration(id: any): any {
  return this.http.post(AppConstants.BasePath + 'OpenReqApprovalConfiguration',id).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}
SaveRequestApproverConfiguration(data: any): any {
  return this.http.post(AppConstants.BasePath+'SaveRequestApproverConfiguration',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
CreateReqApproverConfiguration(): any {
  return this.http.get(AppConstants.BasePath+'CreateReqApproverConfiguration').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}

LoadReqApproverConfigurationList(): any {
  return this.http.get(AppConstants.BasePath+'LoadReqApproverConfigurationList').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
LoadReqApproverConfiguration(id: any): any {
  return this.http.post(AppConstants.BasePath + 'LoadReqApproverConfiguration',id).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}


GetReqApproverConfigurationInitialData(): any {
  return this.http.get(AppConstants.BasePath+'GetReqApproverConfigurationInitialData').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////Request TYpe//////////////////////////////////////////////////////////////

DeleteRequestType(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteRequestType',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}

SaveRequestType(data: any): any {
  return this.http.post(AppConstants.BasePath+'SaveRequestType',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
CreateRequestType(): any {
  return this.http.get(AppConstants.BasePath+'CreateRequestType').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}

LoadRequestTypeList(): any {
  return this.http.get(AppConstants.BasePath+'LoadRequestTypeList').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
LoadbusRequestType(id: any): any {
  return this.http.post(AppConstants.BasePath + 'LoadbusRequestType',id).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}


////////////////////////////////////////////




//////////////////////////////////////////Employee Shift Config//////////////////////////////////////////////////////////////
GetEmployeeShiftConfigInitialData(): any {
  return this.http.get(AppConstants.BasePath+'GetEmployeeShiftConfigInitialData').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}

SaveEmployeeShiftConfig(data: any): any {
  return this.http.post(AppConstants.BasePath+'SaveEmployeeShiftConfig',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
SelectedEmployeeList(data: any): any {
  return this.http.post(AppConstants.BasePath+'SelectedEmployeeList',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}



LoadshiftconfigChilds(data: any): any {
  return this.http.post(AppConstants.BasePath+'LoadshiftconfigChilds',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
CreateEmployeeShiftConfig(): any {
  return this.http.get(AppConstants.BasePath+'CreateNewShiftConfig').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}

LoadEmployeeShiftConfigList(): any {
  return this.http.get(AppConstants.BasePath+'LoadEmployeeShiftConfigList').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
LoadEmployeeShiftConfig(id: any): any {
  return this.http.post(AppConstants.BasePath + 'LoadEmployeeShiftConfig',id).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}

DeleteEmployeeShiftConfig(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteEmployeeShiftConfig',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}


public LoadEmployeeList(deptId: any) {
  return this.http.post(AppConstants.BasePath +'LoadEmployeeList',deptId).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}


public GetDepartBasedOnTeam(deptId: any) {
  return this.http.post(AppConstants.BasePath + 'GetDepartBasedOnTeam',deptId).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}


DeleteApprovalHierarchyConfigDetails(data: any): any {
  return this.http.post(AppConstants.BasePath + 'DeleteApprovalHierarchyConfigDetails',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}
LoadApprovalHierarchyConfigList(): any {
  return this.http.get(AppConstants.BasePath+'LoadApprovalHierarchyConfigList').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
LoadApprovalHierarchyConfigDetails(id: any): any {
  return this.http.post(AppConstants.BasePath + 'LoadApprovalHierarchyConfigDetails',id).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler));
}

SaveApprovalHierarchyConfigDetails(data: any): any {
  return this.http.post(AppConstants.BasePath+'SaveApprovalHierarchyConfigDetails',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}


LoadRequestApproverConfiguration(data: any): any {
  return this.http.post(AppConstants.BasePath+'LoadApprovalHierarchyConfig',data).pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}


CreateApprovalHierarchyConfigDetails(): any {
  return this.http.get(AppConstants.BasePath+'CreateApprovalHierarchyConfigDetails').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}
GetApprovalHierarchyConfigInitialData(): any {
  return this.http.get(AppConstants.BasePath+'GetApprovalHierarchyConfigInitialData').pipe(
    map(this.SuccessHandler),catchError(this.ErrorHandler)
  );
}

////////////////////////////////////////////
  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }
  private ErrorHandler(error: Response | any) {
    let errorMSg: string;
    if (error instanceof Response) {
      const err = error || '';
      errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errorMSg = error.message ? error.message : error.toString();
    }
    return _throw(error);
  }
}
