import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map,catchError} from 'rxjs/operators';
import { AppConstants } from '../../common/common';
import { _throw } from 'rxjs/observable/throw'


@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  UserName: any;

  constructor(public http: HttpClient) {

  }


  public GetToken() {
    console.log('GetToken Service Call Begins')
    return this.http.get(AppConstants.BasePath + 'getToken').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

 public ActiveUser (username) {
    return this.http.post(AppConstants.BasePath + 'ActiveUser', username)
    .pipe(
      map(this.SuccessHandler), 
      catchError(this.ErrorHandler)
    );
}

public SetPasswordLinkGetByEmailID (username) {
  return this.http.post(AppConstants.BasePath +'SetPasswordLinkGetByEmailID',username)
  .pipe(
    map(this.SuccessHandler), 
    catchError(this.ErrorHandler)
  );
}

  public AuthenticateUser(LoggedinUser) {
    return this.http.post(AppConstants.BasePath + 'authenticateUser', LoggedinUser)
    .pipe(
      map(this.SuccessHandler), 
      catchError(this.ErrorHandler)
    );
  }

  
  public GetUserNameByEmployeeID(forgotuserid:any) {
    return this.http.post(AppConstants.BasePath + 'GetUserNameByEmployeeID', forgotuserid)
    .pipe(
      map(this.SuccessHandler), 
      catchError(this.ErrorHandler)
    );
  }

  
  public SaveResetUserPassword(Resetpass:any) {
    return this.http.post(AppConstants.BasePath + 'SaveResetUserPassword', Resetpass)
    .pipe(
      map(this.SuccessHandler), 
      catchError(this.ErrorHandler)
    );
  }


  public CreateNewRegistration() {
    
    return this.http.get(AppConstants.BasePath + 'CreateNewRegistration').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SubmitRegistration(data:any) {
    return this.http.post(AppConstants.BasePath + 'SubmitRegistration', data)
    .pipe(
      map(this.SuccessHandler), 
      catchError(this.ErrorHandler)
    );
  }


  public CheckUserInfo() {
   
    return this.http.get(AppConstants.BasePath + 'CheckSession').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetIntervalSessionTime() {
   
    return this.http.get(AppConstants.BasePath + 'GetIntervalSessionTime').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }




  private SuccessHandler(response: Response) {
    let data = response;
    return data || {};
  }

  private ErrorHandler(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || '';
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return _throw(error);
    }


}
