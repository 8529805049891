import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(public http: HttpClient) { }

  public GetRoleDetailInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetRoleDetailsInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetRoleSearch() {
    return this.http.get(AppConstants.BasePath + 'getRoleSearchObject').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public searchRoles(RoleSearch: any): any {
    return this.http.post(AppConstants.BasePath + 'searchRoles', RoleSearch).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public CreateNewRole(): any {
    return this.http.get(AppConstants.BasePath + 'createNewRole').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  // public openSelectedRole(Roleid: number): any {
  //   return this.http.post(AppConstants.BasePath + 'openSelectedRole', Roleid).pipe(
  //     map(this.SuccessHandler), catchError(this.ErrorHandler));
  // }


  public OpenRole(Roleid: number): any {
    return this.http.post(AppConstants.BasePath + 'openSelectedRole', Roleid).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenRoleResource(Roleid: number): any {
    return this.http.post(AppConstants.BasePath + 'openSelectedRole', Roleid).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }



  public SaveRole(RoleUI: any): any {
    return this.http.post(AppConstants.BasePath + 'saveRole', RoleUI).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadSelectedRoleResource(selecteScreenRole: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadSelectedRoleResource', selecteScreenRole).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public saveRoleResource(RoleUI: any): any {
    return this.http.post(AppConstants.BasePath + 'saveRoleResource', RoleUI).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetScreensbyModule(ModuleValue: any): any {
    return this.http.post(AppConstants.BasePath + 'GetScreensbyModule', ModuleValue).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteRole(RoleId: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteRole', RoleId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }
  private ErrorHandler(error: Response | any) {
    let errorMSg: string;
    if (error instanceof Response) {
      const err = error || '';
      errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
    } 
    else {
      errorMSg = error.message ? error.message : error.toString();
    }
    return _throw(error);
  }
}
