import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './view/common/header/header.component';
import { AlertComponent } from './view/common/alert/alert.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AlertService } from './services/common/alert.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { Pagination, SearchResultCount, IsLoggedIn, FormatDate, FileFilter, TotalWorkingHrs, UnPaidLeave } from './common/common';
import { DatePipe } from '@angular/common';
import { DateCheck } from './common/dateCheck';
import { NgbDateCustomParserFormatter } from './common/datepicker';
import { AngularWebStorageModule } from 'angular-web-storage';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { LayoutComponent } from './view/common/layout/layout.component';
import { TitleServiceService } from './services/common/title-service.service';
import { PreloaderComponent } from './view/common/preloader/preloader.component';
import { PreloadService } from './services/common/preload.service';
import {
  ResetPasswordUI, UserValidationUI,
  UserGroupValidationUI, UserBranchValidationUI, UserDepartValidationUI,
  UserAdditonalValidationUI, RoleValidationUI, GroupValidationUI, GroupRoleValidationUI,
  ResourceValidationUI, ConfigValidationUI, ConfigValueValidationUI, ConfigMetaValidationUI,
  MassageValidationUI,
  CompanyBranchValidationUI,
  EmployeeJobInformationUIValidationUI,
  EmployeeBankValidationUI,
  EmployeeAddressValidationUI,
  EmployeeEducationValidationUI,
  EmployeeTrainingValidationUI,
  EmployeeEmploymentHistoryValidationUI,
  EmployeeDocumentValidationUI,
  AttendanceValidationUI,
  AdjustmentRequestApprovalUI,
  MasterValidationUI,
  RequesttypeUI,
  EmployeeShiftConfigValidationUI,
  ReqApproverConfigValidationUI,
  AttendancemontylwiseValidationUI,
  LeaveRequestUI,
  AttendancePolicyValidationUI,
  LeaveRequestMonthwiseValidationUI,
  LRAdjustmentValidationUI,
  CompanyRegistrationUI,
  CompanyValidationUI,
  TrainingValidationUI,
  PayrollBatchValidationUI
} from './common/validationUI';
import { DataService } from './services/common/data.service';
import { GrdFilterPipe } from './services/common/grd-filter.pipe';
import { ResetpasswordComponent } from './view/common/resetpassword/resetpassword.component';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { AttendanceComponent } from './view/application/attendance/attendance/attendance.component';
import { RequestApproverConfigurationComponent } from './view/master/request-approver-configuration/request-approver-configuration.component';
import { RequesttypeComponent } from './view/master/requesttype/requesttype.component';
import { EmployeeShiftConfigComponent } from './view/master/employee/employee-shift-config/employee-shift-config.component';
import { AttendanceAdjustRequestComponent } from './view/application/attendance/attendance-adjust-request/attendance-adjust-request.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
(window as any).$ = (window as any).jQuery = jQuery;
import * as jQuery from 'jquery';
//import { CanDeactivateGuardService } from './common/can-deactivate-guard.service';
import { AttAdjustReqListEmpComponent } from './view/application/attendance/att-adjust-req-team/att-adjust-req-emp.component';
//import { DeactiveGuardForTeam } from './common/deactive.guard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { ToggleClassDirective } from './common/toggle-class.directive';
import { MatRippleModule } from '@angular/material/core';
import { FormInputModule } from './form-input/form-input.module';
import { AttendanceTeamComponent } from './view/application/attendance/attendance-team/attendance-team.component';
import { SharedModule } from './Shared/Shared.module';
import { MatCardModule, MatTooltipModule } from '@angular/material';
//import { DataService } from './services/common/data.service';
import { HttpRequestInterceptor } from './common/HttpRequestInterceptor';


import { LoginComponent } from 'src/app/view/common/login/login.component';
import { ConfigMenuComponent } from './view/common/config-menu/config-menu.component';
import { CommonAlertModule } from './view/common/common-alert/common-alert.module';
import { HolidayFilterPipe } from './view/application/attendance/holiday-filter.pipe';


@NgModule({
  declarations: [
   LoginComponent,
    AppComponent,
    HeaderComponent,
    GrdFilterPipe,
    PreloaderComponent,
    AlertComponent,
    LayoutComponent,
     ResetpasswordComponent,
    AttendanceComponent,
    RequestApproverConfigurationComponent,
    RequesttypeComponent,
    EmployeeShiftConfigComponent,
    AttendanceAdjustRequestComponent,
    AttendanceAdjustRequestComponent,
    AttAdjustReqListEmpComponent ,
    ToggleClassDirective,
    AttendanceTeamComponent,
    ConfigMenuComponent,
    HolidayFilterPipe,
    
      ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    FormInputModule,
    NgbModule,
    HttpClientModule,
    AngularWebStorageModule,
    AmazingTimePickerModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DragDropModule,
    MatSelectModule,
    MatMenuModule,
    MatRippleModule,
    MatTooltipModule,
    MatCardModule,
    CommonAlertModule,

    SharedModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    //ChartsModule
  ],
  providers: [
    CompanyRegistrationUI,
    TitleServiceService, AlertService, PreloadService, Pagination, SearchResultCount,
    IsLoggedIn, DatePipe,
    FormatDate, FileFilter, DateCheck, ResetPasswordUI, UserValidationUI,
    UserGroupValidationUI, CompanyValidationUI,
    UserBranchValidationUI, UserDepartValidationUI, UserAdditonalValidationUI,
    RoleValidationUI, GroupValidationUI,
    GroupRoleValidationUI, ResourceValidationUI, ConfigValidationUI, ConfigValueValidationUI,
    ConfigMetaValidationUI, CompanyBranchValidationUI,
    MassageValidationUI, DataService, GrdFilterPipe, EmployeeJobInformationUIValidationUI,
    EmployeeBankValidationUI, EmployeeAddressValidationUI,
    EmployeeEducationValidationUI, EmployeeTrainingValidationUI,
     EmployeeEmploymentHistoryValidationUI, EmployeeDocumentValidationUI,
    MasterValidationUI, AdjustmentRequestApprovalUI, RequesttypeUI, TrainingValidationUI,
    AttendancemontylwiseValidationUI, LeaveRequestMonthwiseValidationUI,
    AttendanceValidationUI, EmployeeShiftConfigValidationUI,
    ReqApproverConfigValidationUI, LeaveRequestUI, AttendancePolicyValidationUI,
     TotalWorkingHrs,PayrollBatchValidationUI,
    , LRAdjustmentValidationUI,UnPaidLeave,

    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    {
      provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
