import { isUndefined } from 'util';
import { LocalStorageService } from 'angular-web-storage';

export class CommonFilter {


    //File Format Filter
    public FileFormatFilter(file: File): boolean {
        let LocalStorage = new LocalStorageService();

        if (!isUndefined(file) && file != null) {
            let FileName = file.name;
            let fileSplit = FileName.split('.');
            let fileExtension = fileSplit[fileSplit.length - 1];
            let allowedFormats = LocalStorage.get('attachmentTypes');
            if (allowedFormats.search(fileExtension) == -1) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            console.log('File undefined on File Formatter');
        }

    }


    //File Size Filter
    public FileSizeFilter(file: File): boolean {
        let LocalStorage = new LocalStorageService();
        let maxSize: number;
        if (!isUndefined(file) && file != null) {
            maxSize = parseInt(LocalStorage.get('attachmentSize'));
            let fileSize = (file.size) / (1000 * 1000);  //Converting Size from Bytes to Megabytes(MB)
            if (fileSize < maxSize) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            console.log('File Type is undefined..');
            return false;
        }
    }
}