import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TitleServiceService } from 'src/app/services/common/title-service.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
import { Router } from '@angular/router';
import { Pagination, SearchResultCount, FormatDate, ErrorMsg } from 'src/app/common/common';
import {DataService} from 'src/app/services/common/data.service';
import { IsLoggedIn } from 'src/app/common/common';
import { isNullOrUndefined, format } from 'util';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PreloadService } from 'src/app/services/common/preload.service';
import { MasterValidationUI,RequesttypeUI} from 'src/app/common/validationUI';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { ErrorMsgTab } from 'src/app/common/common';
import { MasterService } from 'src/app/services/master/master.service';


@Component({
  selector: 'app-requesttype',
  templateUrl: './requesttype.component.html',
  styleUrls: ['./requesttype.component.scss']
})
export class RequesttypeComponent implements OnInit {

  IntialDDL: any;
  errorCount: number;
  infoCount: number;
  warningCount: number;
  MsgTabList: any;
 Requesttype: any;
  response: any;
 RequesttypeList: any;
  Flag: boolean;
  RequesttypeUI: RequesttypeUI;
  ErrorMsgTab: ErrorMsgTab;
  DuplicateMsgTabList: any;
  UISelectedRequesttype: any;
  Message: string;
  modalref: any;
  DDLRequesttypeDesignation:any;
  DDLRequesttypeWaitingPeriod:any;
  constructor(
    private title: TitleServiceService,
    private alert: AlertService,
    private preLoader: PreloadService,
    private dataService: DataService,
    public localStorage: LocalStorageService,
    private router: Router,
    private pagination: Pagination,
    private updateResultCount: SearchResultCount,
    public dateFormat: FormatDate,
    private dateParseFormatter: NgbDateParserFormatter,
    public SessionStorage: SessionStorageService,
    private isLoggedIn: IsLoggedIn,
    public masterService: MasterService,
    private modalService: NgbModal,
    private atp: AmazingTimePickerService
  ) { }


  ngOnInit() {
    this.title.setTitle('Configuration');
    this.title.setTitleforPdf('RequestType');
    this.title.setshowpdf(true);
    this.RequesttypeUI = new RequesttypeUI();
    this.CreateRequesttype();
    this.LoadRequesttypeList();
  }

  
  GetCommonErrorMessage(error: any | string) {
    console.log(error);
    this.ShowErrorMsg(ErrorMsg.ErrorMessage);
    this.preLoader.show(false);
  }
  public CreateRequesttype() {
    this.preLoader.show(true);
     this.RequesttypeUI = new RequesttypeUI();
    this.masterService.CreateRequestType().subscribe(
      data => { this.CreateRequesttypeSuccess(data) }, error => { this.CreateRequesttypeError(error) });
  }

  CreateRequesttypeSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.Requesttype = data;
      this.UISelectedRequesttype=null;
     
    }
    this.preLoader.show(false);
  }

  CreateRequesttypeError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

 

  public SaveRequesttype() {
    if (!(isNullOrUndefined(this.Requesttype))) {
      if (this.IsValidReqTypeConfig()) {
        this.preLoader.show(true);
        this.masterService.SaveRequestType(this.Requesttype).subscribe(
          data => { this.SaveTypeConfigurationSuccess(data) }, error => { this.SaveTypeConfigurationError(error) });
      }
    }
  }
  SaveTypeConfigurationSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      var result = data;
      this.Requesttype = data;
      if (this.Requesttype.ilstErrorMessages == null || this.Requesttype.ilstErrorMessages.length == 0) {
        if (result != undefined && result != null) {
          var UpdateMode = false;
          if (this.RequesttypeList == null || this.RequesttypeList.length == 0) {
            this.RequesttypeList = [];
          }
          for (var i = 0; i < this.RequesttypeList.length; i++) {
            if (this.RequesttypeList[i].lngrequest_type_id == result.lngrequest_type_id) {
              UpdateMode = true;
              this.RequesttypeList[i] = result;
              console.log("RequesttypeList",this.RequesttypeList);
              this.Flag = true;
            }
          }
        }
        if (UpdateMode == false && result.ilstErrorMessages.length == 0) {
          this.RequesttypeList.push(data);
          this.Flag = true;
        }
      }
      if (this.Requesttype.ilstErrorMessages == null || this.Requesttype.ilstErrorMessages.length == 0) {
         this.CreateRequesttype();
        this.ShowSuccessAlert(this.Requesttype.iInfoMessage);
    
      }
      else {


        let i = this.Requesttype.ilstErrorMessages.length
        this.ShowErrorAlert(i, this.Requesttype.ilstErrorMessages, 'ER001')
        this.ShowErrorMessageInTab(this.Requesttype.ilstErrorMessages);
      }
    }
    this.preLoader.show(false);
   
  }
  SaveTypeConfigurationError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }


  IsValidReqTypeConfig() {
    let IsValidUser = false;
    if (!isNullOrUndefined(this.Requesttype)) {
      this.RequesttypeUI = new RequesttypeUI();

      let errors = [];
      let errMsg = ""
      let errorCount = 0;
   
      
      let RequestTypeName = this.Requesttype.strreqeust_type_name;
      let Description = this.Requesttype.strdescription;
      if (RequestTypeName == "" || isNullOrUndefined(RequestTypeName)) {
        errMsg = "Request Type Name is required"
        this.RequesttypeUI.reqeust_type_name = true;
        errorCount++;
      }

    
      if (Description == "" || isNullOrUndefined(Description)) {
        errMsg = "description is required"
        this.RequesttypeUI.description = true;
        errorCount++;
      }
    
      if (errorCount > 0) {
        return false;
      }
      else {
        IsValidUser = true;
        return IsValidUser;
      }
    }
  }

  public ClearRequesttype() {

    
    this.CreateRequesttype()
  
   this.ShowSuccessAlert("Values cleared Successfully.");
    }
  public LoadRequesttypeList()
  {
    this.preLoader.show(true);
    this.masterService.LoadRequestTypeList().subscribe(
      data=>{this.LoadRequesttypeSuccess(data)},
     error=>{this.LoadRequesttypeListError(error)}
    )
  }

  public CancelRequesttype() {

    this.CreateRequesttype();
  
   this.ShowSuccessAlert("Values cancelled Successfully.");
    }
  
  private LoadRequesttypeSuccess(data: any): any {
    if (!isNullOrUndefined(data)) {
    let response = data;
   this.RequesttypeList = response;
   this.preLoader.show(false);
  }
  }

  private LoadRequesttypeListError(error: any): any {
    this.dataService.GetCommonErrorMessage(error);
  }

  public DeleteRequesttype() {
    if (!isNullOrUndefined(this.UISelectedRequesttype)){
       this.RequesttypeUI = new RequesttypeUI();
       this.preLoader.show(true);
       this.masterService.DeleteRequestType(this.UISelectedRequesttype.lngrequest_type_id).subscribe(
      data => { this.DeleteRequesttypeSuccess(data) },
      error => { this.DeleteRequesttypeError(error) }
    );
  }
  else{
    this.Message="Please select any one Request Type Record.";
      this.ShowWarinngAlert(this.Message); 
  }
  }
  
  private DeleteRequesttypeSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.Requesttype = data;
      this.modalref.close();
      if (this.Requesttype.ilstErrorMessages == null || this.Requesttype.ilstErrorMessages.length == 0) {
        
        var lstdepartDetail = this.RequesttypeList;
        var Deptl = this.Requesttype;
        for (var i = 0; i < lstdepartDetail.length; i++) {
            if (lstdepartDetail[i].lngrequest_type_id == Deptl.lngrequest_type_id) {
              lstdepartDetail.splice(i, 1);
            }
        }
  
        this.RequesttypeList = lstdepartDetail;
         //this.CreateDepartmentDetails();
         this.CreateRequesttype();
        this.ShowInfoAlert(this.Requesttype.iInfoMessage);
        
      }
      else {
     
       let i = this.Requesttype.ilstErrorMessages.length
       this.ShowErrorAlert(i, this.Requesttype.ilstErrorMessages, 'ER001')
       this.ShowErrorMessageInTab(this.Requesttype.ilstErrorMessages);
        //this.alert.alert(Errmsg);
      }
     
    }
    this.preLoader.show(false);
   // this.modalref.close();
  }
  
  private DeleteRequesttypeError(error: any) {
    this.dataService.GetCommonErrorMessage(error);
    //this.preload.show(false);
  }

  CloseUserGroupPopUp(){
    this.modalref.close();
  }

  public SelectedRequesttype(ReqType: any) {
    if (!isNullOrUndefined(ReqType)) {
      this.UISelectedRequesttype = ReqType;
    }

  }

  public LoadRequesttype(element:any)
  { this.UISelectedRequesttype=element;
    this.RequesttypeUI = new RequesttypeUI();
    if(this.UISelectedRequesttype!=null && this.UISelectedRequesttype.lngrequest_type_id)
    {
    this.LoadRequesttypedetails(this.UISelectedRequesttype.lngrequest_type_id);
    }
    else{
      let listerrormessage=[];
      this.Message="Please select any one Request Type Record.";
      this.ShowWarinngAlert(this.Message); 
    }
  }
  public RefreshRequesttype() {
    if (!(isNullOrUndefined(this.UISelectedRequesttype)) && this.UISelectedRequesttype.lngrequest_type_id > 0) {
      this.RequesttypeUI = new RequesttypeUI();
      this.LoadRequesttypedetails(this.UISelectedRequesttype.lngrequest_type_id);
    }
    this.Message="Please select any one Request Type  Record.";
      this.ShowWarinngAlert(this.Message); 
  }
  
  public ConfirmDeleteRequesttype(RequestDelete: any) {
    if (!isNullOrUndefined(this.UISelectedRequesttype))
     {
     
      this.modalref = this.modalService.open(RequestDelete,{ backdrop: 'static' });
    }
    else{
      this.Message="Please select any one Request Type Record.";
      this.ShowWarinngAlert(this.Message); 
    }
  }

public LoadRequesttypedetails(ReqType)
{
  this.RequesttypeUI = new RequesttypeUI();
  if(this.UISelectedRequesttype!=null && this.UISelectedRequesttype.lngrequest_type_id)
  {

  this.preLoader.show(true);
  this.masterService.LoadbusRequestType(ReqType).subscribe(
    data=>{this.LoadRequesttypedetailsSuccess(data)},
   error=>{this.LoadRequesttypedetailsError(error)}
  )
}
else{
  this.Message="Please select any one Request Type Record.";
    this.ShowWarinngAlert(this.Message); 
}
}
private LoadRequesttypedetailsSuccess(data: any): any {
  if (!isNullOrUndefined(data)) {
    let response = data;
  this.Requesttype = response;
  this.UISelectedRequesttype=this.Requesttype;
  this.preLoader.show(false);
  this.ShowSuccessAlert("Data selected and opened successfully.");
  
}

}

private LoadRequesttypedetailsError(data: any): any {
  this.GetCommonErrorMessage(data);
}
  
  ShowWarinngAlert(Msg: any) {
    this.alert.ShowWarningAlert(true);
    this.alert.SetWarningMsg(Msg);
  }
  
  GetMessageCount() {
    this.errorCount = 0;
    this.infoCount = 0;
    this.warningCount = 0;

    if (!isNullOrUndefined(this.MsgTabList) && this.MsgTabList.length > 0) {
      for (let i = 0; i < this.MsgTabList.length; i++) {

        if (this.MsgTabList[i].Type == 'Error') {
          this.errorCount = this.errorCount + 1;
        }
        else if (this.MsgTabList[i].Type == 'Warn') {
          this.warningCount = this.warningCount + 1;
        }
        else if (this.MsgTabList[i].Type == 'Info') {
          this.infoCount = this.infoCount + 1;
        }
      }

    }
  }
  ShowErrorAlert(ErrorCount: any, ErrorMsg: any, ErrorCode: any) {
    let ErrMsg = '';
    if (ErrorCount > 2) {
      ErrMsg = 'Error has been occured.'
    }
    else {
      ErrMsg = ErrorMsg;
    }

    this.alert.SetErrorCode(ErrorCode);
    this.alert.SetErrorDescription(ErrMsg);
    this.alert.ShowErrorAlert(true);
  }
  ShowErrorMsg(Message: any) {
    this.alert.clearAlert();
    let Msg = { "Status": "true", "Message": Message, "Type": "danger" }
    this.alert.alert(Msg);
  }

  ShowSuccessAlert(Msg: any) {
    this.alert.ShowSuccessAlert(true);
    this.alert.SetSuccessMsg(Msg);
  }

  ShowInfoAlert(Msg: any) {
    this.alert.ShowInfoAlert(true);
    this.alert.SetInfoMsg(Msg);
  }

  ShowWarningAlert(Msg: any) {
    this.alert.ShowWarningAlert(true);
    this.alert.SetWarningMsg(Msg);
  } 

  ShowErrorMessageInTab(ErrorMessage: any) {

    // if (isNullOrUndefined(this.MsgTabList)) {
    this.MsgTabList = [];
    // }
    if (isNullOrUndefined(this.ErrorMsgTab)) {
      this.ErrorMsgTab = new ErrorMsgTab();
    }

    if (!isNullOrUndefined(ErrorMessage) && ErrorMessage.length > 0) {
      for (let i = 0; i < ErrorMessage.length; i++) {
        this.ErrorMsgTab.Date = new Date();
        this.ErrorMsgTab.MessageDescription = ErrorMessage[0];
        this.ErrorMsgTab.Type = 'Error';
        this.MsgTabList.push(this.ErrorMsgTab);
      }

      this.GetMessageCount();
    }

    this.DuplicateMsgTabList = this.MsgTabList;
  }



}
