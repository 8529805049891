import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorDateBase } from '../value-accessor-date-base';
import { ValueAccessorBase } from '../value-accessor-base';
import * as moment from 'moment';
import { parse } from 'date-fns';
// import { parse,format,isMatch } from 'date-fns';

@Component({
  selector: 'app-date-control',
  templateUrl: './date-control.component.html',
  styleUrls: ['./date-control.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DateControlComponent, multi: true }
  ],
})
// export class DateControlComponent extends ValueAccessorDateBase<string> implements OnInit {

//   @Input() public label: string;
//   @Input() public requiredtrue: string = 'false';
//   @Input() public name: string;
//   @Input() public placeholder: string;
//   errorTrueNow = false;
//   @Input()
//   set errorTrue(errorTrue: boolean) {
//     this.errorTrueNow = (errorTrue) || false;
//   }
//   get errorTrue() { return this.errorTrueNow; }
//   constructor() {
//     super();
//   }
//   btnClickedNow = false;
//   @Input()
//   set btnClicked(btnClicked: boolean) {
//     this.btnClickedNow = (btnClicked) || false;
//   }
//   get btnClicked() { return this.btnClickedNow; }

//   ngOnInit() {
//   }

// }
export class DateControlComponent extends ValueAccessorDateBase<string> implements OnInit {
  xvalue = {
    date: ''
  };
  @Input() public label: string;
  @Input() public name: string;
  @Input() public placeholder: string;
  @Input() public errormsgsmall: string
  @Input() public requiredtrue: string = 'false';
  @Input() public errormsg = '';

  @Output('onblur') onblur: EventEmitter<any> = new EventEmitter();



  minDateNow: any = '';
  maxDateNow: any = '';
  setYearNow:any='';

  @Input()
  set setYear(setYear: string) {
    if (setYear !== '') {
        this.minDateNow = setYear+'-01-01';
        let maxDate = setYear+'-12-31';
        this.maxDateNow = maxDate;
    } else {
      this.setYearNow = '';
    }
  }
  get setYear() {
    return this.setYearNow;
  }

  @Input()
  set minDate(minDate: string) {
    if (minDate !== '') {
      // this.getDateFormat(minDate).then((parseDateFormat) => {
        this.minDateNow = parse(minDate, 'yyyy-MM-dd', new Date());
      // })
    } else {
      this.minDateNow = '';
    }
  }
  get minDate() {
    return this.minDateNow;
  }

  @Input()
  set maxDate(maxDate: string) {
    if (maxDate !== '') {
      // this.getDateFormat(maxDate).then((parseDateFormat) => {
        this.maxDateNow = parse(maxDate, 'yyyy-MM-dd', new Date());
      // })
    } else {
      this.maxDateNow = '';
    }
  }
  get maxDate() {
    return this.maxDateNow;
  }
  

  // tslint:disable-next-line: variable-name
  errorTrueNow = false;

  @Input()
  set errorTrue(errorTrue: boolean) {
    this.errorTrueNow = (errorTrue) || false;
  }
  get errorTrue() { return this.errorTrueNow; }
  constructor() {
    super();
  }
  btnClickedNow = false;
  @Input()
  set btnClicked(btnClicked: boolean) {
    this.btnClickedNow = (btnClicked) || false;
  }
  get btnClicked() { return this.btnClickedNow; }

  ngOnInit() {
    setTimeout(() => {
      if (this.value !== '') {
        this.xvalue.date = this.value;
      }
    }, 400);
 
    this.getValue().subscribe((xvalue) => {
      console.log('this is called: ' + xvalue);
      this.xvalue.date = this.value;
    });
  }

  dateSelected() {
    console.log(this.xvalue);
    this.value = moment(this.xvalue.date).format('YYYY-MM-DD');
    //this.blured();
  } 
  addEvent(type, event){
    console.log(type, event);
    this.blured();
  } 
  blured() {
    this.onblur.next();
  }


  // getDateFormat(date: string): Promise<string> {
  //   return new Promise((resolve) => {
  //     let dateFormat = this.appSetting.environment.serverDateFormat;
  //     let dateFormatWithTime = this.appSetting.environment.serverDateFormatWithTime;
  //     let parseDateFormat = this.appSetting.environment.serverDateFormatWithTime;
  //     if (isMatch(date, dateFormat)) {
  //       parseDateFormat = dateFormat;
  //     } else if (isMatch(date, dateFormatWithTime)) {
  //       parseDateFormat = dateFormatWithTime;
  //     }
  //     resolve(parseDateFormat);
  //   })
  // }

}

