import { ModuleWithProviders, NgModule } from '@angular/core';
import { TitleServiceService } from 'src/app/services/common/title-service.service';
import { DataService } from 'src/app/services/common/data.service';
import { NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/common/alert.service';
import { FormatDate, Pagination, SearchResultCount, FileFilter, IsLoggedIn, AppConstants } from 'src/app/common/common';
import { DatePipe, CommonModule } from '@angular/common';
import { DateCheck } from '../common/dateCheck';
import { NgbDateCustomParserFormatter } from '../common/datepicker';
import { ProcesslogService } from '../services/admin/processlog.service';
import { AuditService } from '../services/admin/audit.service';
import { ConfigService } from '../services/admin/config.service';
import { EmailtrackingService } from '../services/admin/emailtracking.service';
import { GroupService } from '../services/admin/group.service';
import { ResourceService } from '../services/admin/resource.service';
import { RoleService } from '../services/admin/role.service';
import { UserService } from '../services/admin/user.service';
import { EmployeeService } from '../services/application/employee.service';
import { CompanyService } from '../services/application/company.service';
import { AdjustmentApprovalAssignedOfficerService } from '../services/application/adjustment-request-assigned-officer.service';
import { AttendancePolicyService } from '../services/application/attendance-policy.service';
import { AttendanceRequestApprovelService } from '../services/application/attendance-request-approvel.service';
import { AttendanceService } from '../services/application/attendance.service';
import { AttendancetimesheetService } from '../services/application/attendancetimesheet.service';
import { LeavePolicyService } from '../services/application/leave-policy.service';
import { LeaveService } from '../services/application/leave.service';
import { LeaveAdjustmentRequestservice } from '../services/application/leaveAdjustmentRequest.service';
import { MasterService } from '../services/master/master.service';
import {SeverancePayConfigurationService} from '../services/application/severance-pay-configuration.service';
import {SalarybenefitdeductionService} from '../services/application/salarybenefitdeduction.service';
import {SalaryService} from '../services/application/salary.service';
import { AesService } from '../services/login/aes.service';
import { AuthenticationService } from '../services/login/authentication.service';
import { LoginService } from '../services/login/login.service';
import { AppService } from '../services/common/app.service';
import { GrdFilterPipe } from '../services/common/grd-filter.pipe';
import { HeaderService } from '../services/common/header.service';
import { PreloadService } from '../services/common/preload.service';
import { SecurityServiceService } from '../services/common/security-service.service';
import { SidebarService } from '../services/common/sidebar.service';
//import { CanDeactivateGuardService } from '../common/can-deactivate-guard.service';
import { CommonFilter } from '../common/commonFilter';
//import { DialogService } from '../common/dialog.service';
//import { DeactiveGuardForTeam, DeactiveGuard } from '../common/deactive.guard';
import { SecurityRole } from '../common/securityRole';
import {
    ResetPasswordUI, UserValidationUI,
    UserGroupValidationUI, UserBranchValidationUI, UserDepartValidationUI,
    UserAdditonalValidationUI, RoleValidationUI, GroupValidationUI, GroupRoleValidationUI,
    ResourceValidationUI, ConfigValidationUI, ConfigValueValidationUI, ConfigMetaValidationUI,
    MassageValidationUI,
    CompanyBranchValidationUI,
    EmployeeJobInformationUIValidationUI,
    EmployeeBankValidationUI,
    EmployeeAddressValidationUI,
    EmployeeEducationValidationUI,
    EmployeeTrainingValidationUI,
    EmployeeEmploymentHistoryValidationUI,
    EmployeeDocumentValidationUI,
    AttendanceValidationUI,
    AdjustmentRequestApprovalUI,
    MasterValidationUI,
    RequesttypeUI,
    EmployeeShiftConfigValidationUI,
    ReqApproverConfigValidationUI,
    AttendancemontylwiseValidationUI,
    LeaveRequestUI,
    AttendancePolicyValidationUI,
    LeaveRequestMonthwiseValidationUI,
    LRAdjustmentValidationUI,
    CompanyRegistrationUI,
    CompanyValidationUI,
    TrainingValidationUI
  } from '../common/validationUI';
import { MatRippleModule, MatTooltipModule } from '@angular/material';
import { DashboardService } from '../services/dashboard/dashboard.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from '../common/HttpRequestInterceptor';
  

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatRippleModule,
    MatTooltipModule
  ],
  exports: [
    MatRippleModule,
    MatTooltipModule
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders {
    return {
    ngModule: SharedModule,
    providers: [TitleServiceService, AlertService, Pagination, SearchResultCount, DatePipe,AppService,GrdFilterPipe,
    FormatDate, FileFilter, DateCheck, DataService,HeaderService,PreloadService,SecurityServiceService,SidebarService,
    ProcesslogService,AuditService,ConfigService,EmailtrackingService,GroupService,
    ResourceService,RoleService,UserService,EmployeeService,CompanyService,
    AdjustmentApprovalAssignedOfficerService,AttendancePolicyService,AttendanceRequestApprovelService,
    AttendanceService,AttendancetimesheetService,LeavePolicyService,LeaveService,LeaveAdjustmentRequestservice,MasterService,
    AesService,AuthenticationService,LoginService,CommonFilter,
    IsLoggedIn,AppConstants,SecurityRole,SeverancePayConfigurationService,
    SalarybenefitdeductionService,SalaryService,
    CompanyRegistrationUI,
   ResetPasswordUI, UserValidationUI,
    UserGroupValidationUI, CompanyValidationUI,
    UserBranchValidationUI, UserDepartValidationUI, UserAdditonalValidationUI,
    RoleValidationUI, GroupValidationUI,
    GroupRoleValidationUI, ResourceValidationUI, ConfigValidationUI, ConfigValueValidationUI,
    ConfigMetaValidationUI, CompanyBranchValidationUI,
    MassageValidationUI,EmployeeJobInformationUIValidationUI,
    EmployeeBankValidationUI, EmployeeAddressValidationUI,
    EmployeeEducationValidationUI, EmployeeTrainingValidationUI,
     EmployeeEmploymentHistoryValidationUI, EmployeeDocumentValidationUI,
    MasterValidationUI, AdjustmentRequestApprovalUI, RequesttypeUI, TrainingValidationUI,
    AttendancemontylwiseValidationUI, LeaveRequestMonthwiseValidationUI,
    AttendanceValidationUI, EmployeeShiftConfigValidationUI,
    ReqApproverConfigValidationUI, LeaveRequestUI, AttendancePolicyValidationUI,
      LRAdjustmentValidationUI, DashboardService,

    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
      {
        provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true
      }
    ]
    
       }
    
    }
    
}