import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  ShowAdmin: boolean = true;
  ShowApplication: boolean = false;
  Div: string = '';

  constructor(public http: HttpClient) { }
  @Output() change: EventEmitter<string> = new EventEmitter();




  public RefreshDbCache() {
    return this.http.get(AppConstants.BasePath + 'RefreshDBCache').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public StartLIARawDataProcess() {
    return this.http.get(AppConstants.BasePath + 'StartLIARawDataProcess').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetBasePath() {
    return this.http.get(AppConstants.BasePath + 'GetBasePath').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  ShowAdminDiv() {
    this.Div = 'Admin';
    this.change.emit('Admin')
  }

  ShowApplicationDiv() {
    this.Div = 'Application';
    this.change.emit('Application')
  }


  private SuccessHandler(response: Response) {
    let data = response;
    return data || {};
  }

  private ErrorHandler(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || '';
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return errMsg;
  }
}
