import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})

export class AttendancetimesheetService {

  constructor(public http: HttpClient) { }

  public CreateNewAttendance() {
    return this.http.get(AppConstants.BasePath + 'CreateNewAttendance').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public CancelAttendance() {
    return this.http.get(AppConstants.BasePath + 'CancelAttendance').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadAttendanceInitially(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadAttendanceInitially', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  public GetSelectedDayAttendance(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetSelectedDayAttendance', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  public SaveAttendance(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveAttendance', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public AllowToSaveSTDAttendanceExistingInandOutTime(data: any): any {
    return this.http.post(AppConstants.BasePath + 'AllowToSaveSTDAttendanceExistingInandOutTime', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveStdTimeAttendance(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveStdTimeAttendance', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  //
  public SaveSTDAttendanceBothStdTimeandExistingTime(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveSTDAttendanceBothStdTimeandExistingTime', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  // attendance adjustmetn req
  public AllowToSaveSTDAttendanceAdjustReqBothStdTimeandExistingTime(data: any): any {
    return this.http.post(AppConstants.BasePath + 'AllowToSaveSTDAttendanceAdjustReqBothStdTimeandExistingTime', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  public SaveStdTimeAttendanceAdjustReq(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveStdTimeAttendanceAdjustReq', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public AllowToSaveSTDAttendanceAdjustReqExistingInTimeandOuttime(data: any): any {
    return this.http.post(AppConstants.BasePath + 'AllowToSaveSTDAttendanceAdjustReqExistingInTimeandOuttime', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadAttendance(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadAttendance', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadAttendanceBasedOnDeilyMonthlyWeekly(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadAttendanceBasedOnDailyMonthlyWeekly', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetHolidayList(): any {
    return this.http.get(AppConstants.BasePath + 'GetHolidayList').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadAttendanceRequestApprovelDeilyMonthlyWeekly(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadAttendanceRequestApprovelOnDeilyMonthlyWeekly', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetAttendanceInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetAttendanceInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenAttendanceDetail(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenAttendanceDetail', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenAttendance(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenAttendance', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteAttendanceDetail(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteAttendanceDetail', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenAttendanceType(data: any): any {
    return this.http.post(AppConstants.BasePath + 'IsAttnInTimeOutTimeExitsExcessDuty', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  ////////////////////////////Submitapproval
  public Submitattendanceapproval(data: any): any {
    return this.http.post(AppConstants.BasePath + 'Submitattendanceapproval', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


   ////////////////////////////supervisor 
   public GetSupervisorddlattendance(deptId: any) {
    return this.http.post(AppConstants.BasePath + 'GetSupervisorddl', deptId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenAttendanceOTType(data: any): any {
    return this.http.post(AppConstants.BasePath + 'IsAttnAdjutInTimeOutTimeExitsExcessDuty', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public CreateNewAttendanceAdjustmentRequest() {
    return this.http.get(AppConstants.BasePath + 'CreateNewAttendanceAdjustmentRequest').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  ////////////////////////////supervisor 
  public GetSupervisorddlattendanceadjust(deptId: any) {
    return this.http.post(AppConstants.BasePath + 'GetSupervisorddl', deptId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  /////////////////////////////
  public Holidayalert(data: any) {
    return this.http.post(AppConstants.BasePath + 'Holidayalert', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public Holidayalertteamtimesheet(data: any) {
    return this.http.post(AppConstants.BasePath + 'Holidayalert', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveAttendanceAdjustmentRequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveAttendanceAdjustmentRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadAttendanceAdjustmentRequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadAttendanceAdjustmentRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetAttendanceRequestInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetAttendanceRequestInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadAttendanceRequestOnDeilyMonthlyWeekly(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadAttendanceRequestOnDeilyMonthlyWeekly', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenAttendanceAdjustmentRequestDetail(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenAttendanceAdjustmentRequestDetail', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenAttendanceAdjustmentRequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenAttendanceAdjustmentRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteAttendanceAdjustmentRequestDateDetail(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteAttendanceAdjustmentRequestDateDetail', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteAttendanceAdjustmentRequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteAttendanceAdjustmentRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetAttendanceDetailFromSelectedDate(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetAttendanceDetailFromSelectedDate', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenAttendanceAdjustRequestDateDetail(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenAttendanceAdjustRequestDateDetail', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenAttendanceAdjustmentRequestTimeDetail(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenAttendanceAdjustmentRequestTimeDetail', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public UpdateAttendanceAdjustmentRequestForApproval(data: any): any {
    return this.http.post(AppConstants.BasePath + 'UpdateAttendanceAdjustmentRequestForApproval', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteAttendanceAdjustmentRequestTimeDetail(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteAttendanceAdjustmentRequestTimeDetail', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public CancelAttendanceAdjustmentRequest() {
    return this.http.get(AppConstants.BasePath + 'CancelAttendanceAdjustmentRequest').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DownloadAttendanceDocument(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DownloadAttendanceDocument', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DownloadAttendanceAdjustmentRequestDocument(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DownloadAttendanceAdjustmentRequestDocument', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public RequestToCancelAttnAdjustReqToApprover(data: any): any {
    return this.http.post(AppConstants.BasePath + 'RequestToCancelAttnAdjustReqToApprover', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  /////////////////////////holidayalert
  public Holidayalertadjustment(data: any) {
    return this.http.post(AppConstants.BasePath + 'Holidayalert', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public Holidayalertadjustmentteam(data: any) {
    return this.http.post(AppConstants.BasePath + 'Holidayalert', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }
  private ErrorHandler(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || '';
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return _throw(error);
    }

    ////////////////////////////Submitapproval
  public Submitteamattendanceapproval(json: any): any {
    return this.http.post(AppConstants.BasePath + 'Submitattendanceapproval', json).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

 public Getpayrollstartdateandenddate(json: any): any {
    return this.http.post(AppConstants.BasePath + 'Getpayrollstartdateandenddate', json).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
 

  public GetNextpayrollstartdateenddate() {
    return this.http.get(AppConstants.BasePath + 'GetNextpayrollstartdateenddate').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public LoadLeaveshowonTimesheet(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadLeaveshowonTimesheet', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadLeaveshowonTimesheetAdjustment(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadLeaveshowonTimesheetAdjustment', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

}
