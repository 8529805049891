import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { TitleServiceService } from 'src/app/services/common/title-service.service';
import { SecurityServiceService } from 'src/app/services/common/security-service.service';
import { PreloadService } from 'src/app/services/common/preload.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { SidebarService } from 'src/app/services/common/sidebar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMsg,AppConstants } from 'src/app/common/common';
import { DataService } from 'src/app/services/common/data.service';
import { Router } from '@angular/router';
import { AttendancetimesheetService } from 'src/app/services/application/attendancetimesheet.service';
import { isNullOrUndefined } from 'util';
import { LocalStorageService } from 'angular-web-storage';
import { MasterService } from 'src/app/services/master/master.service';
import { CompanyService } from 'src/app/services/application/company.service';
import { UserService } from 'src/app/services/admin/user.service';
import { EmployeeService } from 'src/app/services/application/employee.service';
import { DatePipe,Location } from '@angular/common';
import { SalaryService } from '../../../services/application/salary.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  showhelp = true;
  opensidebar = false;
  CompanyData: any;
  DataService: any;
  EmployeeUI: any;
  modalReferrence: any;
  myDate: Date;
  user: any;
  showHelppdf:boolean;
  UserName: any;
  currentdate: any;
  LastLoginDate: string = null;
  logintym: Date;
  FirstLetter: any;
  currenttime: string;
  currentTime: string;
  LastLoginTime: string;
  width: string;
  height: string;
  ModuleName: String;
  constructor(
    private title: TitleServiceService,
    private _location: Location,
    private SecurityService: SecurityServiceService,
    public preLoader: PreloadService,
    public Layout: SidebarService,
    public alert: AlertService,
    public modalService: NgbModal,
    private datePipe: DatePipe,
    private dataService: DataService,
    public router: Router,
    public LocalStorage: LocalStorageService,
    public MasterService: MasterService,
    public CompanyService: CompanyService,
    public userService: UserService,
    public EmployeeService: EmployeeService,
    public SalaryService:  SalaryService,
    private AttendancetimesheetService: AttendancetimesheetService,

  ) { }
  ngOnInit() {
    console.log('layout')
    this.showhelp =true;
    this.checkSideBar();
    this.GetLoginDetails();
    this.title.getTitle().subscribe(appTitle => this.pageName = appTitle);
    this.title.getTitleforPdf().subscribe(appTitlepdf => this.pageNameForPdf = appTitlepdf);
    this.title.getsubTitle().subscribe(appsubTitle => this.parentName = appsubTitle)
    this.title.getModuleName().subscribe(appModule => this.ModuleName = appModule);
    this.title.getshowpdf().subscribe(appModule => this.showHelppdf = appModule);
    this.GetHelpDocspath();
    // $(document).ready(function () {

    //   if ($(".sidebar").hasClass('toggled')) {
    //     $("#content-wrapper").addClass("toggledContent-wrapper");
    //   }
    //   else {
    //     $("#content-wrapper").removeClass("toggledContent-wrapper");
    //   }
    // });

    // $(".dropdown-submenu").on('click', function () {
    //   $(".dropdown-toggle").toggleClass("show");
    // });





    // $("#sidebarToggle").on('click', function () {
    //   // alert('a');

    //   $("body").toggleClass("sidebar-toggled");
    //   // $(".dropdown-item").toggleClass("hide");

    //   $(".sidebar").toggleClass("toggled");
    //   if ($(".sidebar").hasClass('toggled')) {
    //     $("#content-wrapper").addClass("toggledContent-wrapper");
    //     $("#sidebarToggle").removeClass("main-toggle-arrow");
    //     $("#sidebarToggle").addClass("main-toggled-arrow");
    //   }
    //   else {
    //     $("#content-wrapper").removeClass("toggledContent-wrapper");
    //     $("#sidebarToggle").removeClass("main-toggled-arrow");
    //     $("#sidebarToggle").addClass("main-toggle-arrow");
    //   }

    //   $("#footer").toggleClass("hide");
    //   $("#searchCollapseBtn").toggleClass("l60");
    //   // $(".sidebar").toggleClass("top60");
    //   $("#userType").toggleClass("hide");
    //   $(".logoutList").toggleClass("hide");
    //   $(".logoutListSmall").toggleClass("show");
    //   $("#userForipad").toggleClass("show");

    // });
    // $("#content-wrapper").on('click', function () {

    //   $("#content-wrapper").removeClass("hhide");
    // });
    // $(".m1").click(function () {
    //   // alert('1');
    //   $(".m2").toggle();
    //   // alert('2');
    // });


    // $(document).ready(function () {
    //   $('.arrow12').click(function () {
    //     $('.div12').toggle();
    //   })
    // });

    // $(document).ready(function () {
    //   $('#pg').click(function () {
    //     $('#pg1').toggle();
    //   })
    // });


    // $("ul.dropdown-menu [data-toggle='dropdown']").on("click", function (event) {
    //   event.preventDefault();
    //   event.stopPropagation();

    //   $(this).siblings().toggleClass("show");


    //   if (!$(this).next().hasClass('show')) {
    //     $(this).parent('.dropdown-menu').first().find('.show').removeClass("show");
    //   }
    //   $(this).parent('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
    //     $('.   .show').removeClass("show");
    //   });

    // });

    // $('img').click(function () {
    //   $(this).css({
    //     // "-webkit-transform": "rotate(90deg)",
    //     // "-moz-transform": "rotate(90deg)",
    //     // "transform": "rotate(90deg)" /* For modern browsers(CSS3)  */
    //   });
    // });



    // $("ul.dropdown-menu .fa-thumb-tack").on("click", function (event) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   $(this).parent('.dropdown-menu').first().toggleClass("show");
    //   $(this).parent('.dropdown').first().toggleClass("show");

    //   // $(this).closest('a').attr("aria-expanded","false");

    //   // if (!$(this).next().hasClass('show')) {
    //   //   $(this).parent('.dropdown-menu').first().toggleClass("show");
    //   // }
    //   // $(this).parent('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
    //   //   $('.dropdown-submenu .show').removeClass("show");
    //   // });

    // });

  }
  checkSideBar() {
    const opensidebar = localStorage.getItem('opensidebar');
    opensidebar === 'true' ? this.opensidebar = true : this.opensidebar = false;
  }
  toggleSideBar() {
    this.opensidebar = !this.opensidebar;
    localStorage.setItem('opensidebar', this.opensidebar.toString());
  }
  public OpenPdf()
  {
      let width = screen.width - 100;
      let height = screen.height - 100;
  
  if(!isNullOrUndefined(this.ModuleName) && !isNullOrUndefined(this.pageNameForPdf))
  {
  window.open("assets/HelpDocs/"+ this.ModuleName + "/" + this.pageNameForPdf + ".pdf", "_blank",
  "toolbar=no,scrollbars=yes,resizable=no,top=0,left=50,width=" + width + ",height=" + height + "");

  //window.open("assets/HelpDocs/AdminModule/AuditLog.pdf")
    }
  }

  
  parentName:String;
  pageName: String;
  subTitle: String;
  pageNameForPdf :String;

  adminMenu: boolean = true;
  appMenu: boolean = false;
  classType1: string = 'usermenu1 active';
  classType2: string = 'usermenu2';

  admin() {
    this.adminMenu = true;
    this.appMenu = false;
    this.classType2 = "usermenu2";
    this.classType1 = "usermenu1 active";
  }
  application() {
    this.adminMenu = false;
    this.appMenu = true;
    this.classType1 = "usermenu1";
    this.classType2 = "usermenu2 active";
  }

  public ngAfterViewChecked() {
    this.SecurityService.ApplySecurity();

  }

  Helpdocspath:any;
  GetHelpDocspath() {
    this.preLoader.show(true);
    this.Layout.GetBasePath().subscribe(
      data => { this.GetHelpDocspathSuccess(data) }, error => { this.GetHelpDocspathError(error) });
  }

  GetHelpDocspathSuccess(data: any) {
    this.Helpdocspath=data;
console.log("Layoutpath",data);
  }

  GetHelpDocspathError(error: any | string) {
    console.log(error);
    this.ShowErrorAlert(1, ErrorMsg.ErrorMessage, 1022);
    this.preLoader.show(false);
  }
  backClicked() {
    this._location.back();
    }
  Logout() {
    //this.alert.ClearMsgPanels();
    //this.LocalStorage.clear();
    this.LocalStorage.clear();
    // this.SessionStorage.set("SideBarDiv", '');
    this.LocalStorage.set("IsLoggedIn", false);
    this.router.navigate(['/login']);
  }
  ConfirmClearCache(ClearCacheContent) {
    this.modalReferrence = this.modalService.open(ClearCacheContent);
  }
  ClearCache() {
    this.modalReferrence.close();
    this.LocalStorage.clear();
    this.ShowSuccessAlert("Cache cleared successfully.");
    //this.router.navigateByUrl("/hrPayroll/");
    this.Logout();
    this.preLoader.show(false);
  }
  ConfirmRefreshDbCache(RefreshDB) {
    this.modalReferrence = this.modalService.open(RefreshDB);
  }

  RefreshDbCache() {
    this.preLoader.show(true);
    this.Layout.RefreshDbCache().subscribe(
      data => { this.RefreshDbCacheSuccess(data) }, error => { this.RefreshDbCacheError(error) });
  }

  RefreshDbCacheSuccess(data: any) {
    this.modalReferrence.close();
    this.ShowSuccessAlert("Data refreshed successfully.");
    this.preLoader.show(false);
  }

  RefreshDbCacheError(error: any | string) {
    console.log(error);
    this.ShowErrorAlert(1, ErrorMsg.ErrorMessage, 1022);
    this.preLoader.show(false);
  }

  ShowSuccessAlert(Msg: any) {
    this.alert.ShowSuccessAlert(true);
    this.alert.SetSuccessMsg(Msg);
  }

  ShowErrorAlert(ErrorCount: any, ErrorMsg: any, ErrorCode: any) {
    let ErrMsg = '';
    if (ErrorCount > 2) {
      ErrMsg = 'Error has been occured.'
    }
    else {
      ErrMsg = ErrorMsg;
    }
    this.alert.SetErrorCode(ErrorCode);
    this.alert.SetErrorDescription(ErrMsg);
    this.alert.ShowErrorAlert(true);
  }


  public LoadEmployeeTimeSheetDetail() {
    this.dataService.AttendanceUI = {};
    let UserDetails = this.LocalStorage.get("LoggedInUser");
    this.LoadEmployeeTimeSheet(UserDetails.user_serial_id);
  }

  public LoadEmployeeTimeSheet(user_serial_id) {
    this.AttendancetimesheetService.LoadAttendance(user_serial_id).subscribe(
      data => { this.LoadAttendanceSuccess(data) },
      error => { this.LoadAttendanceError(error) });
  }

  LoadAttendanceSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.dataService.AttendanceListUI = data;
      this.router.navigate(['/Attendance']);
    }
  }


  LoadAttendanceError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }




  public LoadAttendanceAdjustmentRequestDetail() {
    this.dataService.AttendanceAdjustmentRequestListUI = {};
    let UserDetails = this.LocalStorage.get("LoggedInUser");
    this.LoadAttendanceAdjustmentRequest(UserDetails.user_serial_id);
  }

  public LoadAttendanceAdjustmentRequest(user_serial_id) {
    if (user_serial_id > 0) {
      let EmpID = 0;
      let Detail = { UserSerialID: user_serial_id, EmployeeID: EmpID };
     
      this.AttendancetimesheetService.LoadAttendanceAdjustmentRequest(Detail).subscribe(
        data => { this.LoadAttendanceAdjustmentRequestSuccess(data) },
        error => { this.LoadAttendanceAdjustmentRequestError(error) });
    }
  }

  LoadAttendanceAdjustmentRequestSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.dataService.AttendanceAdjustmentRequestListUI = data;
      this.router.navigate(['/AttendanceAdjustmentRequest']);

    }
  }


  LoadAttendanceAdjustmentRequestError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }



  public GetMasterDataJobDetails() {
    this.dataService.MasterDataUI = {};
    let UserDetails = this.LocalStorage.get("LoggedInUser");
    let lngreference_id = UserDetails.lngreference_id;
    this.GetMasterDataDetail(lngreference_id);
  }


  public GetMasterDataDetail(lngreference_id) {
    if (lngreference_id > 0) {
      let reference_id = { reference_id: lngreference_id };
      this.MasterService.GetMasterDataForJobTitleAndDesignationAndLevel(reference_id).subscribe(
        data => { this.GetMasterDataSuccess(data) },
        error => { this.GetMasterDataError(error) });
    } else {
      this.dataService.MasterDataUI = {};
      this.router.navigate(['/JobDetails']);
    }
  }

  GetMasterDataSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.dataService.MasterDataUI = data;
      this.router.navigate(['/JobDetails']);

    }
  }


  GetMasterDataError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }



  GetCommonErrorMessage(error: any | string) {
    console.log(error);
  }



  public GetMasterDataBenefits() {
    this.dataService.MasterDataUI = {};
    let UserDetails = this.LocalStorage.get("LoggedInUser");
    let lngreference_id = UserDetails.lngreference_id;
    this.GetMasterDataBenefitsDetail(lngreference_id);
  }


  public GetMasterDataBenefitsDetail(lngreference_id) {
    if (lngreference_id > 0) {
      let reference_id = { reference_id: lngreference_id };
      this.MasterService.GetMasterDataBenefits(reference_id).subscribe(
        data => { this.GetMasterDataBenefitsSuccess(data) },
        error => { this.GetMasterDataBenefitsError(error) });
    } 
    else {
      this.dataService.MasterDataUI = {};
      this.router.navigate(['/BenefitsDeductions']);
    }
  }

  GetMasterDataBenefitsSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.dataService.MasterDataUI = data;
      this.router.navigate(['/BenefitsDeductions']);

    }
  }


  GetMasterDataBenefitsError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }


  GetLoginDetails() {
    this.myDate = new Date();
    this.user = this.LocalStorage.get("LoggedInUser")
    if (this.user != null && !isNullOrUndefined(this.user)) {
      //full_name
      this.UserName = this.user.user_login_id;
      this.currentdate = this.datePipe.transform(this.user.ibusUIUserAddtionalDetail.dtmCurrentLogin, 'dd-MM-yyyy');
      this.currentTime = this.datePipe.transform(this.user.ibusUIUserAddtionalDetail.dtmCurrentLogin, 'HH:mm');
      this.LastLoginDate = this.datePipe.transform(this.user.ibusUIUserAddtionalDetail.dtmLastLogin, 'dd-MM-yyyy');
      this.LastLoginTime = this.datePipe.transform(this.user.ibusUIUserAddtionalDetail.dtmLastLogin, 'HH:mm');
      this.logintym = new Date();
      this.logintym = this.LocalStorage.get("LoginTime");
      if (this.logintym != null) {
        this.logintym = this.logintym;
      }
      this.FirstLetter = (this.UserName.substring(0, 1)).toUpperCase();
    }
  }

  public GetMasterDataForEducationLevelAndlDocument() {
    this.dataService.MasterDataUI = {};
    let UserDetails = this.LocalStorage.get("LoggedInUser");
    let lngreference_id = UserDetails.lngreference_id;
    this.GetMasterDataForEducationLevelAndlDocumentDetail(lngreference_id);
  }

  public GetMasterDataForEducationLevelAndlDocumentDetail(lngreference_id) {
    if (lngreference_id > 0) {
      let reference_id = { reference_id: lngreference_id };
      this.MasterService.GetMasterDataForEducationLevelAndlDocument(reference_id).subscribe(
        data => { this.GetMasterDataForEducationLevelAndlDocumentSuccess(data) },
        error => { this.GetMasterDataForEducationLevelAndlDocumentError(error) });
    } 
    else {
      this.dataService.MasterDataUI = {};
      this.router.navigate(['/Others']);
    }
  }

  GetMasterDataForEducationLevelAndlDocumentSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.dataService.MasterDataUI = data;
      this.router.navigate(['/Others']);
    }
  }

  GetMasterDataForEducationLevelAndlDocumentError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }
  public GetMasterDataForLeave() {
    this.dataService.MasterDataUI = {};
    let UserDetails = this.LocalStorage.get("LoggedInUser");
    let lngreference_id = UserDetails.lngreference_id;
    this.GetMasterDataLeaveDetail(lngreference_id);
  }

  public GetMasterDataLeaveDetail(lngreference_id) {
    if (lngreference_id > 0) {
      let reference_id = { reference_id: lngreference_id };
      this.MasterService.GetMasterDataLeaveDetail(reference_id).subscribe(
        data => { this.GetMasterDataLeaveDetailSuccess(data) },
        error => { this.GetMasterDataLeaveDetailError(error) });
    }
     else {
      this.dataService.MasterDataUI = {};
      this.router.navigate(['/MLeaveType']);
    }
  }

  GetMasterDataLeaveDetailSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.dataService.MasterDataUI = data;
      this.router.navigate(['/MLeaveType']);
    }
  }


  GetMasterDataLeaveDetailError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }




  public GetMasterDataForHoliday() {
    this.dataService.MasterDataUI = {};
    let UserDetails = this.LocalStorage.get("LoggedInUser");
    let lngreference_id = UserDetails.lngreference_id;
    this.GetMasterDataPublicHalidayDetail(lngreference_id);
  }

  public GetMasterDataPublicHalidayDetail(lngreference_id) {
    if (lngreference_id > 0) {
      let reference_id = { reference_id: lngreference_id };
      this.MasterService.GetMasterDataLeaveDetail(reference_id).subscribe(
        data => { this.GetMasterDataPublicHalidayDetailSuccess(data) },
        error => { this.GetMasterDataPublicHalidayDetailDetailError(error) });
    } 
    else {
      this.dataService.MasterDataUI = {};
      this.router.navigate(['/MHoliday']);
    }
  }

  GetMasterDataPublicHalidayDetailSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.dataService.MasterDataUI = data;
      this.router.navigate(['/MHoliday']);
    }
  }

  GetMasterDataPublicHalidayDetailDetailError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }




  public LoadCompanyByCompanyId() {

    this.preLoader.show(true);
    this.CompanyService.LoadCompanyDetail().subscribe(
      data => { this.LoadCompanyByCompanyIdSuccess(data) },
      error => { this.LoadCompanyByCompanyIdError(error) }
    );

  }

  LoadCompanyByCompanyIdSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.alert.clearAlert();
      if (data.ilstErrorMessages != null && data.ilstErrorMessages.length > 0) {
        this.ShowErrorAlert(data.ilstErrorMessages.length, data.ilstErrorMessages, 1087);
      }
      else {
        this.dataService.CompanyData = data;
        this.router.navigate(['/CompanyProfile']);
      }
    }
    this.preLoader.show(false);
  }

  LoadCompanyByCompanyIdError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }


  OpenAttendanceReport() {
    window.open(AppConstants.BasePath + "Report/benefitsAndDeductionReport/WebForms/Attendance.aspx", "_blank",
      "toolbar=no,scrollbars=yes,resizable=no,top=0,left=50,width=" + this.width + ",height=" + this.height + "");
    // myWindow.focus();
  }

  public LoadUserByUserId() {
    let UserDetails = this.LocalStorage.get("LoggedInUser");
    if (!isNullOrUndefined(UserDetails) && UserDetails.user_serial_id > 0) {
      this.preLoader.show(true);
      this.userService.OpenUser(UserDetails.user_serial_id).subscribe(
        data => { this.LoadUserByUserIdSuccess(data) },
        error => { this.LoadUserByUserIdError(error) }
      );
    } else {
      this.ShowErrorAlert(1, "Your Session is Expired. Please login Once again.", 'ER001');
    }
  }

  LoadUserByUserIdSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.alert.clearAlert();
      if (data.ilstErrorMessages != null && data.ilstErrorMessages.length > 0) {
        let msg = { "Status": "true", "Message": data.ilstErrorMessages.toString(), "Type": "danger" };
        this.alert.alert(msg);
      }
      else {
        this.dataService.UserData = data;
        this.router.navigate(['/userDetail']);
      }
    }

    this.preLoader.show(false);
  }

  LoadUserByUserIdError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);

  }

  public LoadEmployeeDetail() {
    this.preLoader.show(true);
    this.EmployeeService.LoadEmployeeDetail()
      .subscribe(
        data => { this.LoadEmployeeSuccess(data) }, error => { this.LoadEmployeeError(error) });
  }

  LoadEmployeeSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      this.alert.clearAlert();
      if (data.ilstErrorMessages != null && data.ilstErrorMessages.length > 0) {
        let msg = { "Status": "true", "Message": data.ilstErrorMessages.toString(), "Type": "danger" };
        this.alert.alert(msg);
      }
      else {
        let empdata = data;
        this.dataService.EmployeeDataUI = [];
        this.dataService.EmployeeDataUI = empdata;
        this.router.navigate(['/Myprofile']);
      }
    }
    this.preLoader.show(false);
  }

  LoadEmployeeError(error: any | string) {
    this.dataService.GetCommonErrorMessage(error);
  }

///// LoadSalaryConfiguration
public LoadSalaryConfig() {
  this.preLoader.show(true);
   this.CompanyData = this.DataService.CompanyData;
   let Id = this.CompanyData.lngCompany_id;
   this.SalaryService.OpenSalaryConfig(Id).subscribe(
    data => { this.LoadSalaryBySalaryIdSuccess(data) },
    error => { this.LoadSalaryBySalaryIdError(error) }
  );
}

LoadSalaryBySalaryIdSuccess(data: any) {
  this.preLoader.show(true);
  if (!isNullOrUndefined(data)) {
    this.alert.clearAlert();
    if (data.ilstErrorMessages != null && data.ilstErrorMessages.length > 0) {
      this.ShowSuccessAlert(data.ilstErrorMessages);
    }
    else {
      let empdata=data;
      this.DataService.SalaryDataUi =[];
      this.DataService.SalaryDataUi =empdata;
      this.router.navigate(['/salary-config']);
    }
  }
  this.preLoader.show(false);
}

LoadSalaryBySalaryIdError(error: any | string) {
this.dataService.GetCommonErrorMessage(error);
}

  isShow = false;

  toggleDisplay() {
    this.isShow = !this.isShow;
  }
  navigateconfig(){
    this.showHelppdf=false;
    this.router.navigate(['/ConfigMenu']);
  }
  hideshowhelp()
  {
    console.log("working")
    this.showhelp = false;
  }
  showshowhelp(){
    this.showhelp = true;
  }

}
