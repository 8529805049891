
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(public http: HttpClient) { }


  public CreateNewEmployee() {
    return this.http.get(AppConstants.BasePath + 'CreateNewEmployee').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveEmployee(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveEmployee', data).pipe(
       map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public GetEmployeeDetailInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetEmployeeInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetDepartBasedOnTeam(deptId: any) {
    return this.http.post(AppConstants.BasePath + 'GetDepartBasedOnTeamForEmployeeScreen', deptId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public GetPayPointCountBasedonDestLevel(deslvId: any) {
    return this.http.post(AppConstants.BasePath + 'GetPayPointCountBasedonDestLevel', deslvId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public GetReportToBasedOnEmployee(deslvId: any) {
    return this.http.post(AppConstants.BasePath + 'GetneedforPayrollYESBasedOnEmployee', deslvId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public GetneedforPayrollNOBasedOnEmployee(deslvId: any) {
    return this.http.post(AppConstants.BasePath + 'GetneedforPayrollNOBasedOnEmployee', deslvId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public GetStartandEndTimeBasedOnShift(Id: any) {
    return this.http.post(AppConstants.BasePath + 'GetStartandEndTimeBasedOnShift', Id).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetTrainingNameBasedOnTrainingType(Id: any) {
    return this.http.post(AppConstants.BasePath + 'GetTrainingNameBasedOnTrainingType', Id).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetPayFrequenceBased(data: any) {
    return this.http.post(AppConstants.BasePath + 'GetPayFrequenceBased',data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public GetDesignationBasedOnEmployee(deptId: any) {
    return this.http.post(AppConstants.BasePath + 'GetDesignationBasedOnEmployee', deptId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  // public CreateNewEmployeeJobInformationUI() {
  //   return this.http.get(AppConstants.BasePath + 'CreateNewJobInformation').pipe(
  //     map(this.SuccessHandler), catchError(this.ErrorHandler));
  // }

  public CreateNewEmployeeJobInformationUI(data: any): any {
    return this.http.post(AppConstants.BasePath + 'CreateNewJobInformation', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveEmployeeJobInformation(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveEmployeeJobInformation', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public OpenEmployeeJobInformation(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenEmployeeJobInformation', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public DeleteEmployeeJobInformation(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteEmployeeJobInformation', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public CreateNewEmployeeDepartment(data: any): any {
    return this.http.post(AppConstants.BasePath + 'CreateNewEmployeeDepartment', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveEmployeeInfo(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveEmployeeInfo', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  // public OpenEmployeeDepartment(data: any): any {
  //   return this.http.post(AppConstants.BasePath + 'OpenEmployeeDepartment', data).pipe(
  //     map(this.SuccessHandler), catchError(this.ErrorHandler));
  // }
  // public DeleteEmployeeDepartment(data: any): any {
  //   return this.http.post(AppConstants.BasePath + 'DeleteEmployeeDepartment', data).pipe(
  //     map(this.SuccessHandler), catchError(DeleteEmployeeDepartmentthis.ErrorHandler));
  // }

  public CreateNewEmployeeInfo(data: any): any {
    return this.http.post(AppConstants.BasePath + 'CreateNewEmployeeInfo', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveEmployeeDepartment(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveEmployeeDepartment', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public OpenEmployeeDepartment(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenEmployeeDepartment', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public DeleteEmployeeDepartment(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteEmployeeDepartment', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  /// address
  public CreateNewEmployeeAddress() {
    return this.http.get(AppConstants.BasePath + 'CreateNewEmployeeAddress').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveEmployeeAddress(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveEmployeeAddress', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenEmployeeAddress(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenEmployeeAddress', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public DeleteEmployeeAddress(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteEmployeeAddress', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }



  /// bank

  public CreateNewEmployeeBankDetail() {
    return this.http.get(AppConstants.BasePath + 'CreateNewEmployeeBankDetail').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveEmployeeBankDetail(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveEmployeeBankDetail', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public OpenEmployeeBankDetail(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenEmployeeBankDetail', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public DeleteEmployeeBankDetail(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteEmployeeBankDetail', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }




  ///Education

  public CreateNewEmployeeEducation() {
    return this.http.get(AppConstants.BasePath + 'CreateNewEmployeeEducation').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveEmployeeEducation(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveEmployeeEducation', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenEmployeeEducation(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenEmployeeEducation', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteEmployeeEducation(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteEmployeeEducation', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }



  /// Empoyee history

  public CreateNewEmploymentHistory() {
    return this.http.get(AppConstants.BasePath + 'CreateNewEmploymentHistory').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveEmployeeEmploymentHistory(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveEmployeeEmploymentHistory', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenEmployeeEmploymentHistory(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenEmployeeEmploymentHistory', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteEmployeeEmploymentHistory(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteEmployeeEmploymentHistory', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  ///Training

  public CreateNewEmployeeTraining() {
    return this.http.get(AppConstants.BasePath + 'CreateNewEmployeeTraining').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveEmployeeTraining(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveEmployeeTraining', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenEmployeeTraining(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenEmployeeTraining', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public DeleteEmployeeTraining(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteEmployeeTraining', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public SaveEmployeeTrainingDocument(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveEmployeeTrainingDocument', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DownloadEmployeeTrainingDocument(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DownloadEmployeeTrainingDocument', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


public GetTrainingBasedOnTrainingType(deptId: any) {
  return this.http.post(AppConstants.BasePath + 'GetTrainingBasedOnTrainingType',deptId).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}

  //document

  public UploadEmployeeDoument(data: any): any {
    return this.http.post(AppConstants.BasePath + 'UploadEmployeeDocument', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public OpenEmployeeDoument(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenEmployeeDocument', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public CreateNewEmployeeDocument() {
    return this.http.get(AppConstants.BasePath + 'CreateNewEmployeeDocument').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }

  public DeleteEmployeeDocument(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteEmployeeDocument', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public DownloadDocument(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DownloadEmployeeDocument', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  /// search
  public GetEmployeeSearch() {
    return this.http.get(AppConstants.BasePath + 'GetEmployeeSearch').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public searchEmployee(data: any): any {
    return this.http.post(AppConstants.BasePath + 'searchEmployee', data).pipe(
     map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenEmployee(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenEmployee', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public LoadEmployeeDetail() {
    return this.http.get(AppConstants.BasePath + 'LoadEmployeeDetail').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public GetPayrollProcessedDate(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetPayrollProcessedDateBasedONEmpIdMonth', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  

  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }
  private ErrorHandler(error: Response | any) {
    let errorMSg: string;
    if (error instanceof Response) {
      const err = error || '';
      errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errorMSg = error.message ? error.message : error.toString();
    }
    return _throw(error);
  }
}
