import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/common/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

   
  constructor(public alertService: AlertService) { }
  ShowError: boolean = false;
  ShowSuccess: boolean = false;
  ShowInfo: boolean = false;
  ShowWarn: boolean = false;
  ShowErrorMsgPanel: boolean = false;


  ErrorCode: string = '';
  ErrorDescription: string = '';


  SuccessMsg: string = '';
  InfoMsg: string = '';
  WarningMsg: string = '';

  ErrorMsgPanel: string = '';


  ngOnInit() {

    this.alertService.ShowAlert().subscribe(ErrorAlert => this.ShowError = ErrorAlert);
    this.alertService.GetErrorCode().subscribe(Code => this.ErrorCode = Code);
    this.alertService.GetErrorDescription().subscribe(Description => this.ErrorDescription = Description);
    
    this.alertService.ShowSuccess().subscribe(
      Success => this.ShowSuccessPanel(Success)
    );


    this.alertService.ShowInfo().subscribe(
      Success => this.ShowInfoPanel(Success)
    );

    this.alertService.ShowWarning().subscribe(
      Success => this.ShowWarningPanel(Success)
    );

    this.alertService.ShowErrorMsgPanel().subscribe(
      Success => this.ShowErrorPanel(Success)
    );
  }


  ShowSuccessPanel(value: any) {
    this.ShowSuccess = value

    this.alertService.GetSuccessMsg().
      subscribe(Msg => this.SuccessMsg = Msg);

    this.HidePanels();
  }

  ShowInfoPanel(value: any) {
    this.ShowInfo = value

    this.alertService.GetInfoMsg().
      subscribe(Msg => this.InfoMsg = Msg);

    this.HidePanels();
  }

  ShowWarningPanel(value: any) {
    this.ShowWarn = value

    this.alertService.GetWarningMsg().
      subscribe(Msg => this.WarningMsg = Msg);

    this.HidePanels();
  }

  ShowErrorPanel(value: any) {
    this.ShowErrorMsgPanel = value

    this.alertService.GetErrorPanelMsg().
      subscribe(Msg => this.ErrorMsgPanel = Msg);

    this.HidePanels();
  }



  CloseErrorAlert() {
    this.ShowError = false;
    this.ErrorCode = '';
    this.ErrorDescription = '';
  }


  HidePanels() {
    setTimeout(() => {
      this.ShowSuccess = false;
      this.ShowInfo = false;
      this.ShowErrorMsgPanel=false;
      this.ShowWarn = false;
    }, 5000);
  }

  ClosePannels()
  {
    this.ShowSuccess = false;
    this.ShowInfo = false;
    this.ShowWarn = false;
    this.ShowErrorMsgPanel=false;
  }
}


