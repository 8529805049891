import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { DataService } from 'src/app/services/common/data.service';
import { Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
@Injectable()
export class HttpRequestInterceptor  implements HttpInterceptor {

    constructor(public DataServices: DataService, public Router: Router,
         public localStorage: LocalStorageService,
         public sessionStorage: SessionStorageService
         
         ){

    }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let sessionkey = this.sessionStorage.get("SessionKey");
        req = req.clone({
            setHeaders: {
                Authorization: `${sessionkey}`
            }
           
        });


        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status == 401) {
                    this.Router.navigate(['login']);
                       
                } else {
                    return throwError(err);
                }
            })
        );
    }
}