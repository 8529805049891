import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app/common/common';
//import { AlertService } from './alert.service';
import { AlertService } from 'src/app/services/common/alert.service';
//import { PreloadService } from './preload.service';
import { PreloadService } from 'src/app/services/common/preload.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public CompanyData: any;
  public UserData: any;
  public RoleData: any;
  public GroupData: any;
  public ConfigData: any;
  public EmployeeDataUI: any
  public LeavePolicyList: any
  public AttendanceUI: any;
  public AttendanceListUI: any;
  public AttendanceAdjustmentRequestUI: any;
  public AttendanceAdjustmentRequestListUI: any;
  public MasterDataUI: any;
  public ViewpayslipDataUI: any;
  public SessionId: any;
  token = '';
  basePath = '';
  LeavePolicyListUI: {};

  constructor(
    public preLoader: PreloadService,
    public alert: AlertService,
    public http: HttpClient,

  ) { }

  GetCommonErrorMessage(error: any | string) {
    console.log(error);
    let i;
    if (error.status > 0) {
      this.ShowErrorAlert(i, error.error, 1087);
      this.preLoader.show(false);
    }
    else {
      this.ShowErrorAlert(i, "There is an issue with your connection.Please Check your Internet.", 1087);
      this.preLoader.show(false);
    }

  }
  ShowErrorAlert(ErrorCount: any, ErrorMsg: any, ErrorCode: any) {
    let ErrMsg = '';
    if (ErrorCount > 2) {
      ErrMsg = 'Error has been occured.'
    }
    else {
      ErrMsg = ErrorMsg;
    }
    this.alert.SetErrorCode(ErrorCode);
    this.alert.SetErrorDescription(ErrMsg);
    this.alert.ShowErrorAlert(true);
  }

  postPDFData(path: any, body: any, options?: any) {
    let header = new HttpHeaders({
      Authorization: 'Bearer ' + this.token
    });
    return this.http.post(AppConstants.BasePath + path, body, {
      headers: header,
      responseType: 'blob',
    });
  }
}









