import { Pipe, PipeTransform } from '@angular/core';
import { MOMENT } from 'angular-calendar';
import { parseISO } from 'date-fns';

@Pipe({
  name: 'holidayFilter'
})
export class HolidayFilterPipe implements PipeTransform {

  transform(value: any, items:any): boolean {
    if(!value){
      return true;
    }
    if(!items){
      return true;
    }
    if(items.length === 0){
      return true;
    }

    let valueDate = parseISO(value);
    console.log(valueDate);

    return true;
  }

}
