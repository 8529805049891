import { Injectable } from '@angular/core';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private http: HttpClient) { }



  // Getting Initial DDL Datas
  public GetInitialData() {
    return this.http.get(AppConstants.BasePath + 'getGroupDetailsInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  // Getting Group Search Object
  public GroupSearchObject() {
    return this.http.get(AppConstants.BasePath + 'getGroupSearchObject').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  // Group Search
  public SearchGroup(data: any) {
    return this.http.post(AppConstants.BasePath + 'searchGroups', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  /// Group Detail  ///
  // Create New Group
  public CreateNewGroup() {
    return this.http.get(AppConstants.BasePath + 'createNewGroup').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  // Save Group Details
  public SaveGroup(data: any) {
    return this.http.post(AppConstants.BasePath + 'saveGroup', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  // Open Group Details
  public OpenGroup(id: number): any {
    return this.http.post(AppConstants.BasePath + 'openSelectedGroup', id).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  // //  Group Child Services //  //

  // Create New Group Child
  public CreateNewGroupRole() {
    return this.http.get(AppConstants.BasePath + 'createNewRoleGroup').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  // Save Group Role
  public SaveGroupRole(data: any) {
    return this.http.post(AppConstants.BasePath + 'saveRoleGroup', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  // Open Group Role
  public OpenGroupRole(data: any) {
    return this.http.post(AppConstants.BasePath + 'openSelectedRoleGroup', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  public OpenGroupUser(data: any) {
    return this.http.post(AppConstants.BasePath + 'openSelectedGroup', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }


  // Delete Group Role
  public DeleteGroupRole(data: any) {
    return this.http.post(AppConstants.BasePath + 'DeleteRoleInGroup', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }


  public GetRolesByModule(data: any) {
    return this.http.post(AppConstants.BasePath + 'GetRolesByModule', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }


  public DeleteGroup(GroupId: any) {
    return this.http.post(AppConstants.BasePath + 'DeleteGroup', GroupId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }





  private SuccessHandler(response: Response) {
    let data = response;
    return data || {};
  }

  private ErrorHandler(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || '';
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return _throw(error);
  }

}
