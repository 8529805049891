
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

const newLocal = 'GetLeaveRequestInitialData';
@Injectable({
  providedIn: 'root'
})

export class LeaveService {

  constructor(public http: HttpClient) { }



  public GetReportInitialData(): any {
    return this.http.get(AppConstants.BasePath + 'GetReportInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public GetLeaveRequestInitialData(): any {
    return this.http.get(AppConstants.BasePath + 'GetLeaveRequestInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public CreateNewLeaveRequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'CreateNewLeaveRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  ////////////////////////////supervisor
  public GetSupervisorddl(deptId: any) {
    return this.http.post(AppConstants.BasePath + 'GetSupervisorddl', deptId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
///////////////////////////////////////////////////GetSupervisormail
public GetSupervisormail(deptId: any) {
  return this.http.post(AppConstants.BasePath + 'GetSupervisormail', deptId).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}


  public SaveLeaveRequestApproval(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveLeaveRequestApproval', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveLeaveRequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveLeaveRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenLeaveRequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenLeaveRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenListOFLRDocumentForPopUP(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenListOFLRDocumentForPopUP', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteLeaveRequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DeleteLeaveRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public UploadLRDocument(data: any): any {
    return this.http.post(AppConstants.BasePath + 'UploadLRDocument', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DownloadLRDocument(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DownloadLRDocument', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenLARDocument(DocumentObject: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenLARDocument', DocumentObject).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetAllLeaveRequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetAllLeaveRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadDocumentBasedonLeaveTypeForLR(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadDocumentBasedonLeaveTypeForLR', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetValueFromLeavePolicy(DocumentObject: any): any {
    return this.http.post(AppConstants.BasePath + 'GetValueFromLeavePolicy', DocumentObject).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public RequestToCancelLeaveToApproverForLR(data: any): any {
    return this.http.post(AppConstants.BasePath + 'RequestToCancelLeaveToApproverForLR', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetLeaveDaysCountbyWeekShiftConfig(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetLeaveDaysCountbyWeekShiftConfig', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetRequestedEmployeeShiftTimeForLR(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetRequestedEmployeeShiftTimeForLR', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public   CreateNewLeaveRequestApproval(data: any): any {
    return this.http.post(AppConstants.BasePath + 'CreateNewLeaveRequestApproval', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetDepartBasedOnTeam(deptId: any) {
    return this.http.post(AppConstants.BasePath + 'GetDepartBasedOnTeam', deptId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public Getemployeebasedonteamanddept(deptId: any) {
    return this.http.post(AppConstants.BasePath + 'Getemployeebasedonteamanddept', deptId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }



  public SendLeaveRequestpprovalRemainderMail() {
    return this.http.get(AppConstants.BasePath + 'SendLeaveRequestpprovalRemainderMail').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetAllLeaveRequestApproval(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetAllLeaveRequestApproval', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

 public SearchLeaveRequest(data: any) {
    return this.http.post(AppConstants.BasePath + 'SearchLeaveRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

   public LeaveRequestApprovalSearch(data: any) {
    return this.http.post(AppConstants.BasePath + 'LeaveRequestApprovalSearch', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }


  public GetEmployeeLeaveBalance(empId: any) {
    return this.http.post(AppConstants.BasePath + 'GetEmployeeLeaveBalance', empId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveEmployeeLeaveBalance(data: any) {
    return this.http.post(AppConstants.BasePath + 'SaveEmployeeLeaveBalance', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
    //  ********************  Attachment Tab Ends     ********************  //
  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }
  private ErrorHandler(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
    const err = error || '';
    errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
    errMsg = error.message ? error.message : error.toString();
    }
    return _throw(error);
    }

 public Getleavebalancefield(data: any): any {
    return this.http.post(AppConstants.BasePath + 'Getleavebalancefield', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

}
