import { FLATPICKR_CONTROL_VALUE_ACCESSOR } from "angularx-flatpickr/flatpickr.directive";

export class CompanyRegistrationUI {
    ComapnyName: Boolean = false;
    PhoneNo: Boolean = false;
    EmailID: Boolean = false;
    PhoneNoFormat: Boolean = false;
    EmailIDFormat: Boolean = false;
    user_login_id: Boolean = false;
}


export class ResetPasswordUI {
    NewPasswordConfirmPasswordnotempty: Boolean = false;
    NewPasswordempty: Boolean = false;
    Confirmempty: Boolean = false;
    NewPasswordConfirPassworddoesntmatch: Boolean = false;
    Passwordlengthbe6to15characters: Boolean = false;
    PleasePasswordoneUppercase: Boolean = false;
    Pleaseeastonenumeric: Boolean = false;
    Pleasepasswordleastonespecial: Boolean = false;
    Password: Boolean = false;
    ConfirmPassword: Boolean = false;
}

export class UserValidationUI {
    LoginID: Boolean = false;
    LoginIDWhite: Boolean = false;
    Password: Boolean = false;
    FirstName: Boolean = false;
    LastName: Boolean = false;
    EmailId: Boolean = false;
    BeginDateG: Boolean = false;
    BeginDate: Boolean = false;
    Status: Boolean = false;
    ContactNo: Boolean = false;
    EmailIdV: Boolean = false;
    validEmailID: Boolean = false;
    BoolResetPasswordLink: Boolean = false;
    usrnme: Boolean = false;
    user_name : Boolean = false;
}

export class UserGroupValidationUI {
    Group: Boolean = false;
    BeginDate: Boolean = false;
    BeginDateG: Boolean = false;
    Status: Boolean = false;
}

export class UserBranchValidationUI {
    Branch: Boolean = false;
    BeginDate: Boolean = false;
    BeginDateG: Boolean = false;
    Status: Boolean = false;
}


export class UserDepartValidationUI {
    Department: Boolean = false;
    Team: Boolean = false;
    BeginDate: Boolean = false;
    BeginDateG: Boolean = false;
    Status: Boolean = false;
}



export class UserAdditonalValidationUI {
    Location: Boolean = false;
    Branch: Boolean = false;

}



export class RoleValidationUI {
    Module: Boolean = false;
    Role: Boolean = false;
    BeginDate: Boolean = false;
    BeginDateG: Boolean = false;
    Status: Boolean = false;
}




export class GroupValidationUI {
    Group: Boolean = false;
    BeginDate: Boolean = false;
    BeginDateG: Boolean = false;
    Status: Boolean = false;
}



export class GroupRoleValidationUI {
    Module: Boolean = false;
    Role: Boolean = false;
    BeginDate: Boolean = false;
    BeginDateG: Boolean = false;
    Status: Boolean = false;
    Module_Name: boolean = false;

}



export class ResourceValidationUI {
    ViewName: Boolean = false;
    ResourceName: Boolean = false;
    ResourceType: Boolean = false;
    ResourceDescription: Boolean = false;

}



export class ConfigValidationUI {
    ConfigId: Boolean = false;
    ConfigIdNeg: Boolean = false;
    ConfigValueF: Boolean = false;
    ConfigValue: Boolean = false;
    Configdescription: Boolean = false;


}


export class ConfigValueValidationUI {
    Constant: Boolean = false;
    ConfigConst: Boolean = false;
    ConfigConstdescription: Boolean = false;



}


export class ConfigMetaValidationUI {
    ConfigMetadataType: Boolean = false;
    ConfigMetadataValue: Boolean = false;
    ConfigMetadataName: Boolean = false;



}


export class MassageValidationUI {
    message_no: Boolean = false;
    message_type_value: Boolean = false;
    message: Boolean = false;



}

export class CompanyValidationUI {
    ComapnyName: Boolean = false;
    PhoneNo: Boolean = false;
    EmailID: Boolean = false;
    PhoneNoFormat: Boolean = false;
    EmailIDFormat: Boolean = false;
    Status: Boolean = false;
    FiscalBegindate: Boolean = false;
    FiscalEnddate: Boolean = false;
    BeginDate: Boolean = false;
    EmpRefNo: Boolean = false;

    strCurrencyValue: Boolean = false;
    strSymbolValue: Boolean = false;
    strSuffixSymbol: Boolean = false;
    intNoOfDecimalPlace: Boolean = false;
    strCurrencyValueName: Boolean = false;
    strDecimalValueName: Boolean = false;
    EnterNumericvalues: Boolean = false;
}

export class CompanyBranchValidationUI {
    ComapnyBranchName: Boolean = false;
    PhoneNo: Boolean = false;
    EmailID: Boolean = false;
    PhoneNoFormat: Boolean = false;
    EmailIDFormat: Boolean = false;
    Status: Boolean = false;
    BranchType: Boolean = false;
}

export class EmployeeValidationUI {
    ComapnyBranchName: Boolean = false;
    PhoneNo: Boolean = false;
    EmailID: Boolean = false;
    PhoneNoFormat: Boolean = false;
    EmailIDFormat: Boolean = false;
    Status: Boolean = false;
    BranchType: Boolean = false;
    email_id: Boolean = false;
    last_name: Boolean = false;
    first_name: Boolean = false;
    first_nameValid: Boolean = false;
    last_nameValid :  Boolean = false;
    middle_nameValid :  Boolean = false;
    middle_name: Boolean = false;
    EmpIDWhite: Boolean = false;
    EmpId: Boolean = false;
    gender_value: Boolean = false;
    EmailIdV: Boolean = false;
    contact_no: Boolean = false;
    DateOfbirth: Boolean = false;
    DOBshouldnotbecurrentdateorfuturedate: Boolean = false;
    Shift: Boolean = false;
    Department: Boolean = false;
    Team: Boolean = false;
    lngm_designation_id: Boolean = false;
    Keyword: Boolean = false;
    user_login_id: Boolean = false;
    lngm_designation_level_id: Boolean = false;
    EmployeePFRate : Boolean = false;
    EmployerPFRate : Boolean = false;
    PayPointcount : Boolean = false;
    lngSupervisor_id : Boolean = false;
    PFNumber : Boolean = false;
    PFNumberValid : Boolean = false;
    EmployeePfrate : Boolean = false;
    EmployerPfrate : Boolean = false;
}

export class EmployeeInfoValidationUI {
    EmployeeName: Boolean = false;
}

export class EmployeeJobInformationUIValidationUI {
    GrossSalary:boolean = false;
    CommencementDate:boolean = false;
    UpdatesalaryConfig = false;
    employeeType: Boolean = false;
    categoryvalue: Boolean = false;
    ContactNo: Boolean = false;
    Notes: Boolean = false;
    EmployeeDOJ: Boolean = false;
    PhoneNo: Boolean = false;
    dateofjoinsValid: Boolean = false;
    jobtitleid: Boolean = false;
    strJobTitleName: Boolean = false;
    strpay_frequency_value: Boolean = false;
    lngm_designation_level_id: Boolean = false;
    lngm_designation_id: Boolean = false;
    lngSupervisor_id: Boolean = false;
    EnterNumericvalues: Boolean = false;
    EffectiveDateShouldbeCurrentorFuturedate: Boolean = false;
    SalaryEffectiveDate: Boolean = false;
    StartTime: Boolean = false;
    CheckValidEndTime: Boolean = false;
    EndTime: Boolean = false;
    GreaterEndTime: Boolean = false;
    GrossSalaryAmount: Boolean = false;
    GrossSalaryAmountDisable: Boolean = false;
    partimeduration: Boolean = false;
    OccupationCode :Boolean = false;
    DOBConfirmDate:Boolean = false;
    DOBConfirmDateWithDOJ:Boolean = false;
    DOBConfirmDateWithACPTDate:Boolean = false;
    CDConfirmDate:Boolean = false;
    SalaryEffectivewithDOJ =false;
    SalaryEffectivewithDOA=false;
    EffectivewithDOJ=false;
    EffectivewithDOA=false;    
    relivedateDOJ=false;   
    Relivedate=false;
}

export class EmployeeAddressValidationUI {
    straddress_type: Boolean = false;
    strLine1: Boolean = false;
    strCuntry_value: Boolean = false;
    strLine2: Boolean = false;
    EffectiveDateShouldbeCurrentorFuturedate: Boolean = false;
    EffectiveDateMandtory: Boolean = false;
}

export class EmployeeDepartmentValidationUI
{
    lngDt_department_id: Boolean = false;
    lngDt_team_id: Boolean = false;
    lngmDl_designation_level_id: Boolean = false;
    lngmDl_designation_id: Boolean = false;
}

export class EmployeeBankValidationUI {
    strBank_name: Boolean = false;
    strbranch_name: Boolean = false;
    strbranch_code: Boolean = false;
    intsalary_per: Boolean = false;
    strAccount_type_value: Boolean = false;
    strAccount_no: Boolean = false;
    UIEmployeeBankEffectiveDate: Boolean = false;
    EnterNumericvalues: Boolean = false;
    hundredpersent: Boolean = false;
    EffectiveDateShouldbeCurrentorFuturedate: Boolean = false;
}

export class ApprovalHierarchyConfigValidationUI {
    request_type_value: Boolean = false;
    approval_level_value: Boolean = false;
    designation_id: Boolean = false;
    designation_level_id: Boolean = false;
    waiting_period: Boolean = false;
    leavetype: Boolean = false;
}

export class EmployeeEducationValidationUI {
    strEucation_level_value: Boolean = false;
    strInstitution_name: Boolean = false;
    strLocation: Boolean = false;
    UIEmployeeEduPassedOutDate: Boolean = false;
    strGrade: Boolean = false;
    PaaedoutValid: Boolean = false;
    Country:Boolean = false;
}
export class EmployeeEmploymentHistoryValidationUI {
    strJob_title: Boolean = false;
    strOrganisation_name: Boolean = false;
    UIEmployeeEmpHisJoiningDate: Boolean = false;
    UIEmployeeEmpHisRelievingDate: Boolean = false;
    strLocation: Boolean = false;
    UIJoindate: Boolean = false;
    UIReliving: Boolean = false;
    Designation : Boolean = false;
    Country : Boolean = false;
    futuredate :Boolean = false;
}

export class EmployeeTrainingValidationUI {
    strdescription: Boolean = false;
    lngm_training_id: Boolean = false;
    lngm_training_type_id:boolean= false;
    formdata: boolean=false;
    TrainingDoc: Boolean = false;
    UIEmployeeTraingStartDate: Boolean = false;
    StartEndValid: Boolean = false;
    UIEmployeeTraingEndingDate: Boolean = false;
}
export class EmployeeDocumentValidationUI {
    strDocument_name: Boolean = false;
    formdata: Boolean = false;
    strdocument_type_name: Boolean = false;
    strother_training_name: Boolean = false;
}

export class AttendanceValidationUI {
    Intime: Boolean = false;
    CheckValidOutTime: Boolean = false;
    OutTime: Boolean = false;
    year: Boolean = false;
    monthly: Boolean = false;
    week: Boolean = false;
    Comments: Boolean = false;
    IsvalidInTime: Boolean = false;
    IsvalidOutTime: Boolean = false;
    IsCheckValidOutTime: Boolean = false;
    IsCheckValidInTime: Boolean = false;
    EmployeeID: Boolean = false;
}


export class AttendancemontylwiseValidationUI {
    year: Boolean = false;
    monthly: Boolean = false;
    week: Boolean = false;
    EmpId: Boolean = false;
}
export class MasterValidationUI {
    strjob_title_name: Boolean = false;
    strkyc_document_name: Boolean = false;
    strtraining_type_name: Boolean = false;
    description: Boolean = false;
    strbenifit_short_namelength: Boolean = false;
    strAttendance_type_name: Boolean = false;
    istrbreak_name: Boolean = false;
    idtmbreak_start: Boolean = false;
    idtmbreak_end: Boolean = false;
    CheckValidOutTime: Boolean = false;

    strdesignation_level_name: Boolean = false;
    strdesignation_name: Boolean = false;
    strdesignation_hierarchy: Boolean = false;
    intlevel_count:Boolean = false;
    intdesignatio_level_Count:Boolean = false;
    intpay_point_count: Boolean = false;
    intpay_point_count_limit: Boolean = false;
    strdesignation_hierarchy_Format: Boolean = false;
    strbelongs_to_value: Boolean = false;
    strbenifit_name: Boolean = false;

    EffectiveDateShouldbeCurrentorFuturedateForDesignationLevel: Boolean = false;
    strbenifit_short_name: Boolean = false;

    EffectiveDateShouldbeCurrentorFuturedateForDesignation: Boolean = false;

    EnterNumericvaluesForBenefit: Boolean = false;
    hundredpersent: Boolean = false;
    dcebenefit_percentage: Boolean = false;
    strdeduction_name: Boolean = false;
    dcededuction_percentage: Boolean = false;
    strdeduction_short_name: Boolean = false;
    strdeduction_short_namelength : Boolean = false;
    hundredpersentForBenefit: Boolean = false;
    strdocument_name: Boolean = false;
    strdocument_for_value: Boolean = false;

    streducation_level_name: Boolean = false;
    strLeaveType_name: Boolean = false;
    strPublicHolidaysForfutureDate: Boolean = false;
    strPublicHolidays_name: Boolean = false;
    intyear: Boolean = false;
    dtmholiday_date: Boolean = false;

    EnterNumericvaluesForDeduction: Boolean = false;
    hundredpersentForDeduction: Boolean = false;
}
export class TrainingValidationUI {
    description: Boolean = false;
    Trainingname: Boolean = false;
    BeginDateG: Boolean = false;
    BeginDate: Boolean = false;
}
export class ShiftConfigValidationUI {

    ShiftName: Boolean = false;
    WorkingDay: Boolean = false;
    WorkingDayS: Boolean = false;
    WorkingHours: Boolean = false;
    WorkingHoursneg: Boolean = false;

    strshift_name: Boolean = false;
    strweek_start_day_value: Boolean = false;
    SelectedMaxNonBreakWorkHours: Boolean = false;
    SelectedMinUnpaidBreakHours: Boolean = false;
    STD_ValidSatrtTime: Boolean = false;
    STD_ValidEndTime: Boolean = false;

    SelectedMaxBreakWorkHrs: Boolean = false;
    dcepay_percentage_non_working_day: Boolean = false;
}

export class AdjustmentRequestApprovalUI {
    Notes: Boolean = false;

}

export class DepartmentValidationUI {
    departmentname: Boolean = false;
    departmentcode: Boolean = false;
    activefrom: Boolean = false;
    status_value: Boolean = false;
    description: Boolean = false;
}
export class ReqApproverConfigValidationUI {
    request_type: Boolean = false;
    approval_level: Boolean = false;
    intrequest_type_id: Boolean = false;
    designation: Boolean = false;
    waiting_period: Boolean = false;
    description: Boolean = false;
}
export class RequesttypeUI {
    request_type_id: Boolean = false;
    reqeust_type_name: Boolean = false;
    company_id: Boolean = false;
    designation: Boolean = false;
    description: Boolean = false;

}

export class TeamValidationUI {
    Teamname: Boolean = false;
    Teamcode: Boolean = false;
    activefrom: Boolean = false;
    status_value: Boolean = false;
    description: Boolean = false;
}

export class EmployeeShiftConfigValidationUI {
    Department: Boolean = false;
    Shift: Boolean = false;
    FromDate: Boolean = false;
    Todate: Boolean = false;
    Team: Boolean = false;
    Employee: Boolean = false;
    FromDateG: Boolean = false;
}


export class BreakTimeConfigValidationUI {
    Teamname: Boolean = false;
    Teamcode: Boolean = false;
    activefrom: Boolean = false;
    status_value: Boolean = false;
    description: Boolean = false;
}



export class LeaveRequestUI {
    LeaveType: Boolean = false;
    FromDate: Boolean = false;
    ToDate: Boolean = false;
    ToDateReq: Boolean = false;
    FromDateReq: Boolean = false;
    email_id: Boolean = false;
    Days: Boolean = false;
    Applyto: Boolean = false;
    CCTo: Boolean = false;
    FromSession: Boolean = false;
    ToSession: Boolean = false;
    Balance: Boolean = false;
    Reason: Boolean = false;
    Attachment: Boolean = false;
    formdata: Boolean = false;
    Intime: Boolean = false;
    CheckValidOutTime: Boolean = false;
    validstartshifttime: Boolean = false;
    Validendshifttime: Boolean = false;
    request_by_employee_id: Boolean = false;
    year: Boolean = false;
    monthly: Boolean = false;
    week: Boolean = false;
    empid:Boolean = false;
}

export class LeaveRequestMonthwiseValidationUI {
    year: Boolean = false;
    monthly: Boolean = false;
    week: Boolean = false;

}

export class AttendancePolicyValidationUI {



}

export class LRAdjustmentValidationUI {
    LeaveRequestDate: Boolean = false;
    LeaveRequestId: Boolean = false;
    RequestByEmployeeId: Boolean = false;
    LeaveSeason: Boolean = false;
    MLeaveTypeId: Boolean = false;
    CheckValidOutTime: Boolean = false;
    Fromdate: Boolean = false;
    ToDate: Boolean = false;
    year: Boolean = false;
    monthly: Boolean = false;
    week: Boolean = false;
    email_id: Boolean = false;
    empid:Boolean=false;
}

  export class LeavePolicyUI
    {
        LeaveTypeName :Boolean=false;
        LeaveTypeCode :Boolean=false;
        LeaveCountMeasure:Boolean=false;
        CreditPeriod:Boolean=false;
        EligibleLeaveCount:Boolean=false;
        PaidLeaveCount:Boolean=false;
            BeginDate:Boolean=false;
            BeginDateG:Boolean=false;
            Status:Boolean=false;
            MinExp:Boolean=false;
            MaxExp:Boolean=false;
            ExpUnit:Boolean=false;
            ExpinCompany:Boolean=false;
            UnUtilizedLeaveBenefit:Boolean=false;
            Gender:boolean=false;
            MinCompulsoryLeave:boolean=false;
            decconsecutive_leave_day_count_more_than:boolean=false;
            decleave_day_count_more_than_in_cal_year:boolean=false;
            is_docchecked :boolean=false;

            compulsoryleavecountmeasure:boolean=false;
            utilizedleavecountmeasure:boolean=false;
            MaxLeaveEncashment:boolean=false;
            MaxAccumulation:boolean=false;
            APPlicableEmp:boolean=false;
            MinLeaveCount:boolean=false;
    }


export class ApprovalHierarchyUI {

    approval_level_value: Boolean = false;
    designation_id: Boolean = false;
    designation_level_id: Boolean = false;
    waiting_period: Boolean = false;
}
 export class SalaryConfigValidationUI {
        EffectiveDate : Boolean = false;;
        FutureDate : Boolean;
        PastDate :Boolean;
        //sal config approval
        Status : Boolean = false;
    }
export class SeverancePayConfigUI{
    min_exp: Boolean = false;
    effective_from: Boolean = false;
    exp_unit_value: Boolean = false;
}
export class SalaryBenefitDeductionValidationUI {
    lngemployee_id: Boolean = false;
    strpayment_mode_value: Boolean = false;
    intpayment_type_id: Boolean = false;
    dtmstart_date: Boolean = false;
    dtmend_date: Boolean = false;
    intno_of_times: Boolean = false;
    strbatch_type_value: Boolean = false;
    decamount: Boolean = false;
    dtend_Date: Boolean = false;
}

export class PaySlipValidationValidationUI {
    Year: Boolean = false;
    Month: Boolean = false;
    ViewPayslipYear: Boolean = false;
    ViewPayslipMonth: Boolean = false;
    payroll_payment_id: Boolean = false;
    PayrollDate : Boolean = false;
}

export class PayrollBatchValidationUI {
    dtmfrom_date : Boolean = false;
    dtmto_date: Boolean = false;
    strbatch_type_value: Boolean = false;
    strRun_status_value: Boolean = false;
    strPay_frequency_value: Boolean = false;
}