import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAlertComponent } from './common-alert.component';
import { MatDialogModule, MatButtonModule } from '@angular/material';



@NgModule({
  declarations: [CommonAlertComponent],
  entryComponents: [
    CommonAlertComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports:[
    CommonAlertComponent
  ]
})
export class CommonAlertModule { }
