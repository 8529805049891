
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})

export class AttendanceRequestApprovelService {

  constructor(public http: HttpClient) { }

  public GetAttendanceRequestApprovelInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetAttendanceRequestApprovelInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadAttendanceRequestApprovelOnDeilyMonthlyWeekly(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadAttendanceRequestApprovelOnDeilyMonthlyWeekly', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public CreateAttendanceRequestApprovel() {
    return this.http.get(AppConstants.BasePath + 'CreateAttendanceRequestApprovel').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public CreateNewAttendanceAdjustmentRequest() {
    return this.http.get(AppConstants.BasePath + 'CreateNewAttendanceAdjustmentRequest').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public SaveAttendanceAdjustmentRequestApproval(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveAttendanceAdjustmentRequestApprovel', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public RequestToCancelAttnAdjustReqToApprover(data: any): any {
    return this.http.post(AppConstants.BasePath + 'RequestToCancelAttnAdjustReqToApprover', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  

  public OpenAttendanceAdjustmentRequestAprrovel(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenAttendanceAdjustmentRequestAprrovel', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenAttendanceAdjustmentRequestDateDetail(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenAttendanceAdjustmentRequestDateDetail', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadAttendanceRequestForEmpTeamOnDeilyMonthlyWeekly(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadAttendanceRequestForEmpTeamOnDeilyMonthlyWeekly', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DownloadAttendanceAdjustmentRequestDocument(data: any): any {
    return this.http.post(AppConstants.BasePath + 'DownloadAttendanceAdjustmentRequestDocument', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
/////////////////////////////////////
public Holidayalertadjustmentteam(data: any) {
  return this.http.post(AppConstants.BasePath + 'Holidayalert', data).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler));
}
  public SendAttnAdjustApprovalRemainderMail() {
    return this.http.get(AppConstants.BasePath + 'SendAttnAdjustApprovalRemainderMail').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public SendTimesheetApprovalRemainderMail() {
    return this.http.get(AppConstants.BasePath + 'SendTimesheetApprovalRemainderMail').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  


  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }
  private ErrorHandler(error: Response | any) {
    let errorMSg: string;
    if (error instanceof Response) {
      const err = error || '';
      errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errorMSg = error.message ? error.message : error.toString();
    }
    return _throw(error);
  }

  public GetAttendanceApprovelInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetAttendanceApprovelInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadAttendancerequest(data: any): any {
    return this.http.post(AppConstants.BasePath + 'LoadAttendancerequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public createattendancereqapproval() {
    return this.http.get(AppConstants.BasePath + 'createattendancereqapproval').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveAttendanceRequestApprovel(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveAttendanceRequestApprovel', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

 public OpenAttendanceRequestAprrovel(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenAttendanceRequestAprrovel', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

/////////////////////////////attenreq
  public TimesheetrequestGetDepartBasedOnTeam(deptId: any) {
    return this.http.post(AppConstants.BasePath + 'GetDepartBasedOnTeam', deptId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public TimesheetrequestGetemployeebasedonteamanddept(deptId: any) {
    return this.http.post(AppConstants.BasePath + 'Getemployeebasedonteamanddept', deptId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  //////////////////////////////attenadj
  
  public AttenadjGetDepartBasedOnTeam(deptId: any) {
    return this.http.post(AppConstants.BasePath + 'GetDepartBasedOnTeam', deptId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public AttenadjGetemployeebasedonteamanddept(deptId: any) {
    return this.http.post(AppConstants.BasePath + 'Getemployeebasedonteamanddept', deptId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public SearchAttendenceRequest(data: any) {
    return this.http.post(AppConstants.BasePath + 'SearchAttendenceRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }
  public SearchAttendenceAdjustmentRequest(data: any) {
    return this.http.post(AppConstants.BasePath + 'SearchAttendenceAdjustmentRequest', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }
}