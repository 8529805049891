
import { Component, OnInit, Input } from '@angular/core';
import { SessionStorageService, LocalStorageService } from 'angular-web-storage';
import { isNullOrUndefined } from 'util';
import { LoginService } from '../../../services/login/login.service';
import { SidebarService } from '../../../services/common/sidebar.service';
import { Location } from '@angular/common';
import { SecurityServiceService } from '../../../services/common/security-service.service';
import { AlertService } from '../../../services/common/alert.service';
import { Router } from '@angular/router';
import { TitleServiceService } from 'src/app/services/common/title-service.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  title: String;
  UserName: any;
  User: any;
  Admin: boolean = false;
  SideDivValue: string = "";

  constructor(private titleService: TitleServiceService,
    public storage: SessionStorageService,
    public loginService: LoginService,
    public sidebarService: SidebarService,
    private securityService: SecurityServiceService,
    public location: Location,
    public alert: AlertService,
    public LocalStorage: LocalStorageService,
    public sessionStorage: SessionStorageService,
    public router: Router,

  ) {


  }

  ngOnInit() {
    this.titleService.getTitle().subscribe(appTitle => this.title = appTitle);

    this.User = this.storage.get("UserName");
    if (!isNullOrUndefined(this.User)) {
      this.UserName = this.User;
    }
    else {
      this.UserName = this.loginService.UserName;
    }

    this.SideDivValue = this.storage.get("SideBarDiv");

    if (!isNullOrUndefined(this.SideDivValue)) {
      if (this.SideDivValue == 'Admin') {
        this.Admin = false;
      }
      if (this.SideDivValue == 'Application') {
        this.Admin = true;
      }
      
    }
  }
  public ngAfterViewInit(): void {
  }

  public ngAfterViewChecked() {
    this.securityService.ApplySecurity();
  }

  ShowLink(Type: string) {
    if (Type == 'Admin') {
      this.storage.set("SideBarDiv", 'Admin');
      this.sidebarService.ShowAdminDiv();
      this.Admin = false;
    }

    if (Type == 'Application') {
      this.storage.set("SideBarDiv", 'Application');
      this.sidebarService.ShowApplicationDiv();
      this.Admin = true;
    }
  }

  Back() {
    this.location.back();
  }
  Logout() {
    this.alert.clearAlert();
    this.LocalStorage.clear();
    this.sessionStorage.clear();
    this.sessionStorage.set("SideBarDiv", '');
    this.sessionStorage.set("IsLoggedIn", false);
    this.router.navigate(['/login']);
  }
}

