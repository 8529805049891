import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-web-storage';
import { isUndefined, isNullOrUndefined } from 'util';
import { interval } from 'rxjs/internal/observable/interval';
import { map, catchError } from 'rxjs/operators';
import { AppConstants } from '../../common/common';


@Injectable({
  providedIn: 'root'
})
export class SecurityServiceService {
  public UserRights = [];
  private MasterDiv: any;
  constructor(
    private http: HttpClient,
    private LocalStorage: LocalStorageService
  ) { }

  public ApplySecurity(): any {
    let strUserRights = this.LocalStorage.get('UserRightsList');
    this.MasterDiv = document.getElementsByClassName('MasterViewClass');

    if (this.MasterDiv == null || this.MasterDiv.length == 0) {
      return;
    }

    for (let i = 0; i < this.MasterDiv.length; i++) {
      let ViewName = this.MasterDiv[i].id;
      this.UserRights = strUserRights;

      let IsScreenPresent = false;
      if (this.UserRights != null && this.UserRights.length > 0) {
        for (let j = 0; j < this.UserRights.length; j++) {
          let ScreenRights = this.UserRights[j];
          if (ScreenRights.iViewName == 'fullaccess') {
            return;
          }

          if (ScreenRights.iViewName == ViewName) {
            let Permission = ScreenRights.iResourcePermission;
            if (isUndefined(Permission) || Permission == null || Permission == '') {
              break;
            }
            this.UpdateScreenSecurity(Permission);
            IsScreenPresent = true;
            break;
          }
        }
        if (!IsScreenPresent) {
          let Elmnt = document.getElementById(ViewName);
          if (Elmnt != null) {
            Elmnt.remove();
          }
        }
      }
    }
  }

  public UpdateScreenSecurity(Per: any) {
    if (isNullOrUndefined(Per) || Per == null || Per == '') {
      return;
    }

    for (let i = 0; i < Per.length; i++) {
      let elm = document.getElementById(Per[i].iResourceName);
      if (elm != null) {
        if (Per[i].iPermission == '0') {
          elm.remove();
        }
      }
    }
  }
  public InterValCheckSession(): any {
    interval(900000).pipe(
      map((x) => {
        this.CheckSession();

      })
    );
  }

  public CheckSession() {
    return this.http.get(AppConstants.BasePath + 'CheckSession').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  private SuccessHandler(response: Response) {
    let data = response;
    return data || {};
  }

  private ErrorHandler(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || '';
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return errMsg;
  }
}

