import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateControlComponent } from './date-control/date-control.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material';
import { DateFormatModule } from './date-control/date-format.module';

@NgModule({
  declarations: [
    DateControlComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DateFormatModule,
    MatIconModule
  ],
  exports: [DateControlComponent]
})
export class FormInputModule { }
