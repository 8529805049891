import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class SalarybenefitdeductionService {

  constructor(public http: HttpClient) { }

  public GetSalaryBenefitDeductionDetailInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetSalaryBenefitDeductionDetailsInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  // public GetSalaryBenefitDeductionSearch() {
  //   return this.http.get(AppConstants.BasePath + 'GetSalaryBenefitDeductionSearchObject').pipe(
  //     map(this.SuccessHandler), catchError(this.ErrorHandler));
  // }
  public GetSalaryBenefitDeductionSearch(Data: any): any { 
    return this.http.post(AppConstants.BasePath + 'GetSalaryBenefitDeductionSearchObject', Data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public searchSalaryBenefitDeductions(SalaryBenefitDeductionSearch: any): any {
    return this.http.post(AppConstants.BasePath + 'searchSalaryBenefitDeductions', SalaryBenefitDeductionSearch).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public CreateNewSalaryBenefitDeduction(): any {
    return this.http.get(AppConstants.BasePath + 'createNewSalaryBenefitDeduction').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public openSelectedSalaryBenefitDeduction(SalaryBenefitDeductionid: number): any {
    return this.http.post(AppConstants.BasePath + 'openSelectedSalaryBenefitDeduction', SalaryBenefitDeductionid).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteSalaryBenefitDeduction(SalaryBenefitDeductionid: number): any {
    return this.http.post(AppConstants.BasePath + 'DeleteSalaryBenefitDeduction', SalaryBenefitDeductionid).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  public GetEmployeeJobInformationByEmpID(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetEmployeeJobInformationByEmpID', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  public GetPaymentType(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetPaymentTypeDDL', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public saveSalaryBenefitDeduction(SalaryBenefitDeductionUI: any): any {
    return this.http.post(AppConstants.BasePath + 'saveSalaryBenefitDeduction', SalaryBenefitDeductionUI).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveSalaryBenefitDeductionApprovel(SalaryBenefitDeductionUI: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveSalaryBenefitDeductionApprovel', SalaryBenefitDeductionUI).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SendSalaryBenefitDeductionApprovalRemainderMail(): any {
    return this.http.get(AppConstants.BasePath + 'SendSalaryBenefitDeductionApprovalRemainderMail').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }
  private ErrorHandler(error: Response | any) {
    let errorMSg: string;
    if (error instanceof Response) {
      const err = error || '';
      errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errorMSg = error.message ? error.message : error.toString();
    }
    return _throw(error);
  }

}
