import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(public http: HttpClient) {


  }


  public GetAuditSearch() {
    return this.http.get(AppConstants.BasePath + 'GetAuditSearch').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }
  public SearchAuditLog(AuditLog: any) {
    return this.http.post(AppConstants.BasePath + 'SearchAuditLog', AuditLog).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  public OpenAuditLog(AuditLog: any) {
    return this.http.post(AppConstants.BasePath + "OpenAuditLog", AuditLog).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }




  private SuccessHandler(response: Response) {
    let data = response;
    return data || {};
  }

  private ErrorHandler(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || '';
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return _throw(error);   
  }
}
