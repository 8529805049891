import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './view/common/login/login.component';
import { LayoutComponent } from './view/common/layout/layout.component';
import { ResetpasswordComponent } from './view/common/resetpassword/resetpassword.component';
import { AttendanceComponent } from './view/application/attendance/attendance/attendance.component';
import { RequestApproverConfigurationComponent } from './view/master/request-approver-configuration/request-approver-configuration.component';
import { EmployeeShiftConfigComponent } from './view/master/employee/employee-shift-config/employee-shift-config.component';
import { AttendanceAdjustRequestComponent } from './view/application/attendance/attendance-adjust-request/attendance-adjust-request.component';
//import { CanDeactivateGuardService } from './common/can-deactivate-guard.service';
//import { DeactiveGuard, DeactiveGuardForTeam } from './common/deactive.guard';
import { AttAdjustReqListEmpComponent } from './view/application/attendance/att-adjust-req-team/att-adjust-req-emp.component';
import { AttendanceTeamComponent } from './view/application/attendance/attendance-team/attendance-team.component';
import { ConfigMenuComponent } from './view/common/config-menu/config-menu.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', component: LoginComponent },
  { path: 'PayrollResetPassword', component: ResetpasswordComponent },
  {
    path: '',
    component: LayoutComponent,
    children: [
      // --admin--

      { path: 'userSearch', loadChildren: () => import('./view/admin/user-search/user-search.module').then(m => m.UserSearchModule) },
      { path: 'userDetail', loadChildren: () => import('./view/admin/user-detail/user-detail.module').then(m => m.UserDetailModule) },
      { path: 'RoleSearch', loadChildren: () => import('./view/admin/role-search/role-search.module').then(m => m.RoleSearchModule) },
      { path: 'RoleDetail', loadChildren: () => import('./view/admin/role-detail/role-detail.module').then(m => m.RoleDetailModule) },
      { path: 'configDetail', loadChildren: () => import('./view/admin/configuration-detail/config-detail-component.module').then(m => m.ConfigDetailComponentModule) },
      { path: 'configuration-search', loadChildren: () => import('./view/admin/configuration-search/configuration-search.module').then(m => m.ConfigurationSearchModule) },
      { path: 'GroupSearch', loadChildren: () => import('./view/admin/group-search/group-search.module').then(m => m.GroupSearchModule) },
      { path: 'GroupDetail', loadChildren: () => import('./view/admin/group-detail/group-detail.module').then(m => m.GroupDetailModule) },
      { path: 'AuditLog', loadChildren: () => import('./view/admin/audit-log/audit-log.module').then(m => m.AuditLogModule) },
      { path: 'ProcessLog', loadChildren: () => import('./view/admin/process-log/process-log.module').then(m => m.ProcessLogModule) },
      { path: 'CompanySearch', loadChildren: () => import('./view/application/company/company-search/company-search.module').then(m => m.CompanySearchModule) },
      { path: 'CompanyDetail', loadChildren: () => import('./view/application/company/company-detail/company-detail.module').then(m => m.CompanyDetailModule) },
      { path: 'EmailTracking', loadChildren: () => import('./view/admin/email-tracking/email-tracking.module').then(m => m.EmailTrackingModule) },
      { path: 'resource', loadChildren: () => import('./view/admin/resource/resource.module').then(m => m.ResourceModule) },
      { path: 'companyapprovalsearch', loadChildren: () => import('./view/admin/company-approval-search/company-approval-search.module').then(m => m.CompanyApprovalSearchModule) },
      { path: 'report', loadChildren: () => import('./view/application/employee/report/report.module').then(m => m.ReportModule) },

      { path: 'AddEmployee', loadChildren: () => import('./view/application/employee/add-employee/add-employee.module').then(m => m.AddEmployeeModule) },
      { path: 'EmployeeSearch', loadChildren: () => import('./view/application/employee/view-employee-search/view-employee-search.module').then(m => m.ViewEmployeeSearchModule) },
      //{ path: 'Attendance', loadChildren: () => import('./view').then(m=>m.RoleSearchModule) },  
      { path: 'AttendancePolicy', loadChildren: () => import('./view/application/attendance/attendance-policy/attendance-policy.module').then(m => m.AttendancePolicyModule) },
      //{ path: 'AttendanceAdjustmentRequest', loadChildren: () => import('./view/admin/configuration-detail/config-detail-component.module').then(m=>m.ConfigDetailComponentModule) },  
      { path: 'DepartmentTeam', loadChildren: () => import('./view/master/common/department-team/department-team.module').then(m => m.DepartmentTeamModule) },
      { path: 'JobDetails', loadChildren: () => import('./view/master/employee/job-details/job-details.module').then(m => m.JobDetailsModule) },
      { path: 'TrainingTypeTraining', loadChildren: () => import('./view/master/common/training-type-training/training-type-training.module').then(m => m.TrainingTypeTrainingModule) },
      { path: 'AttendaceTimeType', loadChildren: () => import('./view/master/attendance/attendace-time-type/attendace-time-type.module').then(m => m.AttendaceTimeTypeModule) },
      { path: 'BenefitsDeductions', loadChildren: () => import('./view/master/employee/benefits-deductions/benefits-deductions.module').then(m => m.BenefitsDeductionsModule) },
      { path: 'Others', loadChildren: () => import('./view/master/employee/others/others.module').then(m => m.OthersModule) },
      { path: 'AdjustmentRequestApprovel', loadChildren: () => import('./view/application/attendance/attendance-adjust-request-approval/attendance-adjust-request-approval.module').then(m => m.AttendanceAdjustRequestApprovalModule) },
      { path: 'AdjustmentRequestApprovel/detail', loadChildren: () => import('./view/application/attendance/attendance-adjust-request-approval-detail/attendance-adjust-request-approval-detail.module').then(m => m.AttendanceAdjustRequestApprovalDetailModule) },
      { path: 'attendanceRequestApprovel', loadChildren: () => import('./view/application/attendance/attendance-request-approval/attendance-request-approval.module').then(m => m.AttendanceRequestApprovalModule) },
      { path: 'attendanceRequestApprovel/detail', loadChildren: () => import('./view/application/attendance/attendance-request-approval-detail/attendance-request-approval-detail.module').then(m => m.AttendanceRequestApprovalDetailModule) },
      { path: 'LeaveRequest', loadChildren: () => import('./view/application/leave/leave-request/leave-request.module').then(m => m.LeaveRequestModule) },
      { path: 'LeaveRequest/detail', loadChildren: () => import('./view/application/leave/leave-request-detail/leave-request-detail.module').then(m => m.LeaveRequestDetailModule) },
      { path: 'FlexTimeExcessDutyApproval', loadChildren: () => import('./view/application/attendance/flex-time-excess-duty-approval/flex-time-excess-duty-approval.module').then(m => m.FlexTimeExcessDutyApprovalModule) },
      { path: 'LeaveRequestApproval', loadChildren: () => import('./view/application/leave/leave-request-approval/leave-request-approval.module').then(m => m.LeaveRequestApprovalModule) },
      { path: 'LeaveRequestApproval/detail', loadChildren: () => import('./view/application/leave/leave-request-approval-detail/leave-request-approval-detail.module').then(m => m.LeaveRequestApprovalDetailModule) },
      { path: 'MLeaveType', loadChildren: () => import('./view/master/leave/mleave-type.module').then(m => m.MleaveTypeModule) },
      { path: 'MHoliday', loadChildren: () => import('./view/master/holidays/holidays.module').then(m => m.HolidaysModule) },
      { path: 'ApprovalHierarchyConfig', loadChildren: () => import('./view/master/attendance/approval-hierarchy-config/approval-hierarchy-config.module').then(m => m.ApprovalHierarchyConfigModule) },
      { path: 'AttnAdjtReqEmpTeamList', loadChildren: () => import('./view/application/attendance/attn-adjt-req-emp-team-list/attn-adjt-req-emp-team-list.module').then(m => m.AttnAdjtReqEmpTeamListModule) },
      { path: 'Myprofile', loadChildren: () => import('./view/application/employee/my-profile/my-profile.module').then(m => m.MyProfileModule) },
      { path: 'LeavePolicy', loadChildren: () => import('./view/application/leave/leave-policy-config/leave-policy-config.module').then(m => m.LeavePolicyConfigModule) },
      { path: 'LeaveAdjustmentRequest', loadChildren: () => import('./view/application/leave/leave-adjustment-request/leave-adjustment-request.module').then(m => m.LeaveAdjustmentRequestModule) },
      { path: 'LeaveAdjustmentRequest/detail', loadChildren: () => import('./view/application/leave/leave-adjustment-request-detail/leave-adjustment-request-detail.module').then(m => m.LeaveAdjustmentRequestDetailModule) },
      { path: 'LeaveAdjustmentApproval', loadChildren: () => import('./view/application/leave/leave-adjustment-approval/leave-adjustment-approval.module').then(m => m.LeaveAdjustmentApprovalModule) },
      { path: 'LeaveAdjustmentApproval/detail', loadChildren: () => import('./view/application/leave/leave-adjustment-approval-detail/leave-adjustment-approval-detail.module').then(m => m.LeaveAdjustmentApprovalDetailModule) },
      { path: 'LeaveEligibilityForEmp', loadChildren: () => import('./view/application/leave/leave-eligibility-for-emp/leave-eligibility-for-emp.module').then(m => m.LeaveEligibilityForEmpModule) },
      { path: 'LeaveRequestTeam', loadChildren: () => import('./view/application/leave/leave-request-team/leave-request-team.module').then(m => m.LeaveRequestTeamModule) },
      { path: 'LeaveRequestTeam/detail', loadChildren: () => import('./view/application/leave/leave-request-team-detail/leave-request-team-detail.module').then(m => m.LeaveRequestTeamDetailModule) },
      { path: 'LeaveAdjustmentRequestTeam', loadChildren: () => import('./view/application/leave/leave-adjustment-request-team/leave-adjustment-request-team.module').then(m => m.LeaveAdjustmentRequestTeamModule) },
      { path: 'LeaveAdjustmentRequestTeam/detail', loadChildren: () => import('./view/application/leave/leave-adjustment-request-team-detail/leave-adjustment-request-team-detail.module').then(m => m.LeaveAdjustmentRequestTeamDetailModule) },
      { path: 'BenefitsAndDeductions', loadChildren: () => import('./view/application/salary/benefits-and-deductions/benefits-and-deductions.module').then(m => m.BenefitsAndDeductionsModule) },
      { path: 'salary-config', loadChildren: () => import('./view/application/salary/sal-config/sal-config.module').then(m => m.SalConfigModule) },
      { path: 'salary-configruation-list', loadChildren: () => import('./view/application/salary/salary-configuration-list/salary-configuration-list.module').then(m => m.SalaryConfigurationListModule) },
      { path: 'SeverancePayConfiguration', loadChildren: () => import('./view/application/salary/severance-pay-configuration/severance-pay-configuration.module').then(m => m.SeverancePayConfigurationModule) },
      { path: 'BenefitsAndDeductionsApproval', loadChildren: () => import('./view/application/salary/benefits-and-deductions-approval/benefits-and-deductions-approval.module').then(m => m.BenefitsAndDeductionsApprovalModule) },
      { path: 'BenefitsAndDeductionsApproval/detail', loadChildren: () => import('./view/application/salary/benefits-and-deductions-approval-detail/benefits-and-deductions-approval-detail.module').then(m => m.BenefitsAndDeductionsApprovalDetailModule) },
      { path: 'CompanyProfile', loadChildren: () => import('./view/application/company/company-profile/company-profile.module').then(m => m.CompanyProfileModule) },
      { path: 'Attendance', component: AttendanceComponent },
      { path: 'AttendanceAdjustmentRequest', component: AttendanceAdjustRequestComponent },
      { path: 'requestapprovalconfig', component: RequestApproverConfigurationComponent },

      { path: 'EmployeeShiftConfig', component: EmployeeShiftConfigComponent }, 
      { path: 'user/new', component: AttendanceAdjustRequestComponent },
      { path: 'AttAdjustReqListEmp', component: AttAdjustReqListEmpComponent },
      { path: 'PayrollBatchRun', loadChildren: () => import('./view/application/Payroll/trail-final-payroll-generation/trail-final-payroll-generation.module').then(m => m.TrailFinalPayrollGenerationModule) },
      { path: 'PayrollBatchRun/detail', loadChildren: () => import('./view/application/Payroll/trail-final-payroll-generation-detail/trail-final-payroll-generation-detail.module').then(m => m.TrailFinalPayrollGenerationDetailModule) },
      { path: 'AttendanceTeam', component: AttendanceTeamComponent },
      { path: 'FinalPayrollApproval', loadChildren: () => import('./view/application/Payroll/final-payroll-approval/final-payroll-approval.module').then(m => m.FinalPayrollApprovalModule) },
      { path: 'FinalPayrollApproval/detail', loadChildren: () => import('./view/application/Payroll/final-payroll-approval-detail/final-payroll-approval-detail.module').then(m => m.FinalPayrollApprovalDetailModule) },
      { path: 'salary-configruation-approval', loadChildren: () => import('./view/application/salary/salary-configruation-approval/salary-configruation-approval.module').then(m => m.SalaryConfigruationApprovalModule) },
      { path: 'payslip-generation', loadChildren: () => import('./view/application/Payroll/payslip-generation/payslip-generation.module').then(m => m.PayslipGenerationModule) },
      { path: 'view-payslip', loadChildren: () => import('./view/application/Payroll/view-payslip-team/view-payslip-team.module').then(m => m.ViewPayslipTeamModule) },
      { path: 'employee-dashboard', loadChildren: () => import('./view/dashboard/employee-dashboard/employee-dashboard.module').then(m => m.EmployeeDashboardModule) },
      { path: 'Management-dashboard', loadChildren: () => import('./view/dashboard/management-dashboard/management-dashboard.module').then(m => m.ManagementDashboardModule) },
      { path: 'emp-leave-balance', loadChildren: () => import('./view/application/leave/employee-leave-balance/employee-leave-balance.module').then(m => m.EmployeeLeaveBalanceModule) },
      { path: 'ConfigMenu', component: ConfigMenuComponent },
      { path: 'benefitsAndDeductionReport', loadChildren: () => import('./view/dashboard/report/benefit-and-deduction-report/benefit-and-deduction-report.module').then(m => m.BenefitAndDeductionReportModule) },

    ]
  },

  { path: '**', redirectTo: '/login' },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
