import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
interface myData {
  obj: object
}
@Injectable({
  providedIn: 'root'
})

export class TitleServiceService {

  private title = new BehaviorSubject<String>('');

  private title$ = this.title.asObservable();

  private showpdf = new BehaviorSubject<boolean>(true);

  private showpdf$ = this.showpdf.asObservable();

  private titlepdf =new BehaviorSubject<String>('');
  private titlepdf$ = this.titlepdf.asObservable();

  private ModuleName = new BehaviorSubject<String>('');
  private ModuleName$ = this.ModuleName.asObservable();
  private subtitle = new BehaviorSubject<String>('');
  private subtitle$ = this.subtitle.asObservable();
  constructor(private http: HttpClient) {
  }

  getData() {
    return this.http.get<myData>('')
  }

  setshowpdf(pdf: boolean) {
    this.showpdf.next(pdf);
  }

  getshowpdf(): Observable<boolean> {
    return this.showpdf$;
  }

  getTitle(): Observable<String> {
    return this.title$;
  }

  setTitle(title: String) {
    this.title.next(title);
  }

 



  setTitleforPdf(titlepdf: String) {
    this.titlepdf.next(titlepdf);
  }

  getTitleforPdf(): Observable<String> {
    return this.titlepdf$;
  }

  setsubTitle(title: String) {
    this.subtitle.next(title);
  }

  getsubTitle(): Observable<String> {
    return this.subtitle$;
  }
  setModuleName(ModuleName: String) {
    this.ModuleName.next(ModuleName);
  }

  getModuleName(): Observable<String> {
    return this.ModuleName$;
  }

}


