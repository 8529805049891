import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  constructor(public http: HttpClient) { }

  public GetEmployeeDetailDashboardInitialData() {
    return this.http.get(AppConstants.BasePath + "GetEmployeeDetailDashboardInitialData").pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

   //all Dashboard  employe detail 

   public GenerateEmployeeDetailDashBoard(Dashboard: any) {
    return this.http.post(AppConstants.BasePath + "GenerateEmployeeDetailDashBoard", Dashboard).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }
// end

 // Dashboard 1'st Attendance employe detail  (std,Excess Duty hrs)

  public GenerateEmployeeAttenDetailDashBoard(Dashboard: any) {
    return this.http.post(AppConstants.BasePath + "GenerateEmployeeAttenDetailDashBoard", Dashboard).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }
// end

// Dashboard 2'st Attendance employe detail  (Excess Duty hrs)

public GenerateEmployeeExcessDutyDetailDashBoard(Dashboard: any) {
  return this.http.post(AppConstants.BasePath + "GenerateEmployeeExcessDutyDetailDashBoard", Dashboard).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler)
  );
}
// end

// Dashboard 4 Utilized Leave Balance

public GenerateEmployeeUtilizedLeaveBalDashBoard(Dashboard: any) {
  return this.http.post(AppConstants.BasePath + "GenerateEmployeeUtilizedLeaveBalDashBoard", Dashboard).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler)
  );
}
// end

// Dashboard 6 MonthWise Salary 

public GenerateEmployeeMonthWiseSalaryDashBoard(Dashboard: any) {
  return this.http.post(AppConstants.BasePath + "GenerateEmployeeMonthWiseSalaryDashBoard", Dashboard).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler)
  );
}
// end

// Management Dashboard 

public GenerateManagementAllDetailDashBoard(Dashboard: any) {
  return this.http.post(AppConstants.BasePath + "GenerateManagementAllDetailDashBoard", Dashboard).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler)
  );
}

// DashBoard 2 

public GenerateTotalSalaryProcessfortnightlyMMDB(Dashboard: any) {
  return this.http.post(AppConstants.BasePath + "GenerateTotalSalaryProcessfortnightlyMMDB", Dashboard).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler)
  );
}

// DashBoard 3

public GenerateTotalBenefitPaidbyfortnightlyMMDB(Dashboard: any) {
  return this.http.post(AppConstants.BasePath + "GenerateTotalBenefitPaidbyfortnightlyMMDB", Dashboard).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler)
  );
}

// DashBoard 4

public GenerateTotalDeductionsPaidbyfortnightlyMMDB(Dashboard: any) {
  return this.http.post(AppConstants.BasePath + "GenerateTotalDeductionsPaidbyfortnightlyMMDB", Dashboard).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler)
  );
}

// DashBoard 5

public GenerateTotalEmpTakenLeaveCurrentVsPreviousMonthMMDB(Dashboard: any) {
  return this.http.post(AppConstants.BasePath + "GenerateTotalEmpTakenLeaveCurrentVsPreviousMonthMMDB", Dashboard).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler)
  );
}

// DashBoard 6

public GenerateTotalleavemonthwiseMMDB(Dashboard: any) {
  return this.http.post(AppConstants.BasePath + "GenerateTotalleavemonthwiseMMDB", Dashboard).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler)
  );
}

// DashBoard 7

public GenerateTotalPendingApprovalLeavemonthwiseMMDB(Dashboard: any) {
  return this.http.post(AppConstants.BasePath + "GenerateTotalPendingApprovalLeavemonthwiseMMDB", Dashboard).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler)
  );
}

// DashBoard 8

public GenerateTotalPendingExcessDutymonthwiseMMDB(Dashboard: any) {
  return this.http.post(AppConstants.BasePath + "GenerateTotalPendingExcessDutymonthwiseMMDB", Dashboard).pipe(
    map(this.SuccessHandler), catchError(this.ErrorHandler)
  );
}





  private SuccessHandler(response: Response) {
    let data = response;
    return data || {};
  }

  private ErrorHandler(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || '';
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
   return _throw(error);
  }

}
