import { Directive, HostListener, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appToggleClass]'
})
export class ToggleClassDirective {
  @Input() appToggleClass = '';
  constructor(
    private el: ElementRef,
    public renderer: Renderer2
  ) { }
  @HostListener('click') onClick() {
    // tslint:disable-next-line: max-line-length
    (this.el.nativeElement.parentElement.classList.contains('opened')) ? this.renderer.removeClass(this.el.nativeElement.parentElement, 'opened') : this.renderer.addClass(this.el.nativeElement.parentElement, 'opened');
  }

}
