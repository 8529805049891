import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { AppConstants } from '../../common/common';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  SelectedUserId: number;

  constructor(public http: HttpClient) {

  }

  public GetUserInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetUserInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetUserSearch() {
    return this.http.get(AppConstants.BasePath + 'GetUserSearch').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public SearchUser(UserSearch: any): any {
    return this.http.post(AppConstants.BasePath + 'searchUsers', UserSearch).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public CreateNewUser(): any {
    return this.http.get(AppConstants.BasePath + 'CreateNewUser').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenUser(UserId: number): any {
    return this.http.post(AppConstants.BasePath + 'OpenUser', UserId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  public OpenUserRole(UserRoleId: number): any {
    return this.http.post(AppConstants.BasePath + 'OpenUserRole', UserRoleId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveUser(User: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveUser', User).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public ResetUserPassword(User: any): any {
    return this.http.post(AppConstants.BasePath + 'ResetUserPassword', User).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public CreateNewBranchLink(): any {
    return this.http.get(AppConstants.BasePath + 'CreateNewBranchLink').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveBranchLink(UserTeamLink: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveBranchLink', UserTeamLink).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenBranchLink(UserTeamlinkId: number): any {
    return this.http.post(AppConstants.BasePath + 'OpenBranchLink', UserTeamlinkId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public CreateNewUserGroup(): any {
    return this.http.get(AppConstants.BasePath + 'CreateNewUserGroup').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public SaveUserGroup(UserGroup: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveUserGroup', UserGroup).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenUserGroup(UserGroupId: number): any {
    return this.http.post(AppConstants.BasePath + 'OpenUserGroup', UserGroupId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public LoadDefaultLandingPage() {
    return this.http.get(AppConstants.BasePath + 'LoadDefaultLandingPages').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public DeleteBranchLink(UserTeamlinkId: number): any {
    return this.http.post(AppConstants.BasePath + 'DeleteBranchLink', UserTeamlinkId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public DeleteUserGroup(UserGroupId: number): any {
    return this.http.post(AppConstants.BasePath + 'DeleteUserGroup', UserGroupId).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  
  public ForgotUserPaaswordReset(username) {
    return this.http.post(AppConstants.BasePath + 'ForgotUserPaaswordReset', username).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

  private SuccessHandler(response: Response) {
    let data = response;
    return data || {};
  }

  private ErrorHandler(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || '';
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return _throw(error);
  }
  public GetBranchBasedOnLocation(Types: any): any {
    return this.http.post(AppConstants.BasePath + 'GetBranchBasedOnLocation', Types).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  // muthu
  public CreateNewDepartmentlink(): any {
    return this.http.get(AppConstants.BasePath + 'CreateNewDepartmentlink').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public SaveDepartmentLink(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveDepartmentLink', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenDepartmentLink(Departmentlinkid: number): any {
    return this.http.post(AppConstants.BasePath + 'OpenDepartmentLink', Departmentlinkid).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public DeleteDepartmentLink(Departmentlinkid: number): any {
    return this.http.post(AppConstants.BasePath + 'DeleteDepartmentLink', Departmentlinkid).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }



  public GetTeambyDepartment(depvalue: any): any {
    return this.http.post(AppConstants.BasePath + 'GetTeambyDepartment', depvalue).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public ForgotUserPasswordResetForEmployee(username) {
    return this.http.post(AppConstants.BasePath + 'ForgotUserPasswordResetForEmployee', username).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler)
    );
  }

}

