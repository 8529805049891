import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../common/common';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(public http: HttpClient) { }


  public GetCompanySearch() {
    return this.http.get(AppConstants.BasePath + 'getCompanySearchObject').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public searchCompany(data: any): any {
    return this.http.post(AppConstants.BasePath + 'searchCompany', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public ActivateOnRegisteredcompany(data: any): any {
    return this.http.post(AppConstants.BasePath + 'ActivateOnRegisteredcompany', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetCompanyApprovalSearch() {
    return this.http.get(AppConstants.BasePath + 'GetCompanyapprovalSearchObject').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public CompanyApprovalSearch(data: any): any {
    return this.http.post(AppConstants.BasePath + 'CompanyApprovalSearch', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public GetCompanyDetailInitialData() {
    return this.http.get(AppConstants.BasePath + 'GetCompanyDetailInitialData').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public OpenCompany(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenCompany', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public CreateNewCompany() {
    return this.http.get(AppConstants.BasePath + 'CreateNewCompany').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }
  public SaveCompany(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveCompany', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public CreateNewCompanyBranch() {
    return this.http.get(AppConstants.BasePath + 'CreateNewCompanybranch').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));

  }
  public SaveCompanyBranch(data: any): any {
    return this.http.post(AppConstants.BasePath + 'SaveCompanyBranch', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public OpenCompanyBranch(data: any): any {
    return this.http.post(AppConstants.BasePath + 'OpenCompanyBranch', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  public GetSymbolbyCurrency(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetSymbolbyCurrency', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }



  public LoadCompanyDetail() {
    return this.http.get(AppConstants.BasePath + 'LoadCompanyDetail').pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }

  public GetValuesbyCurrency(data: any): any {
    return this.http.post(AppConstants.BasePath + 'GetValuesbyCurrency', data).pipe(
      map(this.SuccessHandler), catchError(this.ErrorHandler));
  }


  private SuccessHandler(responce: Response) {
    let data = responce;
    return data || {};
  }
  private ErrorHandler(error: Response | any) {
    let errorMSg: string;
    if (error instanceof Response) {
      const err = error || '';
      errorMSg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errorMSg = error.message ? error.message : error.toString();
    }
    return _throw(error);
  }
}
