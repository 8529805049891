import { Directive, ElementRef, HostListener } from '@angular/core';
import * as moment from 'moment';
@Directive({
  selector: '[appDateInput]'
})
export class DateInputDirective {

  constructor(
    private el: ElementRef
  ) { }
  private specialKeys = [
    'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', 'Meta', 'Control'
  ];
  private highmonth = [
    '01', '03', '05', '07', '08', '10', '12'
  ];

  private checkNumberPattern(value: string) {
    return String(value).match(new RegExp(/^\d+$/));
  }
  private checkDateMonth(day, month) {
    // tslint:disable-next-line: max-line-length
    return this.highmonth.indexOf(month) !== -1 ? true : ((month === '02' && parseFloat(day) < 30) ? true : (month === '02' && parseFloat(day) > 29) ? false : true);
  }
  @HostListener('keydown', ['$event']) onInputChange(event) {
    const key = event.key;
    // console.log(event);
    if (this.specialKeys.indexOf(key) !== -1) {
      return;
    }
    if (event.ctrlKey || event.metaKey) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (key === 'Backspace') {
      let newvalue = current;
      if (newvalue.length === 3 || newvalue.length === 6) {
        // event.preventDefault();
        newvalue = newvalue.substr(0, newvalue.length - 1);
      }
      /*remove last character*/
      newvalue = newvalue.substr(0, newvalue.length);
      // console.log(newvalue);
      this.el.nativeElement.value = newvalue;
    } else if (key !== '/' && !this.checkNumberPattern(key)) {
      event.preventDefault();
    } else {
      if (current.length === 10) {
        event.preventDefault();
      }
      if (key === '/') {
        let newvalue = '';
        event.preventDefault();
        if (current.length === 1) {
          // console.log(next);
          newvalue = '0' + next;
          this.el.nativeElement.value = newvalue;
        }
        if (current.length === 4) {

          // console.log(current);
          const lastvalue = current.charAt(current.length - 1);
          newvalue = current.substring(0, current.length - 1) + '0' + lastvalue;
          const arr = newvalue.split('/');
          if (this.checkDateMonth(arr[0], arr[1])) {
            this.el.nativeElement.value = newvalue + key;
          }
        }
      } else {
        if (next.length === 2 && parseFloat(next) > 31) {
          event.preventDefault();
        } else if (next.length === 2 || next.length === 5) {
          if (next.length === 5) {
            const arr = next.split('/');
            if (parseFloat(arr[1]) > 12) {
              event.preventDefault();
              return;
            }
            if (!this.checkDateMonth(arr[0], arr[1])) {
              event.preventDefault();
              return;
            }
          }
          next += '/';
          event.preventDefault();
          this.el.nativeElement.value = next;
        } else if (next.length === 10) {
          if (!moment(next, 'DD/MM/YYYY').isValid()) {
            event.preventDefault();
          }
        }
      }
    }
  }
}
