import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../../services/common/alert.service';
import { Router } from '@angular/router';
import { ResetPasswordUI } from '../../../common/validationUI';
import { isNullOrUndefined } from 'util';
import { LocalStorageService } from 'angular-web-storage';

import { AuthenticationService } from '../../../services/login/authentication.service';
import { ErrorMsg, ErrorMsgTab } from '../../../common/common';
import { PreloadService } from 'src/app/services/common/preload.service';
import * as CryptoJS from 'crypto-js';  
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {

  public LoggedInUser: any;
  errorMsgTab: ErrorMsgTab;
  MsgTabList: any[];
  DuplicateMsgTabList: any[];
  errorCount: number;
  infoCount: number;
  warningCount: number;
  ResetPasswordUI: ResetPasswordUI;
  isActive: boolean =true;
  isActiveCon: boolean =true;
  constructor(
    public alert: AlertService,
    public router: Router,
  //  public ResetPasswordUI: ResetPasswordUI,
    public LocalStorage: LocalStorageService,
    public preLoader: PreloadService,
    public authenticationService: AuthenticationService,
  ) {

  }



  ngOnInit() {
    this.setpass();
    this.preLoader.show(false);
    //this.GetResetpasswordFromURl();

  }
  eye(){
    this.isActive = !this.isActive;
    }
  eyeCon(){
      this.isActiveCon = !this.isActiveCon;
      }
  setpass() {
    this.alert.clearAlert();
    this.LoggedInUser = {};
  }
  id: any;
  value: any;
  key: any;
  LinkCreatedDate : any;
  ConfigSec : any;
  // plainText:string;  
   encryptText: string;  
  // encPassword: string;  
  // decPassword:string;  
  CreatedateDecrypt: string;  
  ConfigSecdecrypt:string; 


  // public GetResetpasswordFromURl() {
  //   var search = [];
  //   search[0] = window.location.search
  //     .split(/[&||?]/)
  //     .filter(function (x) { return x.indexOf("=") > -1; })
  //     .map(function (x) { return x.split(/=/); })
  //     .map(function (x) {
  //       x[1] = x[1].replace(/\+/g, " ");
  //       return x;
  //     })
  //     .reduce(function (acc, current) {
  //       acc[current[0]] = current[1];
  //       return acc;
  //     }, {});
  //   if (search[0].value == "Y") {
  //     this.key = search[0].key;
  //     this.value = search[0].value;
  //     this.id = search[0].id;
  //     this.LinkCreatedDate = search[0].CRDT;
  //   this.LocalStorage.set("LoggedInUser", this.LoggedInUser);
  //   }
  //   else {
  //     this.router.navigate(['/login']);
  //   }
  // }


  loginOpen() {
    this.router.navigate(['/login']);
  }
  setNewUserPassword() {
    this.alert.clearAlert();
    this.ResetPasswordUI = new ResetPasswordUI();
    if (this.IsValidPassword()) {
      if (this.LoggedInUser.newpassword == this.LoggedInUser.confirmpassword) {
        var user = this.LocalStorage.get("LoggedInUser");
        if (user != null && !isNullOrUndefined(user)) {
          this.preLoader.show(true);
          var NewPasswordDetail = { Password: this.LoggedInUser.newpassword,user_login_id: user.user_login_id
          };
          console.log("NewPassword", NewPasswordDetail);
          this.authenticationService.SaveResetUserPassword(NewPasswordDetail).subscribe(
            data => { this.ResetUserPasswordSuccess(data) }, error => { this.ResetUserPasswordError(error) });
        }
      }
      else {
        this.ResetPasswordUI.NewPasswordConfirPassworddoesntmatch = true;
      }
    }


  }
  ResetUserPasswordSuccess(data: any) {
    if (!isNullOrUndefined(data)) {
      console.log("sucs", data);
      this.LoggedInUser = data;
      // this.LoggedInUser = {};
      if (this.LoggedInUser.ilstErrorMessages == null || this.LoggedInUser.ilstErrorMessages.length == 0) {
        this.ShowInfoAlert(this.LoggedInUser.iInfoMessage);
      }
      else {
        let i = this.LoggedInUser.ilstErrorMessages.length
        this.ShowErrorAlert(i, this.LoggedInUser.ilstErrorMessages, 1087);
        this.ShowErrorMessageInTab(this.LoggedInUser.ilstErrorMessages);
      }
    }
    this.preLoader.show(false);
  }
  ResetUserPasswordError(error: any) {
    console.log(error);
    this.GetCommonErrorMessage();
  }

  IsValidPassword() {
    this.ResetPasswordUI = new ResetPasswordUI();
    let errorCount = 0;
    let errMsg = ""
    var pwd = this.LoggedInUser.newpassword;
    var cmpwd = this.LoggedInUser.confirmpassword;
    var minLength = 6;
    var maxLength = 15;
    this.alert.clearAlert();
    if (pwd == "" || isNullOrUndefined(pwd)) {
      errMsg = "Password is required"
      this.ResetPasswordUI.Password = true;
      errorCount++;

    }

    if (cmpwd == "" || isNullOrUndefined(cmpwd)) {
      errMsg = "Confirm Password is required"
      this.ResetPasswordUI.ConfirmPassword = true;
      errorCount++;
    }

    if (pwd.length < minLength || pwd.length > maxLength) {
      errorCount++;
      this.ResetPasswordUI.Passwordlengthbe6to15characters = true;
      // errMsg = "Password length should be 6 to 15 characters."
      // errors.push(errMsg);
    }
    var regEx1 = new RegExp("^(?=.*[A-Z])");
    if (!regEx1.test(pwd)) {
      errorCount++;
      this.ResetPasswordUI.PleasePasswordoneUppercase = true;
      // errMsg = "Please ensure your password is having at least one Uppercase character(A to Z).";
      // errors.push(errMsg);
    }
    var regEx2 = new RegExp("^(?=.*[0-9])");
    if (!regEx2.test(pwd)) {
      errorCount++;
      this.ResetPasswordUI.Pleaseeastonenumeric = true;
      // errMsg = "Please ensure your password is having at least one numeric character(0 to 9).";
      // errors.push(errMsg);
    }
    var regEx3 = new RegExp("^(?=.*[ @#$_!])");
    if (!regEx3.test(pwd)) {
      errorCount++;
      this.ResetPasswordUI.Pleasepasswordleastonespecial = true;
      // errMsg = "Please ensure your password is having at least one special character Like(@,#,$,_,!)";
      // errors.push(errMsg);
    }
    if (errorCount > 0) {
      return false;
    }
    else {
      return true;
    }
  }


  GetCommonErrorMessage() {
    this.ShowErrorAlert(1, ErrorMsg.ErrorMessage, 1087);
    //  this.preLoader.show(false);
  }

  ShowSuccessAlert(Msg: any) {
    this.alert.ShowSuccessAlert(true);
    this.alert.SetSuccessMsg(Msg);
  }

  ShowInfoAlert(Msg: any) {
    this.alert.ShowInfoAlert(true);
    this.alert.SetInfoMsg(Msg);
  }


  ShowErrorPanelAlert(Msg: any) {
    this.alert.ShowErrorPanelAlert(true);
    this.alert.SetErrorPanelMsg(Msg);
  }


  ShowWarinngAlert(Msg: any) {
    this.alert.ShowWarningAlert(true);
    this.alert.SetWarningMsg(Msg);
  }


  ShowErrorAlert(ErrorCount: any, ErrorMsg: any, ErrorCode: any) {
    let ErrMsg = '';
    if (ErrorCount > 2) {
      ErrMsg = 'Error has been occured.'
    }
    else {
      ErrMsg = ErrorMsg;
    }
    this.alert.SetErrorCode(ErrorCode);
    this.alert.SetErrorDescription(ErrMsg);
    this.alert.ShowErrorAlert(true);
  }

  ShowErrorMessageInTab(ErrorMessage: any) {

    // if (isNullOrUndefined(this.MsgTabList)) {
    this.MsgTabList = [];
    // }
    if (isNullOrUndefined(this.errorMsgTab)) {
      this.errorMsgTab = new ErrorMsgTab();
    }

    if (!isNullOrUndefined(ErrorMessage) && ErrorMessage.length > 0) {
      for (let i = 0; i < ErrorMessage.length; i++) {
        this.errorMsgTab.Date = new Date();
        this.errorMsgTab.MessageDescription = ErrorMessage[0];
        this.errorMsgTab.Type = 'Error';
        this.MsgTabList.push(this.errorMsgTab);
      }

      this.GetMessageCount();
    }

    this.DuplicateMsgTabList = this.MsgTabList;
  }
  GetMessageCount() {
    this.errorCount = 0;
    this.infoCount = 0;
    this.warningCount = 0;

    if (!isNullOrUndefined(this.MsgTabList) && this.MsgTabList.length > 0) {
      for (let i = 0; i < this.MsgTabList.length; i++) {

        if (this.MsgTabList[i].Type == 'Error') {
          this.errorCount = this.errorCount + 1;
        }
        else if (this.MsgTabList[i].Type == 'Warn') {
          this.warningCount = this.warningCount + 1;
        }
        else if (this.MsgTabList[i].Type == 'Info') {
          this.infoCount = this.infoCount + 1;
        }
      }

    }
  }
}
